import { FC } from 'react';

import ValidatedTextField, { ValidatedTextFieldProps } from './ValidatedTextField';

export interface NumericFieldProps {
  onChange: (fieldName: string, number: number) => void;
  value: string | number;
}

type ValidatedNumericFieldProps = NumericFieldProps & Omit<ValidatedTextFieldProps, 'onChange' | 'onKeyDown'>;

const ValidatedNumericField: FC<ValidatedNumericFieldProps> = ({ name, value, onChange, ...rest }) => {
  const handleChange = (newValue: string) => {
    onChange(name, Number(newValue));
  };

  return (
    <ValidatedTextField
      name={name}
      type="number"
      value={value}
      tabIndex={0}
      onKeyDown={(event) => {
        const validKey = [
          'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab'
        ].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code !== 'Space';
        if (!validKey) {
          event.preventDefault();
        }
        return validKey;
      }}
      onChange={(event) => handleChange(event.target.value)}
      {...rest}
    />
  );
};

export default ValidatedNumericField;
