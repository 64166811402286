import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

import { ReactComponent as BanSignLineSvg } from '../../assets/uxwing-icon-svgs/ban-sign-line.svg';
import { ReactComponent as ExcelFileSvg } from '../../assets/uxwing-icon-svgs/excel-file.svg';
import { ReactComponent as FileLineSvg } from '../../assets/uxwing-icon-svgs/file-line.svg';
import { ReactComponent as ImageFileSvg } from '../../assets/uxwing-icon-svgs/image-file.svg';
import { ReactComponent as PdfFileSvg } from '../../assets/uxwing-icon-svgs/pdf-file.svg';
import { ReactComponent as PowerpointSvg } from '../../assets/uxwing-icon-svgs/powerpoint.svg';
import { ReactComponent as TextFileLineSvg } from '../../assets/uxwing-icon-svgs/text-file-line.svg';
import { ReactComponent as WordFileSvg } from '../../assets/uxwing-icon-svgs/word-file.svg';
import { ReactComponent as ArchiveFileSvg } from '../../assets/uxwing-icon-svgs/archive-file.svg';
import { ReactComponent as CubeSvg } from '../../assets/uxwing-icon-svgs/cube.svg';
import { ReactComponent as GlobeSvg } from '../../assets/uxwing-icon-svgs/globe.svg';
import { ReactComponent as GlobeNetworkSvg } from '../../assets/uxwing-icon-svgs/globe-network.svg';
import { ReactComponent as BanSignSvg } from '../../assets/uxwing-icon-svgs/ban-sign.svg';
import { ReactComponent as DatabaseSvg } from '../../assets/uxwing-icon-svgs/database.svg';
import { ReactComponent as TableSvg } from '../../assets/uxwing-icon-svgs/table.svg';
import { ReactComponent as MultipleLayersSvg } from '../../assets/uxwing-icon-svgs/multiple-layers.svg';
import { ReactComponent as BigQuerySvg } from '../../assets/icons/bigquery-512-color.svg';
import { ReactComponent as GeoPackageSvg } from '../../assets/uxwing-icon-svgs/empty-box.svg';
import { ReactComponent as SitemapSvg } from '../../assets/uxwing-icon-svgs/sitemap.svg';
import { ReactComponent as JsonSvg } from '../../assets/uxwing-icon-svgs/json-file-icon.svg';
import { ReactComponent as ConformanceReportSvg } from '../../assets/uxwing-icon-svgs/audit-report-survey-icon.svg';
import { ReactComponent as XmlSvg } from '../../assets/uxwing-icon-svgs/xml-file-icon.svg';

export const createIcon = (displayName: string, Svg: FC<React.SVGProps<SVGSVGElement>>, defaultColor?: string): FC<SvgIconProps> => {
  const icon: FC<SvgIconProps> = ({ htmlColor = defaultColor, ...rest }) => (
    <SvgIcon htmlColor={htmlColor} {...rest}>
      <Svg />
    </SvgIcon>
  );
  icon.displayName = displayName;
  return icon;
};

export const UxAccessFileIcon = createIcon('UxAccessFileIcon', DatabaseSvg, '#af2032');
export const UxAutoCadIcon = createIcon('UxAutoCadIcon', FileLineSvg, '#931516');
export const UxBentlyV8Icon = createIcon('UxBentlyV8Icon', FileLineSvg, '#002A44');
export const BigQueryIcon = createIcon('GoogleBigQueryIcon', BigQuerySvg);
export const UxCsvIcon = createIcon('UxCsvIcon', TextFileLineSvg, '#1F7244');
export const UxDefaultDataStoreIcon = createIcon('UxDefaultDataStoreIcon', CubeSvg);
export const UxErrorIcon = createIcon('UxErrorIcon', BanSignLineSvg, '#000000');
export const UxExcelFileIcon = createIcon('UxExcelFileIcon', ExcelFileSvg, '#4cb050');
export const UxFileIcon = createIcon('UxFileIcon', FileLineSvg, '#1aad61');
export const UxGeoPackageIcon = createIcon('UxGeoPackageIcon', GeoPackageSvg, '#273746');
export const UxGlobeIcon = createIcon('UxGlobeIcon', GlobeSvg, 'darkblue');
export const UxGlobeNetworkIcon = createIcon('UxGlobeNetworkIcon', GlobeNetworkSvg, 'darkblue');
export const UxImageIcon = createIcon('UxImageIcon', ImageFileSvg, '#5c1aad');
export const UxMariaDbDatabaseIcon = createIcon('UxMariaDbDatabaseIcon', DatabaseSvg, '#003445');
export const UxMultipleLayersIcon = createIcon('UxMultipleLayersIcon', MultipleLayersSvg, '#273746');
export const UxMySqlDbDatabaseIcon = createIcon('UxMySqlDbDatabaseIcon', DatabaseSvg, '#00758f');
export const UxNotSupportedIcon = createIcon('UxNotSupportedIcon', BanSignSvg);
export const UxOracleDatabaseIcon = createIcon('UxOracleDatabaseIcon', DatabaseSvg, 'red');
export const UxPdfFileIcon = createIcon('UxPdfFileIcon', PdfFileSvg, '#db143b');
export const UxPostgresDatabaseIcon = createIcon('UxPostgresDatabaseIcon', DatabaseSvg, '#326690');
export const UxPowerpointFileIcon = createIcon('UxPowerpointFileIcon', PowerpointSvg, '#d06a51');
export const UxSqlServerIcon = createIcon('UxSqlServerIcon', DatabaseSvg, 'grey');
export const UxTableIcon = createIcon('UxTableIcon', TableSvg);
export const UxTextFileIcon = createIcon('UxTextFileIcon', TextFileLineSvg, '#273746');
export const UxWordFileIcon = createIcon('UxWordFileIcon', WordFileSvg, '#2472ba');
export const UxZipFileIcon = createIcon('UxZipFileIcon', ArchiveFileSvg, '#EB984E');
export const UxSitemapIcon = createIcon('UxSitemapIcon', SitemapSvg, '#000000');
export const UxJsonIcon = createIcon('UxJsonIcon', JsonSvg, '#000000');
export const UxConformanceReportIcon = createIcon('UxconformanceReportIcon', ConformanceReportSvg, '#000000');
export const UxXmlIcon = createIcon('UxXmlIcon', XmlSvg, '#000000');
