import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, ButtonGroup, Typography, Tooltip, Skeleton, styled } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LastPageIcon from '@mui/icons-material/LastPage';
import GpsOffIcon from '@mui/icons-material/GpsOff';

import { DefaultButton } from '../../../../../../../components';
import { ObjectReport } from '../../../../../../../types';
import { dataStoreNameFromPath, dateTimeFormat } from '../../../../../../../util';
import { intl } from '../../../../../../../Internationalization';

import ReportDescription from './ReportDescription';

interface ReportPanelProps {
  objectReport?: ObjectReport;
  reportCount: number;
  reportStart: number;
  loading: boolean;
  setReportStart: (reportStart: number) => void;
  zoomToFeature: () => void;
}

const ReportPanelContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1, 1),
  overflowY: 'scroll',
  height: '100%'
}));

const ReportPanel: FC<ReportPanelProps> = ({ objectReport, reportCount, reportStart, loading, setReportStart, zoomToFeature }) => {
  const disablePagination = reportCount === 0;
  const disableBackNavigation = disablePagination || reportStart === 0;
  const disableForwardNavigation = disablePagination || reportStart >= reportCount - 1;

  const renderObjectReport = () => {
    if (loading) {
      return (
        <ReportPanelContainer>
          <Skeleton height={40} width="50%" />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
        </ReportPanelContainer>
      );
    }

    if (objectReport) {
      return (
        <ReportPanelContainer>
          <Typography variant="h6" gutterBottom>
            <FormattedMessage
              id="openSubmission.map.menu.reportPanel.pagination"
              defaultMessage="Feature {current} of {reportCount}"
              values={{ current: reportStart + 1, reportCount }}
            />
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            <FormattedMessage
              id="openSubmission.map.menu.reportPanel.featureClass"
              defaultMessage="Feature class: {name}"
              values={{ name: objectReport.className }}
            />
          </Typography>
          {
            objectReport.reports && objectReport.reports.map(({ label, values }, index) => {
              return (
                <Box py={1} key={`${label}-${index}`}>
                  <Typography variant="subtitle2">{label}</Typography>
                  {
                    values.map(({ value, dataType }) => {
                      if (dataType === 'Timestamp') {
                        return (
                          <Typography variant="body2" key={value}>{dateTimeFormat(value)}</Typography>
                        );
                      }
                      return (
                        <Typography variant="body2" key={value}>{value}</Typography>
                      );
                    })
                  }
                </Box>
              );
            })
          }
          {
            objectReport.nonconformances && objectReport.nonconformances.map(({ path, description }) => (
              <Box py={1} key={path} className="non-conformance">
                <Tooltip title={path}>
                  <Typography variant="subtitle2">{dataStoreNameFromPath(path)}</Typography>
                </Tooltip>
                <ReportDescription description={description} />
              </Box>
            ))
          }
        </ReportPanelContainer>
      );
    }

    return (
      <Box display="flex" flexDirection="column" justifyContent="space-evenly" alignItems="center" minHeight={125} m={1}>
        <GpsOffIcon />
        <Typography variant="subtitle1">
          <FormattedMessage
            id="openSubmission.map.menu.reportPanel.noReport"
            defaultMessage="No object report available for this task"
          />
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      id="map-control-tabpanel-report"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '0', /* IMPORTANT: you need this for non-chrome browsers */
      }}
    >
      <Box p={1} justifyContent="flex-start" display="flex" flexWrap="wrap">
        <ButtonGroup color="primary" fullWidth>
          <DefaultButton
            id="first-report"
            disabled={disableBackNavigation}
            onClick={() => setReportStart(0)}
            aria-label={intl.formatMessage({
              id: 'openSubmission.map.menu.reportPanel.firstFeature.ariaLabel',
              defaultMessage: 'First feature'
            })}
          >
            <FirstPageIcon />
          </DefaultButton>
          <DefaultButton
            id="previous-report"
            disabled={disableBackNavigation}
            onClick={() => setReportStart(reportStart - 1)}
            aria-label={intl.formatMessage({
              id: 'openSubmission.map.menu.reportPanel.previousFeature.ariaLabel',
              defaultMessage: 'Previous feature'
            })}
          >
            <NavigateBeforeIcon />
          </DefaultButton>
          <DefaultButton
            id="locate-report"
            disabled={disablePagination || !objectReport}
            onClick={zoomToFeature}
            aria-label={intl.formatMessage({
              id: 'openSubmission.map.menu.reportPanel.locateFeature.ariaLabel',
              defaultMessage: 'Locate feature'
            })}
          >
            <GpsFixedIcon />
          </DefaultButton>
          <DefaultButton
            id="next-report"
            disabled={disableForwardNavigation}
            onClick={() => setReportStart(reportStart + 1)}
            aria-label={intl.formatMessage({
              id: 'openSubmission.map.menu.reportPanel.nextFeature.ariaLabel',
              defaultMessage: 'Next feature'
            })}
          >
            <NavigateNextIcon />
          </DefaultButton>
          <DefaultButton
            id="last-report"
            disabled={disableForwardNavigation}
            onClick={() => setReportStart(reportCount - 1)}
            aria-label={intl.formatMessage({
              id: 'openSubmission.map.menu.reportPanel.lastFeature.ariaLabel',
              defaultMessage: 'Last feature'
            })}
          >
            <LastPageIcon />
          </DefaultButton>
        </ButtonGroup>
      </Box>
      {renderObjectReport()}
    </Box>
  );
};

export default ReportPanel;
