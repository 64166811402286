import { FC } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { Box } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';

import { AppTabs, AppDecorator, RouterTabs, RouterTab } from '../../../components';
import { intl } from '../../../Internationalization';
import { useRouterTab } from '../../../hooks';

import SystemHeader from '../SystemHeader';
import ServerSettings from './settings/ServerSettings';
import UserSettings from './settings/UserSettings';
import SyncMonitor from './sync-monitor/SyncMonitor';

const LdapRouting: FC = () => {
  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="ldap-tabs" value={routerTab}>
      <RouterTab
        id="server-settings-tab"
        value="server_settings"
        label={intl.formatMessage({
          id: 'ldap.routing.tabs.serverSettings',
          defaultMessage: 'Server Settings',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="user-settings-tab"
        value="user_settings"
        label={intl.formatMessage({
          id: 'ldap.routing.tabs.userSettings',
          defaultMessage: 'User Settings',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="sync-monitor-tab"
        value="sync_monitor"
        label={intl.formatMessage({
          id: 'ldap.routing.tabs.syncMonitor',
          defaultMessage: 'Sync Monitor',
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  return (
    <AppDecorator renderTabs={renderTabs}>
      <SystemHeader
        title={intl.formatMessage({
          id: 'ldap.routing.title',
          defaultMessage: 'LDAP Configuration'
        })}
        icon={GroupIcon}
      />
      <AppTabs />
      <Box p={3}>
        <Routes>
          <Route path="server_settings" element={<ServerSettings />} />
          <Route path="user_settings" element={<UserSettings />} />
          <Route path="sync_monitor" element={<SyncMonitor />} />
          <Route path="*" element={<Navigate to="server_settings" replace />} />
        </Routes>
      </Box>
    </AppDecorator>
  );
};

export default LdapRouting;
