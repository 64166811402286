import { FC, useContext, useEffect, useState } from 'react';

import * as SpecificationApi from '../../../../../api/specification';
import * as AssignmentSavedMappingApi from '../../../../../api/assignmentSavedMapping';
import { extractErrorMessage } from '../../../../../api/endpoints';

import { useErrorBlock } from '../../../../../contexts/error-block';
import { AppDecorator, TabsSkeleton } from '../../../../../components';
import { intl } from '../../../../../Internationalization';
import { DataStoreConfigDetail, SavedMappingDetail, SavedMappingInputDetail, SessionSchema } from '../../../../../types';
import { useRequiredParams } from '../../../../../hooks';

import { MyAssignmentContext } from '../../MyAssignmentContext';
import { SavedMappingContext } from './SavedMappingContext';
import SavedMappingRouting from './SavedMappingRouting';

interface SavedMappingContextData {
  savedMapping: SavedMappingDetail;
  dataStoreConfigs: DataStoreConfigDetail[];
  sessionSchema: SessionSchema;
  specificationValid: boolean;
}

const SavedMapping: FC = () => {
  const { savedMappingKey } = useRequiredParams<{ savedMappingKey: string }>();
  const { raiseError } = useErrorBlock();

  const myAssignmentContext = useContext(MyAssignmentContext);
  const { assignmentKey, specification } = myAssignmentContext;

  const [savedMappingContextData, setSavedMappingContextData] = useState<SavedMappingContextData>();

  useEffect(() => {
    const fetchSchemaMappingData = async () => {
      try {
        const [
          { data: savedMappingData },
          { data: dataStoreConfigsData },
          { data: sessionSchemaData },
          specificationValidationResult
        ] = await Promise.all([
          AssignmentSavedMappingApi.getSavedMapping(assignmentKey, savedMappingKey),
          SpecificationApi.getDataStoreConfigs(specification.key),
          SpecificationApi.getSessionSchema(specification.key),
          SpecificationApi.isSpecificationValid(specification.key)
        ]);
        setSavedMappingContextData({
          savedMapping: savedMappingData,
          dataStoreConfigs: dataStoreConfigsData,
          sessionSchema: sessionSchemaData,
          specificationValid: specificationValidationResult
        });
      } catch (error: any) {
        raiseError(extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'myAssignment.savedMapping.loadError',
            defaultMessage: 'Failed to fetch saved mapping'
          })
        ));
      }
    };

    fetchSchemaMappingData();
  }, [assignmentKey, raiseError, savedMappingKey, specification.key]);

  const updateSavedMapping = (savedMapping: SavedMappingDetail) => {
    setSavedMappingContextData((prevContextData) => prevContextData && ({ ...prevContextData, savedMapping }));
  };

  const updateSavedMappingInput = (dataStorePath: string, input?: SavedMappingInputDetail) => {
    setSavedMappingContextData((prevContextData) => {
      if (!prevContextData) {
        return prevContextData;
      }
      const updatedSavedMappingInputs = [...prevContextData.savedMapping.inputs].filter((savedMapping) => (
        savedMapping.dataStorePath !== dataStorePath
      ));

      if (input) {
        updatedSavedMappingInputs.push(input);
      }

      return {
        ...prevContextData,
        savedMapping: {
          ...prevContextData.savedMapping,
          inputs: updatedSavedMappingInputs
        }
      };
    });
  };

  if (savedMappingContextData) {
    return (
      <SavedMappingContext.Provider
        value={{
          ...myAssignmentContext,
          ...savedMappingContextData,
          updateSavedMapping,
          updateSavedMappingInput
        }}
      >
        <SavedMappingRouting />
      </SavedMappingContext.Provider>
    );
  }

  return (
    <AppDecorator renderTabs={() => <TabsSkeleton tabCount={3} />} />
  );
};

export default SavedMapping;
