import { useContext } from 'react';
import { Container } from '@mui/material';

import SupplierSettingsForm from './SupplierSettingsForm';
import { SupplierContext } from './SupplierContext';

function SupplierConfiguration() {
  const supplierContext = useContext(SupplierContext);

  return (
    <Container maxWidth="md" id="supplier-configuration" disableGutters>
      <SupplierSettingsForm
        supplierDetail={supplierContext.supplier}
        onSupplierUpdated={supplierContext.supplierUpdated}
      />
    </Container>
  );
}

export default SupplierConfiguration;
