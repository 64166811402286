import { SvgIconProps } from "@mui/material";
import { MediaDetail, PageResponse, SimplePageRequest } from "./shared";

import SuccessIcon from '../components/icons/SuccessIcon';
import AutorenewInfoIcon from '../components/icons/AutorenewInfoIcon';
import HourglassInfoIcon from '../components/icons/HourglassInfoIcon';
import CancelledIcon from '../components/icons/CancelledIcon';
import FailedIcon from '../components/icons/FailedIcon';
import { intl } from "../Internationalization";

export type UserImportJobsRequest = SimplePageRequest;

export type UserImportJobsResponse = PageResponse<UserImportJobDetail>;

export enum UserImportJobState {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED'
}

export interface UserImportJobStateMetadata {
  label: string;
  icon: React.ComponentType<SvgIconProps>;
}

export const USER_IMPORT_JOB_STATE_METADATA: { [type in UserImportJobState]: UserImportJobStateMetadata } = {
  PENDING: {
    label: intl.formatMessage({
      id: "userImport.jobStateMetadata.pending",
      defaultMessage: "Pending"
    }),
    icon: HourglassInfoIcon
  },
  RUNNING: {
    label: intl.formatMessage({
      id: "userImport.jobStateMetadata.running",
      defaultMessage: "Running"
    }),
    icon: AutorenewInfoIcon
  },
  CANCELLED: {
    label: intl.formatMessage({
      id: "userImport.jobStateMetadata.cancelled",
      defaultMessage: "Cancelled"
    }),
    icon: CancelledIcon
  },
  COMPLETE: {
    label: intl.formatMessage({
      id: "userImport.jobStateMetadata.complete",
      defaultMessage: "Complete"
    }),
    icon: SuccessIcon
  },
  FAILED: {
    label: intl.formatMessage({
      id: "userImport.jobStateMetadata.failed",
      defaultMessage: "Failed"
    }),
    icon: FailedIcon
  },
};

export interface UserImportJobDetail {
  id: number;
  state: UserImportJobState;
  createdAt: string;
  startedAt: string;
  finishedAt: string;
  cancelledAt: string;
  added: number;
  skipped: number;
  data: MediaDetail;
  log: MediaDetail;
}

export interface EvaluatedImportUser {
  user: ImportUser;
  fieldErrors: { [K in keyof ImportUser]?: string[] };
}

export interface ImportUser {
  name?: string;
  email?: string;
  supplier?: string;
  receiver?: string;
  activate?: string;
}
