import { FC, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Typography, Box } from '@mui/material';
import ForwardIcon from '@mui/icons-material/Forward';

import * as AuthApi from '../../api/auth';
import { ApplicationContext } from '../../contexts/application';

const SignInSso: FC = () => {
  const { applicationDetails: { identityProviders } } = useContext(ApplicationContext);

  return (
    <>
      <Typography variant="body2" gutterBottom>
        <FormattedMessage id="preauth.signInSso.title" defaultMessage="Sign in with your identity provider" />
      </Typography>
      <Box id="sign-in-sso-form" display="flex" flexDirection="column" my={2}>
        {
          identityProviders.map(identityProvider => (
            <Button
              id={identityProvider.key}
              sx={{
                mt: 2,
                '&:first-child': {
                  mt: 0
                }
              }}
              variant="contained"
              href={AuthApi.generateIdentityProviderAuthenticationUrl(identityProvider)}
              color="primary"
              startIcon={<ForwardIcon />}
              fullWidth
              size="large"
            >
              <FormattedMessage
                id="preauth.signInSso.signInButton"
                values={{ name: identityProvider.name }}
                defaultMessage="Sign In with {name}"
              />
            </Button>
          ))
        }
      </Box>
    </>
  );
};

export default SignInSso;
