import { FC } from "react";

import { Tooltip, SxProps } from "@mui/material";

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

import { intl } from "../../../Internationalization";

import { MappingStatus } from './schemaMappingUtils';

interface MappingStatusIconProps {
  status: MappingStatus;
  iconSize?: 'inherit' | 'medium' | 'small' | 'large';
  sx?: SxProps;
}

const MappingStatusIcon: FC<MappingStatusIconProps> = ({ sx = [], status, iconSize }) => {
  if (status === MappingStatus.ERROR) {
    return (
      <Tooltip
        title={intl.formatMessage({
          id: 'schemaMapper.dataStoreClasses.mappingRequired',
          defaultMessage: 'Mapping required'
        })}
      >
        <ErrorOutlineOutlinedIcon
          sx={[{ color: 'error.main' }, ...(Array.isArray(sx) ? sx : [sx])]}
          fontSize={iconSize}
        />
      </Tooltip>
    );
  }

  if (status === MappingStatus.WARNING) {
    return (
      <Tooltip
        title={intl.formatMessage({
          id: 'schemaMapper.dataStoreClasses.mappingIncomplete',
          defaultMessage: 'Mapping incomplete'
        })}
      >
        <ReportProblemOutlinedIcon
          sx={[{ color: 'warning.main' }, ...(Array.isArray(sx) ? sx : [sx])]}
          fontSize={iconSize}
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={intl.formatMessage({
        id: 'schemaMapper.dataStoreClasses.mappingComplete',
        defaultMessage: 'Mapping complete'
      })}
    >
      <CheckCircleOutlinedIcon
        sx={[{ color: 'success.main' }, ...(Array.isArray(sx) ? sx : [sx])]}
        fontSize={iconSize}
      />
    </Tooltip>
  );
};

export default MappingStatusIcon;

