import { AxiosPromise } from 'axios';
import { AXIOS } from './endpoints';
import { ValuePercentage, GroupedDataSet, DateCount, OffsetDateTimePercentage, PeriodStatsRequest } from '../types';

export function ruleConformance(assignmentKey: string, request: PeriodStatsRequest): AxiosPromise<ValuePercentage[]> {
  return AXIOS.post(`/stats/assignment/${assignmentKey}/rule_conformance`, request);
}

export function submissionCounts(assignmentKey: string, request: PeriodStatsRequest): AxiosPromise<GroupedDataSet<string, DateCount>[]> {
  return AXIOS.post(`/stats/assignment/${assignmentKey}/submission_counts`, request);
}

export function submissionQuality(
  assignmentKey: string, request: PeriodStatsRequest
): AxiosPromise<GroupedDataSet<string, OffsetDateTimePercentage>[]> {
  return AXIOS.post(`/stats/assignment/${assignmentKey}/submission_quality`, request);
}
