import { FC } from 'react';
import { ValidateFieldsError } from 'async-validator';
import { FormHelperText, TextField, TextFieldProps } from '@mui/material';

import { InputTooltip, calculateTooltipVariantTopOffset } from '.';

interface ValidatedFieldProps {
  fieldErrors?: ValidateFieldsError;
  name: string;
  tooltip?: string;
}

export type ValidatedTextFieldProps = ValidatedFieldProps & TextFieldProps;

const ValidatedTextField: FC<ValidatedTextFieldProps> = ({ name, fieldErrors, margin = "none", variant = "standard", fullWidth = true, tooltip, ...rest }) => {
  const errors = (fieldErrors && fieldErrors[name]) || undefined;

  const renderErrors = () => {
    return errors && errors.map((e, i) => (<FormHelperText key={i} data-error-for={name}>{e.message}</FormHelperText>));
  };

  const textField = (
    <TextField
      name={name}
      margin={margin}
      variant={variant}
      fullWidth={fullWidth}
      error={!!errors}
      {...rest}
    />
  );

  const renderContent = () => {
    if (tooltip) {
      return (
        <InputTooltip
          title={tooltip}
          ml={2}
          mt={calculateTooltipVariantTopOffset(margin, variant)}
        >
          {textField}
        </InputTooltip>
      );
    }

    return textField;
  };

  return (
    <>
      {renderContent()}
      {renderErrors()}
    </>
  );
};

export default ValidatedTextField;
