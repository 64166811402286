import { MediaDetail } from '../types';

import { generateApiUri } from './endpoints';

export const downloadUserImportLogFileUri = (jobId: number, log: MediaDetail): string => {
  return generateApiUri(`/user_import/${jobId}/log/${log.storedFilename}`, { params: { download: log.filename } });
};

export const downloadUserImportDataFileUri = (jobId: number, file: MediaDetail): string => {
  return generateApiUri(`/user_import/${jobId}/data/${file.storedFilename}`, { params: { download: file.filename } });
};
