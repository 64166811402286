import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Divider, Box, Typography, Container } from '@mui/material';

import { ErrorIcon, WarningIcon } from '../../components/icons';
import { DefaultButton, PaddedPaper, MessageBox } from '../../components';
import { ErrorLevel } from './context';

interface ErrorMessageProps {
  level: ErrorLevel;
  title: string;
  description: string;
  message: string;
  onClear: () => void;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ level, title, description, message, onClear }) => (
  <Container maxWidth="md">
    <Box mt={6}>
      <PaddedPaper>
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" mb={2}>
          {level === "warning" ? <WarningIcon fontSize="large" /> : <ErrorIcon fontSize="large" />}
          <Box pl={1}>
            <Typography variant="h4">
              {title}
            </Typography>
          </Box>
        </Box>
        <Typography variant="subtitle1" gutterBottom>
          {description}
        </Typography>
        {message && <MessageBox level={level} message={message} gutterBottom />}
        <Divider />
        <Box display="flex" flexDirection="column" pt={2}>
          <DefaultButton onClick={onClear}>
            <FormattedMessage
              id="contexts.errorBlock.errorMessage.retryButton"
              defaultMessage="Try again"
            />
          </DefaultButton>
        </Box>
      </PaddedPaper>
    </Box>
  </Container>
);

export default ErrorMessage;
