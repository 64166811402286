import { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import { Breadcrumbs, Link, Paper, TableRow, TableCell, Dialog, DialogTitle, DialogContent } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import HelpIcon from '@mui/icons-material/Help';
import CheckIcon from '@mui/icons-material/Check';

import { BrowseTable, MessageBox, PaddedDialogActions, DefaultButton, browseTableBody, StyledTableHead } from '../../../../../components';
import * as SessionApi from '../../../../../api/session';
import { extractErrorMessage } from '../../../../../api/endpoints';
import { FolderContents, FolderItem, FolderItemType } from '../../../../../types';
import { intl } from '../../../../../Internationalization';

interface SessionSelectorDialogProps {
  sessionPath?: string;
  onSessionSelected: (sessionPath: string) => void;
  onCancelled: () => void;
}

const FolderItemTableBody = browseTableBody<FolderItem>();

/*
* Trim the last component of the path at the moment we resume
* browsing from the previously selected session's parent.
*/
const extractParentPath = (sessionPath?: string) => {
  if (sessionPath) {
    const parts = sessionPath.split('/');
    if (parts.length > 1) {
      return parts.slice(1, parts.length - 1);
    }
  }
  return [];
};

const extractSessionName = (sessionPath?: string) => {
  if (sessionPath) {
    const parts = sessionPath.split('/');
    return parts.slice(parts.length - 1)[0];
  }
};

const expandPath = (path: string[]) => {
  return "/" + path.join("/");
};

const SessionSelectorDialog: FC<SessionSelectorDialogProps> = ({ sessionPath, onSessionSelected, onCancelled }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [path, setPath] = useState<string[]>(extractParentPath(sessionPath));
  const [selectedSessionPath, setSelectedSessionPath] = useState<string>();
  const [contents, setContents] = useState<FolderContents>();
  const [processing, setProcessing] = useState<boolean>(false);

  useEffect(() => {
    const loadFolderContents = async () => {
      setProcessing(true);
      try {
        const response = await SessionApi.listFolderContents(expandPath(path));
        setContents(response.data);
        setProcessing(false);
      } catch (error: any) {
        if (error.response?.status === 404) {
          selectPath([]);
        } else {
          setProcessing(false);
          setContents(undefined);
          enqueueSnackbar(extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'specification.configuration.sessionSelector.loadError',
              defaultMessage: 'Failed to fetch folder contents'
            })
          ), { variant: "error" });
        }
      }
    };

    loadFolderContents();
  }, [enqueueSnackbar, path]);

  const selectPath = (newPath: string[]) => {
    setPath(newPath);
    setSelectedSessionPath(undefined);
  };

  const selectSession = (sessionName: string) => {
    const currentPath = expandPath(path);
    const newSelectedSessionPath = (currentPath !== '/' ? currentPath : '') + '/' + sessionName;
    setSelectedSessionPath(newSelectedSessionPath);
  };

  const isSessionType = (folderItem: FolderItem) => {
    switch (folderItem.type) {
      case FolderItemType.SESSION:
      case FolderItemType.RUNNING_SESSION:
      case FolderItemType.PAUSED_SESSION:
      case FolderItemType.FINISHED_SESSION:
      case FolderItemType.PARTITIONED_SESSION:
        return true;
      default:
        return false;
    }
  };

  const iconForItem = (folderItem: FolderItem) => {
    switch (folderItem.type) {
      case FolderItemType.FOLDER:
        return (
          <FolderIcon
            titleAccess={intl.formatMessage({
              id: 'specification.configuration.sessionSelector.folder.titleAccess',
              defaultMessage: 'Folder'
            })}
          />
        );
      case FolderItemType.SESSION:
      case FolderItemType.RUNNING_SESSION:
      case FolderItemType.PAUSED_SESSION:
      case FolderItemType.FINISHED_SESSION:
      case FolderItemType.PARTITIONED_SESSION:
        return (
          <DescriptionIcon
            titleAccess={intl.formatMessage({
              id: 'specification.configuration.sessionSelector.session.titleAccess',
              defaultMessage: 'Session'
            })}
          />
        );
      default:
        return (
          <HelpIcon
            titleAccess={intl.formatMessage({
              id: 'specification.configuration.sessionSelector.noSessions.titleAccess',
              defaultMessage: 'No sessions'
            })}
          />
        );
    }
  };

  const folderItemRow = (folderItem: FolderItem) => {
    const selectedSessionName = extractSessionName(selectedSessionPath);

    return (
      <TableRow
        key={folderItem.name}
        sx={selectedSessionName === folderItem.name ? {
          bgcolor: (theme) => theme.palette.highlight.selected,
          "& a": {
            fontWeight: "bolder"
          }
        } : {}}
      >
        <TableCell sx={{ p: 1, textAlign: "center", width: "40px" }}>
          {iconForItem(folderItem)}
        </TableCell>
        <TableCell align="left">
          {
            isSessionType(folderItem) ?
              <Link onClick={() => selectSession(folderItem.name)} sx={{ cursor: "pointer" }} color="textPrimary">
                {folderItem.name}
              </Link>
              :
              <Link onClick={() => selectPath([...path, folderItem.name])} sx={{ cursor: "pointer" }} color="textPrimary">
                {folderItem.name}
              </Link>
          }
        </TableCell>
      </TableRow>
    );
  };

  const createPathBreadcrumb = () => (
    <Breadcrumbs aria-label="breadcrumb">
      {createPathBreadcrumbItems()}
    </Breadcrumbs>
  );

  const createPathBreadcrumbItems = () => {
    const items: JSX.Element[] = [
      <Link key="/" onClick={() => selectPath([])} sx={{ display: "flex", cursor: "pointer", alignItems: 'center' }} color="textPrimary">
        <HomeIcon sx={{ mr: 1, width: 22, height: 22 }} />
        <FormattedMessage id="specification.configuration.sessionSelector.breadcrumb" defaultMessage="Sessions" />
      </Link>
    ];

    path.forEach((name, index) => {
      const nodePath = path.slice(0, index + 1);
      const nodePathKey = expandPath(nodePath);
      items.push(
        <Link key={nodePathKey} onClick={() => selectPath(nodePath)} sx={{ display: "flex", cursor: "pointer", alignItems: 'center' }} color="textPrimary">
          <FolderIcon
            sx={{ mr: 1, width: 22, height: 22 }}
            titleAccess={intl.formatMessage({
              id: 'specification.configuration.sessionSelector.folder.titleAccess',
              defaultMessage: 'Folder'
            })}
          />{name}
        </Link>
      );
    });
    return items;
  };

  return (
    <Dialog
      id="session-selector-dialog"
      aria-labelledby="session-selector-dialog-title"
      onClose={onCancelled}
      open={true}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="session-selector-dialog-title">
        <FormattedMessage id="specification.configuration.sessionSelector.title" defaultMessage="Session Template" />
      </DialogTitle>
      <DialogContent dividers={true}>
        <Paper sx={{ p: 1, mb: 2 }}>
          {createPathBreadcrumb()}
        </Paper>
        <BrowseTable height={350}>
          <StyledTableHead>
            <TableRow>
              <TableCell sx={{ p: 1, textAlign: "center", width: "40px" }} />
              <TableCell align="left">
                <FormattedMessage id="specification.configuration.sessionSelector.table.nameColumn" defaultMessage="Name" />
              </TableCell>
            </TableRow>
          </StyledTableHead>
          <FolderItemTableBody
            processing={processing}
            data={contents && (contents.contents || [])}
            mapToRow={folderItemRow}
            noDataMessage={intl.formatMessage({
              id: 'specification.configuration.sessionSelector.noItemsFound',
              defaultMessage: 'No items found.'
            })}
            numCols={3}
          />
        </BrowseTable>
        {
          sessionPath &&
          <MessageBox
            level="warning"
            message={
              intl.formatMessage({
                id: 'specification.configuration.sessionSelector.newSessionSelection',
                defaultMessage: 'When selecting an alternative session, reconfiguration of tasks and data stores may be required.'
              })
            }
          />
        }
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton name="cancel" color="secondary" type="submit" onClick={onCancelled}>
          <FormattedMessage id="specification.configuration.sessionSelector.cancelButton" defaultMessage="Cancel" />
        </DefaultButton>
        <DefaultButton
          name="confirm"
          disabled={!selectedSessionPath}
          onClick={() => onSessionSelected(selectedSessionPath!)}
          startIcon={<CheckIcon />}
        >
          <FormattedMessage id="specification.configuration.sessionSelector.confirmButton" defaultMessage="Confirm" />
        </DefaultButton>
      </PaddedDialogActions>
    </Dialog>
  );
};

export default SessionSelectorDialog;
