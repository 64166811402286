import { FC, useCallback, useEffect, useState } from "react";
import { ValidateFieldsError } from "async-validator";

import { Box, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { passwordRequirementsValidator } from "../api/auth";
import { intl } from "../Internationalization";
import { PasswordRequirements, PASSWORD_REQUIREMENTS_METADATA } from "../types";
import { validate } from "../util";

import MessageBox from "./MessageBox";

interface RequirementIconProps {
  outstanding: boolean;
  info?: boolean;
}

const RequirementIcon: FC<RequirementIconProps> = ({ outstanding, info }) => {

  if (info) {
    return (
      <InfoOutlinedIcon fontSize="small" color="info" sx={{ mr: 1 }} />
    );
  }

  if (outstanding) {
    return (
      <CloseIcon fontSize="small" color="error" sx={{ mr: 1 }} />);
  }
  return (
    <DoneIcon fontSize="small" color="success" sx={{ mr: 1 }} />
  );
};

interface PasswordRequirementDetailsProps {
  newPasswordValue: string;
  passwordRequirements: PasswordRequirements;
}

const PasswordRequirementDetails: FC<PasswordRequirementDetailsProps> = ({ newPasswordValue, passwordRequirements }) => {

  const [outstandingRequirements, setOutstandingRequirements] = useState<ValidateFieldsError>();
  const validateNewPassword = useCallback(async (newPassword: string) => {
    try {
      await validate(passwordRequirementsValidator(passwordRequirements, true), { password: newPassword });
      setOutstandingRequirements({ password: [] });
    } catch (errors: any) {
      setOutstandingRequirements(errors);
    }
  }, [passwordRequirements]);

  useEffect(() => {
    validateNewPassword(newPasswordValue);
  }, [newPasswordValue, validateNewPassword]);

  return (
    <Box my={1}>
      <MessageBox
        level="info"
        size="small"
        message={intl.formatMessage({
          id: 'passwordRequirementDetails.info',
          defaultMessage: 'New passwords must adhere to the following requirements:',
        })}
      />
      <Box m={1}>
        {Object.entries(passwordRequirements).map(([key, value]) => {
          if (!value) {
            return null;
          }
          const message = PASSWORD_REQUIREMENTS_METADATA[key as keyof PasswordRequirements].message(value);
          const requirementOutstanding: boolean = (outstandingRequirements && newPasswordValue) ?
            !!outstandingRequirements?.password.find((validatedReq) => validatedReq.message === key) :
            true;

          return (
            <Box display="flex" alignItems="center" key={key}>
              <RequirementIcon
                outstanding={requirementOutstanding}
                info={key === 'reuseGenerations' || key === 'changeInterval'}
              />
              <Typography variant="body2">
                {message}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default PasswordRequirementDetails;
