import { FC, useContext, useState } from 'react';
import Schema, { ValidateFieldsError } from 'async-validator';

import { AxiosError } from 'axios';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';

import { Link, Box } from '@mui/material';
import ForwardIcon from '@mui/icons-material/Forward';
import Typography from '@mui/material/Typography';

import * as AuthApi from '../../api/auth';
import { ACCESS_TOKEN, extractErrorMessage } from '../../api/endpoints';
import { ValidatedTextField, DefaultButton, ValidatedPasswordField } from '../../components';
import { AuthenticationContext } from '../../contexts/authentication';
import { onEnterCallback, validate, updateValue } from '../../util';
import { intl } from '../../Internationalization';
import { LoginRequest } from '../../types';

const VALIDATOR = new Schema({
  username: {
    required: true, message: intl.formatMessage({
      id: 'preauth.signInForm.validator.usernameRequired',
      defaultMessage: 'Please provide a username'
    })
  },
  password: {
    required: true, message: intl.formatMessage({
      id: 'preauth.signInForm.validator.passwordRequired',
      defaultMessage: 'Please provide a password'
    })
  }
});

const SignInForm: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const authenticationContext = useContext(AuthenticationContext);

  const [loginRequest, setLoginRequest] = useState<LoginRequest>({
    username: '',
    password: ''
  });

  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const updateLoginRequestValue = updateValue(setLoginRequest);

  const onSignIn = () => {
    setProcessing(true);
    validateAndSignIn();
  };

  const submitOnEnter = onEnterCallback(onSignIn);

  const validateAndSignIn = async () => {
    try {
      signIn(
        await validate(VALIDATOR, loginRequest)
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const signIn = async (request: LoginRequest) => {
    try {
      const { data } = await AuthApi.login(request);
      localStorage.setItem(ACCESS_TOKEN, data.accessToken);
      authenticationContext.refresh();
    } catch (error: any) {
      signInError(error);
      setProcessing(false);
    }
  };

  const signInError = (error: AxiosError) => {
    if (error.response?.status === 401) {
      if (error.response.data.tokenType === 'PasswordReset') {
        navigate(`/reset_password?token=${error.response.data.accessToken}`);
        enqueueSnackbar(intl.formatMessage({
          id: 'preauth.signInForm.expiredLoginDetails',
          defaultMessage: 'Login details have expired'
        }), { variant: "warning" });
      } else {
        enqueueSnackbar(intl.formatMessage({
          id: 'preauth.signInForm.invalidLoginDetails',
          defaultMessage: 'Invalid login details'
        }), { variant: "warning" });
      }
    } else {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'preauth.signInForm.signInError',
            defaultMessage: 'Unexpected error, please try again'
          })
        ),
        { variant: "error" }
      );
    }
  };

  return (
    <>
      <Typography variant="body2" gutterBottom>
        <FormattedMessage id="preauth.signInForm.title" defaultMessage="Sign in with your username/password" />
      </Typography>
      <Box id="sign-in-form" display="flex" flexDirection="column">
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="username"
          label={intl.formatMessage({
            id: 'preauth.signInForm.username',
            defaultMessage: 'Username'
          })}
          value={loginRequest.username}
          onChange={updateLoginRequestValue}
          margin="normal"
          variant="outlined"
        />
        <ValidatedPasswordField
          fieldErrors={fieldErrors}
          disabled={processing}
          type="password"
          name="password"
          label={intl.formatMessage({
            id: 'preauth.signInForm.password.label',
            defaultMessage: 'Password'
          })}
          value={loginRequest.password}
          onChange={updateLoginRequestValue}
          onKeyDown={submitOnEnter}
          margin="normal"
          variant="outlined"
        />
        <DefaultButton
          startIcon={<ForwardIcon />}
          fullWidth
          name="signIn"
          size="large"
          type="submit"
          disabled={processing}
          onClick={onSignIn}
          sx={{ mt: 2 }}
        >
          <FormattedMessage id="preauth.signInForm.signInButton" defaultMessage="Sign In" />
        </DefaultButton>
        <Typography align="right" variant="body2" sx={{ my: 2 }}>
          <Link id="navigate-request-password-reset" component={RouterLink} to="/request_password_reset">
            <FormattedMessage id="preauth.signInForm.resetPasswordLink" defaultMessage="Reset your password" />
          </Link>
        </Typography>
      </Box>
    </>
  );
};

export default SignInForm;
