import { FC, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import * as DeletionJobApi from '../../../api/deletionJob';
import { extractErrorMessage } from "../../../api/endpoints";
import { ActiveAvatar, DefaultButton, SecureConfirmDialog, ListItemActionArea, PaddedPaper } from "../../../components";
import { DeletionJobEntity } from "../../../types";
import { intl } from "../../../Internationalization";
import { toDeletionJobUrl } from "../../system/storage/DeletionJobs";

import { ProjectContext } from "./ProjectContext";

const ProjectManagement: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { project } = useContext(ProjectContext);
  const navigate = useNavigate();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const handleDelete = async () => {
    setProcessing(true);
    try {
      const { data: deletionJob } = await DeletionJobApi.createDeletionJob({ entity: DeletionJobEntity.PROJECT, entityKey: project.key });
      enqueueSnackbar(intl.formatMessage({
        id: 'project.management.deletionStarted',
        defaultMessage: 'Deletion task started'
      }), { variant: 'success' });
      navigate(toDeletionJobUrl(deletionJob));
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'project.management.deleteError',
        defaultMessage: 'Failed to start project deletion task'
      })), { variant: "error" });
      if (error.response.status === 400) {
        setDeleteDialogOpen(false);
      }
      setProcessing(false);
    }
  };

  return (
    <PaddedPaper>
      <Typography variant="h5" component="h3" gutterBottom>
        <FormattedMessage
          id="project.management.title"
          defaultMessage="Manage Project"
        />
      </Typography>
      <List>
        <ListItem>
          <ListItemAvatar>
            <ActiveAvatar activated="inactive">
              <DeleteForeverIcon />
            </ActiveAvatar>
          </ListItemAvatar>
          <ListItemText
            primary={intl.formatMessage({
              id: 'project.management.deleteProject',
              defaultMessage: 'Delete Project'
            })}
            secondary={intl.formatMessage({
              id: 'project.management.deleteProject.description',
              defaultMessage: "Permanently remove the project and all of its associated specifications, assignments and submissions"
            })}
          />
          <ListItemActionArea>
            <DefaultButton
              name="deleteProjectButton"
              color="grey"
              onClick={() => setDeleteDialogOpen(true)}
            >
              <FormattedMessage
                id="project.management.deleteProjectButton"
                defaultMessage="Delete Project"
              />
            </DefaultButton>
          </ListItemActionArea>
        </ListItem>
      </List>
      <SecureConfirmDialog
        id="confirm-delete-project"
        disabled={processing}
        confirmCode={project.key}
        confirmCodeLabel={intl.formatMessage({
          id: 'project.management.confirmDeleteProject.confirmCodeLabel',
          defaultMessage: 'Project Key'
        })}
        isOpen={deleteDialogOpen}
        title={intl.formatMessage({
          id: 'project.management.confirmDeleteProject.title',
          defaultMessage: 'Delete Project?'
        })}
        confirmBtnText={intl.formatMessage({
          id: 'project.management.confirmDeleteProject.button',
          defaultMessage: 'Delete Project'
        })}
        confirmAction={handleDelete}
        closeAction={() => setDeleteDialogOpen(false)}
      >
        <Typography variant="body1" component="p" gutterBottom>
          <FormattedMessage
            id="project.management.confirmDeleteProject.warningMessage"
            defaultMessage="Are you absolutely sure you want to delete this project? This will delete the project and the associated specifications, assignments and submissions permanently."
          />
        </Typography>
        <Box my={2}>
          <Typography>
            <strong>
              <FormattedMessage
                id="project.management.confirmDeleteProject.projectKeyLabel"
                defaultMessage="Project Key:"
              />
            </strong> {project.key}
          </Typography>
          <Typography>
            <strong>
              <FormattedMessage
                id="project.management.confirmDeleteProject.projectNameLabel"
                defaultMessage="Project Name:"
              />
            </strong> {project.name}
          </Typography>
        </Box>
        <Typography variant="body1" component="p">
          <FormattedMessage
            id="project.management.confirmDeleteProject.confirmMessage"
            defaultMessage="Type the project key into the box below to confirm this action."
          />
        </Typography>
      </SecureConfirmDialog>
    </PaddedPaper>
  );
};

export default ProjectManagement;
