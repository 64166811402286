import { FC } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Fab, styled } from '@mui/material';

import { FabProps } from '@mui/material/Fab';

interface LabeledAddFabProps extends Omit<FabProps, "className" | "color" | "variant" | "children"> {
  label: string;
}

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(3),
  right: theme.spacing(3),
  lineHeight: "initial"
}));

const LabeledAddFab: FC<LabeledAddFabProps> = ({ label, ...rest }) => (
  <StyledFab color="primary" variant="extended" {...rest}>
    <AddIcon sx={{ mr: 1 }} />
    {label}
  </StyledFab>
);

export default LabeledAddFab;
