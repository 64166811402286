import { StyleRules, Rule, Declaration, parse } from "css";

const POLYGON_SELECTOR_SUFFIX = "[geometryType(geometry)='Polygon' or geometryType(geometry)='MultiPolygon']";
const LINE_SELECTOR_SUFFIX = "[geometryType(geometry)='LineString' or geometryType(geometry)='MultiLineString' or geometryType(geometry)='LinearRing']";
const POINT_SELECTOR_SUFFIX = "[geometryType(geometry)='Point']";
const MARK_SELECTOR_SUFFIX = "[geometryType(geometry)='Point']:mark";

export enum PointSymbol {
  CIRCLE = "symbol(circle)",
  SQUARE = "symbol(square)",
  TRIANGLE = "symbol(triangle)",
  ARROW = "symbol(arrow)",
  CROSS = "symbol(x)",
  PLUS = "symbol(cross)",
  STAR = "symbol(star)",
}

// Object for resolving PointSymbol instances by value
const POINT_SYMBOLS: { [key: string]: PointSymbol } = {
  "symbol(circle)": PointSymbol.CIRCLE,
  "symbol(square)": PointSymbol.SQUARE,
  "symbol(triangle)": PointSymbol.TRIANGLE,
  "symbol(arrow)": PointSymbol.ARROW,
  "symbol(x)": PointSymbol.CROSS,
  "symbol(cross)": PointSymbol.PLUS,
  "symbol(star)": PointSymbol.STAR
};

export interface MapLayerPolygonStyle {
  fill: string;
  fillOpacity: number;
  stroke: string;
  strokeWidth: number;
}

export interface MapLayerPointStyle {
  mark: PointSymbol;
  markSize: number;

  // mark styling
  fill: string;
  fillOpacity: number;
  stroke: string;
  strokeWidth: number;
}

export interface MapLayerLineStyle {
  stroke: string;
  strokeOpacity: number;
  strokeWidth: number;
}

export interface MapLayerStyle {
  point: MapLayerPointStyle;
  line: MapLayerLineStyle;
  polygon: MapLayerPolygonStyle;
}

export interface MapLayerStyles {
  [key: string]: MapLayerStyle;
}

/**
 * Produces a default map layer style as a fallback if no style (or a partial style) is returned for a given layer.
 */
export function defaultMapLayerStyle(): MapLayerStyle {
  return {
    point: {
      mark: PointSymbol.CIRCLE,
      markSize: 6,
      fill: "#33ff33",
      fillOpacity: 0.8,
      stroke: "#222222",
      strokeWidth: 1
    },
    line: {
      stroke: "#ff3333",
      strokeOpacity: 0.8,
      strokeWidth: 1
    },
    polygon: {
      fill: "#999999",
      fillOpacity: 0.5,
      stroke: "#000000",
      strokeWidth: 0.5
    }
  };
}

const extractBySelector = (styleRules: StyleRules, selector: string): Rule | undefined => {
  return styleRules.rules.find(r => {
    if (r.type !== "rule") {
      return false;
    }
    const rule = (r as Rule);
    return rule.selectors && rule.selectors[0] === selector;
  });
};

type ExtractedDeclarations = { [key: string]: string };
const populateStyles = <T>(
  styleRules: StyleRules, selector: string, styles: T, callback: (declarations: ExtractedDeclarations, styles: T) => void
) => {
  const rule = extractBySelector(styleRules, selector);
  if (rule && rule.declarations) {
    const properties = rule.declarations.filter(d => d.type === 'declaration').reduce((v, d: Declaration) => {
      if (d.property && d.value) {
        v[d.property] = d.value;
      }
      return v;
    }, {} as ExtractedDeclarations);
    callback(properties, styles);
  }
};

const COLOR_REGEX = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
const extractColor = (fallback: string, value?: string) => {
  if (value && value.match(COLOR_REGEX)) {
    return value;
  }
  return fallback;
};

const extractFloat = (fallback: number, value?: string) => {
  if (value) {
    const parsed = parseFloat(value);
    if (!isNaN(parsed)) {
      return parsed;
    }
  }
  return fallback;
};

const extractPointSymbol = (fallback: PointSymbol, value?: string) => {
  if (value) {
    const pointSymbol = POINT_SYMBOLS[value];
    if (pointSymbol) {
      return pointSymbol;
    }
  }
  return fallback;
};

const populatePolygon = (declarations: ExtractedDeclarations, polygon: MapLayerPolygonStyle) => {
  polygon.fill = extractColor(polygon.fill, declarations["fill"]);
  polygon.fillOpacity = extractFloat(polygon.fillOpacity, declarations["fill-opacity"]);
  polygon.stroke = extractColor(polygon.stroke, declarations["stroke"]);
  polygon.strokeWidth = extractFloat(polygon.strokeWidth, declarations["stroke-width"]);
};

const populateLine = (declarations: ExtractedDeclarations, line: MapLayerLineStyle) => {
  line.stroke = extractColor(line.stroke, declarations["stroke"]);
  line.strokeOpacity = extractFloat(line.strokeOpacity, declarations["stroke-opacity"]);
  line.strokeWidth = extractFloat(line.strokeWidth, declarations["stroke-width"]);
};

const populatePoint = (declarations: ExtractedDeclarations, point: MapLayerPointStyle) => {
  point.mark = extractPointSymbol(point.mark, declarations["mark"]);
  point.markSize = extractFloat(point.markSize, declarations["mark-size"]);
};

const populatePointMark = (declarations: ExtractedDeclarations, point: MapLayerPointStyle) => {
  point.fill = extractColor(point.fill, declarations["fill"]);
  point.fillOpacity = extractFloat(point.fillOpacity, declarations["fill-opacity"]);
  point.stroke = extractColor(point.stroke, declarations["stroke"]);
  point.strokeWidth = extractFloat(point.strokeWidth, declarations["stroke-width"]);
};

export function cssToMapLayerStyles(layers: string[], css: string): MapLayerStyles {
  const styleRules = parse(css).stylesheet;
  const mapLayerStyles: MapLayerStyles = {};
  if (styleRules) {
    layers.forEach(layer => {
      const mapLayerStyle: MapLayerStyle = defaultMapLayerStyle();
      const cssLayerId = layer.replace(':', '_');
      populateStyles(styleRules, `${cssLayerId} ${POLYGON_SELECTOR_SUFFIX}`, mapLayerStyle.polygon, populatePolygon);
      populateStyles(styleRules, `${cssLayerId} ${LINE_SELECTOR_SUFFIX}`, mapLayerStyle.line, populateLine);
      populateStyles(styleRules, `${cssLayerId} ${POINT_SELECTOR_SUFFIX}`, mapLayerStyle.point, populatePoint);
      populateStyles(styleRules, `${cssLayerId} ${MARK_SELECTOR_SUFFIX}`, mapLayerStyle.point, populatePointMark);
      mapLayerStyles[layer] = mapLayerStyle;
    });
  }
  return mapLayerStyles;
}
