import { AxiosPromise } from 'axios';

import {  UserImportJobDetail, EvaluatedImportUser } from '../types';
import { AXIOS } from './endpoints';

export function previewJob(jobId: number): AxiosPromise<EvaluatedImportUser[]> {
  return AXIOS.get(`/user_import/${jobId}/preview`);
}

export function startJob(jobId: number): AxiosPromise {
  return AXIOS.post(`/user_import/${jobId}/start`);
}

export function cancelJob(jobId: number): AxiosPromise<UserImportJobDetail> {
  return AXIOS.post(`/user_import/${jobId}/cancel`);
}
