import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ListItemText, MenuList, ListItemIcon, ListItem, Checkbox, ListItemSecondaryAction, ButtonGroup, Box } from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';
import LayersClearIcon from '@mui/icons-material/LayersClear';

import { MapLayerStyles, TaskMapConfig } from '../../../../../../../types';
import { DefaultButton, Loading } from '../../../../../../../components';

import { LayerSelections } from '../MapContainer';
import LayerKey from './LayerKey';

interface LayersPanelProps {
  mapStyles?: MapLayerStyles;
  taskMapConfig: TaskMapConfig;
  layerSelections: LayerSelections;
  setLayerSelections: (value: React.SetStateAction<LayerSelections>) => void;
}

const LayersPanel: FC<LayersPanelProps> = ({ mapStyles, taskMapConfig, layerSelections, setLayerSelections }) => {
  const handleToggleAllLayers = (selected: boolean) => {
    setLayerSelections(prevLayers => Object.fromEntries(Object.keys(prevLayers).map((key) => [key, selected])));
  };

  const handleLayerToggle = (layer: string) => setLayerSelections(prevLayers => ({ ...prevLayers, [layer]: !prevLayers[layer] }));

  return (
    <Box
      id="map-control-tabpanel-layers"
      sx={{
        overflowY: 'scroll',
        height: '100%'
      }}
    >
      <Box m={1}>
        <ButtonGroup fullWidth color="primary">
          <DefaultButton
            startIcon={<LayersIcon />}
            onClick={() => handleToggleAllLayers(true)}
            variant="outlined"
          >
            <FormattedMessage id="openSubmission.map.menu.mapMenu.layerPanel.selectAllLabel" defaultMessage="Select all" />
          </DefaultButton>
          <DefaultButton
            startIcon={<LayersClearIcon />}
            onClick={() => handleToggleAllLayers(false)}
            variant="outlined"
          >

            <FormattedMessage id="openSubmission.map.menu.mapMenu.layerPanel.deselectAllLabel" defaultMessage="Deselect all" />
          </DefaultButton>
        </ButtonGroup>
      </Box>
      <MenuList disablePadding>
        {!mapStyles ? <Loading /> :
          Object.keys(layerSelections).map((layer) => (
            <ListItem key={layer} button dense onClick={() => handleLayerToggle(layer)}>
              <ListItemIcon sx={{ mr: 1 }}>
                <LayerKey style={mapStyles[layer]} />
              </ListItemIcon>
              <ListItemText primary={layer} />
              <ListItemSecondaryAction>
                <Checkbox
                  onClick={() => handleLayerToggle(layer)}
                  edge="end"
                  checked={layerSelections[layer]}
                  disabled={!taskMapConfig.layers.includes(layer)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </MenuList>
    </Box>
  );
};

export default LayersPanel;
