
import React, { useContext, useState, useEffect } from "react";

import { Link as RouterLink, useParams } from 'react-router-dom';
import { Typography, Link, Container, Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import StorageIcon from '@mui/icons-material/Storage';

import * as ApiTokensApi from '../../../../api/apiTokens';
import { extractErrorMessage } from "../../../../api/endpoints";
import { HeaderBreadcrumb, AppDecorator, PaperFormSkeleton } from '../../../../components';
import { useErrorBlock } from "../../../../contexts/error-block";
import { ApiTokenDetail } from "../../../../types";
import { intl } from "../../../../Internationalization";

import { UserContext } from '../UserContext';
import ApiTokenSettingsForm from './ApiTokenSettingsForm';

const ApiToken = () => {
  const { user: { email, key } } = useContext(UserContext);

  const { apiTokenName } = useParams<{ apiTokenName: string }>();
  const { raiseError } = useErrorBlock();

  const [apiToken, setApiToken] = useState<ApiTokenDetail>();

  useEffect(() => {
    fetchApiToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchApiToken = async () => {
    try {
      const response = await ApiTokensApi.getApiToken(key, apiTokenName!);
      setApiToken(response.data);
    } catch (error: any) {
      raiseError(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'user.apiToken.loadError',
          defaultMessage: 'Failed to update new API token'
        })
      ));
    }
  };

  const renderBreadcrumb = () => {
    return (
      <HeaderBreadcrumb>
        <Link component={RouterLink} to={`/users/${key}/api_tokens`}>
          <Box display="flex" alignItems="center">
            <PersonIcon
              titleAccess={intl.formatMessage({
                id: 'user.routing.breadcrumb.user.titleAccess',
                defaultMessage: 'User'
              })}
            />
            {email}
          </Box>
        </Link>
        <Box display="flex" alignItems="center">
          <StorageIcon
            titleAccess={intl.formatMessage({
              id: 'user.routing.breadcrumb.apiToken.titleAccess',
              defaultMessage: 'API token'
            })}
          />
          <Typography color="inherit">
            {apiTokenName}
          </Typography>
        </Box>
      </HeaderBreadcrumb>
    );
  };

  return (
    <AppDecorator renderTabs={undefined} renderBreadcrumb={renderBreadcrumb}>
      <Container maxWidth="md" disableGutters>
        {
          apiToken ?
            <ApiTokenSettingsForm apiToken={apiToken} /> :
            <PaperFormSkeleton contentRows={3} />
        }
      </Container>
    </AppDecorator>
  );
};

export default ApiToken;
