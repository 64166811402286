import Schema from "async-validator";
import { AxiosPromise } from "axios";

import { CreateProjectRequest, ProjectDetail, ProjectsRequest, ProjectsResponse } from "../types";
import { duplicateValidator, NOT_BLANK_REGEX, slugKeyValidator } from '../util';
import { intl } from "../Internationalization";

import { AXIOS, RequestConfig } from "./endpoints";
import { projectNameValidator, projectDescriptionValidator, uniqueByKey } from "./project";

export function createProject(request: CreateProjectRequest): AxiosPromise<ProjectDetail> {
  return AXIOS.post('/projects', request);
}

export function getProjects(request: ProjectsRequest, config?: RequestConfig): AxiosPromise<ProjectsResponse> {
  return AXIOS.get('/projects', {
    ...config,
    params: request
  });
}

export function createProjectSettingsValidator(): Schema {
  return new Schema({
    name: projectNameValidator(),
    description: projectDescriptionValidator,
    key: [
      {
        required: true,
        pattern: NOT_BLANK_REGEX,
        message: intl.formatMessage({
          id: 'project.validator.key.required',
          defaultMessage: 'Please provide a key'
        })
      },
      slugKeyValidator,
      duplicateValidator({
        regex: NOT_BLANK_REGEX,
        checkUnique: uniqueByKey,
        alreadyExistsMessage: intl.formatMessage({
          id: 'project.validator.key.unique',
          defaultMessage: 'A project with this key already exists'
        }),
        errorMessage: intl.formatMessage({
          id: 'project.validator.key.checkUniqueError',
          defaultMessage: 'There was a problem verifying the project key'
        })
      })
    ]
  });
}
