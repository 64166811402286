import { intl } from "../Internationalization";
import { AuditEventDetail } from ".";

export interface MailTemplateDetail {
  id: number;
  name: string;
  subject: string;
  text: string;
  updateEvent: AuditEventDetail;
}

export interface MailTemplateSettings {
  subject: string;
  text: string;
}

export enum MailSenderSecurity {
  NONE = 'NONE',
  TLS_OPTIONAL = 'TLS_OPTIONAL',
  TLS = 'TLS'
}

export const mailSenderSecurities = [MailSenderSecurity.NONE, MailSenderSecurity.TLS_OPTIONAL, MailSenderSecurity.TLS];

export const MAIL_SENDER_SECURITY_METADATA: { [type in MailSenderSecurity]: string } = {
  NONE: intl.formatMessage({
    id: "mail.senderSecuritiesMetadata.none",
    defaultMessage: "None"
  }),
  TLS_OPTIONAL: intl.formatMessage({
    id: "mail.senderSecuritiesMetadata.tlsOptional",
    defaultMessage: "TLS Optional"
  }),
  TLS: intl.formatMessage({
    id: "mail.senderSecuritiesMetadata.tls",
    defaultMessage: "TLS"
  }),
};

export enum MailSenderProtocol {
  SMTP = 'SMTP',
  SMTPS = 'SMTPS',
}

export const mailSenderProtocols = [MailSenderProtocol.SMTP, MailSenderProtocol.SMTPS];

export interface MailSenderSettings {
  enabled: boolean;
  from: string;
  protocol: MailSenderProtocol;
  host: string;
  port?: number;
  security: MailSenderSecurity;
  username: string;
  password: string;
}
