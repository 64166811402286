import { FC, useContext } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

import { AccountHeader, AppDecorator, AppTabs, HeaderBreadcrumb, RouterTab, RouterTabs } from '../../../components';
import { intl } from '../../../Internationalization';
import RequireReceiverAdmin from '../../../routing/RequireReceiverAdmin';
import { AuthenticatedContext } from '../../../contexts/authentication';
import { useRouterTab } from '../../../hooks';

import { AccountEvents } from '../../components/account-events';

import AccountStatus from './AccountStatus';
import UserPermissions from './UserPermissions';
import { UserContext } from './UserContext';
import UserProfile from './UserProfile';

import IdentityProviderLinks from './identity-provider-link/IdentityProviderLinks';
import ApiTokenRouting from './api-tokens/ApiTokenRouting';

const UserRouting: FC = () => {
  const { user, userUpdated } = useContext(UserContext);
  const { me } = useContext(AuthenticatedContext);

  const isReceiverAdmin = me.receiverPermissions?.admin;
  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="user-tabs" value={routerTab}>
      <RouterTab
        id="status-tab"
        value="status"
        label={intl.formatMessage({
          id: 'user.routing.tabs.status',
          defaultMessage: 'Status'
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="profile-tab"
        value="profile"
        label={intl.formatMessage({
          id: 'user.routing.tabs.profile',
          defaultMessage: 'Profile'
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="permissions-tab"
        value="permissions"
        label={intl.formatMessage({
          id: 'user.routing.tabs.permissions',
          defaultMessage: 'Permissions'
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="account-events-tab"
        value="account_events"
        label={intl.formatMessage({
          id: 'user.routing.tabs.accountEvents',
          defaultMessage: 'Account Events'
        })}
        resolveTabRoute={resolveTabRoute}
      />
      {
        isReceiverAdmin &&
        <RouterTab
          id="identity-provider-links-tab"
          value="identity_provider_links"
          label={intl.formatMessage({
            id: 'user.routing.tabs.identityProviderLinks',
            defaultMessage: 'Identity Provider Links'
          })}
          resolveTabRoute={resolveTabRoute}
        />
      }
      {
        isReceiverAdmin &&
        <RouterTab
          id="api-tokens-tab"
          value="api_tokens"
          label={intl.formatMessage({
            id: 'user.routing.tabs.apiTokens',
            defaultMessage: 'API Tokens'
          })}
          resolveTabRoute={resolveTabRoute}
        />
      }
    </RouterTabs>
  );

  const renderBreadcrumb = () => (
    <HeaderBreadcrumb>
      <Box display="flex" alignItems="center">
        <PersonIcon
          titleAccess={intl.formatMessage({
            id: 'user.routing.breadcrumb.user.titleAccess',
            defaultMessage: 'User'
          })}
        />
        <Typography color="inherit">
          {user.email}
        </Typography>
      </Box>
    </HeaderBreadcrumb>
  );

  return (
    <AppDecorator renderTabs={renderTabs} renderBreadcrumb={renderBreadcrumb}>
      <AccountHeader user={user} />
      <AppTabs />
      <Box p={3} id="user-content">
        <Routes>
          <Route path="status" element={<AccountStatus user={user} userUpdated={userUpdated} />} />
          <Route path="profile" element={<UserProfile user={user} userUpdated={userUpdated} />} />
          <Route path="permissions" element={<UserPermissions user={user} userUpdated={userUpdated} />} />
          <Route path="account_events" element={<AccountEvents userConstraint={user} />} />
          <Route
            path="identity_provider_links"
            element={(
              <RequireReceiverAdmin>
                <IdentityProviderLinks />
              </RequireReceiverAdmin>
            )}
          />
          <Route
            path="api_tokens/*"
            element={(
              <RequireReceiverAdmin>
                <ApiTokenRouting />
              </RequireReceiverAdmin>
            )}
          />
          <Route path="*" element={<Navigate to="status" replace />} />
        </Routes>
      </Box>
    </AppDecorator>
  );
};

export default UserRouting;
