import { FC, ReactElement } from 'react';
import { Box, styled } from '@mui/material';

const KEYWORDS = [' is less than ', ' is greater than ', ' or equal to ', ' is equal to ', ' equals ', ' does not equal '];
const KEYWORDS_INDENT = [' and ', ' or ', ' if ', ' then ', ' else ', ' xor ', ' for which ', 'there is exactly', 'there is at least ', 'there are no '];

const RULE_REGEXP = /____(.*)?____[\s]*([\s\S]*)/;

const findEndsWith = (buffer: string, values: string[]) => {
  return values.find(value => buffer.endsWith(value));
};

// 1Integrate returns all sorts of highlighting font tags, bold tags etc...
// We opt to remove them here for safety sake.
const stripHtml = (html: string) => {
  const tmp = document.createElement("div");
  tmp.innerHTML = html;
  return tmp.innerText || "";
};

const Monospaced = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontFamily: "monospace",
  fontSize: "1rem"
}));

const ReportDescription: FC<{ description: string }> = ({ description }) => {
  const generateRuleNodes = (rule: string) => {
    const rootNodes: ReactElement[] = [];
    var currentNodes = rootNodes;
    var buffer = '';
    for (var i = 0; i < rule.length; i++) {
      buffer += rule.charAt(i);
      var keyword: string | undefined;
      var indent: boolean = false;
      if (!(keyword = findEndsWith(buffer, KEYWORDS)) && (keyword = findEndsWith(buffer, KEYWORDS_INDENT))) {
        indent = true;
      }
      if (keyword) {
        if (buffer.length > keyword.length) {
          currentNodes.push(<span key={"txt_" + i}>{stripHtml(buffer.substr(0, buffer.length - keyword.length))}</span>);
        }
        if (rootNodes.length > 0 && indent) {
          currentNodes = [];
          rootNodes.push(
            <Box key={"kw2_" + i} pl={2}>
              {currentNodes}
            </Box>
          );
        }
        currentNodes.push(
          <Box
            component="span"
            key={"kw_" + i}
            sx={{ color: indent ? "#0000aa" : "#00aa00" }}
          >
            {keyword}
          </Box>
        );
        buffer = '';
      }
    }
    if (buffer.length > 0) {
      currentNodes.push(<span key="txt_end">{stripHtml(buffer)}</span>);
    }
    return rootNodes;
  };

  var match = RULE_REGEXP.exec(description);
  if (match) {
    return (
      <>
        <Box mt={1}>
          {generateRuleNodes(match[1])}
        </Box>
        <Monospaced>
          {match[2]}
        </Monospaced>
      </>
    );
  }
  return (
    <Monospaced>
      {description}
    </Monospaced>
  );
};

export default ReportDescription;
