import { FC, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { TableRow, TableCell, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { UserSummary, UserProfile } from '../../types';
import * as UsersApi from '../../api/users';
import {
  AddFab, BrowseTable, FilterPagination, browseTableBody, FilterBar, FilterContainer, DefaultButton,
  NamedAccountAvatar, MinWidthTableCell, FilterSearch, StyledTableHead
} from '../../components';
import { ActivatedIcon, SuspendedIcon } from '../../components/icons';
import TriStateFilter from '../../components/browse-table/TriStateFilter';
import { withErrorBlock } from '../../contexts/error-block';
import { intl } from '../../Internationalization';
import { updateOnChangeValue } from '../../util';
import { useBrowseRequest } from '../../hooks';

import NewUserForm from './user/NewUserForm';

export const toUserUrl = (user: UserProfile) => `/users/${user.key}`;

const PAGE_SIZE = 10;

const UsersTableBody = browseTableBody<UserSummary>();

const Users: FC = () => {
  const { request, response, processing, updateRequest, setPage } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, filter: "" },
    onRequest: UsersApi.getUsers,
  });
  const [addUserDialogOpen, setAddUserDialogOpen] = useState<boolean>(false);

  const handleFilterUpdate = useCallback((filter: string) => updateRequest({ filter }), [updateRequest]);
  const updateTriFilterValue = updateOnChangeValue(updateRequest);

  const userRow = (user: UserSummary) => (
    <TableRow key={user.key}>
      <TableCell align="left">
        <NamedAccountAvatar user={user} />
      </TableCell>
      <TableCell align="left">{user.email}</TableCell>
      <TableCell align="center">
        <ActivatedIcon
          disabled={!user.activated}
          titleAccess={!!user.activated ?
            intl.formatMessage({
              id: 'users.user.activated.titleAccess',
              defaultMessage: 'User activated'
            }) : intl.formatMessage({
              id: 'users.user.notActivated.titleAccess',
              defaultMessage: 'User not activated'
            })}
        />
      </TableCell>
      <TableCell align="center">
        <SuspendedIcon
          disabled={!user.suspended}
          titleAccess={!!user.suspended ?
            intl.formatMessage({
              id: 'users.user.suspended.titleAccess',
              defaultMessage: 'User suspended'
            }) : intl.formatMessage({
              id: 'users.user.active.titleAccess',
              defaultMessage: 'User active'
            })}
        />
      </TableCell>
      <MinWidthTableCell>
        <DefaultButton className="navigate-to-user" color="grey" component={Link} to={toUserUrl(user)}>
          <EditIcon
            titleAccess={intl.formatMessage({
              id: 'users.navigateToUser.titleAccess',
              defaultMessage: 'Navigate to user'
            })}
          />
        </DefaultButton>
      </MinWidthTableCell>
    </TableRow>
  );

  return (
    <Box p={3} id="users">
      <FilterBar
        startInput={
          <FilterSearch
            placeholder={intl.formatMessage({
              id: 'users.filterSearch.placeholder',
              defaultMessage: 'Filter users…'
            })}
            onSearch={handleFilterUpdate}
          />
        }
        actions={
          <FilterPagination page={request.page} size={request.size} total={response?.total} disabled={processing} setPage={setPage} />
        }
        filterGroups={[
          {
            name: "filters",
            title: intl.formatMessage({
              id: 'users.filterGroup.title',
              defaultMessage: 'Filters'
            }),
            component: (
              <FilterContainer>
                <TriStateFilter
                  name="receiver"
                  value={request.receiver}
                  label={intl.formatMessage({
                    id: 'users.filterReceiverPermissions.label',
                    defaultMessage: 'Receiver permissions'
                  })}
                  unsetLabel={intl.formatMessage({
                    id: 'users.filterReceiverPermissions.anyLabel',
                    defaultMessage: 'Any'
                  })}
                  trueLabel={intl.formatMessage({
                    id: 'users.filterReceiverPermissions.grantedLabel',
                    defaultMessage: 'Granted'
                  })}
                  falseLabel={intl.formatMessage({
                    id: 'users.filterReceiverPermissions.notGrantedLabel',
                    defaultMessage: 'Not Granted'
                  })}
                  onChange={updateTriFilterValue}
                />
                <TriStateFilter
                  name="activated"
                  value={request.activated}
                  label={intl.formatMessage({
                    id: 'users.filterActivationStatus.label',
                    defaultMessage: 'Activation status'
                  })}
                  unsetLabel={intl.formatMessage({
                    id: 'users.filterActivationStatus.anyLabel',
                    defaultMessage: 'Any'
                  })}
                  trueLabel={intl.formatMessage({
                    id: 'users.filterActivationStatus.activatedLabel',
                    defaultMessage: 'Activated'
                  })}
                  falseLabel={intl.formatMessage({
                    id: 'users.filterActivationStatus.notActivatedLabel',
                    defaultMessage: 'Not Activated'
                  })}
                  onChange={updateTriFilterValue}
                />
                <TriStateFilter
                  name="suspended"
                  label={intl.formatMessage({
                    id: 'users.filterSuspendedStatus.label',
                    defaultMessage: 'Suspended status'
                  })}
                  value={request.suspended}
                  unsetLabel={intl.formatMessage({
                    id: 'users.filterSuspendedStatus.anyLabel',
                    defaultMessage: 'Any'
                  })}
                  trueLabel={intl.formatMessage({
                    id: 'users.filterSuspendedStatus.suspendedLabel',
                    defaultMessage: 'Suspended'
                  })}
                  falseLabel={intl.formatMessage({
                    id: 'users.filterSuspendedStatus.activeLabel',
                    defaultMessage: 'Active'
                  })}
                  onChange={updateTriFilterValue}
                />
              </FilterContainer>
            )
          }
        ]}
      />
      <BrowseTable>
        <StyledTableHead>
          <TableRow>
            <TableCell align="left">
              <FormattedMessage id="users.table.nameColumn" defaultMessage="Name" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="users.table.emailColumn" defaultMessage="Email" />
            </TableCell>
            <TableCell align="center">
              <FormattedMessage id="users.table.activatedColumn" defaultMessage="Activated?" />
            </TableCell>
            <TableCell align="center">
              <FormattedMessage id="users.table.suspendedColumn" defaultMessage="Suspended?" />
            </TableCell>
            <MinWidthTableCell>
              <FormattedMessage id="users.table.actionsColumn" defaultMessage="Actions" />
            </MinWidthTableCell>
          </TableRow>
        </StyledTableHead>
        <UsersTableBody
          data={response?.results}
          mapToRow={userRow}
          noDataMessage={intl.formatMessage({
            id: 'users.noUsers',
            defaultMessage: 'No matching users.'
          })}
          numCols={5}
        />
      </BrowseTable>
      {
        addUserDialogOpen && <NewUserForm onCancel={() => setAddUserDialogOpen(false)} />
      }
      <AddFab
        id="add-user"
        onClick={() => setAddUserDialogOpen(true)}
        aria-label={intl.formatMessage({
          id: 'users.addFab.addUser.ariaLabel',
          defaultMessage: 'Add user'
        })}
      />
    </Box>
  );
};

export default withErrorBlock(Users);
