import { useState, FC, Fragment } from 'react';
import { IconButton, TextField, InputAdornment } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { intl } from '../../../../../Internationalization';

import SessionSelectorDialog from './SessionSelectorDialog';

interface SpecificationSessionSelectorProps {
  sessionPath?: string;
  onUpdateSessionPath: (sessionPath: string) => void;
}

const SpecificationSessionSelector: FC<SpecificationSessionSelectorProps> = ({ sessionPath, onUpdateSessionPath }) => {
  const [selectingSession, setSelectingSession] = useState<boolean>(false);

  const beginSelectSession = () => {
    setSelectingSession(true);
  };

  const saveSelectedSession = (selectedSessionPath: string) => {
    onUpdateSessionPath(selectedSessionPath);
    setSelectingSession(false);
  };

  const cancelSelectSession = () => {
    setSelectingSession(false);
  };

  return (
    <Fragment>
      <TextField
        label={intl.formatMessage({
          id: 'specification.configuration.sessionTemplate.label',
          defaultMessage: 'Session Template'
        })}
        name="sessionPath"
        value={sessionPath || ''}
        variant="outlined"
        margin="normal"
        fullWidth
        contentEditable={false}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={beginSelectSession}
                size="large"
                aria-label={intl.formatMessage({
                  id: 'specification.configuration.sessionSelector.open.ariaLabel',
                  defaultMessage: 'Open session selector dialog'
                })}
              >
                <EditIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {selectingSession && (
        <SessionSelectorDialog sessionPath={sessionPath} onCancelled={cancelSelectSession} onSessionSelected={saveSelectedSession} />
      )}
    </Fragment>
  );
};

export default SpecificationSessionSelector;
