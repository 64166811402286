import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Checkbox, FormControlLabel, IconButton, Popover, Typography } from '@mui/material';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

import { StringKey } from '../../util/types';
import { intl } from '../../Internationalization';

export type ColumnContent = JSX.Element | string;

interface ColumnSelectorProps<T> {
  columnKeys: readonly (StringKey<T>)[];
  columnLabels: Record<StringKey<T>, string>;
  columnSelections: Record<StringKey<T>, boolean>;
  onColumnSelectionsUpdated: (columnSelections: Record<StringKey<T>, boolean>) => void;
}

function ColumnSelector<T>({ columnKeys, columnLabels, columnSelections, onColumnSelectionsUpdated }: ColumnSelectorProps<T>) {
  const oneActiveSelection = Object.values(columnSelections).filter(Boolean).length === 1;
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={() => setOpen((current) => (!current))}
        size="large"
        name="toggleColumnSelector"
        aria-label={
          open ? intl.formatMessage({
            id: 'components.columnSelector.closeDialogButton.ariaLabel',
            defaultMessage: 'Close column selector dialog'
          }) :
            intl.formatMessage({
              id: 'components.columnSelector.openDialogButton.ariaLabel',
              defaultMessage: 'Open column selector dialog'
            })
        }
      >
        <ViewColumnIcon />
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableScrollLock
      >
        <Box py={2} px={3} display="flex" flexDirection="column">
          <Typography variant="body1" gutterBottom>
            <FormattedMessage id="components.columnSelector.label" defaultMessage="Show Columns" />
          </Typography>
          {columnKeys.map((columnKey) => {
            const visible = columnSelections[columnKey];
            return (
              <FormControlLabel
                key={columnKey}
                control={
                  <Checkbox
                    checked={visible}
                    disabled={visible && oneActiveSelection}
                    onChange={() => onColumnSelectionsUpdated({
                      ...columnSelections,
                      [columnKey]: !visible
                    })}
                    name={columnKey}
                  />
                }
                label={columnLabels[columnKey]}
              />
            );
          })}
        </Box>
      </Popover>
    </>
  );
}

export default ColumnSelector;
