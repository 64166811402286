import { intl } from "../Internationalization";
import { UserProfile } from "./user";

import { OffsetDateTimeRange, PageResponse, SimplePageRequest } from "./shared";

export interface AccountEventsRequest extends SimplePageRequest {
  performedAt?: OffsetDateTimeRange;
  userKey?: string;
  performedByUserKey?: string;
  types?: AccountEventType[];
}

export interface AccountEventsResponse extends PageResponse<AccountEventDetail> {
}

export enum AccountEventType {
  CREATED = "CREATED",
  ACTIVATION_EMAIL_SENT = "ACTIVATION_EMAIL_SENT",
  ACTIVATED = "ACTIVATED",
  WELCOME_EMAIL_SENT = "WELCOME_EMAIL_SENT",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE",
  SUSPENDED = "SUSPENDED",
  UNSUSPENDED = "UNSUSPENDED",
  RECEIVER_PERMISSIONS_UPDATED = "RECEIVER_PERMISSIONS_UPDATED",
  RECEIVER_PERMISSIONS_REMOVED = "RECEIVER_PERMISSIONS_REMOVED",
  PASSWORD_RESET_REQUESTED = "PASSWORD_RESET_REQUESTED",
  PASSWORD_RESET_COMPLETE = "PASSWORD_RESET_COMPLETE",
  PASSWORD_CHANGED = "PASSWORD_CHANGED"
}

export const ACCOUNT_EVENT_TYPE_METADATA: { [type in AccountEventType]: { label: string } } = {
  CREATED: {
    label: intl.formatMessage({ id: 'user.accountEvent.type.created', defaultMessage: 'Created' })
  },
  ACTIVATION_EMAIL_SENT: {
    label: intl.formatMessage({ id: 'user.accountEvent.type.activationEmailSent', defaultMessage: 'Activation Email Sent' })
  },
  ACTIVATED: {
    label: intl.formatMessage({ id: 'user.accountEvent.type.activated', defaultMessage: 'Activated' })
  },
  WELCOME_EMAIL_SENT: {
    label: intl.formatMessage({ id: 'user.accountEvent.type.welcomeEmailSent', defaultMessage: 'Welcome Email Sent' })
  },
  LOGIN_SUCCESS: {
    label: intl.formatMessage({ id: 'user.accountEvent.type.loginSuccess', defaultMessage: 'Login Success' })
  },
  LOGIN_FAILURE: {
    label: intl.formatMessage({ id: 'user.accountEvent.type.loginFailure', defaultMessage: 'Login Failure' })
  },
  SUSPENDED: {
    label: intl.formatMessage({ id: 'user.accountEvent.type.suspended', defaultMessage: 'Suspended' })
  },
  UNSUSPENDED: {
    label: intl.formatMessage({ id: 'user.accountEvent.type.unsuspended', defaultMessage: 'Unsuspended' })
  },
  RECEIVER_PERMISSIONS_UPDATED: {
    label: intl.formatMessage({ id: 'user.accountEvent.type.receiverPermissionsUpdated', defaultMessage: 'Receiver Permissions Updated' })
  },
  RECEIVER_PERMISSIONS_REMOVED: {
    label: intl.formatMessage({ id: 'user.accountEvent.type.receiverPermissionsRemoved', defaultMessage: 'Receiver Permissions Removed' })
  },
  PASSWORD_RESET_REQUESTED: {
    label: intl.formatMessage({ id: 'user.accountEvent.type.passwordResetRequested', defaultMessage: 'Password Reset Requested' })
  },
  PASSWORD_RESET_COMPLETE: {
    label: intl.formatMessage({ id: 'user.accountEvent.type.passwordResetComplete', defaultMessage: 'Password Reset Complete' })
  },
  PASSWORD_CHANGED: {
    label: intl.formatMessage({ id: 'user.accountEvent.type.passwordChange', defaultMessage: 'Password Change' })
  },
};

export interface AccountEventSummary {
  performedBy: UserProfile;
  performedAt: string;
}

export interface AccountEventDetail extends AccountEventSummary {
  user: UserProfile;
  type: AccountEventType;
}
