import { FC } from 'react';
import { Box, BoxProps } from '@mui/material';

const FlexButtons: FC<BoxProps> = ({ sx = [], children, ...rest }) => (
  <Box
    sx={[{
      pt: 1, '& button, & > a': {
        flex: 'auto',
        m: 1,
      }
    },
    ...(Array.isArray(sx) ? sx : [sx])
    ]}
    {...rest}
  >
    {children}
  </Box>
);

export default FlexButtons;
