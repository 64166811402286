import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import { ValidateFieldsError } from 'async-validator';

import { Typography, Box } from '@mui/material';
import HowToRegIcon from '@mui/icons-material/HowToReg';

import * as AuthApi from '../../api/auth';
import { extractErrorMessage } from '../../api/endpoints';
import { VerifiedPassword } from '../../api/auth';
import { ApplicationContext } from '../../contexts/application';
import { DefaultButton, ValidatedPasswordField } from '../../components';
import { useQuery } from '../../hooks';
import { onEnterCallback, updateValue, validate } from '../../util';
import { intl } from '../../Internationalization';

import PreAuthPage from './PreAuthPage';

const Activate: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const activationToken = useQuery().get('token') || '';

  const { applicationDetails: { site: { passwordRequirements } } } = useContext(ApplicationContext);

  const [processing, setProcessing] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const [verifiedPassword, setVerifiedPassword] = useState<VerifiedPassword>({
    password: '',
    verifyPassword: ''
  });

  useEffect(() => {
    const verifyActivationToken = async () => {
      try {
        await AuthApi.verifyActivationToken({ activationToken });
        setValidated(true);
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          enqueueSnackbar(intl.formatMessage({
            id: 'preauth.activate.tokenInvalidError',
            defaultMessage: 'Activation token is not valid'
          }), { variant: "warning" });
        } else {
          enqueueSnackbar(extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'preauth.activate.verifyTokenError',
              defaultMessage: 'Unexpected error when validating token'
            })
          ), { variant: "error" });
        }
        navigate('/');
      }
    };

    verifyActivationToken();

  }, [activationToken, enqueueSnackbar, navigate]);

  const validateAndSubmit = async () => {
    setProcessing(true);
    try {
      activate(
        await validate(AuthApi.passwordResetValidator(passwordRequirements), verifiedPassword)
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const activate = async ({ password }: VerifiedPassword) => {
    try {
      await AuthApi.activate({ password, activationToken });
      enqueueSnackbar(intl.formatMessage({
        id: 'preauth.activate.activateSuccess',
        defaultMessage: 'Your account has been activated, please log in'
      }), { variant: "success" });
      navigate('/');
    } catch (error: any) {

      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'preauth.activate.activateError',
          defaultMessage: 'Unexpected error when activating account'
        })
      ), { variant: "error" });
      setProcessing(false);
    }
  };

  const submitOnEnter = onEnterCallback(validateAndSubmit);

  const updateVerifiedPasswordValue = updateValue(setVerifiedPassword);

  return (
    <PreAuthPage loading={!validated} loadingMessage={intl.formatMessage({ id: 'preauth.activate.loadingMessage', defaultMessage: 'Validating token…' })}>
      <Typography variant="h4" gutterBottom>
        <FormattedMessage id="preauth.activate.title" defaultMessage="Welcome to 1Data Gateway" />
      </Typography>
      <Typography variant="body2" gutterBottom>
        <FormattedMessage id="preauth.activate.description" defaultMessage="Enter a password below to activate your account." />
      </Typography>
      <Box display="flex" flexDirection="column">
        <ValidatedPasswordField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="password"
          label={intl.formatMessage({
            id: 'preauth.activate.password.label',
            defaultMessage: 'Password'
          })}
          type="password"
          value={verifiedPassword.password}
          onChange={updateVerifiedPasswordValue}
          margin="normal"
          variant="outlined"
        />
        <ValidatedPasswordField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="verifyPassword"
          label={intl.formatMessage({
            id: 'preauth.activate.verifyPassword.label',
            defaultMessage: 'Verify Password'
          })}
          type="password"
          value={verifiedPassword.verifyPassword}
          onChange={updateVerifiedPasswordValue}
          onKeyDown={submitOnEnter}
          margin="normal"
          variant="outlined"
        />
        <DefaultButton
          startIcon={<HowToRegIcon />}
          fullWidth
          id="activate-account"
          size="large"
          type="submit"
          disabled={processing}
          onClick={validateAndSubmit}
          sx={{ mt: 2 }}
        >
          <FormattedMessage id="preauth.activate.activateButton" defaultMessage="Activate Account" />
        </DefaultButton>
      </Box>
    </PreAuthPage>
  );
};

export default Activate;

