import { FC, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import { ValidateFieldsError } from 'async-validator';

import { Typography, Checkbox } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { intl } from '../../../../../Internationalization';
import { validate } from '../../../../../util';
import * as AssignmentApi from '../../../../../api/assignment';
import { extractErrorMessage } from '../../../../../api/endpoints';
import { SupplierDetail, AssignmentSettings } from '../../../../../types';
import {
  ActiveSupplierAutocomplete, ValidatedTextField, PaddedPaper, FormButtons, DefaultButton, BlockFormControlLabel, InputTooltip
} from '../../../../../components';

import { AssignmentContext } from './AssignmentContext';

interface AssignmentSettingsFormProps {
  supplier: SupplierDetail;
}

const AssignmentSettingsForm: FC<AssignmentSettingsFormProps> = (props) => {
  const { assignment, specificationKey, assignmentUpdated } = useContext(AssignmentContext);
  const { enqueueSnackbar } = useSnackbar();

  const [reference, setReference] = useState<string>(assignment.reference);
  const [active, setActive] = useState<boolean>(assignment.active);
  const [restricted, setRestricted] = useState<boolean>(assignment.restricted);
  const [supplier, setSupplier] = useState<SupplierDetail | null>(props.supplier);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>({});
  const [processing, setProcessing] = useState<boolean>(false);

  const validator = AssignmentApi.assignmentSettingsValidator(() => specificationKey, () => assignment.reference);

  const validateAndSubmit = async () => {
    setProcessing(true);
    try {
      const assignmentSettings = { reference, active, restricted, specificationKey, supplierKey: supplier?.key };
      const response = await validate(validator, assignmentSettings);
      updateAssignment(response);
    } catch (error: any) {
      setFieldErrors(error);
      setProcessing(false);
    }
  };

  const updateAssignment = async (assignmentSettings: AssignmentSettings) => {
    setFieldErrors({});
    try {
      const { data: assignmentData } = await AssignmentApi.updateAssignment(assignment.key, assignmentSettings);
      assignmentUpdated(assignmentData);
      setProcessing(false);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'assignment.settings.saveSuccess',
          defaultMessage: 'Assignment {reference} has been updated'
        }, { reference: assignmentData.reference }),
        { variant: "success" }
      );
    } catch (error: any) {
      setProcessing(false);
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'assignment.settings.saveError.label',
          defaultMessage: 'Failed to update assignment'
        })
      ), { variant: "error" });
    }
  };

  return (
    <PaddedPaper>
      <Typography variant="h5">
        <FormattedMessage id="assignment.settings.title" defaultMessage="Assignment Settings" />
      </Typography>
      <ValidatedTextField
        tooltip={
          intl.formatMessage({
            id: 'assignment.settings.key.tooltip',
            defaultMessage: 'Key is a user-friendly identifier, not editable.'
          })
        }
        disabled={true}
        name="key"
        label={intl.formatMessage({
          id: 'assignment.settings.key.label',
          defaultMessage: 'Key'
        })}
        value={assignment.key}
        margin="normal"
        variant="outlined"
      />
      <ActiveSupplierAutocomplete
        id="active-supplier-select"
        name="supplierKey"
        tooltip={
          intl.formatMessage({
            id: 'assignment.settings.supplierKey.tooltip',
            defaultMessage: 'Users from supplier will be able to open submissions if they have the necessary permissions.'
          })
        }
        label={intl.formatMessage({
          id: 'assignment.settings.supplierKey.label',
          defaultMessage: 'Supplier'
        })}
        variant="outlined"
        margin="normal"
        fullWidth
        fieldErrors={fieldErrors}
        disabled={processing}
        value={supplier}
        onChange={setSupplier}
      />
      <ValidatedTextField
        tooltip={
          intl.formatMessage({
            id: 'assignment.settings.reference.tooltip',
            defaultMessage: 'A reference for the assignment, unique within the specification.'
          })
        }
        fieldErrors={fieldErrors}
        disabled={processing}
        name="reference"
        label={intl.formatMessage({
          id: 'assignment.settings.reference.label',
          defaultMessage: 'Reference'
        })}
        value={reference}
        onChange={(e) => setReference(e.target.value)}
        margin="normal"
        variant="outlined"
      />
      <InputTooltip
        title={
          intl.formatMessage({
            id: 'assignment.settings.active.tooltip',
            defaultMessage: 'If selected the assignment will be visible and can be accessed by suppliers.'
          })
        }
      >
        <BlockFormControlLabel
          control={
            <Checkbox
              color="primary"
              name="active"
              checked={active}
              onChange={(e, checked) => setActive(checked)}
              disabled={processing}
            />
          }
          label={intl.formatMessage({
            id: 'assignment.settings.active.label',
            defaultMessage: 'Active?'
          })}
        />
      </InputTooltip>
      <InputTooltip
        title={
          intl.formatMessage({
            id: 'assignment.settings.restricted.tooltip',
            defaultMessage: 'If selected, only specified users from the supplier group will be able to open submissions.'
          })
        }
      >
        <BlockFormControlLabel
          control={
            <Checkbox
              color="primary"
              name="restricted"
              checked={restricted}
              onChange={(e, checked) => setRestricted(checked)}
              disabled={processing}
            />
          }
          label={intl.formatMessage({
            id: 'assignment.settings.restricted.label',
            defaultMessage: 'Restrict to specified members?'
          })}
        />
      </InputTooltip>
      <FormButtons>
        <DefaultButton
          name="updateAssignment"
          onClick={validateAndSubmit}
          disabled={processing}
          startIcon={<SaveIcon />}
        >
          <FormattedMessage id="assignment.settings.saveButton" defaultMessage="Save" />
        </DefaultButton>
      </FormButtons>
    </PaddedPaper>
  );
};

export default AssignmentSettingsForm;
