import { FC, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import FileCopy from '@mui/icons-material/FileCopy';
import { DialogContent, Divider } from '@mui/material';

import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';

import { validate } from '../../../../../util';
import { intl } from '../../../../../Internationalization';
import * as AssignmentApi from '../../../../../api/assignment';
import * as CopyAssignmentsApi from '../../../../../api/copyAssignments';
import { extractErrorMessage } from '../../../../../api/endpoints';
import { CopyAssignmentsRequest, ProjectDetail, SpecificationDetail } from '../../../../../types';
import { PaddedDialogActions, DefaultButton, SpecificationAutocomplete, ProjectAutocomplete, Loading } from '../../../../../components';

import { SpecificationContext } from '../SpecificationContext';

interface NewAssignmentFormProps {
  onClose: () => void;
  onRefreshAssignments: () => void;
}

const CopyAssignmentsForm: FC<NewAssignmentFormProps> = ({ onClose, onRefreshAssignments }) => {
  const context = useContext(SpecificationContext);
  const { enqueueSnackbar } = useSnackbar();

  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const [project, setProject] = useState<ProjectDetail | null>(context.project);
  const [specification, setSpecification] = useState<SpecificationDetail | null>(null);

  const selectProject = (newProject: ProjectDetail | null) => {
    setProject(newProject);
    setSpecification(null);
    setFieldErrors(undefined);
  };

  const validateAndSubmitCopyAssignments = async () => {
    const settings: Partial<CopyAssignmentsRequest> = {
      sourceSpecificationKey: specification?.key
    };

    setProcessing(true);
    setFieldErrors(undefined);
    try {
      copyAssignments(
        await validate(AssignmentApi.COPY_ASSIGNMENT_VALIDATOR, settings)
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const copyAssignments = async (request: CopyAssignmentsRequest) => {
    try {
      await CopyAssignmentsApi.copyAssignments(context.specificationKey, request);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'assignment.copy.copySuccess',
          defaultMessage: 'Assignments copied successfully'
        }, { project: project?.name, specification: specification?.name }),
        { variant: "success" }
      );
      onRefreshAssignments();
      onClose();
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'assignment.copy.copyError',
          defaultMessage: 'Failed to copy assignments'
        })
      ), { variant: "error" });
      setProcessing(false);
    }
  };

  const renderContent = () => {
    if (processing) {
      return (
        <Loading
          loadingText={
            intl.formatMessage({
              id: 'assignment.copy.copying',
              defaultMessage: 'Copying assignments…'
            })
          }
        />
      );
    }
    return (
      <>
        <ProjectAutocomplete
          id="project-select"
          tooltip={
            intl.formatMessage({
              id: 'assignment.copy.sourceProject.tooltip',
              defaultMessage: 'Select the project to copy assignments from.'
            })
          }
          name="sourceProjectKey"
          label={intl.formatMessage({
            id: 'assignment.copy.sourceProject.label',
            defaultMessage: 'Project'
          })}
          fieldErrors={fieldErrors}
          disabled={processing}
          value={project}
          onChange={selectProject}
          margin="normal"
          variant="outlined"
          fullWidth
        />
        <SpecificationAutocomplete
          id="specification-select"
          tooltip={
            intl.formatMessage({
              id: 'assignment.copy.sourceSpecification.tooltip',
              defaultMessage: 'Select the specification to copy assignments from.'
            })
          }
          name="sourceSpecificationKey"
          label={intl.formatMessage({
            id: 'assignment.copy.sourceSpecification.label',
            defaultMessage: 'Specification'
          })}
          fieldErrors={fieldErrors}
          disabled={processing}
          value={specification}
          onChange={setSpecification}
          margin="normal"
          variant="outlined"
          projectKey={project?.key}
          filterOptions={options => options.filter(option => option.key !== context.specification.key)}
          fullWidth
        />
      </>
    );
  };

  return (
    <>
      <DialogContent>
        {renderContent()}
      </DialogContent>
      <Divider />
      <PaddedDialogActions>
        <DefaultButton
          id="cancel-copy-assignments"
          color="secondary"
          onClick={onClose}
          disabled={processing}
        >
          <FormattedMessage id="assignment.copy.cancelButton" defaultMessage="Cancel" />
        </DefaultButton>
        <DefaultButton
          id="copy-assignments"
          onClick={validateAndSubmitCopyAssignments}
          disabled={processing || !project}
          startIcon={<FileCopy />}
        >
          <FormattedMessage id="assignment.copy.copyButton" defaultMessage="Copy Assignments" />
        </DefaultButton>
      </PaddedDialogActions>
    </>
  );
};

export default CopyAssignmentsForm;
