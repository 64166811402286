import { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { TableRow, TableCell, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

import { extractErrorMessage } from '../../../api/endpoints';
import * as IdentityProvidersApi from '../../../api/identityProviders';

import {
  AddFab, BrowseTable, FilterPagination, browseTableBody, FilterBar, DefaultButton, MinWidthTableCell, ConfirmDialog,
  ButtonRow, FilterSearch, StyledTableHead
} from '../../../components';
import { intl } from '../../../Internationalization';
import { useBrowseRequest } from '../../../hooks';
import { IdentityProviderMetadata, IDENTITY_PROVIDER_TYPE_METADATA } from '../../../types';

import NewIdentityProvider from './NewIdentityProvider';

export const toIdentityProviderUrl = (provider: IdentityProviderMetadata) => {
  return `/system/identity_providers/${IDENTITY_PROVIDER_TYPE_METADATA[provider.type].type}/${provider.key}`;
};

const IdentityProvidersTableBody = browseTableBody<IdentityProviderMetadata>();

const PAGE_SIZE = 10;

const IdentityProviders: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { request, response, processing, updateRequest, setPage } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, filter: "" },
    onRequest: IdentityProvidersApi.getIdentityProviders
  });

  const [addIdentityProvider, setAddIdentityProvider] = useState<boolean>();
  const [confirmDeleteIdentityProvider, setConfirmDeleteIdentityProvider] = useState<IdentityProviderMetadata>();

  const handleFilterUpdate = useCallback((filter: string) => updateRequest({ filter }), [updateRequest]);

  const onDeleteConfirm = async () => {
    const deleteApi = IDENTITY_PROVIDER_TYPE_METADATA[confirmDeleteIdentityProvider!.type].deleteApi;
    try {
      await deleteApi(confirmDeleteIdentityProvider!.key);
      enqueueSnackbar(intl.formatMessage({
        id: 'identityProvider.deleteSuccess',
        defaultMessage: 'Identity provider deleted'
      }), { variant: 'success' });
      updateRequest();
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'identityProvider.deleteError',
        defaultMessage: 'Failed to delete identity provider'
      })), { variant: 'error' });
    } finally {
      setConfirmDeleteIdentityProvider(undefined);
    }
  };

  const identityProviderRow = (provider: IdentityProviderMetadata) => (
    <TableRow key={provider.key}>
      <TableCell>{provider.name}</TableCell>
      <TableCell>{provider.key}</TableCell>
      <TableCell>{provider.type}</TableCell>
      <MinWidthTableCell>
        <ButtonRow whiteSpace="nowrap">
          <DefaultButton
            className="navigate-to-identity-provider"
            color="grey"
            component={Link}
            to={toIdentityProviderUrl(provider)}
            aria-label={intl.formatMessage({
              id: 'identityProvider.navigateToIdentityProvider.ariaLabel',
              defaultMessage: 'Navigate to identity provider'
            })}
          >
            <EditIcon />
          </DefaultButton>
          <DefaultButton
            className="delete-identity-provider"
            color="grey"
            onClick={() => setConfirmDeleteIdentityProvider(provider)}
            aria-label={intl.formatMessage({
              id: 'identityProvider.deleteIdentityProvider.ariaLabel',
              defaultMessage: 'Delete identity provider'
            })}
          >
            <CloseIcon />
          </DefaultButton>
        </ButtonRow>
      </MinWidthTableCell>
    </TableRow>
  );

  return (
    <Box p={3} id="system-identity-providers">
      <FilterBar
        startInput={
          <FilterSearch
            placeholder={intl.formatMessage({
              id: 'identityProvider.filterSearch.placeholder',
              defaultMessage: 'Filter identity providers…'
            })}
            onSearch={handleFilterUpdate}
          />
        }
        actions={
          <FilterPagination page={request.page} size={request.size} total={response?.total} disabled={processing} setPage={setPage} />
        }
      />
      <BrowseTable>
        <StyledTableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="identityProvider.table.nameColumn" defaultMessage="Name" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="identityProvider.table.keyColumn" defaultMessage="Key" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="identityProvider.table.typeColumn" defaultMessage="Type" />
            </TableCell>
            <MinWidthTableCell>
              <FormattedMessage id="identityProvider.table.actionsColumn" defaultMessage="Actions" />
            </MinWidthTableCell>
          </TableRow>
        </StyledTableHead>
        <IdentityProvidersTableBody
          data={response && response.results}
          mapToRow={identityProviderRow}
          noDataMessage={intl.formatMessage({
            id: 'identityProvider.noData',
            defaultMessage: 'No matching identity providers.'
          })}
          numCols={4}
        />
      </BrowseTable>
      {
        addIdentityProvider && <NewIdentityProvider onCancel={() => setAddIdentityProvider(false)} />
      }
      {
        confirmDeleteIdentityProvider &&
        <ConfirmDialog
          id="confirm-delete-identity-provider"
          isOpen={true}
          text={intl.formatMessage({
            id: 'identityProvider.confirmDelete.text',
            defaultMessage: 'Are you sure you want to delete {name}?'
          }, { name: confirmDeleteIdentityProvider.name })}
          confirmBtnText={intl.formatMessage({
            id: 'identityProvider.confirmDelete.confirmButton',
            defaultMessage: 'Delete'
          })}
          confirmAction={onDeleteConfirm}
          closeAction={() => setConfirmDeleteIdentityProvider(undefined)}
        />
      }
      <AddFab
        id="add-identity-provider"
        onClick={() => setAddIdentityProvider(true)}
        aria-label={intl.formatMessage({
          id: 'identityProvider.addFab.addIdentityProvider.ariaLabel',
          defaultMessage: 'Add identity provider'
        })}
      />
    </Box>
  );
};

export default IdentityProviders;
