import { FC, useContext } from 'react';
import { Link as RouterLink, Route, Navigate, Routes } from 'react-router-dom';

import { Link, Typography } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import { AppDecorator, HeaderBreadcrumb, RouterTab, RouterTabs } from '../../../../../components';
import { intl } from '../../../../../Internationalization';
import { useRouterTab } from '../../../../../hooks';

import { SavedMappingContext } from './SavedMappingContext';
import SavedMappingSettings from './SavedMappingSettings';
import SampleData from './SampleData';
import SchemaMapping from './SchemaMapping';

const SavedMappingRouting: FC = () => {
  const { assignment, savedMapping } = useContext(SavedMappingContext);
  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="saved-mapping-tabs" value={routerTab}>
      <RouterTab
        id="settings-tab"
        value="settings"
        label={intl.formatMessage({
          id: 'myAssignment.savedMapping.routing.tabs.settings',
          defaultMessage: 'Settings'
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="sample-data-tab"
        value="sample_data"
        label={intl.formatMessage({
          id: 'myAssignment.savedMapping.routing.tabs.sampleData',
          defaultMessage: 'Sample Data'
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="schema-mapping-tab"
        value="schema_mapping"
        label={intl.formatMessage({
          id: 'myAssignment.savedMapping.routing.tabs.schemaMapping',
          defaultMessage: 'Schema Mapping'
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  const renderBreadcrumb = () => (
    <HeaderBreadcrumb>
      <Link component={RouterLink} to={`/my_assignments/${assignment.key}/saved_mappings`}>
        <AssignmentIcon
          titleAccess={intl.formatMessage({
            id: 'myAssignment.savedMapping.breadcrumb.assignment.titleAccess',
            defaultMessage: 'Assignment'
          })}
        />
        {assignment.reference}
      </Link>
      <Typography>
        <SyncAltIcon
          titleAccess={intl.formatMessage({
            id: 'myAssignment.savedMapping.breadcrumb.savedMapping.titleAccess',
            defaultMessage: 'Saved mapping'
          })}
        />
        {savedMapping.name}
      </Typography>
    </HeaderBreadcrumb>
  );

  return (
    <AppDecorator renderTabs={renderTabs} renderBreadcrumb={renderBreadcrumb}>
      <Routes>
        <Route path="settings" element={<SavedMappingSettings />} />
        <Route path="sample_data" element={<SampleData />} />
        <Route path="schema_mapping" element={<SchemaMapping />} />
        <Route path="*" element={<Navigate to="settings" replace />} />
      </Routes>
    </AppDecorator>
  );
};

export default SavedMappingRouting;
