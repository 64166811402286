import React, { FC } from 'react';

import { Box, Divider, Typography, CardContent } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

import NavigationCard from './NavigationCard';

interface IconCardProps {
  icon: React.ComponentType<SvgIconProps>;
  title: string;
  description: string;
  linkUrl: string;
}

const IconCard: FC<IconCardProps> = ({ icon: Icon, linkUrl, title, description, ...rest }) => (
  <NavigationCard to={linkUrl} className="IconCard" {...rest}>
    <Box display="flex" flexDirection="column" alignItems="center" py={1}>
      <Icon sx={{ fontSize: "125px" }} color="action" />
    </Box>
    <Divider />
    <CardContent>
      <Typography gutterBottom variant="h5">
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        {description}
      </Typography>
    </CardContent>
  </NavigationCard>
);

export default IconCard;
