import { AxiosPromise } from "axios";
import Schema from 'async-validator';

import { OAuth2IdentityProviderSettings, OAuth2IdentityProviderDetail, CreateOAuth2IdentityProviderRequest } from "../types";
import { absoluteHttpUriValidator } from "../util";
import { intl } from "../Internationalization";

import { AXIOS } from "./endpoints";

export function createIdentityProvider(request: CreateOAuth2IdentityProviderRequest): AxiosPromise<OAuth2IdentityProviderDetail> {
  return AXIOS.post('/identity_providers/oauth2', request);
}

export function getIdentityProvider(identityProviderKey: string): AxiosPromise<OAuth2IdentityProviderDetail> {
  return AXIOS.get(`/identity_providers/oauth2/${identityProviderKey}`);
}

export function updateIdentityProvider(
  identityProviderKey: string, request: OAuth2IdentityProviderSettings
): AxiosPromise<OAuth2IdentityProviderDetail> {
  return AXIOS.post(`/identity_providers/oauth2/${identityProviderKey}`, request);
}

export function deleteIdentityProvider(identityProviderKey: string): AxiosPromise<void> {
  return AXIOS.delete(`/identity_providers/oauth2/${identityProviderKey}`);
}

export const OAUTH_IDENTITY_PROVIDER_SERVICE_SETTINGS_VALIDATOR = new Schema({
  authenticationMethod: {
    required: true,
    message: intl.formatMessage({
      id: 'oAuth2.validator.authenticationMethod.required',
      defaultMessage: 'Please select an authentication method'
    })
  },
  clientId: {
    required: true,
    message: intl.formatMessage({
      id: 'oAuth2.validator.clientId.required',
      defaultMessage: 'Please provide a clientId'
    })
  },
  clientSecret: {
    required: true,
    message: intl.formatMessage({
      id: 'oAuth2.validator.clientSecret.required',
      defaultMessage: 'Please provide a client secret'
    })
  },
  authorizationUri: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'oAuth2.validator.authorizationUri.required',
        defaultMessage: 'Please provide an authorization URI'
      })
    },
    absoluteHttpUriValidator
  ],
  tokenUri: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'oAuth2.validator.tokenUri.required',
        defaultMessage: 'Please provide a token URI'
      })
    },
    absoluteHttpUriValidator
  ],
  jwkSetUri: absoluteHttpUriValidator,
  userInfoUri: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'oAuth2.validator.userInfoUri.required',
        defaultMessage: 'Please provide a user info URI'
      })
    },
    absoluteHttpUriValidator
  ],
  userNameAttributeName: {
    required: true,
    message: intl.formatMessage({
      id: 'oAuth2.validator.userNameAttributeName.required',
      defaultMessage: 'Please provide a user name attribute name'
    })
  },
  userInfoAuthenticationMethod: {
    required: true,
    message: intl.formatMessage({
      id: 'oAuth2.validator.userInfoAuthenticationMethod.required',
      defaultMessage: 'Please select a user info authentication method'
    })
  }
});
