import { Options, stateToHTML } from "draft-js-export-html";
import { EntityInstance, EditorState } from "draft-js";

const TO_HTML_OPTIONS: Options = {
  entityStyleFn: (entity: EntityInstance) => {
    if (entity.getType() === 'LINK') {
      const data = entity.getData();
      return {
        element: 'a',
        attributes: {
          href: data.url,
          target: data.targetOption
        }
      };
    }
  }
};

export const htmlFromEditorState = (editorState: EditorState) => {
  return stateToHTML(editorState.getCurrentContent(), TO_HTML_OPTIONS);
};

export const textFromEditorState = (editorState: EditorState) => {
  return editorState.getCurrentContent().getPlainText();
};

