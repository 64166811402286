import Schema, { ValidateOption, InternalRuleItem } from "async-validator";
import { isUri } from "valid-url";
import { parse, serialize, normalize } from 'uri-js';
import emailAddresses from 'email-addresses';

import { intl } from "../Internationalization";

export const SLUG_KEY_VALIDATOR = /^$|^[A-Z0-9][A-Z0-9_]*$/;
export const URL_SAFE_VALIDATOR = /^[^\\/;%]*$/;
export const NOT_BLANK_REGEX = /.*\S.*/;

const minOccurencesRegex = (count: number, characterClass: string, regexFlags?: string) => (
  new RegExp(`(?:.*?${characterClass}){${count},}.*$`, regexFlags)
);

export const uppercaseCountRegex = (minUppercase: number) => minOccurencesRegex(minUppercase, '[A-Z]');
export const lowercaseCountRegex = (minLowercase: number) => minOccurencesRegex(minLowercase, '[a-z]');
export const numberCountRegex = (minNumbers: number) => minOccurencesRegex(minNumbers, '[0-9]', 'i');
export const specialCharacterCountRegex = (minSpecialCharacter: number) => minOccurencesRegex(minSpecialCharacter, '[^a-zA-Z0-9]');

export const nameLengthValidator = {
  max: 70,
  message: intl.formatMessage({
    id: 'validator.name.length',
    defaultMessage: 'Must be 70 characters or fewer'
  })
};

export const referenceLengthValidator = {
  max: 128,
  message: intl.formatMessage({
    id: 'validator.reference.length',
    defaultMessage: 'Must be 128 characters or fewer'
  })
};

export const descriptionLengthValidator = {
  max: 255,
  message: intl.formatMessage({
    id: 'validator.description.length',
    defaultMessage: 'Must be 255 characters or fewer'
  })
};

interface DuplicateValidatorProps {
  regex: RegExp;
  existingValue?: () => string;
  checkUnique: (value: string) => Promise<boolean>;
  alreadyExistsMessage: string;
  errorMessage: string;
}

export const duplicateValidator = ({ regex, existingValue, checkUnique, alreadyExistsMessage, errorMessage }: DuplicateValidatorProps) => ({
  validator(rule: InternalRuleItem, value: any, callback: (error?: string) => void) {
    if (!value.match(regex) || (existingValue && existingValue() === value)) {
      callback();
      return;
    }
    checkUnique(value).then(unique => {
      if (unique) {
        callback();
        return;
      }
      callback(alreadyExistsMessage);
    }).catch(() => {
      callback(errorMessage);
    });
  }
});

/**
 * Validates returning a promise which is rejected on validation failure
 */
export const validate = <T extends object>(validator: Schema, source: Partial<T>, options?: ValidateOption): Promise<T> => {
  return new Promise(
    (resolve, reject) => {
      validator.validate(source, options ? options : {}, (errors, fieldErrors) => {
        if (errors) {
          reject(fieldErrors);
        } else {
          resolve(source as T);
        }
      });
    }
  );
};

export const internetAddressValidator = {
  validator(rule: any, value: any, callback: (error?: string) => void) {
    if (value && emailAddresses({ input: value }) === null) {
      callback(intl.formatMessage({
        id: "validator.internetAddress.invalidAddress",
        defaultMessage: "Value must be a valid email address which may include the sender name, e.g. 'Data Gateway <no-reply@example.com>'"
      }));
    } else {
      callback();
    }
  }
};

export const slugKeyValidator = {
  validator(rule: InternalRuleItem, value: any, callback: (error?: string) => void) {
    if (value && !SLUG_KEY_VALIDATOR.test(value)) {
      callback(intl.formatMessage({
        id: "validator.slugKey.invalidCharacter",
        defaultMessage: "Value must start with a letter or number and can only contain upper case letters (A-Z), numbers and underscores"
      }));
    } else {
      callback();
    }
  }
};

export const urlSafeValidator = {
  validator(rule: InternalRuleItem, value: any, callback: (error?: string) => void) {
    if (value && !URL_SAFE_VALIDATOR.test(value)) {
      callback(intl.formatMessage({
        id: "validator.urlSafe.invalidCharacter",
        defaultMessage: "Value must not contain the following characters: / \\ ; %"
      }));
    } else {
      callback();
    }
  }
};

export const portValidator = {
  validator(rule: InternalRuleItem, value: any, callback: (error?: string) => void) {
    if (value === undefined || value === null) {
      callback();
    } else if (isNaN(value)) {
      callback(intl.formatMessage({
        id: "validator.port.isNan",
        defaultMessage: "Port must be a number"
      }));
    } else if (value < 1 || value > 65535) {
      callback(intl.formatMessage({
        id: "validator.port.invalidRange",
        defaultMessage: "Port must range between 1 and 65535"
      }));
    } else if (!Number.isInteger(value)) {
      callback(intl.formatMessage({
        id: "validator.port.notAnInteger",
        defaultMessage: "Port must be an integer"
      }));
    } else {
      callback();
    }
  }
};

export const ldapUrlValidator = () => ({
  validator(rule: InternalRuleItem, value: any, callback: (error?: string) => void) {
    if (!value) {
      return callback();
    }

    const isValidLdapUri = () => {
      const parsedUrl = parse(value);
      if (parsedUrl.query || parsedUrl.fragment || parsedUrl.userinfo) {
        return false;
      }
      if (parsedUrl.path && parsedUrl.path !== '/') {
        return false;
      }
      return parsedUrl.host && (parsedUrl.scheme === 'ldap' || parsedUrl.scheme === 'ldaps');
    };

    if (isUri(value) && isValidLdapUri()) {
      callback();
    } else {
      callback(intl.formatMessage({
        id: "validator.ldapUrl.invalidUrl",
        defaultMessage: 'Please provide a valid LDAP server address, e.g. "ldaps://ldap.example.com"'
      }));
    }
  }
});

export const baseUriValidator = {
  validator(rule: InternalRuleItem, value: any, callback: (error?: string) => void) {
    if (!value) {
      return callback();
    }

    const isValidBaseUri = () => {
      const parsedUrl = parse(value);
      if (parsedUrl.query || parsedUrl.fragment || parsedUrl.userinfo) {
        return false;
      }
      return parsedUrl.host && (parsedUrl.scheme === 'http' || parsedUrl.scheme === 'https');
    };

    if (isUri(value) && isValidBaseUri()) {
      callback();
    } else {
      callback(intl.formatMessage({
        id: "validator.baseUri.invalidUrl",
        defaultMessage: 'Please provide a valid URL, e.g. "https://www.example.com/"'
      }));
    }
  }
};

export const absoluteHttpUriValidator = {
  validator(rule: InternalRuleItem, value: any, callback: (error?: string) => void) {
    if (!value) {
      return callback();
    }

    const isValidBaseUri = () => {
      const parsedUrl = parse(value);
      if (parsedUrl.reference !== 'absolute') {
        return false;
      }
      return parsedUrl.scheme === 'http' || parsedUrl.scheme === 'https';
    };

    if (isUri(value) && isValidBaseUri()) {
      callback();
    } else {
      callback(intl.formatMessage({
        id: "validator.absoluteHttpUri.invalidUrl",
        defaultMessage: 'Please provide an absolute URL, e.g. "https://www.example.com/"'
      }));
    }
  }
};

export const notTrimmableValidator = {
  validator(rule: InternalRuleItem, value: string, callback: (error?: string) => void) {
    if (value.trim() !== value) {
      callback(intl.formatMessage({
        id: "validator.notTrimmable.invalidCharacter",
        defaultMessage: "Value must not have leading or trailing whitespace"
      }));
    } else {
      callback();
    }
  }
};

export const normalizeUri = (uri: string) => {
  return serialize(normalize(parse(uri)));
};
