import { FC, useContext } from "react";

import { Box, Divider, Drawer, IconButton, Toolbar, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { ApplicationContext } from "../../../contexts/application";

import { DRAWER_WIDTH } from "../Layout";
import SidebarMenu from "./SidebarMenu";
import { intl } from "../../../Internationalization";

interface SidebarProps {
  sidebarOpen: boolean;
  handleDrawerToggle: () => void;
  permanentDrawer: boolean;
}

const Sidebar: FC<SidebarProps> = ({ sidebarOpen, handleDrawerToggle, permanentDrawer }) => {
  const { applicationDetails } = useContext(ApplicationContext);

  return (
    <Drawer
      anchor="left"
      sx={[
        {
          '& .MuiPaper-root ': {
            width: DRAWER_WIDTH,
            ...(sidebarOpen ?
              {
                transition: (theme) => theme.transitions.create('margin', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
              } : {
                transition: (theme) => theme.transitions.create(['margin'], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
                overflowX: 'hidden',
                marginLeft: -DRAWER_WIDTH
              }
            )
          }
        }
      ]}
      onClose={handleDrawerToggle}
      open={sidebarOpen}
      variant={permanentDrawer ? 'permanent' : 'temporary'}
      id="sidebar"
    >
      <Toolbar
        sx={{
          '&&': {
            display: 'flex',
            justifyContent: 'space-between',
            pr: 0,
          }
        }}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="h6">
            {applicationDetails.site.name}
          </Typography>
        </Box>
        <IconButton
          id="close-sidebar"
          onClick={handleDrawerToggle}
          sx={{ mx: 1 }}
          size="large"
          aria-label={intl.formatMessage({
            id: 'components.sidebar.closeSidebar.ariaLabel',
            defaultMessage: 'Close sidebar'
          })}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Divider absolute />
      </Toolbar>
      <SidebarMenu />
    </Drawer>
  );
};

export default Sidebar;
