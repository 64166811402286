import { useContext } from 'react';
import { Grid, Container } from '@mui/material';

import * as ProjectApi from '../../../api/project';
import { ReceiverAdminOnly, UploadImage } from '../../../components';
import { FileUploadConfig } from '../../../api/endpoints';
import * as ProjectMediaApi from '../../../api/projectMedia';
import { MediaDetail } from '../../../types';
import { intl } from '../../../Internationalization';

import { ProjectContext } from './ProjectContext';
import ProjectSettingsForm from './ProjectSettingsForm';
import ProjectManagement from './ProjectManagement';

function ProjectSettings() {
  const supplierContext = useContext(ProjectContext);
  const { projectKey, project, projectUpdated } = supplierContext;

  const uploadImage = (image: File, fileUploadConfig?: FileUploadConfig) => (
    ProjectApi.uploadProjectImage(projectKey, image, fileUploadConfig)
  );
  const deleteImage = () => ProjectApi.deleteProjectImage(projectKey);

  const imageUploaded = (image: MediaDetail) => projectUpdated({ ...project, image });
  const imageDeleted = () => projectUpdated({ ...project, image: undefined });

  return (
    <Container maxWidth="lg" id="project-settings" disableGutters>
      <Grid container spacing={3} id="project-configuration-content">
        <Grid item xs={12} sm={6}>
          <ProjectSettingsForm />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UploadImage
            id="project-settings-image"
            title={intl.formatMessage({
              id: 'project.settings.imageUpload.title',
              defaultMessage: 'Project Image'
            })}
            image={project.image}
            imageUrl={ProjectMediaApi.projectImageOrDefault(project)}
            uploadHandler={uploadImage}
            deleteHandler={deleteImage}
            onUploaded={imageUploaded}
            onDeleted={imageDeleted}
          />
        </Grid>
        <ReceiverAdminOnly>
          <Grid item xs={12}>
            <ProjectManagement />
          </Grid>
        </ReceiverAdminOnly>
      </Grid>
    </Container>
  );
}

export default ProjectSettings;
