import { FC } from 'react';
import { TableCell, Box, Avatar, Typography } from '@mui/material';

import { SessionSchema, Task, TaskKind, OpenDataTask, CopyToTask, CommitTask, taskMetaData } from '../../../../../../types';
import { dataStoreTypeMetaDataFromPath } from '../../../../../../util';
import { intl } from '../../../../../../Internationalization';

interface DataTransferLavelCallProps {
  task: OpenDataTask | CopyToTask | CommitTask;
  sessionSchema: SessionSchema;
}

const DataTransferLabelCell: FC<DataTransferLavelCallProps> = ({ task, sessionSchema }) => {
  const { label: taskKindLabel, schemaKey } = taskMetaData(task);
  const { icon: DataStoreTypeIcon, label: dataStoreTypeLabel } = dataStoreTypeMetaDataFromPath(sessionSchema, task.datastore, schemaKey);

  return (
    <TableCell>
      <Box display="flex" alignItems="center">
        <DataStoreTypeIcon fontSize="large" />
        <Box pl={1} flexDirection="column">
          <Typography>
            {task.customLabel ? task.customLabel : taskKindLabel}
          </Typography>
          <Typography variant="body2">
            {dataStoreTypeLabel}
          </Typography>
        </Box>
      </Box>
    </TableCell>
  );
};

interface LabelCellProps {
  task: Task;
  secondaryText: string;
}

const LabelCell: FC<LabelCellProps> = ({ task, secondaryText }) => {
  const { icon: Icon, label } = taskMetaData(task);

  return (
    <TableCell>
      <Box display="flex" alignItems="center">
        <Avatar sx={{ height: 35, width: 35 }}>
          <Icon />
        </Avatar>
        <Box pl={1} flexDirection="column">
          <Typography>
            {task.customLabel ? task.customLabel : label}
          </Typography>
          <Typography variant="body2">{secondaryText}</Typography>
        </Box>
      </Box>
    </TableCell>
  );
};

interface TaskLabelCellProps {
  task: Task;
  sessionSchema: SessionSchema;
}

const TaskLabelCell: FC<TaskLabelCellProps> = ({ task, sessionSchema }) => {
  switch (task.kind) {
    case TaskKind.OpenData:
    case TaskKind.CopyTo:
    case TaskKind.Commit:
      return (
        <DataTransferLabelCell task={task} sessionSchema={sessionSchema} />
      );
    case TaskKind.DiscoverRules:
      return (
        <LabelCell
          task={task}
          secondaryText={
            intl.formatMessage({
              id: 'specification.configuration.taskConfig.discoverRules.label',
              defaultMessage: 'Task limit: {limit}'
            }, { limit: task.limit })
          }
        />
      );
    case TaskKind.ApplyActionMap:
      return (
        <LabelCell task={task} secondaryText={task.actionmap} />
      );
    case TaskKind.CheckRules:
      return (
        <LabelCell
          task={task}
          secondaryText={
            intl.formatMessage({
              id: 'specification.configuration.taskConfig.checkRules.label',
              defaultMessage: '{ruleCount} rule(s)'
            }, { ruleCount: task.rules.length })
          }
        />
      );
    case TaskKind.ApplyActions:
      return (
        <LabelCell
          task={task}
          secondaryText={
            intl.formatMessage({
              id: 'specification.configuration.taskConfig.applyActions.label',
              defaultMessage: '{actionCount} action(s)'
            }, { actionCount: task.actions.length })
          }
        />
      );
    case TaskKind.Pause:
      return (
        <LabelCell
          task={task}
          secondaryText={
            intl.formatMessage({
              id: 'specification.configuration.taskConfig.pause.label',
              defaultMessage: 'Session will pause'
            })
          }
        />
      );
    case TaskKind.BuildTopology:
      return (
        <LabelCell
          task={task}
          secondaryText={
            intl.formatMessage({
              id: 'specification.configuration.taskConfig.buildTopology.label',
              defaultMessage: '{classCount} class(es)'
            }, { classCount: task.classes.length })
          }
        />
      );
    default:
      return (
        <LabelCell task={task} secondaryText="" />
      );
  }
};

export default TaskLabelCell;
