import { FC, useContext, useEffect, useState } from 'react';

import * as SiteSettingsApi from '../../../api/siteSettings';
import { extractErrorMessage } from '../../../api/endpoints';
import { Loading } from '../../../components';
import { SiteSettingsDetail } from '../../../types';
import { ApplicationContext } from '../../../contexts/application';
import { useErrorBlock } from '../../../contexts/error-block';
import { intl } from '../../../Internationalization';

import SiteRouting from './SiteRouting';
import { SiteContext } from './SiteContext';

const Site: FC = () => {
  const { raiseError } = useErrorBlock();
  const { refresh } = useContext(ApplicationContext);

  const [siteSettings, setSiteSettings] = useState<SiteSettingsDetail>();

  useEffect(() => {
    const performFetch = async () => {
      try {
        const response = await SiteSettingsApi.getSiteSettings();
        setSiteSettings(response.data);
      } catch(error: any) {
        raiseError(extractErrorMessage(error, intl.formatMessage({
          id: 'site.fetchSettingsError',
          defaultMessage: 'Failed to fetch site settings'
        })));
      }
    };

    performFetch();
  }, [raiseError]);

  if(siteSettings){
    return (
      <SiteContext.Provider
        value={{
          siteSettings,
          siteSettingsUpdated: setSiteSettings,
          refreshApplicationContext: refresh
        }}
      >
        <SiteRouting />
      </SiteContext.Provider>
    );
  }

  return(
    <Loading />
  );
};

export default Site;
