import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Slide, BackdropProps } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { intl } from '../Internationalization';
import { PaddedDialogActions, DefaultButton } from './';

interface ConfirmDialogProps {
  id: string;
  confirmAction: () => void;
  closeAction: () => void;
  isOpen: boolean;
  text: string;
  title?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  BackdropProps?: Partial<BackdropProps>;
  disabled?: boolean;
}

const TransitionComponent = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) => <Slide direction="up" ref={ref} {...props} />);

const ConfirmDialog = ({
  id,
  confirmAction,
  closeAction,
  isOpen,
  text,
  title,
  confirmBtnText = intl.formatMessage({ id: 'components.confirmDialog.confirmButton', defaultMessage: 'Confirm' }),
  cancelBtnText = intl.formatMessage({ id: 'components.confirmDialog.cancelButton', defaultMessage: 'Cancel' }),
  BackdropProps: backdropProps,
  disabled = false
}: ConfirmDialogProps) => (
  <Dialog
    id={id}
    open={isOpen}
    onClose={disabled ? undefined : closeAction}
    TransitionComponent={TransitionComponent}
    BackdropProps={backdropProps}
  >
    {
      title && <DialogTitle>{title}</DialogTitle>
    }
    <DialogContent>
      <DialogContentText>
        {text}
      </DialogContentText>
    </DialogContent>
    <PaddedDialogActions>
      <DefaultButton name="cancel" onClick={closeAction} color="secondary" disabled={disabled}>{cancelBtnText}</DefaultButton>
      <DefaultButton name="confirm" onClick={confirmAction} disabled={disabled}>{confirmBtnText}</DefaultButton>
    </PaddedDialogActions>
  </Dialog>
);

export default ConfirmDialog;
