import { FC, useState, useEffect, useContext } from 'react';

import { extractErrorMessage } from '../../../../api/endpoints';
import * as OAuth2IdentityProviderApi from '../../../../api/oAuth2IdentityProvider';
import { useErrorBlock } from '../../../../contexts/error-block';
import { ApplicationContext } from '../../../../contexts/application';
import { Loading } from '../../../../components';
import { OAuth2IdentityProviderDetail } from '../../../../types';
import { intl } from '../../../../Internationalization';
import { useRequiredParams } from '../../../../hooks';

import { OAuth2IdentityProviderContext } from './OAuth2IdentityProviderContext';
import OAuth2IdentityProviderRouting from './OAuth2IdentityProviderRouting';

const OAuth2IdentityProvider: FC = () => {
  const { identityProviderKey } = useRequiredParams<{ identityProviderKey: string }>();

  const { raiseError } = useErrorBlock();
  const { refresh } = useContext(ApplicationContext);
  const [identityProvider, setIdentityProvider] = useState<OAuth2IdentityProviderDetail>();

  useEffect(() => {
    fetchIdentityProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchIdentityProvider = async () => {
    OAuth2IdentityProviderApi.getIdentityProvider(identityProviderKey).then(response => {
      setIdentityProvider(response.data);
    }).catch(error => {
      raiseError(extractErrorMessage(error, intl.formatMessage({
        id: 'oauth2.loadError',
        defaultMessage: 'Failed to load OAuth2 identity provider details'
      })));
    });
  };

  const identityProviderUpdated = (updatedIdentityProvider: OAuth2IdentityProviderDetail) => {
    setIdentityProvider(updatedIdentityProvider);
    refresh();
  };

  if (!identityProvider) {
    return (
      <Loading />
    );
  }

  return (
    <OAuth2IdentityProviderContext.Provider
      value={{
        identityProvider,
        identityProviderUpdated
      }}
    >
      <OAuth2IdentityProviderRouting />
    </OAuth2IdentityProviderContext.Provider>
  );
};

export default OAuth2IdentityProvider;
