import { FC } from "react";
import { View } from "@react-pdf/renderer";

import { AssignmentSvg, BallotSvg, EventSvg, WorkSvg } from '../../../../../components/pdf/PdfIcons';
import { SubmissionDetail } from '../../../../../types';
import { sharedPdfStyles } from '../../../../../components';
import { dateTimeFormat } from "../../../../../util";

import { SubmissionAuthorAvatar } from "./SubmissionAuthorAvatar";
import { SubmissionSupplierAvatar } from "./SubmissionSupplierAvatar";

import { PdfSection } from "../components";
import { SummaryInfo } from "./SummaryInfo";

interface PdfSubmissionSummaryProps {
  submission: SubmissionDetail;
}

const PdfSubmissionSummary: FC<PdfSubmissionSummaryProps> = ({ submission }) => (
  <PdfSection
    gutterBottom
    style={[sharedPdfStyles.rowContainer, sharedPdfStyles.contentPadding]}
  >
    <View style={[sharedPdfStyles.cell_halves]}>
      <SubmissionAuthorAvatar user={submission.user} gutterBottom />
      <SubmissionSupplierAvatar supplier={submission.assignment.supplier} gutterBottom />
      <SummaryInfo
        infoIcon={<EventSvg />}
        infoText={dateTimeFormat(submission.finishedAt)}
      />
    </View>
    <View style={[sharedPdfStyles.cell_halves]}>
      <SummaryInfo
        infoIcon={<WorkSvg />}
        infoText={submission.assignment.specification.project.name}
        gutterBottom
      />
      <SummaryInfo
        infoIcon={<BallotSvg />}
        infoText={submission.assignment.specification.name}
        gutterBottom
      />
      <SummaryInfo
        infoIcon={<AssignmentSvg />}
        infoText={submission.assignment.reference}
      />
    </View>
  </PdfSection>
);

export default PdfSubmissionSummary;
