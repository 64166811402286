import React from "react";

import { SpecificationValidationResult, SpecificationDetail } from "../../../../types";
import { ProjectContextValue } from "../ProjectContext";

export interface SpecificationContextValue extends ProjectContextValue {
  specificationUpdated: (specification: SpecificationDetail) => void;
  validateSpecification: () => void;
  specificationKey: string;
  specification: SpecificationDetail;
  specificationValidationResult: SpecificationValidationResult;
}

const SpecificationContextDefaultValue = {} as SpecificationContextValue;
export const SpecificationContext = React.createContext(
  SpecificationContextDefaultValue
);
