import { styled } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';

interface SuspendedIconProps {
  disabled: boolean;
}

const SuspendedIcon = styled(BlockIcon)<SuspendedIconProps>(({ theme, disabled }) => ({
  color: disabled ? theme.palette.grey[200] : theme.palette.error.main
}));

export default SuspendedIcon;
