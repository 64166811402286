import { FC } from "react";

import { TableHead, TableHeadProps } from "@mui/material";
import grey from "@mui/material/colors/grey";

const StyledTableHead: FC<TableHeadProps> = ({ sx = [], ...props }) => (
  <TableHead sx={[{ bgcolor: grey[50] }, ...(Array.isArray(sx) ? sx : [sx])]} {...props} />
);

export default StyledTableHead;
