import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import StorageIcon from '@mui/icons-material/Storage';
import ExploreIcon from '@mui/icons-material/Explore';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import TocIcon from '@mui/icons-material/Toc';
import TransformIcon from '@mui/icons-material/Transform';
import { SvgIconProps } from '@mui/material/SvgIcon';

import {
  AssignmentReturnedSvg, AssignmentSvg, AssignmentTurnedInSvg, AutorenewInfoSvg, CheckCircleSuccessSvg,
  EditLocationSvg, FactCheckSvg, ExploreSvg, FileCopySvg, HourglassEmptyInfoSvg, PdfIcon, PlaylistAddCheckSvg,
  ReplyInfoSvg, StorageSvg, TimerSvg, TimerWarningSvg, TransformSvg
} from '../components/pdf/PdfIcons';

import SuccessIcon from '../components/icons/SuccessIcon';
import AutorenewInfoIcon from '../components/icons/AutorenewInfoIcon';
import HourglassInfoIcon from '../components/icons/HourglassInfoIcon';
import TimerWarningIcon from '../components/icons/TimerWarningIcon';
import SkippedInfoIcon from '../components/icons/SkippedInfoIcon';
import { FactCheckIcon } from '../components/icons/Mui6Icons';

import { intl } from '../Internationalization';
import { TocSvg } from '../components/pdf/PdfIcons';

import { DataStoreSchemaKey } from '.';

export enum TaskState {
  NOT_STARTED = 'NOT_STARTED',
  RUNNING = 'RUNNING',
  PAUSED = 'PAUSED',
  SKIPPED = 'SKIPPED',
  FINISHED = 'FINISHED'
}

export interface TaskStateMetadata {
  label: string;
  icon: React.ComponentType<SvgIconProps>;
  pdfIcon: PdfIcon;
}

export const TASK_STATE_METADATA: { [type in TaskState]: TaskStateMetadata } = {
  NOT_STARTED: {
    label: intl.formatMessage({
      id: "sessionResult.taskState.metadata.notStarted",
      defaultMessage: "Not Started"
    }),
    icon: HourglassInfoIcon,
    pdfIcon: HourglassEmptyInfoSvg
  },
  RUNNING: {
    label: intl.formatMessage({
      id: "sessionResult.taskState.metadata.running",
      defaultMessage: "Running"
    }),
    icon: AutorenewInfoIcon,
    pdfIcon: AutorenewInfoSvg
  },
  PAUSED: {
    label: intl.formatMessage({
      id: "sessionResult.taskState.metadata.paused",
      defaultMessage: "Paused"
    }),
    icon: TimerWarningIcon,
    pdfIcon: TimerWarningSvg
  },
  SKIPPED: {
    label: intl.formatMessage({
      id: "sessionResult.taskState.metadata.skipped",
      defaultMessage: "Skipped"
    }),
    icon: SkippedInfoIcon,
    pdfIcon: ReplyInfoSvg
  },
  FINISHED: {
    label: intl.formatMessage({
      id: "sessionResult.taskState.metadata.finished",
      defaultMessage: "Finished"
    }),
    icon: SuccessIcon,
    pdfIcon: CheckCircleSuccessSvg
  }
};

export const ACTIVE_TASK_STATES = [TaskState.PAUSED, TaskState.RUNNING];

export enum SessionState {
  NOT_STARTED = "NOT_STARTED",
  RUNNING = "RUNNING",
  PAUSED = "PAUSED",
  FINISHED = "FINISHED",
  WAITING = "WAITING",
  PAUSING = "PAUSING",
  REWINDING = "REWINDING",
  UNKNOWN = "UNKNOWN",
  STOPPING = "STOPPING"
}

export const SESSION_STATE_LABELS: { [type in SessionState]: string } = {
  NOT_STARTED: intl.formatMessage({
    id: "sessionResult.sessionStateLabels.notStarted",
    defaultMessage: "Not Started"
  }),
  RUNNING: intl.formatMessage({
    id: "sessionResult.sessionStateLabels.running",
    defaultMessage: "Running"
  }),
  PAUSED: intl.formatMessage({
    id: "sessionResult.sessionStateLabels.paused",
    defaultMessage: "Paused"
  }),
  FINISHED: intl.formatMessage({
    id: "sessionResult.sessionStateLabels.Finished",
    defaultMessage: "Finished"
  }),
  WAITING: intl.formatMessage({
    id: "sessionResult.sessionStateLabels.waiting",
    defaultMessage: "Waiting"
  }),
  PAUSING: intl.formatMessage({
    id: "sessionResult.sessionStateLabels.pausing",
    defaultMessage: "Pausing"
  }),
  REWINDING: intl.formatMessage({
    id: "sessionResult.sessionStateLabels.rewinding",
    defaultMessage: "Rewinding"
  }),
  UNKNOWN: intl.formatMessage({
    id: "sessionResult.sessionStateLabels.unknown",
    defaultMessage: "Unknown"
  }),
  STOPPING: intl.formatMessage({
    id: "sessionResult.sessionStateLabels.stopping",
    defaultMessage: "Stopping"
  })
};

export enum TaskResultKind {
  ApplyActionMapTask = 'ApplyActionMapTask',
  ApplyActionTask = 'ApplyActionTask',
  CheckRulesTask = 'CheckRulesTask',
  CopyToTask = 'CopyToTask',
  CommitTask = "CommitTask",
  OpenDataTask = 'OpenDataTask',
  PauseTask = "PauseTask",
  BuildTopologyTask = 'BuildTopologyTask',
  DiscoverRulesTask = 'DiscoverRulesTask',
  OpenSchemaTask = 'OpenSchemaTask',
  TransformSchemaTask = 'TransformSchemaTask',
  ValidateSchemaTask = 'ValidateSchemaTask'
}

export interface TaskResultKindMetadata {
  label: string;
  icon: React.ComponentType<SvgIconProps>;
  pdfIcon: PdfIcon;
  schemaKey?: DataStoreSchemaKey;
}

export const taskResultLabel = (taskResult: TaskResult) => taskResult.customLabel || TASK_KIND_METADATA[taskResult.kind].label;

export const TASK_KIND_METADATA: { [type in TaskResultKind]: TaskResultKindMetadata } = {
  ApplyActionMapTask: {
    label: intl.formatMessage({
      id: "sessionResult.taskKindMetadata.applyActionMap",
      defaultMessage: "Apply Action Map"
    }),
    icon: AssignmentReturnedIcon,
    pdfIcon: AssignmentReturnedSvg
  },
  ApplyActionTask: {
    label: intl.formatMessage({
      id: "sessionResult.taskKindMetadata.applyAction",
      defaultMessage: "Apply Action"
    }),
    icon: AssignmentIcon,
    pdfIcon: AssignmentSvg
  },
  CheckRulesTask: {
    label: intl.formatMessage({
      id: "sessionResult.taskKindMetadata.checkRules",
      defaultMessage: "Check Rules"
    }),
    icon: PlaylistAddCheckIcon,
    pdfIcon: PlaylistAddCheckSvg
  },
  CopyToTask: {
    label: intl.formatMessage({
      id: "sessionResult.taskKindMetadata.copyTo",
      defaultMessage: "Copy To"
    }),
    icon: FileCopyIcon,
    pdfIcon: FileCopySvg,
    schemaKey: "exportSchema"
  },
  CommitTask: {
    label: intl.formatMessage({
      id: "sessionResult.taskKindMetadata.commit",
      defaultMessage: "Commit"
    }),
    icon: AssignmentTurnedInIcon,
    pdfIcon: AssignmentTurnedInSvg,
    schemaKey: "importSchema"
  },
  OpenDataTask: {
    label: intl.formatMessage({
      id: "sessionResult.taskKindMetadata.openData",
      defaultMessage: "Open Data"
    }),
    icon: StorageIcon,
    pdfIcon: StorageSvg,
    schemaKey: "importSchema"
  },
  PauseTask: {
    label: intl.formatMessage({
      id: "sessionResult.taskKindMetadata.pause",
      defaultMessage: "Pause"
    }),
    icon: TimerOutlinedIcon,
    pdfIcon: TimerSvg
  },
  BuildTopologyTask: {
    label: intl.formatMessage({
      id: "sessionResult.taskKindMetadata.buildTopology",
      defaultMessage: "Build Topology"
    }),
    icon: EditLocationIcon,
    pdfIcon: EditLocationSvg
  },
  DiscoverRulesTask: {
    label: intl.formatMessage({
      id: "sessionResult.taskKindMetadata.discoverRules",
      defaultMessage: "Discover Rules"
    }),
    icon: ExploreIcon,
    pdfIcon: ExploreSvg
  },
  OpenSchemaTask: {
    label: intl.formatMessage({
      id: "sessionResult.taskKindMetadata.openSchema",
      defaultMessage: "Open Schema"
    }),
    icon: TocIcon,
    pdfIcon: TocSvg
  },
  TransformSchemaTask: {
    label: intl.formatMessage({
      id: "sessionResult.taskKindMetadata.transformSchema",
      defaultMessage: "Transform Schema"
    }),
    icon: TransformIcon,
    pdfIcon: TransformSvg
  },
  ValidateSchemaTask: {
    label: intl.formatMessage({
      id: "sessionResult.taskKindMetadata.validateSchema",
      defaultMessage: "Validate Schema"
    }),
    icon: FactCheckIcon,
    pdfIcon: FactCheckSvg
  }
};

interface FeatureCounts {
  count: number;
  errors: number;
  processed: number;
  total: number;
}

export interface ClassSummary extends FeatureCounts {
  name: string;
}

export interface RuleSummary extends FeatureCounts {
  path: string;
}

export interface ActionSummary extends FeatureCounts {
  path: string;
}

export interface ActionMapSummary extends FeatureCounts {
  ruleSummary: RuleSummary;
  actionSummary: ActionSummary;
}

export interface TaskResultBase extends FeatureCounts {
  kind: TaskResultKind;
  customLabel?: string;
  taskIdentifier: string;
  started: number;
  duration: number;
  status: TaskState;
  reportCount: number;
}

export interface DataTaskResult extends TaskResultBase {
  classes: ClassSummary[];
}

export interface ApplyActionMapTaskResult extends TaskResultBase {
  kind: TaskResultKind.ApplyActionMapTask;
  actionmaps: ActionMapSummary[];
}

export interface ApplyActionTaskResult extends TaskResultBase {
  kind: TaskResultKind.ApplyActionTask;
  actions: ActionSummary[];
}

export interface CheckRulesTaskResult extends TaskResultBase {
  kind: TaskResultKind.CheckRulesTask;
  rules: RuleSummary[];
}

export interface CopyToTaskResult extends DataTaskResult {
  kind: TaskResultKind.CopyToTask;
}

export interface CommitTaskResult extends DataTaskResult {
  kind: TaskResultKind.CommitTask;
}

export interface OpenDataTaskResult extends DataTaskResult {
  kind: TaskResultKind.OpenDataTask;
}

export interface PauseTaskResult extends TaskResultBase {
  kind: TaskResultKind.PauseTask;
  enforcePassThresholds: boolean;
}

export interface TransformSchemaTaskResult extends TaskResultBase {
  kind: TaskResultKind.TransformSchemaTask;
}

export interface ValidateSchemaTaskResult extends TaskResultBase {
  kind: TaskResultKind.ValidateSchemaTask;
}

export interface OpenSchemaTaskResult extends TaskResultBase {
  kind: TaskResultKind.OpenSchemaTask;
}

export interface BuildTopologyTaskResult extends TaskResultBase {
  kind: TaskResultKind.BuildTopologyTask;
}

export interface DiscoverRulesTaskResult extends TaskResultBase {
  kind: TaskResultKind.DiscoverRulesTask;
}

export interface SessionError {
  session: string;
  message: string;
  stackTrace: string;
  timestamp: number;
}

export interface SessionResult {
  status: SessionState;
  errors: SessionError[];
  tasks: TaskResult[];
}

export type TaskResult = ApplyActionMapTaskResult | ApplyActionTaskResult |
  CheckRulesTaskResult | CopyToTaskResult | CommitTaskResult |
  OpenDataTaskResult | PauseTaskResult | BuildTopologyTaskResult |
  DiscoverRulesTaskResult | OpenSchemaTaskResult | TransformSchemaTaskResult |
  ValidateSchemaTaskResult;
