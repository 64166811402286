import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';
import { Container, Typography, Checkbox, Grid } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { extractErrorMessage } from '../../../api/endpoints';
import * as IdentityProvidersApi from '../../../api/identityProviders';
import { PaddedPaper, ValidatedTextField, FormButtons, DefaultButton, BlockFormControlLabel } from '../../../components';
import { IdentityProviderSettings, IdentityProviderDetail } from '../../../types';
import { validate } from '../../../util';
import { intl } from '../../../Internationalization';

import IdentityProviderInformation from './IdentityProviderInformation';

interface IdentityProviderSettingsProps {
  onValidSubmit: (settings: IdentityProviderSettings) => Promise<void>;
  identityProviderSettings: IdentityProviderDetail;
}

const IdentityProviderSettingsForm: FC<IdentityProviderSettingsProps> = ({ identityProviderSettings, onValidSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [processing, setProcessing] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const [name, setName] = useState<string>(identityProviderSettings.name);
  const [ssoRequired, setSsoRequired] = useState<boolean>(identityProviderSettings.ssoRequired);
  const [linkByEmail, setLinkByEmail] = useState<boolean>(identityProviderSettings.linkByEmail);
  const [createMissing, setCreateMissing] = useState<boolean>(identityProviderSettings.createMissing);
  const [emailAttribute, setEmailAttribute] = useState<string>(identityProviderSettings.emailAttribute);
  const [nameAttribute, setNameAttribute] = useState<string>(identityProviderSettings.nameAttribute);

  // TODO - adding group attribute and booleans to this form as a placeholder
  // the parent could take a partial, or these fields could stay here... which might make for a better UI
  // (we could hide feature unless assignment by group is enabled)
  const [groupAttribute, setGroupAttribute] = useState<string>(identityProviderSettings.groupAttribute);
  const [mapSuppliersByGroup, setMapSuppliersByGroup] = useState<boolean>(identityProviderSettings.mapSuppliersByGroup);
  const [mapReceiverByGroup, setMapReceiverByGroup] = useState<boolean>(identityProviderSettings.mapReceiverByGroup);

  const validateAndSubmit = async () => {
    setProcessing(true);
    try {
      handleValidSubmit(
        await validate(
          IdentityProvidersApi.identityProviderSettingsValidator(() => identityProviderSettings.name),
          {
            name,
            ssoRequired,
            linkByEmail,
            createMissing,
            emailAttribute,
            nameAttribute,
            groupAttribute,
            mapSuppliersByGroup,
            mapReceiverByGroup
          })
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const handleValidSubmit = async (identityProvider: IdentityProviderSettings) => {
    setFieldErrors(undefined);
    try {
      await onValidSubmit(identityProvider);
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'identityProvider.settings.saveError',
        defaultMessage: 'Failed to update identity provider'
      })), { variant: 'error' });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Container maxWidth="lg" id="system-identity-provider-settings" disableGutters>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <PaddedPaper>
            <Typography variant="h5" gutterBottom>
              <FormattedMessage id="identityProvider.settings.title" defaultMessage="Identity Provider Settings" />
            </Typography>
            <ValidatedTextField
              fieldErrors={fieldErrors}
              disabled={true}
              name="key"
              label={intl.formatMessage({
                id: 'identityProvider.settings.key.label',
                defaultMessage: 'Key'
              })}
              value={identityProviderSettings.key}
              margin="normal"
              variant="outlined"

            />
            <ValidatedTextField
              fieldErrors={fieldErrors}
              disabled={processing}
              name="name"
              label={intl.formatMessage({
                id: 'identityProvider.settings.name.label',
                defaultMessage: 'Name'
              })}
              value={name}
              onChange={event => setName(event.target.value)}
              margin="normal"
              variant="outlined"
            />
            <BlockFormControlLabel
              control={
                <Checkbox
                  name="linkByEmail"
                  checked={linkByEmail}
                  onChange={event => setLinkByEmail(event.target.checked)}
                />
              }
              label={intl.formatMessage({
                id: 'identityProvider.settings.linkByEmail.label',
                defaultMessage: 'Link users by email?'
              })}
            />
            <BlockFormControlLabel
              control={
                <Checkbox
                  name="createMissing"
                  checked={createMissing}
                  onChange={event => setCreateMissing(event.target.checked)}
                />
              }
              label={intl.formatMessage({
                id: 'identityProvider.settings.createMissing.label',
                defaultMessage: 'Create missing users on login?'
              })}
            />
            <ValidatedTextField
              fieldErrors={fieldErrors}
              disabled={processing}
              name="emailAttribute"
              label={intl.formatMessage({
                id: 'identityProvider.settings.emailAttribute.label',
                defaultMessage: 'Email Attribute'
              })}
              value={emailAttribute}
              onChange={event => setEmailAttribute(event.target.value)}
              margin="normal"
              variant="outlined"
            />
            <ValidatedTextField
              fieldErrors={fieldErrors}
              disabled={processing}
              name="nameAttribute"
              label={intl.formatMessage({
                id: 'identityProvider.settings.nameAttribute.label',
                defaultMessage: 'Name Attribute'
              })}
              value={nameAttribute}
              onChange={event => setNameAttribute(event.target.value)}
              margin="normal"
              variant="outlined"
            />
            <ValidatedTextField
              fieldErrors={fieldErrors}
              disabled={processing}
              name="groupAttribute"
              label={intl.formatMessage({
                id: 'identityProvider.settings.group.label',
                defaultMessage: 'Group Attribute'
              })}
              value={groupAttribute}
              onChange={event => setGroupAttribute(event.target.value)}
              margin="normal"
              variant="outlined"
            />
            <BlockFormControlLabel
              control={
                <Checkbox
                  name="mapSuppliersByGroup"
                  checked={mapSuppliersByGroup}
                  onChange={event => setMapSuppliersByGroup(event.target.checked)}
                />
              }
              label={intl.formatMessage({
                id: 'identityProvider.settings.assignSuppliersByGroup.label',
                defaultMessage: 'Map supplier membership by group?'
              })}
            />
            <BlockFormControlLabel
              control={
                <Checkbox
                  name="mapReceiverByGroup"
                  checked={mapReceiverByGroup}
                  onChange={event => setMapReceiverByGroup(event.target.checked)}
                />
              }
              label={intl.formatMessage({
                id: 'identityProvider.settings.grantReceiverByGroup.label',
                defaultMessage: 'Map receiver permission by group?'
              })}
            />
            <BlockFormControlLabel
              control={
                <Checkbox
                  name="ssoRequired"
                  checked={ssoRequired}
                  onChange={event => setSsoRequired(event.target.checked)}
                />
              }
              label={intl.formatMessage({
                id: 'identityProvider.settings.ssoRequired.label',
                defaultMessage: 'SSO required?'
              })}
            />
            <FormButtons>
              <DefaultButton
                id="update-identity-provider-settings"
                onClick={validateAndSubmit}
                disabled={processing}
                startIcon={<SaveIcon />}
              >
                <FormattedMessage id="identityProvider.settings.saveButton" defaultMessage="Save Settings" />
              </DefaultButton>
            </FormButtons>
          </PaddedPaper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <IdentityProviderInformation
            identityProviderType={identityProviderSettings.type}
            identityProviderKey={identityProviderSettings.key}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default IdentityProviderSettingsForm;
