import { FC, useCallback } from "react";

import { UserSummary } from "../../types";
import * as SupplierApi from '../../api/supplier';
import { NamedAccountAvatar } from "../avatars";
import FilteredAutocomplete, { BasicFilteredAutocompleteProps } from "./FilteredAutocomplete";

interface SupplierMemberAutocompleteProps extends BasicFilteredAutocompleteProps<UserSummary> {
  supplierKey: string;
}

const fetchMembers = async (supplierKey: string, filter: string) => {
  const response = await SupplierApi.getMembers(supplierKey, { page: 0, size: 100, filter, membership: false });
  return response.data.results;
};

const renderOption = (user: UserSummary) => <NamedAccountAvatar user={user} showEmail />;
const getOptionSelected = (option: UserSummary, value: UserSummary) => option.key === value.key;
const getOptionLabel = (user: UserSummary) => `${user.name} (${user.email})`;

const SupplierMemberAutocomplete: FC<SupplierMemberAutocompleteProps> = ({ supplierKey, ...rest }) => {
  const fetchOptions = useCallback((filter: string) => (
    supplierKey ? fetchMembers(supplierKey, filter) : Promise.resolve([])
  ), [supplierKey]);
  return (
    <FilteredAutocomplete
      {...rest}
      fetchOptions={fetchOptions}
      renderOption={renderOption}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
    />
  );
};

export default SupplierMemberAutocomplete;
