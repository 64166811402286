import { Box, Container } from '@mui/material';

import { HeaderSkeleton, TabsSkeleton, TableFilterSkeleton, BrowseTable, TableRowsSkeleton } from "../../../components";

const SupplierSkeleton = () => (
  <>
    <HeaderSkeleton />
    <TabsSkeleton tabCount={2} />
    <Box p={3}>
      <Container maxWidth="md" disableGutters>
      <TableFilterSkeleton displayFilterSkeleton={false} displayRadioSkeleton={false} />
      <BrowseTable>
        <TableRowsSkeleton colSpan={1} rows={5} />
      </BrowseTable>
      </Container>
    </Box>
  </>
);

export default SupplierSkeleton;
