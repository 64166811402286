import { Grid } from '@mui/material';

import { ValuePercentage, GroupedDataSet, DateCount, OffsetDateTimePercentage } from '../../../types';

import TopRulesChart from './TopRulesChart';
import SubmissionCountsChart from './SubmissionCountsChart';
import SubmissionQualityChart from './SubmissionQualityChart';

interface SpecificationChartsProps {
  ruleConformance: ValuePercentage[];
  submissionCounts: GroupedDataSet<string, DateCount>[];
  submissionQuality: GroupedDataSet<string, OffsetDateTimePercentage>[];
}

const SpecificationCharts = ({ ruleConformance, submissionCounts, submissionQuality }: SpecificationChartsProps) => (
  <Grid container spacing={3}>
    <Grid item lg={6} xs={12} mt={2}>
      <TopRulesChart ruleConformance={ruleConformance} />
    </Grid>
    <Grid item lg={6} xs={12} mt={2}>
      <SubmissionCountsChart submissionCounts={submissionCounts} />
    </Grid>
    <Grid item lg={6} xs={12} mt={2}>
      <SubmissionQualityChart submissionQuality={submissionQuality} />
    </Grid>
  </Grid>
);

export default SpecificationCharts;
