import { FC, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { TableBody, TableRow, TableCell } from '@mui/material';

import { TaskConfig, SessionSchema, TaskConfigKind, TASK_CONFIG_KINDS } from '../../../../../../types';
import { TableInfoRow } from '../../../../../../components';
import { intl } from '../../../../../../Internationalization';

import { SpecificationContext } from '../../SpecificationContext';

import TaskLabelCell from './TaskLabelCell';
import ConformanceTaskRow from './ConformanceTaskRow';
import PauseTaskRow from './PauseTaskRow';

interface TaskConfigTableBodyProps {
  taskConfigs: TaskConfig[];
  sessionSchema: SessionSchema;
  updateTaskConfig: (taskConfig: TaskConfig) => void;
}

const TaskConfigTableBody: FC<TaskConfigTableBodyProps> = ({ taskConfigs, sessionSchema, updateTaskConfig }) => {
  const { specification } = useContext(SpecificationContext);

  return (
    <TableBody>
      {sessionSchema.tasks.length ? (
        sessionSchema.tasks.map((task, index) => {
          const taskConfigKind = TASK_CONFIG_KINDS[task.kind];
          const taskConfig = taskConfigs.find(config => config.taskIdentifier === task.taskIdentifier);

          if (!taskConfigKind || !taskConfig) {
            return (
              <TableRow key={task.taskIdentifier ? task.taskIdentifier : index}>
                <TaskLabelCell task={task} sessionSchema={sessionSchema} />
                <TableCell colSpan={3}>
                  <FormattedMessage id="specification.configuration.taskConfig.table.noConfigOptions" defaultMessage="No configuration options" />
                </TableCell>
              </TableRow>
            );
          }

          if (taskConfig.kind === TaskConfigKind.PauseTask) {
            return (
              <PauseTaskRow
                key={task.taskIdentifier}
                task={task}
                taskConfig={taskConfig}
                sessionSchema={sessionSchema}
                updateTaskConfig={updateTaskConfig}
              />
            );
          }

          return (
            <ConformanceTaskRow
              key={task.taskIdentifier}
              task={task}
              taskConfig={taskConfig}
              sessionSchema={sessionSchema}
              conformanceMode={specification.conformanceMode}
              updateTaskConfig={updateTaskConfig}
            />
          );
        })
      ) : (
        <TableInfoRow
          colSpan={4}
          size="medium"
          message={intl.formatMessage({
            id: "specification.configuration.taskConfig.noTasks",
            defaultMessage: "No tasks in the session template."
          })}
        />
      )}
    </TableBody>
  );
};

export default TaskConfigTableBody;
