import { FC, useContext, useEffect, useState } from 'react';

import { Container, Grid } from '@mui/material';

import { extractErrorMessage } from '../../../../../api/endpoints';
import * as SupplierAPI from '../../../../../api/supplier';
import { useErrorBlock } from '../../../../../contexts/error-block';
import { ReceiverAdminOnly } from '../../../../../components';
import { SupplierDetail } from '../../../../../types';
import { intl } from '../../../../../Internationalization';

import { AssignmentContext } from './AssignmentContext';
import AssignmentSettingsForm from './AssignmentSettingsForm';
import AssignmentSkeleton from './AssignmentSkeleton';
import AssignmentManagement from './AssignmentManagement';

const AssignmentConfiguration: FC = () => {
  const { assignment } = useContext(AssignmentContext);
  const { raiseError } = useErrorBlock();

  const [supplier, setSupplier] = useState<SupplierDetail>();

  useEffect(() => {
    const loadSupplier = async () => {
      try {
        const supplierData = (await SupplierAPI.getSupplier(assignment.supplier.key)).data;
        setSupplier(supplierData);
      } catch (error: any) {
        raiseError(extractErrorMessage(error, intl.formatMessage({
          id: 'assignment.settings.loadError',
          defaultMessage: 'Failed to load assignment configuration'
        })));
      }
    };

    loadSupplier();
  }, [assignment.supplier.key, raiseError]);

  if (supplier) {
    return (
      <Container maxWidth="md" id="project-specification-assignment-settings" disableGutters>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AssignmentSettingsForm
              supplier={supplier}
            />
          </Grid>
          <ReceiverAdminOnly>
            <Grid item xs={12}>
              <AssignmentManagement />
            </Grid>
          </ReceiverAdminOnly>
        </Grid>
      </Container>
    );
  }

  return (
    <AssignmentSkeleton />
  );
};

export default AssignmentConfiguration;
