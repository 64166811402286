import { FC } from 'react';

import TableNoContentRow, { TableNoContentRowProps } from './TableNoContentRow';
import NoContentPlaceholder, { NoContentPlaceholderProps } from '../NoContentPlaceholder';

interface TableInfoRowProps extends TableNoContentRowProps, NoContentPlaceholderProps { }

const TableInfoRow: FC<TableInfoRowProps> = ({ message, size, ...rest }) => (
  <TableNoContentRow {...rest}>
    <NoContentPlaceholder
      size={size}
      message={message}
    />
  </TableNoContentRow>
);

export default TableInfoRow;
