import { FC, useEffect, useRef, useState } from 'react';

import { isValid, isBefore, isAfter, Duration, add, sub, formatDuration } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import { IconButton, Typography, TextField, TextFieldProps, Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { DateRange } from '../../types';
import { intl } from '../../Internationalization';

export type ValidationResult = {
  startError: boolean;
  endError: boolean;
  errorMessage: string;
} | undefined;

interface FilterDateRangeProps {
  range: DateRange;
  onRangeUpdated: (range: DateRange) => void;
  rangeLimit?: Duration;
  auxiliaryValidator?: (range: DateRange) => ValidationResult;
}

// Until date-fns parse() rejects strings that don't match the supplier format string we must check we have
// enough digits in the year compoent manually (day and month cannot be missing digits without the year also
// missing them due to the way we use a masked DatePicker input).
// This can be removed when this issue is fixed in v3: https://github.com/date-fns/date-fns/issues/2561
// Tracked under DG-2012.
const isValidDate = (date: Date) => isValid(date) && date.getUTCFullYear() > 1000;

const FilterDateRange: FC<FilterDateRangeProps> = ({ range, onRangeUpdated, rangeLimit, auxiliaryValidator }) => {
  const didMount = useRef(false);
  const [start, setStart] = useState<Date | undefined>(range.start);
  const [end, setEnd] = useState<Date | undefined>(range.end);
  const [validationResult, setValidationResult] = useState<ValidationResult>();

  const startLimit = rangeLimit && end && add(sub(end, rangeLimit), { days: 1 });
  const endLimit = rangeLimit && start && sub(add(start, rangeLimit), { days: 1 });

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    const validateLimit = (): ValidationResult => {
      if (endLimit && start && end && isAfter(end, endLimit)) {
        return {
          startError: true,
          endError: true,
          errorMessage: intl.formatMessage({
            id: 'components.filterDateRange.exceededLimit',
            defaultMessage: 'Range must not be greater than {duration}'
          }, {
            duration: formatDuration(rangeLimit)
          })
        };
      }
    };

    const validateDate = (): ValidationResult => {
      const startInvalid = start ? !isValidDate(start) : false;
      const endInvalid = end ? !isValidDate(end) : false;

      if (endInvalid || startInvalid) {
        return {
          startError: startInvalid,
          endError: endInvalid,
          errorMessage: intl.formatMessage({
            id: 'components.filterDateRange.invalidDate',
            defaultMessage: 'Dates must be valid'
          })
        };
      }
    };

    const validateOrder = (): ValidationResult => {
      if (end && start && isBefore(end, start)) {
        return {
          startError: true,
          endError: true,
          errorMessage: intl.formatMessage({
            id: 'components.filterDateRange.invalidOrder',
            defaultMessage: 'End must not be before start'
          })
        };
      }
    };

    const result = (auxiliaryValidator && auxiliaryValidator({ start, end })) || validateDate() || validateLimit() || validateOrder();
    setValidationResult(result);

    if (!result) {
      onRangeUpdated({ start, end });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end]);

  function clearFilter() {
    setStart(undefined);
    setEnd(undefined);
  }

  const renderTextInput = ({ sx = [], ...props }: TextFieldProps) => (
    <TextField
      variant="standard"
      sx={[{ minWidth: 150, mx: 1, flexGrow: { xs: 1, lg: 0 } }, ...(Array.isArray(sx) ? sx : [sx])]}
      {...props}
    />
  );

  return (
    <Box display="flex" flexWrap="nowrap" className="FilterDateRange-root">
      <DatePicker
        label={
          intl.formatMessage({
            id: 'components.filterDateRange.startDate.label',
            defaultMessage: 'Start date…'
          })
        }
        disableFuture
        value={start || null}
        minDate={startLimit}
        maxDate={end}
        onChange={date => setStart(date || undefined)}
        InputProps={{
          error: validationResult?.startError,
          name: 'startDate'
        }}
        InputAdornmentProps={{
          sx: { mr: 1.5 }
        }}
        renderInput={renderTextInput}
      />
      <DatePicker
        label={
          intl.formatMessage({
            id: 'components.filterDateRange.endDate.label',
            defaultMessage: 'End date…'
          })
        }
        disableFuture
        value={end || null}
        minDate={start || undefined}
        maxDate={endLimit || undefined}
        onChange={date => setEnd(date || undefined)}
        InputProps={{
          name: 'endDate',
          error: validationResult?.endError,
        }}
        InputAdornmentProps={{
          sx: { mr: 1.5 }
        }}
        renderInput={renderTextInput}
      />
      <IconButton
        sx={{ p: 1 }}
        disabled={!range.start && !range.end}
        onClick={clearFilter}
        size="large"
        aria-label={intl.formatMessage({
          id: 'components.filterDateRange.clearFilters.ariaLabel',
          defaultMessage: 'Clear date range filters'
        })}
      >
        <ClearIcon />
      </IconButton>
      {
        validationResult?.errorMessage &&
        <Box alignSelf="center" component="span" display="flex" flexGrow={1} className="FilterDateRange-errorMessage">
          <Typography color="error" variant="body2">
            {validationResult.errorMessage}
          </Typography>
        </Box>
      }
    </Box>
  );
};

export default FilterDateRange;
