import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text, View } from '@react-pdf/renderer';

import { CheckRulesTaskResult } from '../../../../../types';
import { getPercentageRemainder, substringRuleNameFromPath } from '../../taskResultUtils';
import { PdfTableRow, PdfTableCell } from '../components';
import { OmittedRowsMessage } from './TaskResultSections';

export const CheckRulesTaskHeader: FC = () => (
  <PdfTableRow header>
    <PdfTableCell colSpan="twoFifths" header>
      <Text>
        <FormattedMessage id="submission.resultsPdf.checkRulesTaskResult.ruleHeader" defaultMessage="Rule" />
      </Text>
    </PdfTableCell>
    <PdfTableCell colSpan="twoFifths" number header>
      <Text>
        <FormattedMessage id="submission.resultsPdf.checkRulesTaskResult.resultHeader" defaultMessage="Result" />
      </Text>
    </PdfTableCell>
    <PdfTableCell colSpan="fifth" number header>
      <Text>
        <FormattedMessage id="submission.resultsPdf.checkRulesTaskResult.errorHeader" defaultMessage="Errors" />
      </Text>
    </PdfTableCell>
  </PdfTableRow>
);

interface CheckRulesTaskResultContentProps {
  task: CheckRulesTaskResult;
  hideZeroProcessedRows: boolean;
}

export const CheckRulesTaskResultContent: FC<CheckRulesTaskResultContentProps> = ({ task: { rules }, hideZeroProcessedRows }) => {
  const filteredRows = hideZeroProcessedRows ? rules.filter(({ processed }) => processed !== 0) : rules;
  const filteredRowCount = rules.length - filteredRows.length;
  return (
    <View>
      {
        filteredRows.map(({ path, count, errors, processed }) => (
          <PdfTableRow key={path}>
            <PdfTableCell colSpan="twoFifths">
              <Text>{substringRuleNameFromPath(path)}</Text>
            </PdfTableCell>
            <PdfTableCell colSpan="twoFifths" number>
              <Text>
                <FormattedMessage
                  id="submission.resultsPdf.checkRulesTaskResult.resultData"
                  defaultMessage="{valid} / {processed} features valid ({percentage}%)"
                  values={{
                    valid: processed - count,
                    processed,
                    percentage: getPercentageRemainder(count, processed).toFixed(2)
                  }}
                />
              </Text>
            </PdfTableCell>
            <PdfTableCell colSpan="fifth" number>
              <Text>{errors}</Text>
            </PdfTableCell>
          </PdfTableRow>
        ))
      }
      <OmittedRowsMessage omittedRowCount={filteredRowCount} />
    </View>
  );
};
