import { FC, useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Navigate } from 'react-router-dom';

import { extractErrorMessage } from "../../../../api/endpoints";
import * as SpecificationApi from '../../../../api/specification';
import { ErrorBlockContext } from "../../../../contexts/error-block";
import { SpecificationDetail, SpecificationValidationResult } from "../../../../types";
import { intl } from "../../../../Internationalization";
import { useRequiredParams } from "../../../../hooks";

import { ProjectContext } from "../ProjectContext";
import { toProjectLink } from "../Project";

import { SpecificationContext } from "./SpecificationContext";
import SpecificationRouting from "./SpecificationRouting";
import SpecificationSkeleton from "./SpecificationSkeleton";

const Specification: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { specificationKey } = useRequiredParams<{ specificationKey: string }>();
  const [navigateToProject, setNavigateToProject] = useState(false);

  const projectContext = useContext(ProjectContext);
  const { projectKey } = projectContext;

  const { raiseError } = useContext(ErrorBlockContext);
  const [specification, setSpecification] = useState<SpecificationDetail>();
  const [specificationValidationResult, setSpecificationValidationResult] = useState<SpecificationValidationResult>();

  useEffect(() => {
    const loadAndValidate = async () => {
      try {
        const [
          { data: specificationData },
          { data: specificationValidationResultData }
        ] = await Promise.all([
          SpecificationApi.getSpecification(specificationKey),
          SpecificationApi.validateSpecification(specificationKey)
        ]);
        if (specificationData.project.key === projectKey) {
          setSpecification(specificationData);
          setSpecificationValidationResult(specificationValidationResultData);
        } else {
          enqueueSnackbar(
            intl.formatMessage({
              id: 'specification.invalidSpecification',
              defaultMessage: 'Invalid specification'
            }),
            { variant: "warning" }
          );
          setNavigateToProject(true);
        }
      } catch (error: any) {
        raiseError(extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'specification.loadError',
            defaultMessage: 'Failed to load specification'
          })
        ));
      }
    };

    loadAndValidate();
  }, [enqueueSnackbar, raiseError, projectKey, specificationKey]);

  const validateSpecification = async () => {
    try {
      const validationResult = await SpecificationApi.validateSpecification(specificationKey);
      setSpecificationValidationResult(validationResult.data);
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'specification.validationError',
            defaultMessage: 'Failed to validate specification'
          })
        ),
        { variant: "warning" }
      );
    }
  };

  const specificationUpdated = (updatedSpecification: SpecificationDetail) => {
    setSpecification(updatedSpecification);
  };

  if (navigateToProject) {
    return (
      <Navigate to={toProjectLink(projectKey)} />
    );
  }

  if (specification && specificationValidationResult) {
    return (
      <SpecificationContext.Provider
        value={{
          ...projectContext,
          specificationUpdated,
          specificationKey,
          validateSpecification,
          specification,
          specificationValidationResult
        }}
      >
        <SpecificationRouting />
      </SpecificationContext.Provider>
    );
  }

  return (
    <SpecificationSkeleton />
  );
};

export default Specification;
