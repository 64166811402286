import { AxiosPromise } from 'axios';

import { KeySuggestion } from '../types';
import { AXIOS, wrapUniquePromise } from './endpoints';

export function supplierKeySuggestion(name: string): AxiosPromise<KeySuggestion> {
  return AXIOS.get('/supplierKeySuggestion', { params: { name } });
}

export function supplierByName(name: string): Promise<boolean> {
  return wrapUniquePromise(AXIOS.head('/supplierByName', {
    params: { name }
  }));
}
