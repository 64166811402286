import React, { FC } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import { AppDecorator } from '../../components';
import { ErrorBlock } from '../../contexts/error-block';
import { intl } from '../../Internationalization';

import Suppliers from './Suppliers';
import Supplier from './supplier/Supplier';

const SuppliersRouting: FC = () => (
  <AppDecorator
    title={intl.formatMessage({
      id: 'suppliers.routing.title',
      defaultMessage: 'Suppliers'
    })}
  >
    <ErrorBlock>
      <Routes>
        <Route index element={<Suppliers />} />
        <Route path=":supplierKey/*" element={<Supplier />} />
        <Route path="*" element={<Navigate to="." replace />} />
      </Routes>
    </ErrorBlock>
  </AppDecorator>
);

export default SuppliersRouting;
