import Schema from 'async-validator';
import { AxiosPromise } from 'axios';

import { ProjectSettings, ProjectDetail, MediaDetail } from '../types';
import { NOT_BLANK_REGEX, duplicateValidator, notTrimmableValidator, nameLengthValidator, descriptionLengthValidator } from '../util';
import { intl } from '../Internationalization';

import { AXIOS, FileUploadConfig, wrapUniquePromise } from './endpoints';
import { uploadMedia } from './media';
import { projectByName } from './projectLookup';

export function getProject(projectKey: string): AxiosPromise<ProjectDetail> {
  return AXIOS.get(`/projects/${projectKey}`);
}

export const uploadProjectImage = (projectKey: string, newImage: File, config?: FileUploadConfig): AxiosPromise<MediaDetail> => (
  uploadMedia(`/projects/${projectKey}/image`, newImage, config)
);

export function deleteProjectImage(projectKey: string): AxiosPromise<void> {
  return AXIOS.delete(`/projects/${projectKey}/image`);
}

export function updateProject(projectKey: string, project: ProjectSettings): AxiosPromise<ProjectDetail> {
  return AXIOS.post(`/projects/${projectKey}`, {
    name: project.name,
    description: project.description,
    active: project.active
  });
}

export function uniqueByKey(projectKey: string): Promise<boolean> {
  return wrapUniquePromise(AXIOS.head(`/projects/${projectKey}`));
}

export const extractProjectSettings = ({ name, description, active }: ProjectDetail): ProjectSettings => ({ name, description, active });

export const projectNameValidator = (getExistingName?: () => string) => ([
  {
    required: true,
    pattern: NOT_BLANK_REGEX,
    message: intl.formatMessage({
      id: 'project.validator.name.required',
      defaultMessage: 'Please provide a name'
    }),
  },
  nameLengthValidator,
  notTrimmableValidator,
  duplicateValidator({
    regex: NOT_BLANK_REGEX,
    existingValue: getExistingName,
    checkUnique: projectByName,
    alreadyExistsMessage: intl.formatMessage({
      id: 'project.validator.name.unique',
      defaultMessage: 'A project with this name already exists'
    }),
    errorMessage: intl.formatMessage({
      id: 'project.validator.name.checkUniqueError',
      defaultMessage: 'There was a problem verifying the project name'
    })
  })
]);

export const projectDescriptionValidator = [
  {
    required: true,
    message: intl.formatMessage({
      id: 'project.validator.description.required',
      defaultMessage: 'Please provide a description'
    })
  },
  descriptionLengthValidator
];

export function projectSettingsValidator(getExistingName?: () => string): Schema {
  return new Schema({
    name: projectNameValidator(getExistingName),
    description: projectDescriptionValidator,
  });
}
