import React, { FC, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import debounce from 'lodash/debounce';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ValidateFieldsError } from 'async-validator';

import { Dialog, DialogTitle, DialogContent } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import * as SupplierApi from '../../../api/supplier';
import * as SuppliersApi from '../../../api/suppliers';
import { intl } from '../../../Internationalization';
import { onEnterCallback, validate } from '../../../util';
import { extractErrorMessage } from '../../../api/endpoints';
import * as SupplierLookupApi from '../../../api/supplierLookup';
import { ValidatedTextField, PaddedDialogActions, DefaultButton } from '../../../components';

import { toSupplierUrl } from '../Suppliers';

interface NewSupplierFormProps {
  onCancel: () => void;
}

const VALIDATOR = SupplierApi.createSupplierRequestValidator();

const NewSupplierForm: FC<NewSupplierFormProps> = ({ onCancel }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [key, setKey] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [active] = useState<boolean>(true);
  const [processing, setProcessing] = useState<boolean>(false);
  const [fetchingKey, setFetchingKey] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const disabled = processing || fetchingKey;

  const validateAndSubmit = () => {
    setProcessing(true);
    validate(VALIDATOR, { name, key, active, processing, fetchingKey })
      .then(createSupplier)
      .catch((errors: ValidateFieldsError) => {
        setFieldErrors(errors);
        setProcessing(false);
      });
  };

  const submitOnEnter = onEnterCallback(validateAndSubmit);

  const createSupplier = () => {
    SuppliersApi.createSupplier({ name, key, active }).then(response => {
      navigate(toSupplierUrl(response.data));
      enqueueSnackbar(
        intl.formatMessage(
          {
            id: 'supplier.create.saveSuccess',
            defaultMessage: 'Supplier {name} has been created'
          },
          { name }
        ),
        { variant: "success" });
    }).catch((error: AxiosError) => {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'supplier.create.saveError',
          defaultMessage: 'Failed to create supplier'
        })
      ), { variant: "error" });
      setProcessing(false);
    });
  };

  const updateName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setName(value);

    if (value) {
      setFetchingKey(true);
      suggestKey.current(value);
    }
  };

  const suggestKey = useRef(debounce(async (supplierName: string) => {
    try {
      const response = await SupplierLookupApi.supplierKeySuggestion(supplierName);
      setKey(response.data.key);
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'supplier.create.keyGenerationError',
          defaultMessage: 'Failed to generate key suggestion'
        })
      ), { variant: "error" });
    } finally {
      setFetchingKey(false);
    }
  }, 500));

  return (
    <Dialog id="new-supplier-dialog" onClose={onCancel} aria-labelledby="new-supplier-dialog-title" open={true} fullWidth>
      <DialogTitle id="new-supplier-dialog-title">
        <FormattedMessage id="supplier.create.title" defaultMessage="Add Supplier" />
      </DialogTitle>
      <DialogContent dividers={true}>
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="name"
          label={intl.formatMessage({
            id: 'supplier.create.name.label',
            defaultMessage: 'Name'
          })}
          value={name}
          onChange={updateName}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={disabled}
          name="key"
          label={intl.formatMessage({
            id: 'supplier.create.key.label',
            defaultMessage: 'Key'
          })}
          value={key}
          onChange={(e) => setKey(e.target.value)}
          onKeyDown={submitOnEnter}
          margin="normal"
          variant="outlined"
        />
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton
          name="cancelAddNewSupplier"
          color="secondary"
          onClick={onCancel}
          disabled={disabled}
        >
          <FormattedMessage id="supplier.create.cancelButton" defaultMessage="Cancel" />
        </DefaultButton>
        <DefaultButton
          name="addNewSupplier"
          onClick={validateAndSubmit}
          disabled={disabled}
          startIcon={<AddIcon />}
        >
          <FormattedMessage id="supplier.create.addButton" defaultMessage="Add Supplier" />
        </DefaultButton>
      </PaddedDialogActions>
    </Dialog>
  );
};

export default NewSupplierForm;
