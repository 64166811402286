import { FC, useCallback, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';

import { TableRow, TableCell, Container } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import * as AssignmentApi from '../../../../../api/assignment';
import { extractErrorMessage } from '../../../../../api/endpoints';
import {
  AssignmentPermissionsAutocomplete, BrowseTable, browseTableBody, FilterPagination, FilterBar, ConfirmDialog,
  DefaultButton, NamedAccountAvatar, MinWidthTableCell, FilterSearch, StyledTableHead
} from '../../../../../components';
import { useBrowseRequest } from '../../../../../hooks';
import { intl } from '../../../../../Internationalization';
import { AssignmentPermissionsRequest, UserSummary } from '../../../../../types';

import { AssignmentContext } from './AssignmentContext';

const PermissionsTableBody = browseTableBody<UserSummary>();

const PAGE_SIZE = 10;

const Permissions: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { assignmentKey } = useContext(AssignmentContext);
  const { request, response, processing, updateRequest, setPage } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, exists: true, filter: "" },
    onRequest: useCallback(
      (req: AssignmentPermissionsRequest, config) => AssignmentApi.getPermissions(assignmentKey, req, config), [assignmentKey]
    ),
  });

  const [executing, setExecuting] = useState<boolean>(false);
  const [userToAdd, setUserToAdd] = useState<UserSummary | null>(null);
  const [userToRemove, setUserToRemove] = useState<UserSummary>();

  const handleFilterUpdate = useCallback((filter: string) => updateRequest({ filter }), [updateRequest]);

  const disableInputs = executing || processing;

  const addPermissions = async () => {
    if (!userToAdd) {
      return;
    }
    setExecuting(true);
    try {
      await AssignmentApi.addPermissions(assignmentKey, userToAdd.key);
      setUserToAdd(null);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'assignment.permissions.addSuccess',
          defaultMessage: "Added permissions for user ''{name}''"
        }, { name: userToAdd.name }),
        { variant: "success" });
      updateRequest();
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'assignment.permissions.addError',
          defaultMessage: "Failed to add permissions for user ''{name}''"
        }, { name: userToAdd.name })
      ), { variant: "error" });
    } finally {
      setExecuting(false);
    }
  };

  const removePermissions = async () => {
    if (!userToRemove) {
      return;
    }
    setExecuting(true);
    try {
      await AssignmentApi.removePermissions(assignmentKey, userToRemove.key);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'assignment.permissions.removeSuccess',
          defaultMessage: "Permissions removed for user ''{name}''"
        }, { name: userToRemove.name }),
        { variant: "success" }
      );
      updateRequest();
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'assignment.permissions.removeError',
          defaultMessage: 'Failed to remove permissions for user'
        })
      ), { variant: "error" });
    } finally {
      setExecuting(false);
      setUserToRemove(undefined);
    }
  };

  const userRow = (user: UserSummary) => (
    <TableRow key={user.key}>
      <TableCell>
        <NamedAccountAvatar user={user} showEmail />
      </TableCell>
      <MinWidthTableCell align="center">
        <DefaultButton
          name="removeUser"
          color="grey"
          onClick={() => setUserToRemove(user)}
          aria-label={intl.formatMessage({
            id: 'assignment.permissions.removeUserPermission.ariaLabel',
            defaultMessage: 'Remove user permission'
          })}
        >
          <CloseIcon />
        </DefaultButton>
      </MinWidthTableCell>
    </TableRow>
  );

  return (
    <Container maxWidth="md" id="project-specification-assignment-permissions" disableGutters>
      <FilterBar
        startInput={
          <FilterSearch
            placeholder={intl.formatMessage({
              id: 'assignment.permissions.filterSearch.placeholder',
              defaultMessage: 'Filter permissions…'
            })}
            onSearch={handleFilterUpdate}
          />
        }
        actions={
          <FilterPagination page={request.page} size={request.size} total={response?.total} disabled={disableInputs} setPage={setPage} />
        }
      />
      <BrowseTable>
        <StyledTableHead>
          <TableRow>
            <TableCell>
              <AssignmentPermissionsAutocomplete
                assignmentKey={assignmentKey}
                id="permissions-select"
                name="permissions"
                label={intl.formatMessage({
                  id: 'assignment.permissions.addPermissions',
                  defaultMessage: 'Add permissions…'
                })}
                value={userToAdd}
                onChange={setUserToAdd}
                disabled={disableInputs}
                variant="standard"
              />
            </TableCell>
            <MinWidthTableCell align="center">
              <DefaultButton
                name="addUser"
                color="grey"
                onClick={addPermissions}
                disabled={userToAdd === null || disableInputs}
                aria-label={intl.formatMessage({
                  id: 'assignment.permissions.addUserPermission.ariaLabel',
                  defaultMessage: 'Add user permission'
                })}
              >
                <AddIcon />
              </DefaultButton>
            </MinWidthTableCell>
          </TableRow>
        </StyledTableHead>
        <PermissionsTableBody
          data={response && response.results}
          mapToRow={userRow}
          noDataMessage={intl.formatMessage({
            id: 'assignment.permissions.noPermissions',
            defaultMessage: 'No matching permissions.'
          })}
          numCols={3}
        />
      </BrowseTable>
      <ConfirmDialog
        id="confirm-remove-user-permissions"
        isOpen={!!userToRemove}
        title={intl.formatMessage({
          id: 'assignment.permissions.confirmRemovePermission.title',
          defaultMessage: 'Remove permissions'
        })}
        text={intl.formatMessage({
          id: 'assignment.permissions.confirmRemovePermission.text',
          defaultMessage: "Are you sure you wish to remove permissions for user ''{name}''?"
        }, { name: userToRemove?.name })}
        confirmBtnText={intl.formatMessage({
          id: 'assignment.permissions.confirmRemovePermission.button',
          defaultMessage: 'Remove permissions'
        })}
        confirmAction={removePermissions}
        closeAction={() => setUserToRemove(undefined)}
      />
    </Container>
  );
};

export default Permissions;
