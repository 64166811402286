import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';

import { Box, Container, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';

import * as LdapUserSyncJobApi from '../../../../api/ldapUserSyncJob';
import { extractErrorMessage } from '../../../../api/endpoints';
import { PaddedPaper, ConfirmDialog, DefaultButton, FilterPagination } from '../../../../components';
import { LdapUserSyncJobState } from '../../../../types';
import { intl } from '../../../../Internationalization';
import { useBrowseRequest, useInterval } from '../../../../hooks';

import SyncMonitorTable from './SyncMonitorTable';

const PAGE_SIZE = 10;
const POLL_INTERVAL = 5000;

const SyncMonitor: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [processing, setProcessing] = useState<boolean>(false);
  const [confirmSync, setConfirmSync] = useState<boolean>(false);
  const { request, response, processing: loading, setPage, updateRequest, refresh } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE },
    onRequest: LdapUserSyncJobApi.getUserSyncJobs
  });

  const shouldRefresh = () => response && response.results.find(job => job.state === LdapUserSyncJobState.RUNNING);

  const refreshIfRequired = () => {
    if (shouldRefresh()) {
      refresh();
    }
  };

  useInterval(refreshIfRequired, POLL_INTERVAL);

  const startSync = () => {
    setConfirmSync(false);
    setProcessing(true);
    LdapUserSyncJobApi.startSyncJobs().then(() => updateRequest()).catch(error => {
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'ldap.syncMonitor.startSyncError',
        defaultMessage: 'Failed to start synchronisation job'
      })), { variant: 'error' });
    }).finally(() => {
      setProcessing(false);
    });
  };

  return (
    <Container maxWidth="lg" id="system-ldap-sync-monitor" disableGutters>
      <PaddedPaper>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h5">
            <FormattedMessage id="ldap.syncMonitor.title" defaultMessage="Synchronisation Monitor" />
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={1}>
              <DefaultButton
                name="synchronise"
                onClick={() => setConfirmSync(true)}
                startIcon={<SyncIcon />}
                color="grey"
                size="small"
                disabled={processing}
              >
                <FormattedMessage id="ldap.syncMonitor.syncButton" defaultMessage="Synchronise" />
              </DefaultButton>
            </Box>
            <FilterPagination page={request.page} size={request.size} total={response?.total} disabled={loading} setPage={setPage} />
          </Box>
          <ConfirmDialog
            id="confirm-start-sync"
            isOpen={confirmSync}
            title={intl.formatMessage({
              id: 'ldap.syncMonitor.confirmStartSync.title',
              defaultMessage: 'Start Synchronisation?'
            })}
            text={intl.formatMessage({
              id: 'ldap.syncMonitor.confirmStartSync.text',
              defaultMessage: 'Are you sure you wish to start the synchronisation job?'
            })}
            confirmBtnText={intl.formatMessage({
              id: 'ldap.syncMonitor.confirmStartSync.confirmButton',
              defaultMessage: 'Start sync'
            })}
            confirmAction={startSync}
            closeAction={() => setConfirmSync(false)}
            disabled={processing}
          />
        </Box>
        <SyncMonitorTable jobs={response?.results || []} loading={!response} onRefresh={refresh} />
      </PaddedPaper>
    </Container>
  );

};

export default SyncMonitor;
