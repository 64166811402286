import { Grid } from '@mui/material';

import { ProjectActivity, DailyActivity } from '../../../types';
import ProjectActivityChart from './ProjectActivityChart';
import DailyActivityChart from './DailyActivityChart';

interface GlobalChartsProps {
  projectActivity: ProjectActivity[];
  dailyActivity: DailyActivity[];
}

const GlobalCharts = ({ projectActivity, dailyActivity }: GlobalChartsProps) => (
  <Grid container spacing={3}>
    <Grid item lg={6} xs={12} mt={2}>
      <ProjectActivityChart projectActivity={projectActivity} />
    </Grid>
    <Grid item lg={6} xs={12} mt={2}>
      <DailyActivityChart dailyActivity={dailyActivity} />
    </Grid>
  </Grid>
);

export default GlobalCharts;
