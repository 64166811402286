import { Container, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import { PaddedPaper } from "../../../components";

const SubmissionSkeleton = () => (
  <Container maxWidth="xl" disableGutters>
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12} md={6}>
        <PaddedPaper>
          <Skeleton width={150} height={50} />
          <Skeleton width={250} height={30} />
          <Skeleton width={250} height={30} />
        </PaddedPaper>
      </Grid>
      <Grid item xs={12} md={6}>
        <PaddedPaper>
          <Skeleton width={150} height={50} />
          <Skeleton width={250} height={30} />
          <Skeleton width={250} height={30} />
        </PaddedPaper>
      </Grid>
      <Grid item xs={12}>
        <Skeleton width="100%" height={60} />
        <Skeleton width="100%" height={60} />
        <Skeleton width="100%" height={60} />
        <Skeleton width="100%" height={60} />
      </Grid>
    </Grid>
  </Container>
);

export default SubmissionSkeleton;
