import { EditorState, ContentState } from "draft-js";
import { stateFromHTML, Options } from "draft-js-import-html";

const FROM_HTML_OPTIONS: Options = {
  customInlineFn: (element: Element, { Entity }) => {
    if (element.tagName === 'A') {
      return Entity('LINK', {
        url: element.getAttribute('href'),
        targetOption: element.getAttribute('target')
      });
    }
  }
};

export const editorStateFromHtml = (html: string) => {
  return EditorState.createWithContent(stateFromHTML(html, FROM_HTML_OPTIONS));
};

export const editorStateFromText = (text: string) => {
  return EditorState.createWithContent(ContentState.createFromText(text));
};
