import { FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as MeApi from '../../api/me';
import * as AuthApi from '../../api/auth';
import { Me } from '../../types';
import { FullScreenLoading } from '../../components';
import { InternationalizationContext } from '../../Internationalization';

import { AuthenticationContext } from './context';

const Authentication: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { setPreferredLocale } = useContext(InternationalizationContext);
  const [me, setMe] = useState<Me>();
  const [initialized, setInitialized] = useState<boolean>(false);

  // NB - RequireUnauthenticated expects this to not change
  const signOut = useCallback((redirect: boolean) => {
    AuthApi.clearAccessToken();
    setMe(undefined);
    if (redirect) {
      navigate('/');
    }
  }, [navigate]);

  const updateMe = useCallback((updatedMe: Me) => {
    setMe(updatedMe);
    setInitialized(true);
    setPreferredLocale(updatedMe.locale);
  }, [setPreferredLocale]);

  const refresh = useCallback(async () => {
    try {
      const response = await MeApi.getMe();
      updateMe(response.data);
    } catch (e: any) {
      setMe(undefined);
      setInitialized(true);
    }
  }, [updateMe]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  if (initialized) {
    return (
      <AuthenticationContext.Provider
        value={{
          updateMe,
          refresh,
          signOut,
          me
        }}
      >
        {children}
      </AuthenticationContext.Provider>
    );
  }

  return (
    <FullScreenLoading />
  );
};

export default Authentication;
