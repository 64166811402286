import { AxiosPromise } from 'axios';

import { AccountEventsResponse, AccountEventsRequest } from '../types';

import { AXIOS, RequestConfig } from './endpoints';

export function getAccountEvents(request: AccountEventsRequest, config?: RequestConfig): AxiosPromise<AccountEventsResponse> {
  return AXIOS.get('/account_events', {
    ...config,
    params: request
  });
}
