import { FC, useState, useContext, useEffect } from 'react';
import copy from "clipboard-copy";
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, InputBase, Paper, Typography } from '@mui/material';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import LinkIcon from '@mui/icons-material/Link';

import * as UserApi from '../../../api/user';
import { intl } from '../../../Internationalization';
import { extractErrorMessage } from '../../../api/endpoints';
import { DefaultButton, PaddedDialogActions } from '../../../components';

import { UserContext } from './UserContext';

interface PasswordResetLinkDialogProps {
  open: boolean;
  onClose: () => void;
}

const PasswordResetLinkDialog: FC<PasswordResetLinkDialogProps> = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const [processing, setProcessing] = useState<boolean>(false);
  const [passwordResetLink, setPasswordResetLink] = useState<string>();

  useEffect(() => {
    if (open) {
      setPasswordResetLink(undefined);
    }
  }, [open]);

  const handleGeneratePasswordResetLink = async () => {
    setProcessing(true);
    try {
      const response = await UserApi.generatePasswordResetLink(user.key);
      setPasswordResetLink(response.data.link);
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        enqueueSnackbar(extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'user.status.passwordResetLink.generateUnauthorized',
            defaultMessage: 'This user account is remotely managed, their password can not be reset.'
          })
        ), { variant: "error" });
      } else {
        enqueueSnackbar(extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'user.status.passwordResetLink.generateError',
            defaultMessage: 'Failed to generate password reset link for {username}'
          },
            { username: user.name })
        ), { variant: "error" });
      }
    } finally {
      setProcessing(false);
    }
  };

  const copyResetPasswordLink = () => {
    if (passwordResetLink) {
      copy(passwordResetLink);
      enqueueSnackbar(intl.formatMessage({
        id: 'user.status.passwordResetLink.dialog.copySuccess',
        defaultMessage: 'Copied password reset link to clipboard'
      }), { variant: 'info' });
    }
  };

  const renderDialogAction = () => {
    if (passwordResetLink) {
      return (
        <Paper sx={{ py: 0.25, px: 0.5, display: 'flex', alignItems: 'center', width: '100%', }}>
          <Box p={1.5} display="flex">
            <LinkIcon />
          </Box>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <InputBase sx={{ mx: 1, flex: 1 }} value={passwordResetLink} />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <DefaultButton
            sx={{ mx: 1 }}
            onClick={copyResetPasswordLink}
            startIcon={<FilterNoneIcon />}
            color="grey"
          >
            <FormattedMessage
              id="user.status.passwordResetLink.dialog.copy.copyButton"
              defaultMessage="Copy"
            />
          </DefaultButton>
        </Paper>
      );
    }

    return (
      <DefaultButton
        fullWidth
        startIcon={<FilterNoneIcon />}
        disabled={processing}
        onClick={handleGeneratePasswordResetLink}
      >
        <FormattedMessage
          id="user.status.passwordResetLink.dialog.generate.generateLinkButton"
          defaultMessage="Generate Link"
        />
      </DefaultButton>
    );
  };

  return (
    <>
      <Dialog
        id="reset-password-link-dialog"
        onClose={onClose}
        aria-labelledby="reset-password-link-dialog-title"
        open={open}
        fullWidth
      >
        <DialogTitle id="reset-password-link-dialog-title">
          <FormattedMessage
            id="user.status.passwordResetLink.dialog.title"
            defaultMessage="Password reset link"
          />
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>
            <FormattedMessage
              id="user.status.passwordResetLink.dialog.description"
              defaultMessage="Use this feature to generate a password reset link. This action will invalidate any previously generated password reset links."
            />
          </DialogContentText>
          <Box my={2}>
            <Typography>
              <strong>
                <FormattedMessage
                  id="user.status.passwordResetLink.dialog.email"
                  defaultMessage="Email:"
                />
              </strong> {user.email}
            </Typography>
            <Typography>
              <strong>
                <FormattedMessage
                  id="user.status.passwordResetLink.dialog.name"
                  defaultMessage="Name:"
                />
              </strong> {user.name}
            </Typography>
          </Box>
          {renderDialogAction()}
        </DialogContent>
        <PaddedDialogActions>
          <DefaultButton
            id="close-reset-password-link-dialog"
            color="secondary"
            onClick={onClose}
            disabled={processing}
          >
            <FormattedMessage id="user.status.passwordResetLink.dialog.close.label" defaultMessage="Close" />
          </DefaultButton>
        </PaddedDialogActions>
      </Dialog>
    </>
  );
};

export default PasswordResetLinkDialog;
