import { Paper, Box, Skeleton } from "@mui/material";

const ImageCardSkeleton = () => (
  <Paper>
    <Box py={0.5} />
    <Skeleton variant="rectangular" height={200} width="100%" />
    <Box py={1} mx={3}>
      <Skeleton height={35} width={200} />
      <Skeleton height={30} width={50} />
      <Skeleton height={25} width={250} />
    </Box>
  </Paper>
);

export default ImageCardSkeleton;
