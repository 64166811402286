import React from 'react';
import { isLdapUser, Me } from "../../types";
import { MessageBox } from "../../components";
import { intl } from '../../Internationalization';

const LdapAccountMessage = ({ me }: { me: Me }) => {
  if (isLdapUser(me)) {
    return (
      <MessageBox
        level="info"
        message={intl.formatMessage({
          id: 'account.ldapAccountMessage',
          defaultMessage: 'Password management and profile details must be configured in the external system which manages this account. Please contact your administrator for details.'
        })}
      />
    );
  }
  return null;
};

export default LdapAccountMessage;
