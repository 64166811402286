import { FC, PropsWithChildren } from 'react';
import { Page, Document, Image, Font, Text, StyleSheet } from '@react-pdf/renderer';
import { FormattedMessage } from 'react-intl';

import logo from '../../assets/images/1datagateway_1300.jpg';
import RobotoLight from '../../assets/fonts/roboto/Roboto-Light.ttf';
import RobotoLightItalic from '../../assets/fonts/roboto/Roboto-LightItalic.ttf';
import RobotoRegular from '../../assets/fonts/roboto/Roboto-Regular.ttf';
import RobotoMedium from '../../assets/fonts/roboto/Roboto-Medium.ttf';
import RobotoMediumItalic from '../../assets/fonts/roboto/Roboto-MediumItalic.ttf';
import Internationalization from '../../Internationalization';
import { dateTimeFormat } from '../../util';

Font.register({
  family: 'Roboto', fonts: [
    { src: RobotoLight, fontWeight: 300 },
    { src: RobotoLightItalic, fontWeight: 300, fontStyle: 'italic' },
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoMedium, fontWeight: 500 },
    { src: RobotoMediumItalic, fontWeight: 500, fontStyle: 'italic' }
  ]
});

const pdfStyles = StyleSheet.create({
  page: {
    paddingTop: 10,
    paddingBottom: 65,
    paddingHorizontal: 20,
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  header: {
    marginTop: 5,
    fontSize: 10,
    height: 20,
    marginBottom: 10,
    display: 'flex',
    textAlign: 'center',
    color: 'grey',
  },
  footer: {
    fontSize: 10,
    position: 'absolute',
    bottom: 5,
    left: 35,
    height: 20,
    color: 'grey',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 15
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  logo: {
    position: 'absolute',
    top: 10,
    right: 20,
    height: 20,
    opacity: .5
  }
});

interface PdfDocumentLayoutProps extends PropsWithChildren {
  title?: string;
  documentTitle: string;
}

const PdfDocumentLayout: FC<PdfDocumentLayoutProps> = ({ title, documentTitle, children }) => (
  <Internationalization>
    <Document creator="1Spatial" producer="1Data Gateway" title={documentTitle}>
      <Page style={pdfStyles.page}>
        <Image fixed style={pdfStyles.logo} src={logo} />
        <Text style={pdfStyles.header} fixed>
          <FormattedMessage id="components.pdfDocumentLayout.confidentialInformation" defaultMessage="Confidential Information" />
        </Text>
        {title && <Text style={pdfStyles.title}>{title}</Text>}
        {children}
        <Text style={pdfStyles.footer} fixed>{dateTimeFormat(new Date().toString())}</Text>
        <Text
          style={pdfStyles.pageNumber}
          render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )}
          fixed
        />
      </Page>
    </Document>
  </Internationalization>
);

export default PdfDocumentLayout;
