import React from 'react';

import { AssignmentDetail, DataStoreConfigDetail, ProjectDetail, SpecificationDetail, SubmissionDetail } from '../../../../../types';

interface OpenSubmissionContextValue {
  submissionUpdated: React.Dispatch<React.SetStateAction<SubmissionDetail | undefined>>;
  submission: SubmissionDetail;
  assignment: AssignmentDetail;
  project: ProjectDetail;
  specification: SpecificationDetail;
  dataStores: DataStoreConfigDetail[];
  specificationValid: boolean;
}

const OpenSubmissionContextDefaultValue = {} as OpenSubmissionContextValue;
export const OpenSubmissionContext = React.createContext(
  OpenSubmissionContextDefaultValue
);
