import React, { FC, useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';

import { Typography, Container, MenuItem } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';

import * as UserApi from '../../../api/user';
import { extractErrorMessage } from '../../../api/endpoints';
import { AuthenticatedContext } from '../../../contexts/authentication';
import { PaddedPaper, ValidatedTextField, FormButtons, DefaultButton } from '../../../components';
import { intl } from '../../../Internationalization';
import { UserDetail, isLdapUser, USER_LOCALES, USER_LOCALE_METADATA, UserLocale } from '../../../types';
import { onEnterCallback, validate } from '../../../util';

import LdapUserMessage from './LdapUserMessage';

interface UserProfileProps {
  user: UserDetail;
  userUpdated: (profile: UserDetail) => void;
}

const UserProfile: FC<UserProfileProps> = ({ user, userUpdated }) => {
  const ldapUser = isLdapUser(user);
  const { enqueueSnackbar } = useSnackbar();
  const { me, refresh } = useContext(AuthenticatedContext);
  const validator = UserApi.userProfileValidator(() => user.email);

  const [name, setName] = useState<string>(user.name);
  const [email, setEmail] = useState<string>(user.email);
  const [locale, setLocale] = useState<UserLocale | undefined>(user.locale);
  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>({});

  const disabled = processing || ldapUser;

  const validateAndSubmit = () => {
    setProcessing(true);
    validate(validator, { name, email })
      .then(updateProfile)
      .catch((errors: ValidateFieldsError) => {
        setFieldErrors(errors);
        setProcessing(false);
      });
  };

  const submitOnEnter = onEnterCallback(validateAndSubmit);

  const refreshIfPerformingOnSelf = (userKey: string) => {
    if (userKey === me.key) {
      refresh();
    }
  };

  const updateProfile = () => {
    setFieldErrors({});
    UserApi.updateUserProfile(user.key, { name, email, locale }).then((response) => {
      userUpdated(response.data);
      refreshIfPerformingOnSelf(user.key);
      enqueueSnackbar(intl.formatMessage({
        id: 'user.settings.saveSuccess',
        defaultMessage: 'User profile has been successfully updated'
      }), { variant: "success" });
    }).catch(error => {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'user.settings.saveError',
          defaultMessage: 'Failed to update profile'
        })
      ), { variant: "error" });
    }).finally(() => setProcessing(false));
  };

  const updateLocale = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocale(event.target.value as UserLocale || undefined);
  };

  return (
    <Container maxWidth="md" id="user-profile" disableGutters>
      <PaddedPaper>
        <Typography variant="h5" gutterBottom>
          <FormattedMessage id="user.settings.title" defaultMessage="Update User Profile" />
        </Typography>
        <LdapUserMessage user={user} />
        <ValidatedTextField
          disabled={true}
          name="key"
          label={intl.formatMessage({
            id: 'user.settings.key.label',
            defaultMessage: 'Key'
          })}
          value={user.key}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={disabled}
          name="name"
          label={intl.formatMessage({
            id: 'user.settings.name.label',
            defaultMessage: 'Name'
          })}
          value={name}
          onChange={(e) => setName(e.target.value)}
          onKeyDown={submitOnEnter}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={disabled}
          name="email"
          label={intl.formatMessage({
            id: 'user.settings.email.label',
            defaultMessage: 'Email'
          })}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={submitOnEnter}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={disabled}
          name="locale"
          label={intl.formatMessage({
            id: 'user.settings.locale.label',
            defaultMessage: 'Locale'
          })}
          value={locale || ''}
          onChange={updateLocale}
          onKeyDown={submitOnEnter}
          margin="normal"
          variant="outlined"
          select
        >
          <MenuItem value="">
            <FormattedMessage id="user.settings.locale.unset.label" defaultMessage="Browser default" />
          </MenuItem>
          {USER_LOCALES.map((loc) => (
            <MenuItem key={loc} value={loc}>{USER_LOCALE_METADATA[loc].label}</MenuItem>
          ))}
        </ValidatedTextField>
        <FormButtons>
          <DefaultButton
            id="update-profile"
            onClick={validateAndSubmit}
            disabled={disabled}
            startIcon={<SaveIcon />}
          >
            <FormattedMessage id="user.settings.saveButton" defaultMessage="Update Profile" />
          </DefaultButton>
        </FormButtons>
      </PaddedPaper>
    </Container>
  );
};

export default UserProfile;
