import { FC, PropsWithChildren } from 'react';

import CodeIcon from '@mui/icons-material/Code';

import { intl } from '../../../Internationalization';

import Highlighter from './Highlighter';

export interface TagHighlighterProps extends PropsWithChildren {
  decoratedText: string;
}

const TagHighlighter: FC<TagHighlighterProps> = ({ children }) => (
  <Highlighter
    sx={{
      borderRadius: 1,
      color: (theme) => theme.palette.getContrastText(theme.palette.warning.light),
      backgroundColor: (theme) => theme.palette.warning.light,
      borderColor: (theme) => theme.palette.warning.dark
    }}
    icon={CodeIcon}
    label={intl.formatMessage({
      id: 'components.editor.tagHighlighter.templateTag',
      defaultMessage: 'Template tag'
    })}
  >
    {children}
  </Highlighter>
);

export default TagHighlighter;
