import { Grid, Paper, Skeleton } from "@mui/material";

const ActivityCardSkeleton = () => (
  <Grid item md={3} sm={6} xs={12}>
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        padding: 2,
        marginTop: 4
      }}
    >
      <Skeleton height={35} width="100%" />
      <Skeleton height={30} width={50} />
    </Paper>
  </Grid>
);

export default ActivityCardSkeleton;
