import { FC, useState } from 'react';
import { AxiosPromise } from 'axios';
import { useSnackbar } from 'notistack';

import { CardContent, Card, IconButton, CardHeader, CardMedia } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { imageMimeTypes } from '../../api/media';
import { FileUploadConfig, extractErrorMessage } from '../../api/endpoints';
import { Upload, ConfirmDialog } from '../../components';
import { MediaDetail } from '../../types';
import { intl } from '../../Internationalization';
import { useMediaUpload } from '../../hooks';

interface UploadImageProps {
  id?: string;

  // the image and url generator
  title?: string;
  image?: MediaDetail;
  imageUrl: string;

  // upload and delete handlers
  uploadHandler: (file: File, config?: FileUploadConfig) => AxiosPromise<MediaDetail>;
  deleteHandler: () => AxiosPromise<any>;

  // callbacks for upload/delete
  onUploaded: (media: MediaDetail) => void;
  onDeleted: () => void;
}

const UploadImage: FC<UploadImageProps> = ({ id, image, imageUrl, uploadHandler, deleteHandler, onUploaded, onDeleted,
  title = intl.formatMessage({
    id: 'components.media.uploadImage.title',
    defaultMessage: 'Image'
  }),
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [upload, uploading, uploadProgress] = useMediaUpload<MediaDetail>({
    onUpload: (files: File[], config: FileUploadConfig) => uploadHandler(files[0], config),
    onUploadComplete: onUploaded
  });

  const [deleteImageConfirm, setDeleteImageConfirm] = useState<boolean>(false);

  const handleDeleteImage = () => {
    setDeleteImageConfirm(false);
    performDeleteImage();
  };

  const performDeleteImage = async () => {
    try {
      await deleteHandler();
      onDeleted();
      enqueueSnackbar(
        intl.formatMessage({
          id: 'components.media.uploadImage.deleteSuccess',
          defaultMessage: 'Image deleted'
        }), { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'components.media.uploadImage.deleteError',
          defaultMessage: 'Failed to delete image'
        })), { variant: "error" });
    }
  };

  return (
    <Card id={id}>
      <CardHeader sx={{ pb: 0 }} title={title} />
      <CardContent sx={{ position: 'relative' }}>
        <ConfirmDialog
          id="confirm-delete-image"
          isOpen={!!deleteImageConfirm}
          title={intl.formatMessage({
            id: 'components.media.uploadImage.confirmDelete.title',
            defaultMessage: 'Delete image'
          })}
          text={intl.formatMessage({
            id: 'components.media.uploadImage.confirmDelete.text',
            defaultMessage: 'Are you sure you wish to delete the image?'
          })}
          confirmBtnText={intl.formatMessage({
            id: 'components.media.uploadImage.confirmDelete.confirmButton',
            defaultMessage: 'Delete image'
          })}
          confirmAction={handleDeleteImage}
          closeAction={() => setDeleteImageConfirm(false)}
        />
        <IconButton
          onClick={() => setDeleteImageConfirm(true)}
          disabled={!image}
          aria-label={intl.formatMessage({
            id: 'components.media.uploadImage.deleteButton.ariaLabel',
            defaultMessage: 'Delete {title}'
          }, { title })}
          sx={{
            color: 'primary.main',
            position: 'absolute',
            top: 25,
            right: 25,
            bgcolor: 'rgba(255, 255, 255, 0.6)',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.8)'
            }
          }}
          size="large"
        >
          <DeleteForeverIcon fontSize="large" />
        </IconButton>
        <CardMedia
          image={imageUrl}
          sx={{
            mb: 2,
            height: 200,
            backgroundSize: '100%, auto',
          }}
        />
        <Upload
          onDrop={upload}
          accept={imageMimeTypes}
          uploading={uploading}
          progress={uploadProgress}
          disabled={uploading}
        />
      </CardContent>
    </Card>
  );
};

export default UploadImage;
