import { FC } from "react";
import { Text, View } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import { chunk } from "lodash";

import { PdfTableCell, PdfTableRow } from "../components";

const COLUMN_COUNT = 2;

interface TwoColumnTableProps {
  data: string[];
  tableHeader?: string;
  style?: Style | Style[];
}

const TwoColumnTable: FC<TwoColumnTableProps> = ({ data, tableHeader, style }) => {
  if (!data.length) {
    return null;
  }
  const rows = chunk(data, COLUMN_COUNT);

  return (
    <View style={style}>
      {
        tableHeader && (
          <PdfTableRow header>
            <PdfTableCell header>
              <Text>
                {tableHeader}
              </Text>
            </PdfTableCell>
          </PdfTableRow>
        )
      }
      {rows.map((dataRow, index) => (
        <PdfTableRow key={`${index}-${dataRow[0]}`}>
          <PdfTableCell colSpan="half">
            <Text>
              {dataRow[0]}
            </Text>
          </PdfTableCell>
          <PdfTableCell colSpan="half">
            <Text>
              {dataRow[1]}
            </Text>
          </PdfTableCell>
        </PdfTableRow>
      ))}
    </View>
  );
};

export default TwoColumnTable;
