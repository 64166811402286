import { FC } from "react";
import { Typography } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BallotIcon from "@mui/icons-material/Ballot";
import WorkIcon from "@mui/icons-material/Work";
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import { HeaderBreadcrumb } from "../../../../../components";
import { SavedMappingSummary } from "../../../../../types";
import { intl } from "../../../../../Internationalization";

interface SavedMappingBreadcrumbProps {
  savedMapping: SavedMappingSummary;
  displaySavedMapping?: boolean;
}

const SavedMappingBreadcrumb: FC<SavedMappingBreadcrumbProps> = ({ savedMapping, displaySavedMapping }) => {
  const { assignment } = savedMapping;
  return (
    <HeaderBreadcrumb>
      <Typography>
        <WorkIcon
          titleAccess={intl.formatMessage({
            id: 'myAssignment.savedMappings.copy.breadcrumb.project.titleAccess',
            defaultMessage: 'Project'
          })}
        />
        {assignment.specification.project.name}
      </Typography>
      <Typography>
        <BallotIcon
          titleAccess={intl.formatMessage({
            id: 'myAssignment.savedMappings.copy.breadcrumb.specification.titleAccess',
            defaultMessage: 'Specification'
          })}
        />
        {assignment.specification.name}
      </Typography>
      <Typography>
        <AssignmentIcon
          titleAccess={intl.formatMessage({
            id: 'myAssignment.savedMappings.copy.breadcrumb.assignment.titleAccess',
            defaultMessage: 'Assignment'
          })}
        />
        {assignment.reference}
      </Typography>
      {displaySavedMapping && (
        <Typography>
          <SyncAltIcon
            titleAccess={intl.formatMessage({
              id: 'myAssignment.savedMappings.copy.breadcrumb.savedMapping.titleAccess',
              defaultMessage: 'Saved Mapping'
            })}
          />
          {savedMapping.name}
        </Typography>
      )}
    </HeaderBreadcrumb>
  );
};

export default SavedMappingBreadcrumb;
