import { FC, useState } from 'react';
import { useSnackbar } from 'notistack';

import { IconButton, Tooltip, TableRow, TableCell, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

import * as SubmissionApi from '../../../../../../api/submission';
import { extractErrorMessage, FileUploadConfig } from '../../../../../../api/endpoints';
import { UploadCompact, ConfirmDialog } from '../../../../../../components';
import { UploadedIcon } from '../../../../../../components/icons';
import { MediaDetail, DataStoreConfigDetail, SessionSchema, UploadMode, VirusCheckedMediaDetail, VIRUS_SCANNER_STATE_METADATA, DataStoreParameterDetail } from '../../../../../../types';

import { dataStoreNameFromPath, dataStoreTypeMetaDataFromPath } from '../../../../../../util';
import { intl } from '../../../../../../Internationalization';
import { useMediaUpload } from '../../../../../../hooks';
import VirusScanStateIcon from '../../../../../../components/media/VirusScanStatus';

import InputParametersTableCell from './InputParametersTableCell';

interface InputUploadRowProps {
  submissionReference: string;
  dataStorePath: string;

  media?: VirusCheckedMediaDetail;
  mediaColumn: boolean;
  dataStoreConfig?: DataStoreConfigDetail;
  sessionSchema: SessionSchema;
  virusScannerEnabled: boolean;

  dataStoreInputParameters?: DataStoreParameterDetail[];
  displayParameters: boolean;

  onFileUpload(dataStorePath: string, InputFile: MediaDetail): void;
  onRemoveMedia(dataStorePath: string, uploadable: boolean): void;

  handleUploadChange: (uploading: boolean) => void;
}

const InputUploadRow: FC<InputUploadRowProps> = ({
  dataStorePath, dataStoreConfig, sessionSchema, media, mediaColumn, submissionReference,
  virusScannerEnabled, dataStoreInputParameters, displayParameters,
  onFileUpload, onRemoveMedia, handleUploadChange
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [upload, uploading, uploadProgress] = useMediaUpload({
    onUpload: (files: File[], fileUploadConfig: FileUploadConfig) => {
      handleUploadChange(true);
      return SubmissionApi.uploadInput(submissionReference, files[0], dataStorePath, fileUploadConfig);
    },
    onUploadComplete: (file: MediaDetail) => {
      onFileUpload(dataStorePath, file);
    },
    onResetCallback: () => {
      handleUploadChange(false);
    }
  });

  const [deleteFileConfirm, setDeleteFileConfirm] = useState<boolean>(false);

  const { icon: Icon, label } = dataStoreTypeMetaDataFromPath(sessionSchema, dataStoreConfig?.path, "importSchema");

  const deleteSubmissionInputMedia = async (uploadable: boolean) => {
    setDeleteFileConfirm(false);
    try {
      await SubmissionApi.removeInput(submissionReference, dataStorePath);
      onRemoveMedia(dataStorePath, uploadable);
      enqueueSnackbar(intl.formatMessage({
        id: 'openSubmission.inputUploadRow.deleteSuccess',
        defaultMessage: 'File deleted'
      }), { variant: 'success' });
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'openSubmission.inputUploadRow.deleteError',
          defaultMessage: 'Failed to remove input'
        })), { variant: 'warning' });
    }
  };

  const uploadTitle = () => {
    if (media) {
      if (!dataStoreConfig || dataStoreConfig.uploadMode === UploadMode.NOT_SUPPORTED) {
        return intl.formatMessage({
          id: 'openSubmission.inputUploadRow.dataSetNotFound',
          defaultMessage: '{filename} - Data set not found'
        }, { filename: media.filename });
      }
      return media.filename;
    }
    return intl.formatMessage({
      id: 'openSubmission.inputUploadRow.uploadAFile',
      defaultMessage: 'Please upload a file'
    });
  };

  const renderMediaRequirementCell = () => {
    if (dataStoreConfig?.uploadMode === UploadMode.REQUIRED) {
      return (
        <Tooltip
          title={
            intl.formatMessage({
              id: 'openSubmission.inputUploadRow.uploadRequired.tooltip',
              defaultMessage: 'Upload required'
            })
          }
        >
          <CheckIcon
            fontSize="large"
            titleAccess={intl.formatMessage({
              id: 'openSubmission.inputUploadRow.uploadRequired.titleAccess',
              defaultMessage: 'Upload required'
            })}
          />
        </Tooltip>
      );
    }

    if ((!dataStoreConfig || dataStoreConfig.uploadMode === UploadMode.NOT_SUPPORTED) && media) {
      return (
        <Tooltip
          title={
            intl.formatMessage({
              id: 'openSubmission.inputUploadRow.uploadNotSupported.tooltip',
              defaultMessage: 'Upload not supported'
            })
          }
        >
          <DoNotDisturbIcon
            color="error"
            fontSize="large"
            titleAccess={intl.formatMessage({
              id: 'openSubmission.inputUploadRow.uploadNotSupported.titleAccess',
              defaultMessage: 'Upload not supported'
            })}
          />
        </Tooltip>
      );
    }
  };

  return (
    <TableRow>
      <TableCell>
        <Tooltip title={dataStorePath} placement="top-start">
          <span>
            {dataStoreNameFromPath(dataStorePath)}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          <Icon fontSize="large" />
          <Box pl={1}>
            {label}
          </Box>
        </Box>
      </TableCell>
      {
        mediaColumn &&
        <TableCell align="center">
          {renderMediaRequirementCell()}
        </TableCell>
      }
      {
        (mediaColumn && virusScannerEnabled) &&
        (
          <TableCell className="InputUploadRow-virusScanState">
            {
              media &&
              <Box display="flex" alignItems="center">
                <VirusScanStateIcon state={media.virusScanState} />
                <Box pl={1}>
                  {VIRUS_SCANNER_STATE_METADATA[media.virusScanState].label}
                </Box>
              </Box>
            }
          </TableCell>
        )
      }
      {
        mediaColumn && (
          <TableCell>
            <Box minWidth={400} display="flex" width="100%">
              <UploadCompact
                onDrop={upload}
                progress={uploadProgress}
                uploading={uploading}
                disabled={!dataStoreConfig || dataStoreConfig.uploadMode === UploadMode.NOT_SUPPORTED}
                title={uploadTitle()}
                icon={media && UploadedIcon}
              />
              <Box alignItems="center" display="flex" ml={1}>
                <IconButton
                  name="removeMedia"
                  onClick={() => setDeleteFileConfirm(true)}
                  disabled={dataStoreConfig && !media}
                  size="large"
                  aria-label={intl.formatMessage({
                    id: 'openSubmission.inputUploadRow.deleteUpload.ariaLabel',
                    defaultMessage: 'Delete uploaded file'
                  })}
                >
                  {dataStoreConfig ? <DeleteForeverIcon /> : <CloseIcon />}
                </IconButton>
              </Box>
              <ConfirmDialog
                id="confirm-delete-uploaded-file"
                isOpen={deleteFileConfirm}
                title={intl.formatMessage({
                  id: 'openSubmission.inputUploadRow.confirmDelete.title',
                  defaultMessage: 'Delete file'
                })}
                text={intl.formatMessage({
                  id: 'openSubmission.inputUploadRow.confirmDelete.text',
                  defaultMessage: 'Are you sure you wish to delete the uploaded file?'
                })}
                confirmBtnText={intl.formatMessage({
                  id: 'openSubmission.inputUploadRow.confirmDelete.confirmButton',
                  defaultMessage: 'Delete File'
                })}
                confirmAction={() => deleteSubmissionInputMedia(!!dataStoreConfig)}
                closeAction={() => setDeleteFileConfirm(false)}
              />
            </Box>
          </TableCell>
        )
      }
      {
        displayParameters &&
        <InputParametersTableCell
          dataStoreInputParameters={dataStoreInputParameters}
          submissionReference={submissionReference}
          dataStoreConfig={dataStoreConfig}
        />
      }
    </TableRow>
  );
};

export default InputUploadRow;
