import { AxiosPromise } from 'axios';

import { AssignmentsRequest, AssignmentsResponse, AssignmentDetail, AssignmentSettings } from '../types';

import { AXIOS, RequestConfig } from './endpoints';

export function getAssignments(request: AssignmentsRequest, config?: RequestConfig): AxiosPromise<AssignmentsResponse> {
  return AXIOS.get(`/assignments`, {
    ...config,
    params: request
  });
}

export function createAssignment(request: AssignmentSettings): AxiosPromise<AssignmentDetail> {
  return AXIOS.post(`/assignments`, request);
}
