import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box,  Typography } from '@mui/material';

import WorkIcon from '@mui/icons-material/Work';
import BallotIcon from '@mui/icons-material/Ballot';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { toMySubmissionLink } from '../../../../screens/my-assignments/my-assignment/submissions/MySubmissions';
import { SubmissionSummary, SUBMISSION_OUTCOME_METADATA, SUBMISSION_STATE_METADATA } from '../../../../types';
import { dateTimeFormat } from '../../../../util';

import { IconTypography } from '../../../';

interface OpenSubmissionProps {
  submission: SubmissionSummary;
  onNavigate: () => void;
}

const OpenSubmission: FC<OpenSubmissionProps> = ({ submission, onNavigate }) => {
  const navigate = useNavigate();
  const { assignment } = submission;

  const Icon = submission.outcome ? SUBMISSION_OUTCOME_METADATA[submission.outcome].icon : SUBMISSION_STATE_METADATA[submission.state].icon;
  const description = submission.outcome ? (
    SUBMISSION_OUTCOME_METADATA[submission.outcome].description
  ) : SUBMISSION_STATE_METADATA[submission.state].description;

  const handleNavigate = () => {
    navigate(toMySubmissionLink(submission));
    onNavigate();
  };

  return (
    <Box display="flex" className="OpenSubmission-root" sx={{ "&:hover": { bgcolor: (theme) => theme.palette.grey[100] } }} p={1}>
      <Box display="flex" flexGrow={1} sx={{ cursor: "pointer" }} onClick={handleNavigate}>
        <Icon sx={{ width: (theme) => theme.spacing(6), height: (theme) => theme.spacing(6) }} />
        <Box ml={2} flexGrow={1}>
          <Typography variant="h6" gutterBottom>
            {description}
          </Typography>
          <IconTypography variant="body1">
            <WorkIcon fontSize="small" />
            {assignment.specification.project.name}
          </IconTypography>
          <IconTypography variant="body1">
            <BallotIcon fontSize="small" />
            {assignment.specification.name}
          </IconTypography>
          <IconTypography variant="body1">
            <AssignmentIcon fontSize="small" />
            {assignment.reference}
          </IconTypography>
          <Box mt={1}>
            <Typography variant="caption">
              {dateTimeFormat(submission.createdAt)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OpenSubmission;
