import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from '@react-pdf/renderer';

import { ValidateSchemaTaskResult } from '../../../../../types';
import { PdfTableRow, PdfTableCell } from '../components';

export const ValidateSchemaTaskHeader: FC = () => (
  <PdfTableRow header>
    <PdfTableCell colSpan="third" number header>
      <Text>
        <FormattedMessage id="submission.resultsPdf.validateSchemaTaskResult.processedHeader" defaultMessage="Processed" />
      </Text>
    </PdfTableCell>
    <PdfTableCell colSpan="third" number header>
      <Text>
        <FormattedMessage id="submission.resultsPdf.validateSchemaTaskResult.countHeader" defaultMessage="Validation Failures" />
      </Text>
    </PdfTableCell>
    <PdfTableCell colSpan="third" number header>
      <Text>
        <FormattedMessage id="submission.resultsPdf.validateSchemaTaskResult.errorHeader" defaultMessage="Errors" />
      </Text>
    </PdfTableCell>
  </PdfTableRow>
);

interface ValidateSchemaTaskResultContentProps {
  task: ValidateSchemaTaskResult;
}

export const ValidateSchemaTaskResultContent: FC<ValidateSchemaTaskResultContentProps> = ({ task }) => (
  <PdfTableRow>
    <PdfTableCell colSpan="third" number>
      <Text>{task.processed}</Text>
    </PdfTableCell>
    <PdfTableCell colSpan="third" number>
      <Text>{task.count}</Text>
    </PdfTableCell>
    <PdfTableCell colSpan="third" number>
      <Text>{task.errors}</Text>
    </PdfTableCell>
  </PdfTableRow>
);
