import { AxiosPromise } from 'axios';
import { SpecificationDetail, SpecificationSettings, SpecificationsRequest, SpecificationsResponse } from '../types';
import { AXIOS, RequestConfig } from './endpoints';

export function getSpecifications(
  projectKey: string, request: SpecificationsRequest, config?: RequestConfig
): AxiosPromise<SpecificationsResponse> {
  return AXIOS.get(`/projects/${projectKey}/specifications`, {
    ...config,
    params: request
  });
}

export function createSpecification(projectKey: string, request: SpecificationSettings): AxiosPromise<SpecificationDetail> {
  return AXIOS.post(`/projects/${projectKey}/specifications`, request);
}
