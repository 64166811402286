import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography, IconButton, Box } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { intl } from '../../Internationalization';

interface FilterPaginationProps {
  page: number;
  total?: number;
  size: number;

  setPage: (page: number) => void;

  disabled?: boolean;
}

const FilterPagination: FC<FilterPaginationProps> = ({ page, total = 0, size, setPage, disabled }) => {
  const start = page * size;
  const pageEnd = start + size;
  const end = Math.min(pageEnd, total);
  const previousDisabled = start <= 0;
  const nextDisabled = pageEnd >= total;

  const handlePrevious = () => {
    setPage(page - 1);
  };

  const handleNext = () => {
    setPage(page + 1);
  };

  return (
    <Box display="flex" alignItems="center" className="FilterPagination-root">
      <Typography align="center" display="inline" sx={{ p: 1, minWidth: 50 }}>
        <FormattedMessage
          id="components.filterPagination.pageCount"
          defaultMessage="{start}-{end} of {total}"
          values={{ start: total === 0 ? start : start + 1, end, total }}
        />
      </Typography>
      <Box my={1}>
        <IconButton
          disabled={disabled || previousDisabled}
          size="small"
          onClick={handlePrevious}
          aria-label={intl.formatMessage({
            id: 'components.filterPagination.previous.ariaLabel',
            defaultMessage: 'Previous page'
          })}
        >
          <ArrowLeftIcon />
        </IconButton>
        <IconButton
          disabled={disabled || nextDisabled}
          size="small"
          onClick={handleNext}
          aria-label={intl.formatMessage({
            id: 'components.filterPagination.next.ariaLabel',
            defaultMessage: 'Next page'
          })}
        >
          <ArrowRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default FilterPagination;
