import { FC } from 'react';
import { Link, Path } from 'react-router-dom';

import { Tab, TabProps } from '@mui/material';

interface RouterTabProps extends Omit<TabProps<typeof Link>, "to" | "component"> {
  resolveTabRoute: (value: string) => Path;
}

const RouterTab: FC<RouterTabProps> = ({ value, resolveTabRoute, ...rest }) => (
  <Tab value={value} to={resolveTabRoute(value)} component={Link} {...rest} />
);

export default RouterTab;
