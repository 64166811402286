import { FC, useContext, useEffect, useState } from 'react';
import { EditorState } from 'draft-js';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import { Typography, Box } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';

import { intl } from '../../../../../Internationalization';
import { extractErrorMessage } from '../../../../../api/endpoints';
import * as SpecificationApi from '../../../../../api/specification';
import { editorStateFromHtml, htmlFromEditorState } from '../../../../../util';
import { PaddedPaper, DefaultButton, StyledEditor } from '../../../../../components';

import { SpecificationContext } from '../SpecificationContext';

const Documentation: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { specification, specificationKey, specificationUpdated } = useContext(SpecificationContext);

  const [processing, setProcessing] = useState<boolean>(false);
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  useEffect(() => {
    setEditorState(editorStateFromHtml(specification.documentation));
  }, [specification.documentation]);

  const handleSubmit = () => {
    setProcessing(true);
    const updatedSpecification = { ...specification, documentation: htmlFromEditorState(editorState) };
    SpecificationApi.updateSpecification(specificationKey, updatedSpecification).then(response => {
      enqueueSnackbar(intl.formatMessage({
        id: 'specification.information.documention.saveSuccess',
        defaultMessage: 'Specification updated'
      }), { variant: "success" });
      specificationUpdated(response.data);
    }).catch(error => {
      enqueueSnackbar(
        extractErrorMessage(error, intl.formatMessage({
          id: 'specification.information.documention.saveError',
          defaultMessage: 'Failed to update specification'
        })), { variant: "error" });
    }).finally(() => setProcessing(false));
  };

  return (
    <PaddedPaper>
      <Box mb={2}>
        <Typography variant="h5" gutterBottom>
          <FormattedMessage id="specification.information.documention.title" defaultMessage="Documentation" />
        </Typography>
      </Box>
      <StyledEditor
        editorClassName="Documentation-editor"
        editorState={editorState}
        onEditorStateChange={setEditorState}
        handlePastedText={() => false}
        toolbar={{
          options: ['inline', 'blockType', 'list', 'link', 'history'],
          inline: {
            options: ['bold', 'italic', 'underline']
          },
          list: {
            options: ['unordered', 'ordered']
          }
        }}
      />
      <Box py={1}>
        <DefaultButton
          onClick={handleSubmit}
          disabled={processing}
          startIcon={<SaveIcon />}
          name="saveButton"
        >
          <FormattedMessage id="specification.information.documention.saveButton" defaultMessage="Save" />
        </DefaultButton>
      </Box>
    </PaddedPaper>
  );
};

export default Documentation;
