import { FC } from 'react';
import { EditorState } from 'draft-js';

import { Box } from '@mui/material';

import { Field } from '../../types';

import { MailSubjectEditor, MailBodyRawEditor } from '.';

interface MailEditorProps {
  subjectEditorState: EditorState;
  onSubjectEditorStateChange: (editorState: EditorState) => void;
  body: string;
  setBody: (value: string) => void;
  textFields: Field[];
  linkFields: Field[];
}

const MailEditor: FC<MailEditorProps> = ({
  subjectEditorState, body, onSubjectEditorStateChange, setBody, textFields, linkFields
}) => {
  return (
    <>
      <Box id="subject-editor" pt={1}>
        <MailSubjectEditor
          editorState={subjectEditorState}
          onEditorStateChange={onSubjectEditorStateChange}
          textFields={textFields}
        />
      </Box>
      <Box id="body-editor" pb={1}>
        <MailBodyRawEditor
          value={body}
          onChange={setBody}
          textFields={textFields}
          linkFields={linkFields}
        />
      </Box>
    </>
  );
};

export default MailEditor;
