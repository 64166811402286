import { AxiosPromise } from 'axios';

import { SavedMappingResponse, SavedMappingsRequest } from '../types';

import { AXIOS, RequestConfig } from './endpoints';

export function getSavedMappings( request: SavedMappingsRequest, config?: RequestConfig): AxiosPromise<SavedMappingResponse> {
  return AXIOS.get('/saved_mappings', {
    ...config,
    params: request
  });
}
