import React from 'react';
import { Palette } from '../../types';

export interface CustomThemeContextValue {
  palette: Palette;
  updatePalette: (palette: Palette) => void;
}

const CustomThemeContextDefaultValue = {} as CustomThemeContextValue;
export const CustomThemeContext = React.createContext(
  CustomThemeContextDefaultValue
);
