import { FC } from 'react';
import { CardContent, Typography, CardMedia } from '@mui/material';

import NavigationCard from './NavigationCard';

import { extractInitials } from '../../util';

interface LogoCardProps {
  toUrl: string;
  title: string;
  color: string;
  description?: string;
  mediaUrl?: string;
}

const LogoCard: FC<LogoCardProps> = ({ toUrl, description, mediaUrl, title, color, ...rest }) => (
  <NavigationCard to={toUrl} {...rest}>
    <CardMedia
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 200,
        bgcolor: color,
        backgroundSize: 'contain',
        p: 1,
        backgroundOrigin: 'content-box',
      }}
      image={mediaUrl}
    >
      {
        !mediaUrl && (
          <Typography variant="h1" sx={{ fontWeight: 400, color: (theme) => theme.palette.getContrastText(color) }}>
            {extractInitials(title)}
          </Typography>
        )
      }
    </CardMedia>
    <CardContent>
      <Typography gutterBottom variant="h5" component="h2">
        {title}
      </Typography>
      {
        description &&
        <Typography gutterBottom variant="subtitle1" color="textSecondary" component="h3">
          {description}
        </Typography>
      }
    </CardContent>
  </NavigationCard>
);

export default LogoCard;
