import React from 'react';
import { ApplicationDetails } from '../../types';

export interface ApplicationContextValue {
  applicationDetails: ApplicationDetails;
  refresh: () => void;
}

const ApplicationContextDefaultValue = {} as ApplicationContextValue;
export const ApplicationContext = React.createContext(
  ApplicationContextDefaultValue
);
