import React, { Dispatch, SetStateAction } from 'react';

import { DataStoreSchema, DataStoreMapping, DataStoreClassMapping, DataStoreConfigDetail, SpecificationDetail } from '../../../types';

import { SchemaMappingDirection } from './schemaMappingMetadata';
import { SchemaMappingValidationResults } from './schemaMappingUtils';

interface SchemaMappingContextValue {
  specification: SpecificationDetail;
  dataStoreConfigs: Record<string, DataStoreConfigDetail>;
  selectedDataStoreName: string;
  handleSelectDataStoreName: (dataStoreName: string) => void;
  direction: SchemaMappingDirection;
  setDirection: Dispatch<SetStateAction<SchemaMappingDirection>>;
  sourceDataStores: DataStoreSchema[];
  targetDataStores: DataStoreSchema[];
  schemaMappings: DataStoreMapping[];
  updateSchemaMappings: (schema: DataStoreMapping[]) => void;
  updateClassMappings: (dataStoreClasses: DataStoreClassMapping[]) => void;
  validationResults: SchemaMappingValidationResults;
}

const SchemaMappingContextDefaultValue = {} as SchemaMappingContextValue;
export const SchemaMappingContext = React.createContext(
  SchemaMappingContextDefaultValue
);
