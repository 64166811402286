import { FC, useState, useContext, Fragment } from "react";
import { useNavigate } from "react-router";
import { FormattedMessage } from "react-intl";
import { ValidateFieldsError } from "async-validator";
import { useSnackbar } from "notistack";
import { add, formatISO, startOfDay } from "date-fns";

import { Dialog, DialogTitle, DialogContent, FormHelperText, Box, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ForwardIcon from '@mui/icons-material/Forward';
import { DatePicker } from '@mui/x-date-pickers';

import * as ApiTokensApi from '../../../../api/apiTokens';
import { PaddedDialogActions, DefaultButton, CopyToClipboard, MessageBox, ValidatedTextField } from "../../../../components";
import { validate } from '../../../../util';
import { CreateApiTokenRequest, ApiTokenFullDetail } from "../../../../types";
import { extractErrorMessage } from "../../../../api/endpoints";
import { intl } from "../../../../Internationalization";

import { UserContext } from "../UserContext";

interface NewApiTokenDialogProps {
  onCancel: () => void;
}

const NewApiTokenDialog: FC<NewApiTokenDialogProps> = ({ onCancel }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { userKey } = useContext(UserContext);

  const [processing, setProcessing] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [expiresAt, setExpiresAt] = useState<Date | null>(null);

  const [apiToken, setApiToken] = useState<ApiTokenFullDetail>();

  const handleClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      return;
    }
    onCancel();
  };

  const validateAndSubmit = async () => {
    setProcessing(true);
    const newApiTokenRequest = { name, description, expiresAt, userKey };
    try {
      const validatedResponse = await validate(ApiTokensApi.newApiTokenSettingsValidator(() => userKey), newApiTokenRequest);
      createApiToken({
        ...validatedResponse,
        expiresAt: formatISO(startOfDay(validatedResponse.expiresAt!))
      });
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const createApiToken = async (newApiTokenRequest: CreateApiTokenRequest) => {
    setFieldErrors(undefined);

    try {
      const response = await ApiTokensApi.createApiToken(newApiTokenRequest);
      setApiToken(response.data);
      enqueueSnackbar(intl.formatMessage({
        id: 'user.apiToken.create.saveSuccess',
        defaultMessage: 'New API token created'
      }), { variant: 'success' });
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'user.apiToken.create.saveError',
          defaultMessage: 'Failed to create new API token'
        })
      ), { variant: 'error' });
    } finally {
      setProcessing(false);
    }
  };

  const renderNewForm = () => (
    <Fragment>
      <DialogContent dividers={true}>
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="name"
          label={intl.formatMessage({
            id: 'user.apiToken.create.name.label',
            defaultMessage: 'Name'
          })}
          value={name}
          onChange={event => setName(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="description"
          label={intl.formatMessage({
            id: 'user.apiToken.create.description.label',
            defaultMessage: 'Description'
          })}
          value={description}
          onChange={event => setDescription(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <DatePicker
          label={intl.formatMessage({
            id: 'user.apiToken.create.expiresAt.label',
            defaultMessage: 'Expires At'
          })}
          value={expiresAt}
          onChange={(date) => setExpiresAt(date)}
          minDate={add(new Date(), { days: 1 })}
          disablePast
          InputProps={{
            className: "FilterDateRange-startDate",
            error: !!fieldErrors?.expiresAt
          }}
          disabled={processing}
          renderInput={(props) => (
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              {...props}
            />
          )}
        />
        {
          (fieldErrors && fieldErrors.expiresAt) &&
          <FormHelperText>
            {fieldErrors?.expiresAt[0]?.message}
          </FormHelperText>
        }
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton
          id="cancel-add-api-token"
          color="secondary"
          onClick={onCancel}
          disabled={processing}
        >
          <FormattedMessage id="user.apiToken.create.cancelButton" defaultMessage="Cancel" />
        </DefaultButton>
        <DefaultButton
          id="add-new-api-token"
          onClick={validateAndSubmit}
          disabled={processing}
          startIcon={<AddIcon />}
        >
          <FormattedMessage id="user.apiToken.create.createButton" defaultMessage="Add API Token" />
        </DefaultButton>
      </PaddedDialogActions>
    </Fragment>
  );

  const renderApiToken = () => (
    <Fragment>
      <DialogContent dividers={true}>
        <MessageBox
          message={intl.formatMessage({
            id: 'user.apiToken.create.tokenInformation',
            defaultMessage: 'Your token has been generated and is displayed below. You should copy this token now as you will not be able to view it again.'
          })}
          level="info"
        />
        <Box mt={3} mb={1}>
          <CopyToClipboard>
            {apiToken!.token}
          </CopyToClipboard>
        </Box>
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton
          id="continue-api-token-details"
          onClick={() => navigate(encodeURIComponent(apiToken!.name))}
          startIcon={<ForwardIcon />}
        >
          <FormattedMessage id="user.apiToken.create.continueButton" defaultMessage="Continue" />
        </DefaultButton>
      </PaddedDialogActions>
    </Fragment>
  );

  return (
    <Dialog
      onClose={handleClose}
      open={true}
      fullWidth
      id="new-api-token-dialog"
      aria-labelledby="new-api-token-dialog-title"
    >
      <DialogTitle id="new-api-token-dialog-title">
        <FormattedMessage id="user.apiToken.create.title" defaultMessage="Add API Token" />
      </DialogTitle>
      {
        apiToken ?
          renderApiToken() :
          renderNewForm()
      }
    </Dialog>
  );
};

export default NewApiTokenDialog;
