import { FC } from "react";
import { Box } from "@mui/material";
import { FormattedMessage } from 'react-intl';

import ValidatedCell from "./ValidatedCell";

interface PreviewFieldCellProps {
  fieldErrors?: string[];
  fieldValue?: string;
  displayNoneMessage?: boolean;
}

const PreviewFieldCell: FC<PreviewFieldCellProps> = ({ fieldErrors, fieldValue, displayNoneMessage }) => {
  return (
    <ValidatedCell fieldErrors={fieldErrors}>
      {
        fieldValue ?
          fieldValue : (
            displayNoneMessage &&
            <Box fontStyle="italic">
              (
              <FormattedMessage id="userImport.previewFieldCell.noneSpecified" defaultMessage="None specified" />
              )
            </Box>
          )
      }
    </ValidatedCell>
  );
};

export default PreviewFieldCell;
