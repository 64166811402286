import React, { FC } from 'react';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Typography, SvgIconProps } from '@mui/material';

import UploadDropzone, { UploadDropzoneProps, renderProgressText } from './UploadDropzone';

interface UploadCompactProps extends UploadDropzoneProps {
  title: string;
  icon?: React.ComponentType<SvgIconProps>;
}

const UploadCompact: FC<UploadCompactProps> = ({ progress, uploading, title, icon: Icon, ...rest }) => (
  <UploadDropzone p={1} progress={progress} uploading={uploading} {...rest}>
    <Box display="flex" justifyContent="center" alignItems="center">
      {
        Icon ?
          <Icon fontSize="large" /> :
          <CloudUploadIcon fontSize="large" />
      }
      <Box flexDirection="column" display="flex" pl={2}>
        {
          title &&
          <Typography variant="subtitle1">{title}</Typography>
        }
        <Typography variant="body2">
          {renderProgressText(uploading, progress)}
        </Typography>
      </Box>
    </Box>
  </UploadDropzone>
);

export default UploadCompact;
