import { FC, useContext } from 'react';
import { Link as RouterLink, Routes, Route, Navigate } from 'react-router-dom';
import { Link, Typography } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import BallotIcon from '@mui/icons-material/Ballot';

import { HeaderBreadcrumb, AppDecorator, RouterTabs, RouterTab, InlineIconTab } from '../../../../components';
import { WarningIcon } from '../../../../components/icons';
import { intl } from '../../../../Internationalization';
import { evaluateSpecificationValidationResult } from '../../../../types';
import { useRouterTab } from '../../../../hooks';

import SpecificationSettings from './settings/SpecificationSettings';
import SpecificationInformation from './information/SpecificationInformation';
import AssignmentsRouting from './AssignmentsRouting';
import { SpecificationContext } from './SpecificationContext';
import Configuration from './configuration/Configuration';

const SpecificationRouting: FC = () => {
  const { project, specification, specificationValidationResult } = useContext(SpecificationContext);
  const configurationValid = evaluateSpecificationValidationResult(specificationValidationResult);

  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="specification-tabs" value={routerTab}>
      <RouterTab
        id="settings-tab"
        value="settings"
        label={intl.formatMessage({
          id: 'specification.routing.tabs.settings',
          defaultMessage: 'Settings'
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <InlineIconTab
        id="configuration-tab"
        value="configuration"
        label={intl.formatMessage({
          id: 'specification.routing.tabs.configuration',
          defaultMessage: 'Configuration'
        })}
        to={resolveTabRoute("configuration")}
        component={RouterLink}
        icon={
          configurationValid ?
            undefined :
            <WarningIcon
              titleAccess={intl.formatMessage({
                id: 'specification.routing.tabs.configurationInvalid.titleAccess',
                defaultMessage: 'Configuration invalid'
              })}
            />
        }
      />
      <RouterTab
        id="assignments-tab"
        value="assignments"
        label={intl.formatMessage({
          id: 'specification.routing.tabs.assignments',
          defaultMessage: 'Assignments'
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="information-tab"
        value="information"
        label={intl.formatMessage({
          id: 'specification.routing.tabs.information',
          defaultMessage: 'Information'
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  const renderBreadcrumb = () => (
    <HeaderBreadcrumb>
      <Link component={RouterLink} to={`/projects/${project.key}/specifications`}>
        <WorkIcon
          titleAccess={intl.formatMessage({
            id: 'specification.routing.breadcrumb.project.titleAccess',
            defaultMessage: 'Project'
          })}
        />
        {project.name}
      </Link>
      <Typography id="breadcrumb-specification-name">
        <BallotIcon
          titleAccess={intl.formatMessage({
            id: 'specification.routing.breadcrumb.specification.titleAccess',
            defaultMessage: 'Specification'
          })}
        />
        {specification.name}
      </Typography>
    </HeaderBreadcrumb>
  );

  return (
    <AppDecorator renderTabs={renderTabs} renderBreadcrumb={renderBreadcrumb}>
      <Routes>
        <Route path="settings" element={<SpecificationSettings />} />
        <Route path="configuration" element={<Configuration />} />
        <Route path="assignments/*" element={<AssignmentsRouting />} />
        <Route path="information" element={<SpecificationInformation />} />
        <Route path="*" element={<Navigate to="settings" replace />} />
      </Routes>
    </AppDecorator>
  );
};

export default SpecificationRouting;
