import { styled } from '@mui/material';
import SizedAvatar, { SizedAvatarProps } from './SizedAvatar';

export interface ColorAvatarProps extends SizedAvatarProps {
  color?: string;
}

const ColorAvatar = styled(SizedAvatar)<ColorAvatarProps>(({ theme, color }) => ({
  backgroundColor: color ? color : 'transparent',
  color: color ? theme.palette.getContrastText(color) : theme.palette.text.primary
}));

export default ColorAvatar;
