import { formatDuration } from 'date-fns';
import { intl } from '../Internationalization';
import { lowercaseCountRegex, numberCountRegex, parseDuration, specialCharacterCountRegex, uppercaseCountRegex } from '../util';

import { ThemeOptions } from './application';
import { MediaDetail } from './shared';

export interface ConfigurableSiteSettings {
  name: string;
  rootUrl: string;
  theme: ThemeOptions;
  authenticationTokenExpiration: string;
  passwordResetTokenExpiration: string;
  loginTitle: string;
}

export interface PasswordRequirements {
  minLength: number;
  uppercase: number;
  lowercase: number;
  numbers: number;
  specialCharacters: number;
  reuseGenerations: number;
  authFailureLockoutThreshold: number;
  changeInterval?: string;
}
interface PasswordRequirementsMetadata {
  label: string;
  message: (count?: number | string) => string;
  validationType?: 'min' | 'max';
  validationRegex?: (value: number) => RegExp;
}

export const PASSWORD_REQUIREMENTS_METADATA: Record<keyof PasswordRequirements, PasswordRequirementsMetadata> = {
  minLength: {
    label: intl.formatMessage({
      id: 'passwordRequirements.minLength.label',
      defaultMessage: 'Min length'
    }),
    message: (count) => intl.formatMessage({
      id: 'passwordRequirements.minLength.message',
      defaultMessage: 'Must contain at least {count} {count, plural, one{character}other{characters}}'
    }, {
      count
    }),
    validationType: 'min'
  },
  uppercase: {
    label: intl.formatMessage({
      id: 'passwordRequirements.uppercase.label',
      defaultMessage: 'Uppercase'
    }),
    message: (count) => intl.formatMessage({
      id: 'passwordRequirements.uppercase.message',
      defaultMessage: 'Must contain at least {count} uppercase {count, plural, one{letter}other{letters}}'
    }, {
      count
    }),
    validationRegex: (value) => uppercaseCountRegex(value)
  },
  lowercase: {
    label: intl.formatMessage({
      id: 'passwordRequirements.lowercase.label',
      defaultMessage: 'Lowercase'
    }),
    message: (count) => intl.formatMessage({
      id: 'passwordRequirements.lowercase.message',
      defaultMessage: 'Must contain at least {count} lowercase {count, plural, one{letter}other{letters}}'
    }, {
      count
    }),
    validationRegex: (value) => lowercaseCountRegex(value)
  },
  numbers: {
    label: intl.formatMessage({
      id: 'site.passwordRequirements.numbers.label',
      defaultMessage: 'Numbers'
    }),
    message: (count) => intl.formatMessage({
      id: 'passwordRequirements.numbers.message',
      defaultMessage: 'Must contain at least {count} {count, plural, one{number}other{numbers}}'
    }, {
      count
    }),
    validationRegex: (value) => numberCountRegex(value)
  },
  specialCharacters: {
    label: intl.formatMessage({
      id: 'passwordRequirements.specialCharacters.label',
      defaultMessage: 'Special Characters'
    }),
    message: (count) => intl.formatMessage({
      id: 'passwordRequirements.specialCharacters.message',
      defaultMessage: 'Must contain at least {count} {count, plural, one{special character}other{special characters}}'
    }, {
      count
    }),
    validationRegex: (value) => specialCharacterCountRegex(value)
  },
  reuseGenerations: {
    label: intl.formatMessage({
      id: 'passwordRequirements.reuseGenerations.label',
      defaultMessage: 'Reuse Generations'
    }),
    message: (count) => intl.formatMessage({
      id: 'passwordRequirements.reuseGenerations.message',
      defaultMessage: 'Must not be the same as {count, plural, one{your most recent password used}other{any of the previous {count} passwords used}}'
    }, {
      count
    })
  },
  authFailureLockoutThreshold: {
    label: intl.formatMessage({
      id: 'passwordRequirements.authFailureLockoutThreshold.label',
      defaultMessage: 'Login attempts before automatic suspension'
    }),
    message: (count) => intl.formatMessage({
      id: 'passwordRequirements.authFailureLockoutThreshold.message',
      defaultMessage: '{count} login attempts allowed before automatic suspension'
    }, {
      count
    })
  },
  changeInterval: {
    label: intl.formatMessage({
      id: 'passwordRequirements.changeInterval.label',
      defaultMessage: 'Change Interval'
    }),
    message: (count) => intl.formatMessage({
      id: 'passwordRequirements.changeInterval.message',
      defaultMessage: 'Must be changed every {count}'
    }, {
      count: count && formatDuration(parseDuration(count as string))
    })
  }
};

export interface SiteResources {
  logo?: MediaDetail;
  heroImage?: MediaDetail;
}

export interface SiteSettingSummary {
  name: string;
  rootUrl: string;
  theme: ThemeOptions;
  resources: SiteResources;
  loginTitle: string;
  passwordRequirements: PasswordRequirements;
}

export interface SiteSettingsDetail extends SiteSettingSummary {
  authenticationTokenExpiration: string;
  passwordResetTokenExpiration: string;
  passwordRequirements: PasswordRequirements;
}
