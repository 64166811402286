import { FC, useState } from 'react';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';

import { Dialog, DialogTitle, DialogContent, Checkbox } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import * as SupplierMappingsApi from '../../../api/supplierMappings';
import { extractErrorMessage } from '../../../api/endpoints';
import { ActiveSupplierAutocomplete, PaddedDialogActions, DefaultButton, BlockFormControlLabel, ValidatedTextField } from '../../../components';
import { intl } from '../../../Internationalization';
import { validate } from '../../../util';

import { SupplierDetail, SupplierMappingSettings } from '../../../types';

interface NewSupplierMappingFormProps {
  identityProviderKey: string;
  onCreate: () => void;
  onClose: () => void;
}

const NewSupplierMappingForm: FC<NewSupplierMappingFormProps> = ({ identityProviderKey, onCreate, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [supplier, setSupplier] = useState<SupplierDetail | null>(null);
  const [supervisor, setSupervisor] = useState<boolean>(false);
  const [group, setGroup] = useState<string>('');

  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const VALIDATOR = SupplierMappingsApi.supplierMappingValidator(() => identityProviderKey, () => supplier?.key || '');

  const validateAndSubmit = async () => {
    setProcessing(true);
    try {
      createSupplierMapping(
        await validate(VALIDATOR, { group, supplierKey: supplier?.key || '', identityProviderKey, supervisor })
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const createSupplierMapping = (settings: SupplierMappingSettings) => {
    SupplierMappingsApi.createSupplierMapping(settings).then(() => {
      enqueueSnackbar(intl.formatMessage({
        id: 'identityProvider.supplierMappings.add.saveSuccess',
        defaultMessage: 'Supplier mapping has been added'
      }), { variant: "success" });
      onCreate();
      onClose();
    }).catch((error: AxiosError) => {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'identityProvider.supplierMappings.add.saveError',
          defaultMessage: 'Failed to add supplier mapping'
        })
      ), { variant: "error" });
      setProcessing(false);
    });
  };

  return (
    <Dialog id="new-supplier-mapping-dialog" onClose={onClose} aria-labelledby="new-supplier-mapping-dialog-title" open={true} fullWidth>
      <DialogTitle id="new-supplier-mapping-dialog-title">
        <FormattedMessage id="identityProvider.supplierMappings.add.title" defaultMessage="Add Supplier Mapping" />
      </DialogTitle>
      <DialogContent dividers={true}>
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="group"
          label={intl.formatMessage({
            id: 'identityProvider.supplierMappings.add.group.label',
            defaultMessage: 'Group'
          })}
          value={group}
          onChange={(event) => setGroup(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <ActiveSupplierAutocomplete
          id="supplier-select"
          name="supplierKey"
          label={intl.formatMessage({
            id: 'identityProvider.supplierMappings.add.supplierKey.label',
            defaultMessage: 'Supplier'
          })}
          variant="outlined"
          margin="normal"
          fieldErrors={fieldErrors}
          disabled={processing}
          value={supplier}
          onChange={setSupplier}
          fullWidth
        />
        <BlockFormControlLabel
          control={
            <Checkbox
              color="primary"
              name="supervisor"
              checked={supervisor}
              onChange={(event) => setSupervisor(event.target.checked)}
            />
          }
          label={intl.formatMessage({
            id: 'identityProvider.supplierMappings.add.supervisor.label',
            defaultMessage: 'Supervisor?'
          })}
        />
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton
          id="cancel-add-supplier-mapping"
          color="secondary"
          onClick={onClose}
          disabled={processing}
        >
          <FormattedMessage id="identityProvider.supplierMappings.add.cancelButton" defaultMessage="Cancel" />
        </DefaultButton>
        <DefaultButton
          id="add-supplier-mapping"
          onClick={validateAndSubmit}
          disabled={processing}
          startIcon={<AddIcon />}
        >
          <FormattedMessage id="identityProvider.supplierMappings.add.addButton" defaultMessage="Add Supplier Mapping" />
        </DefaultButton>
      </PaddedDialogActions>
    </Dialog>
  );
};

export default NewSupplierMappingForm;
