import { FC, useContext } from 'react';

import * as MeApi from '../../api/me';
import * as UserMediaApi from '../../api/userMedia';
import { AvatarSelect } from '../../components';
import { AuthenticatedContext } from '../../contexts/authentication';
import { intl } from '../../Internationalization';
import { Avatar, MediaDetail } from '../../types';

const MyAvatar: FC = () => {
  const { me, updateMe } = useContext(AuthenticatedContext);
  const onUpdate = (avatar: Avatar) => {
    updateMe({ ...me, avatar });
  };

  const onDelete = () => {
    updateMe({ ...me, avatar: { ...me.avatar, image: undefined } });
  };

  return (
    <div id="account-avatar">
      <AvatarSelect
        title={intl.formatMessage({
          id: 'account.myAvatar.title',
          defaultMessage: 'Profile Avatar'
        })}
        avatar={me.avatar}
        name={me.name}
        onUpdate={onUpdate}
        onDelete={onDelete}
        deleteImage={MeApi.deleteImage}
        uploadImage={MeApi.uploadImage}
        updateAvatar={MeApi.updateAvatar}
        imageUrl={(image: MediaDetail) => UserMediaApi.downloadImageUri(me.key, image)}
      />
    </div>
  );
};

export default MyAvatar;
