import { useState, FC, useEffect } from 'react';
import { Container } from '@mui/material';

import * as VirusScannerApi from '../../../api/virusScanner';
import { extractErrorMessage } from '../../../api/endpoints';
import { PaperFormSkeleton } from '../../../components';
import { VirusScannerSettings } from '../../../types';

import { useErrorBlock } from '../../../contexts/error-block';
import { intl } from '../../../Internationalization';

import { VirusScannerContext } from './VirusScannerContext';
import VirusScannerRouting from './VirusScannerRouting';

const VirusScanner: FC = () => {
  const { raiseError } = useErrorBlock();
  const [virusScannerSettings, setVirusScannerSettings] = useState<VirusScannerSettings>();

  useEffect(() => {
    const fetchVirusScannerSettings = async () => {
      try {
        const response = await VirusScannerApi.getSettings();
        setVirusScannerSettings(response.data);
      } catch (error: any) {
        raiseError(extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'virusScanner.settings.loadError',
            defaultMessage: 'Failed to load virus scanner settings'
          })
        ));
      }
    };

    fetchVirusScannerSettings();
  }, [raiseError]);

  if (!virusScannerSettings) {
    return (
      <Container maxWidth="md" disableGutters>
        <PaperFormSkeleton contentRows={7} />
      </Container>
    );
  }

  return (
    <VirusScannerContext.Provider
      value={{
        virusScannerSettings,
        virusScannerSettingsUpdated: setVirusScannerSettings
      }}
    >
      <VirusScannerRouting />
    </VirusScannerContext.Provider>
  );
};

export default VirusScanner;
