import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Typography, Box } from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';

import { SubmissionDetail } from '../../../types';
import * as SubmissionApi from '../../../api/submission';
import { extractErrorMessage } from '../../../api/endpoints';

import { AppDecorator, HeaderBreadcrumb, HeaderSkeleton } from '../../../components';
import { useErrorBlock } from "../../../contexts/error-block";
import { intl } from '../../../Internationalization';
import { dateTimeFormat } from '../../../util';

import SubmissionContent from '../../components/submission/SubmissionContent';
import SubmissionSkeleton from '../../components/submission/SubmissionSkeleton';
import SubmissionHeader from './SubmissionHeader';

const Submission: FC = () => {
  const { submissionReference } = useParams<{ submissionReference: string }>();
  const [submission, setSubmission] = useState<SubmissionDetail>();
  const { raiseError, raiseAccessError } = useErrorBlock();

  useEffect(() => {
    const load = async () => {
      if (submissionReference !== undefined) {
        try {
          setSubmission((await SubmissionApi.getSubmission(submissionReference)).data);
        } catch (error: any) {
          if (error?.response?.status === 403) {
            raiseAccessError(intl.formatMessage({
              id: 'submission.accessDeniedError',
              defaultMessage: 'Access to the submission was denied'
            }));
          } else {
            raiseError(extractErrorMessage(
              error,
              intl.formatMessage({
                id: 'submission.loadError',
                defaultMessage: 'Failed to fetch submission'
              })
            ));
          }
        }
      }
    };
    load();
  }, [raiseError, raiseAccessError, submissionReference]);

  const renderBreadcrumb = () => (
    <HeaderBreadcrumb>
      {
        (submission && submission.createdAt) &&
        <Typography>
          <AssessmentIcon
            titleAccess={intl.formatMessage({
              id: 'submission.breadcrumb.submission.titleAccess',
              defaultMessage: 'Submission created at'
            })}
          />
          {dateTimeFormat(submission.createdAt)}
        </Typography>
      }
    </HeaderBreadcrumb>
  );

  if (submission) {
    const assignment = submission.assignment;
    const specification = assignment.specification;
    const project = specification.project;

    return (
      <AppDecorator renderBreadcrumb={renderBreadcrumb}>
        <SubmissionHeader assignment={assignment} project={project} specification={specification} />
        <Box p={3}>
          <SubmissionContent
            submissionUpdated={setSubmission}
            submission={submission}
            assignment={assignment}
            specification={specification}
            project={project}
          />
        </Box>
      </AppDecorator>
    );
  }

  return (
    <>
      <HeaderSkeleton />
      <Box m={3}>
        <SubmissionSkeleton />
      </Box>
    </>
  );
};

export default Submission;
