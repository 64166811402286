import React, { FC } from 'react';

import { SupplierMetadata } from '../../types';
import ImageAvatar, { ImageAvatarProps } from './ImageAvatar';
import * as SupplierMediaApi from '../../api/supplierMedia';

interface SupplierAvatarProps extends Omit<ImageAvatarProps, "label" | "color"> {
  supplier: SupplierMetadata;
}

const SupplierAvatar: FC<SupplierAvatarProps> = ({ supplier: { key, name, avatar }, ...rest }) => (
  <ImageAvatar
    label={name}
    alt={name}
    color={avatar.color}
    logo
    src={avatar.image && SupplierMediaApi.downloadImageUri(key, avatar.image)}
    {...rest}
  />
);

export default SupplierAvatar;
