import { FC } from 'react';

import { AssignmentDetail, ProjectDetail, SpecificationDetail, SubmissionDetail } from '../../../../../types';
import SubmissionContent from '../../../../components/submission/SubmissionContent';

import MySubmissionPoller from './MySubmissionPoller';
import OpenSubmission from './OpenSubmission';

interface MySubmissionHandlerProps {
  submissionUpdated: React.Dispatch<React.SetStateAction<SubmissionDetail | undefined>>;
  submission: SubmissionDetail;
  assignment: AssignmentDetail;
  project: ProjectDetail;
  specification: SpecificationDetail;
}

const MySubmissionHandler: FC<MySubmissionHandlerProps> = ({ submissionUpdated, submission, assignment, project, specification }) => {
  const renderSubmission = () => {
    if (submission.closed) {
      return (
        <SubmissionContent
          submissionUpdated={submissionUpdated}
          submission={submission}
          assignment={assignment}
          project={project}
          specification={specification}
        />
      );
    }
    return (
      <OpenSubmission
        submission={submission}
        submissionUpdated={submissionUpdated}
        assignment={assignment}
        project={project}
        specification={specification}
      />
    );
  };

  return (
    <MySubmissionPoller submission={submission} onSubmissionUpdated={submissionUpdated}>
      {renderSubmission()}
    </MySubmissionPoller>
  );
};

export default MySubmissionHandler;
