import { AxiosPromise } from 'axios';
import { AXIOS } from './endpoints';
import { ValuePercentage, GroupedDataSet, DateCount, OffsetDateTimePercentage, SpecificationStatsRequest } from '../types';

export function ruleConformance(specificationKey: string, request: SpecificationStatsRequest): AxiosPromise<ValuePercentage[]> {
  return AXIOS.post(`/stats/specification/${specificationKey}/rule_conformance`, request);
}

export function submissionCounts(
  specificationKey: string, request: SpecificationStatsRequest
): AxiosPromise<GroupedDataSet<string, DateCount>[]> {
  return AXIOS.post(`/stats/specification/${specificationKey}/submission_counts`, request);
}

export function submissionQuality(
  specificationKey: string, request: SpecificationStatsRequest
): AxiosPromise<GroupedDataSet<string, OffsetDateTimePercentage>[]> {
  return AXIOS.post(`/stats/specification/${specificationKey}/submission_quality`, request);
}
