import { FC, useContext, useEffect, useState } from 'react';

import DateRangeIcon from '@mui/icons-material/DateRange';

import * as SpecificationStatsApi from '../../../api/specificationStats';
import { intl } from '../../../Internationalization';
import { extractErrorMessage } from '../../../api/endpoints';
import { useErrorBlock } from '../../../contexts/error-block';
import { toCompleteOffsetDateTimeRange, getCurrentTimeZone } from '../../../util';
import { MessageBox, FilterBar, FilterContainer, ProjectAutocomplete, SpecificationAutocomplete, AssignmentAutocomplete, FilterCompleteDateRange } from '../../../components';
import { ValuePercentage, GroupedDataSet, DateCount, OffsetDateTimePercentage, ProjectDetail, SpecificationDetail, AssignmentDetail, SpecificationStatsRequest, CompleteDateRange } from '../../../types';

import ChartsSkeleton from '../ChartsSkeleton';
import { DashboardContext } from '../DashboardContext';

import SpecificationCharts from './SpecificationCharts';
import SpecificationExportMenu from './SpecificationExportMenu';

const Specification: FC = () => {
  const { specificationFilters, updateSpecificationFilters } = useContext(DashboardContext);
  const { assignment, specification, project, dateRange } = specificationFilters;
  const { raiseError } = useErrorBlock();

  const [ruleConformance, setRuleConformance] = useState<ValuePercentage[]>();
  const [submissionCounts, setSubmissionCounts] = useState<GroupedDataSet<string, DateCount>[]>();
  const [submissionQuality, setSubmissionQuality] = useState<GroupedDataSet<string, OffsetDateTimePercentage>[]>();

  useEffect(() => {
    const loadSpecificationStats = async () => {
      if (!dateRange.start || !dateRange.end || !specification) {
        return;
      }

      const request: SpecificationStatsRequest = {
        period: toCompleteOffsetDateTimeRange(dateRange),
        timeZone: getCurrentTimeZone(),
        assignmentKey: assignment?.key
      };

      try {
        const [
          { data: ruleConformanceData },
          { data: submissionCountsData },
          { data: submissionQualityData }
        ] = await Promise.all([
          SpecificationStatsApi.ruleConformance(specification.key, request),
          SpecificationStatsApi.submissionCounts(specification.key, request),
          SpecificationStatsApi.submissionQuality(specification.key, request)
        ]);

        setRuleConformance(ruleConformanceData);
        setSubmissionCounts(submissionCountsData);
        setSubmissionQuality(submissionQualityData);
      } catch (error: any) {
        raiseError(extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'dashboard.specification.loadError',
            defaultMessage: 'Failed to load dashboard data'
          })
        ));
      }
    };

    loadSpecificationStats();
  }, [dateRange, specification, assignment, raiseError]);

  const onRangeChange = (newDateRange: CompleteDateRange) => {
    updateSpecificationFilters({ ...specificationFilters, dateRange: newDateRange });
  };

  const handleProjectFilterChange = (selectedProject: ProjectDetail | null) => {
    if (selectedProject?.key !== project?.key) {
      updateSpecificationFilters({ ...specificationFilters, project: selectedProject, specification: null, assignment: null });
    }
  };

  const handleSpecificationFilterChange = (selectedSpecification: SpecificationDetail | null) => {
    if (selectedSpecification?.key !== specification?.key) {
      updateSpecificationFilters({ ...specificationFilters, specification: selectedSpecification, assignment: null });
    }
  };

  const handleAssignmentFilterChange = (selectedAssignment: AssignmentDetail | null) => {
    if (selectedAssignment?.key !== assignment?.key) {
      updateSpecificationFilters({ ...specificationFilters, assignment: selectedAssignment });
    }
  };

  const renderContent = () => {
    if (!specification) {
      return (
        <MessageBox
          message={intl.formatMessage({
            id: 'dashboard.specification.noSpecification',
            defaultMessage: 'No specification selected'
          })}
          level="info"
        />
      );
    }

    if (!ruleConformance || !submissionCounts || !submissionQuality) {
      return (
        <ChartsSkeleton />
      );
    }

    if (!ruleConformance.length && !submissionCounts.length && !submissionQuality.length) {
      return (
        <MessageBox
          message={intl.formatMessage({
            id: 'dashboard.specification.noData',
            defaultMessage: 'There is no data for your selected date period. Please try to refine your search to try again.'
          })}
          level="info"
        />
      );
    }

    return (
      <SpecificationCharts
        ruleConformance={ruleConformance}
        submissionCounts={submissionCounts}
        submissionQuality={submissionQuality}
      />
    );
  };

  return (
    <div id="dashboard-specification">
      <FilterBar
        actions={
          <SpecificationExportMenu />
        }
        filterGroups={[
          {
            name: "finishedAt",
            title: intl.formatMessage({
              id: 'dashboard.specification.filterGroup.finishedAt.label',
              defaultMessage: 'Finished At'
            }),
            icon: DateRangeIcon,
            component: (
              <FilterContainer>
                <FilterCompleteDateRange range={dateRange} onRangeUpdated={onRangeChange} rangeLimit={{ years: 1 }} />
              </FilterContainer>
            )
          },
          {
            name: "filters",
            title: intl.formatMessage({
              id: 'dashboard.specification.filterGroup.filters.label',
              defaultMessage: 'Filters'
            }),
            component: (
              <FilterContainer>
                <ProjectAutocomplete
                  name="project"
                  label={intl.formatMessage({
                    id: 'dashboard.specification.filterProject.label',
                    defaultMessage: 'Project'
                  })}
                  value={project}
                  onChange={handleProjectFilterChange}
                  variant="standard"
                />
                <SpecificationAutocomplete
                  name="specification"
                  label={intl.formatMessage({
                    id: 'dashboard.specification.filterSpecification.label',
                    defaultMessage: 'Specification'
                  })}
                  value={specification}
                  onChange={handleSpecificationFilterChange}
                  variant="standard"
                  projectKey={project?.key}
                />
                <AssignmentAutocomplete
                  name="assignment"
                  label={intl.formatMessage({
                    id: 'dashboard.specification.filterAssignment.label',
                    defaultMessage: 'Assignment'
                  })}
                  value={assignment}
                  onChange={handleAssignmentFilterChange}
                  variant="standard"
                  specificationKey={specification?.key}
                />
              </FilterContainer>
            )
          }
        ]}
      />
      {renderContent()}
    </div>
  );
};

export default Specification;
