import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { extractErrorMessage } from "../../../api/endpoints";
import * as AssignmentApi from '../../../api/assignment';
import * as SpecificationApi from '../../../api/specification';
import * as ProjectApi from '../../../api/project';

import { ErrorBlockContext } from "../../../contexts/error-block";
import { intl } from "../../../Internationalization";
import { AssignmentDetail, SpecificationDetail, ProjectDetail } from "../../../types";

import MyAssignmentSkeleton from "./MyAssignmentSkeleton";
import MyAssignmentRouting, { toMySubmissionsLink } from "./MyAssignmentRouting";
import { MyAssignmentContext } from "./MyAssignmentContext";

interface MyAssignmentContextData {
  assignment: AssignmentDetail;
  project: ProjectDetail;
  specification: SpecificationDetail;
}

interface MyAssignmentProps {
  assignmentKey: string;
}

const MyAssignment: FC<MyAssignmentProps> = ({ assignmentKey }) => {
  const navigate = useNavigate();

  const { raiseError } = useContext(ErrorBlockContext);
  const [myAssignmentContextData, setMyAssignmentContextData] = useState<MyAssignmentContextData>();

  useEffect(() => {
    const initalizeContext = async () => {
      try {
        const assignment = (await AssignmentApi.getAssignment(assignmentKey)).data;
        const specification = (await SpecificationApi.getSpecification(assignment.specification.key)).data;
        const project = (await ProjectApi.getProject(specification.project.key)).data;
        setMyAssignmentContextData({ assignment, project, specification });
      } catch (error: any) {
        raiseError(extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'myAssignment.loadError',
            defaultMessage: 'Failed to initialize assignment'
          })
        ));
      }
    };

    initalizeContext();
  }, [assignmentKey, raiseError]);

  const navigateToMySubmissions = () => {
    navigate(toMySubmissionsLink(assignmentKey));
  };

  if (myAssignmentContextData) {
    return (
      <MyAssignmentContext.Provider
        value={{
          navigateToMySubmissions,
          assignmentKey,
          ...myAssignmentContextData
        }}
      >
        <MyAssignmentRouting />
      </MyAssignmentContext.Provider>
    );
  }

  return (
    <MyAssignmentSkeleton />
  );
};

export default MyAssignment;
