import { FC } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import { AppDecorator } from '../../components';
import { ErrorBlock } from '../../contexts/error-block';
import { intl } from '../../Internationalization';

import Projects from './Projects';
import Project from './project/Project';

const ProjectsRouting: FC = () => (
  <AppDecorator
    title={intl.formatMessage({
      id: 'projects.routing.title',
      defaultMessage: 'Projects'
    })}
  >
    <ErrorBlock>
      <Routes>
        <Route index element={<Projects />} />
        <Route path=":projectKey/*" element={<Project />} />
        <Route path="*" element={<Navigate to="." replace />} />
      </Routes>
    </ErrorBlock>
  </AppDecorator>
);

export default ProjectsRouting;
