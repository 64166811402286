import { FC } from "react";
import { Grid } from "@mui/material";

import { MessageBox } from '../../../components';
import { intl } from "../../../Internationalization";

const InvalidSchemaMapperDataMessage: FC = () => (
  <Grid item xs={12}>
    <MessageBox
      level="warning"
      message={intl.formatMessage({
        id: 'schemaMapper.schemaMapper.invalidData',
        defaultMessage: 'Detected invalid data, unable to display schema mapper.'
      })}
    />
  </Grid>
);

export default InvalidSchemaMapperDataMessage;
