import { FC, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';

import { Container, Typography, Grid } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import * as SiteSettingsApi from '../../../../api/siteSettings';
import { extractErrorMessage } from '../../../../api/endpoints';
import { DefaultButton, PaddedPaper, FormButtons } from '../../../../components';
import { CustomThemeContext } from '../../../../contexts/custom-theme/context';
import { intl } from '../../../../Internationalization';

import { SiteContext } from '../SiteContext';

import ColorPicker from './ColorPicker';

const ThemeCustomizer: FC = () => {
  const { palette, updatePalette } = useContext(CustomThemeContext);
  const { siteSettings, refreshApplicationContext, siteSettingsUpdated } = useContext(SiteContext);
  const { enqueueSnackbar } = useSnackbar();

  // when the customizer mount, call updatePalette
  // this ensures the site is up to date with the settings just loaded
  useEffect(() => {
    updatePalette(siteSettings.theme.palette);
    return () => refreshApplicationContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveColors = () => {
    SiteSettingsApi.updateSiteSettings({ ...siteSettings, theme: { palette } }).then((response) => {
      siteSettingsUpdated(response.data);
      enqueueSnackbar(intl.formatMessage({
        id: 'site.theme.updateSuccess',
        defaultMessage: 'Theme update successful'
      }), { variant: 'success' });
    }).catch((error) => {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'site.theme.updateError',
          defaultMessage: 'Failed to update theme'
        })
      ), { variant: 'error' });
    });
  };

  return (
    <Container maxWidth="md" id="system-site-theme" disableGutters>
      <PaddedPaper>
        <Typography variant="h5" gutterBottom>
          <FormattedMessage id="site.theme.title" defaultMessage="Theme Settings" />
        </Typography>
        <Typography gutterBottom>
          <FormattedMessage id="site.theme.description" defaultMessage="This screen allows you to change the theme colours for the site. According to W3C accessibility guidelines a minimum contrast level of 4.5 required between text and its background to ensure it can be read by people with moderately low vision. The tools on this screen will validate your choice of colours adheres to the recommended contrast levels in the guidelines." />
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item alignContent="center">
            <ColorPicker id="system-site-theme-primary" themeColorType="primary" />
          </Grid>
          <Grid item>
            <ColorPicker id="system-site-theme-secondary" themeColorType="secondary" />
          </Grid>
        </Grid>
        <FormButtons>
          <DefaultButton
            startIcon={<SaveIcon />}
            onClick={handleSaveColors}
            name="saveButton"
          >
            <FormattedMessage id="site.theme.saveButton" defaultMessage="Save Theme" />
          </DefaultButton>
        </FormButtons>
      </PaddedPaper>
    </Container>
  );
};

export default ThemeCustomizer;
