import { AxiosPromise } from 'axios';

import {  CreateDeletionJobRequest, DeletionJobDetail, DeletionJobsRequest, DeletionJobsResponse } from '../types';
import { AXIOS } from './endpoints';

export function fetchDeletionJobs(request: DeletionJobsRequest): AxiosPromise<DeletionJobsResponse> {
  return AXIOS.get('/deletion', { params: request });
}

export function createDeletionJob(request: CreateDeletionJobRequest): AxiosPromise<DeletionJobDetail> {
  return AXIOS.post(`/deletion`, request);
}

export function fetchDeletionJob(jobId: number): AxiosPromise<DeletionJobDetail> {
  return AXIOS.get(`/deletion/${jobId}`);
}
