import { FC, useState, useEffect, useContext } from 'react';

import { extractErrorMessage } from '../../../../api/endpoints';
import * as Saml2IdentityProviderApi from '../../../../api/saml2IdentityProvider';
import { useErrorBlock } from '../../../../contexts/error-block';
import { Loading } from '../../../../components';
import { Saml2IdentityProviderDetail } from '../../../../types';

import { ApplicationContext } from '../../../../contexts/application';
import { intl } from '../../../../Internationalization';
import { useRequiredParams } from '../../../../hooks';

import { Saml2IdentityProviderContext } from './Saml2IdentityProviderContext';
import Saml2IdentityProviderRouting from './Saml2IdentityProviderRouting';

const Saml2IdentityProvider: FC = () => {
  const {identityProviderKey} = useRequiredParams<{ identityProviderKey: string }>();

  const { raiseError } = useErrorBlock();
  const { refresh } = useContext(ApplicationContext);
  const [identityProvider, setIdentityProvider] = useState<Saml2IdentityProviderDetail>();

  useEffect(() => {
    fetchIdentityProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchIdentityProvider = async () => {
    Saml2IdentityProviderApi.getIdentityProvider(identityProviderKey).then(response => {
      setIdentityProvider(response.data);
    }).catch(error => {
      raiseError(extractErrorMessage(error, intl.formatMessage({
        id: 'saml2.loadError',
        defaultMessage: 'Failed to load SAML2 identity provider details'
      })));
    });
  };

  const identityProviderUpdated = (updatedIdentityProvider: Saml2IdentityProviderDetail) => {
    setIdentityProvider(updatedIdentityProvider);
    refresh();
  };

  if (!identityProvider) {
    return (
      <Loading />
    );
  }

  return (
    <Saml2IdentityProviderContext.Provider
      value={{
        identityProvider,
        identityProviderUpdated
      }}
    >
      <Saml2IdentityProviderRouting />
    </Saml2IdentityProviderContext.Provider>
  );
};

export default Saml2IdentityProvider;
