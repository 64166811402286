import React, { FC, useRef, useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';

import { Box, FormControlLabel, IconButton, Popover, Switch, Typography, Divider, Badge } from '@mui/material';

import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

import { AppBarStatsContext } from '../../../../contexts/app-bar-stats';
import { MyNotificationsRequest, NotificationDetail } from '../../../../types';
import { intl } from '../../../../Internationalization';
import { useInfiniteScroll } from '../../../../hooks';

import * as MyNotificationsApi from '../../../../api/myNotifications';
import { extractErrorMessage } from '../../../../api/endpoints';

import { DefaultButton } from '../../..';

import NotificationSkeleton from './NotificationSkeleton';
import Notification from './Notification';

const generateNextRequest = (currentRequest: MyNotificationsRequest, lastItem: NotificationDetail): MyNotificationsRequest => (
  { ...currentRequest, before: lastItem.key }
);

const PAGE_SIZE = 10;

const Notifications: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const appBarStatsContext = useContext(AppBarStatsContext);

  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const { lastItemRef, updateRequest, replaceItem, request, items, moreItems, processing } = useInfiniteScroll({
    initialRequest: { page: 0, size: PAGE_SIZE, read: false },
    onRequest: MyNotificationsApi.getMyNotifications,
    onGenerateNextRequest: generateNextRequest
  });

  useEffect(() => {
    if (open) {
      updateRequest({ before: undefined });
    }
  }, [open, updateRequest]);

  const onNavigate = () => {
    setOpen(false);
  };

  const handleClose = (event: React.MouseEvent<Document>) => {
    if (anchorRef.current && anchorRef.current.contains(event.currentTarget)) {
      return;
    }
    setOpen(false);
  };

  const handleNotificationUpdate = (index: number, updatedNotification?: NotificationDetail) => {
    replaceItem(index, request.read === undefined || request.read === updatedNotification?.read ? updatedNotification : undefined);
    appBarStatsContext.refresh();
  };

  const renderNotification = (notification: NotificationDetail, index: number) => (
    <div ref={index === items.length - 1 ? lastItemRef : undefined} key={notification.key}>
      <Notification
        notification={notification}
        onUpdateNotification={(updatedNotification?: NotificationDetail) => handleNotificationUpdate(index, updatedNotification)}
        onNavigate={onNavigate}
      />
      <Divider />
    </div>
  );

  const renderNotifications = () => (
    <Box display="flex" flexDirection="column" sx={{ maxHeight: "70vh", overflowY: 'auto' }}>
      {items.map(renderNotification)}
      {processing && <NotificationSkeleton />}
      {
        !moreItems &&
        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" m={2}>
          <Box mb={1}>
            <NotificationsNoneIcon fontSize="large" />
          </Box>
          <Typography variant="subtitle1">
            {intl.formatMessage({
              id: 'components.appBar.notifications.noMoreNotifications',
              defaultMessage: 'There are no more notifications'
            })}
          </Typography>
        </Box>
      }
    </Box>
  );

  const toggleUnreadOnly = () => updateRequest((prev) => ({
    ...prev,
    read: prev.read === false ? undefined : false,
    before: undefined
  }));

  const handleMarkAllRead = async () => {
    try {
      await MyNotificationsApi.patchNotifications({ read: true });
      updateRequest({ before: undefined });
      appBarStatsContext.refresh();
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'components.appBar.notifications.markAllRead.error',
          defaultMessage: 'Failed to delete notification'
        })
      ), { variant: "error" });
    }
  };

  return (
    <>
      <IconButton
        id="Notifications-openButton"
        ref={anchorRef}
        aria-haspopup="true"
        onClick={() => setOpen(true)}
        color="inherit"
        size="large"
        aria-label={
          intl.formatMessage({
            id: 'components.appBar.notifications.openButton.ariaLabel',
            defaultMessage: '{notifications} unread {notifications,plural,one{notification}other{notifications}}',
          }, {
            notifications: appBarStatsContext.unreadNotifications
          })
        }
      >
        <Badge
          badgeContent={appBarStatsContext.unreadNotifications}
          color="secondary"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id="Notifications-popover"
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableScrollLock
      >
        <Box width={450}>
          <Box sx={{ display: 'flex', p: 1.5, justifyContent: 'space-between' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={request.read === false}
                  onChange={toggleUnreadOnly}
                />
              }
              label={intl.formatMessage({
                id: 'components.appBar.notifications.showUnread.label',
                defaultMessage: 'Show only unread'
              })}
              labelPlacement="end"
            />
            <DefaultButton onClick={handleMarkAllRead}>
              {intl.formatMessage({
                id: 'components.appBar.notifications.markAllRead.value',
                defaultMessage: 'Mark all as read'
              })}
            </DefaultButton>
          </Box>
          <Divider />
          {renderNotifications()}
        </Box>
      </Popover>
    </>
  );
};

export default Notifications;
