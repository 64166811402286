import React, { useContext, useState, useEffect, Fragment, useCallback, FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { Grid, Tabs, Container, Box } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import { extractErrorMessage } from '../../../../../api/endpoints';
import * as SessionApi from '../../../../../api/session';
import * as SpecificationApi from '../../../../../api/specification';
import { PaddedPaper, MessageBox, Loading, DefaultButton } from '../../../../../components';
import { SpecificationSettings, ConformanceMode, SessionSchema } from '../../../../../types';

import { WarningIcon } from '../../../../../components/icons';
import InlineIconTab from '../../../../../components/tabs/InlineIconTab';
import { useNavigationPrompt } from '../../../../../contexts/navigation-prompt';
import { useErrorBlock } from '../../../../../contexts/error-block';
import { intl } from '../../../../../Internationalization';

import { SpecificationContext } from '../SpecificationContext';
import DataStoreConfigEditor from './data-store-config/DataStoreConfigEditor';
import TaskConfigEditor from './task-config/TaskConfigEditor';
import SpecificationSessionSelector from './SpecificationSessionSelector';
import ConformanceModeSelector from './ConformanceModeSelector';

const Configuration: FC = () => {
  const {
    specification, specificationUpdated,
    specificationKey, specificationValidationResult,
    validateSpecification
  } = useContext(SpecificationContext);
  const { enqueueSnackbar } = useSnackbar();
  const { handleNavigationPrompt } = useNavigationPrompt();

  const { raiseError } = useErrorBlock();
  const [selectedTab, setSelectedTab] = useState<string>('dataStores');
  const [sessionMissing, setSessionMissing] = useState<boolean>(false);
  const [sessionSchema, setSessionSchema] = useState<SessionSchema>();
  const { sessionPath } = specification;

  const fetchSessionSchema = useCallback(() => {
    if (sessionPath) {
      setSessionSchema(undefined);
      SessionApi.fetchSessionSchema(sessionPath).then(response => {
        setSessionSchema(response.data);
        setSessionMissing(false);
      }).catch((error: AxiosError) => {
        if (error.response && error.response.status === 404) {
          setSessionMissing(true);
        } else {
          raiseError(extractErrorMessage(error, intl.formatMessage({
            id: 'specification.configuration.loadError',
            defaultMessage: 'Failed to fetch session'
          })));
        }
      });
    } else {
      setSessionMissing(true);
    }
  }, [sessionPath, raiseError]);

  useEffect(() => {
    fetchSessionSchema();
  }, [fetchSessionSchema]);

  const handleRefresh = () => {
    handleNavigationPrompt(() => {
      validateSpecification();
      fetchSessionSchema();
    });
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    handleNavigationPrompt(() => setSelectedTab(newValue));
  };

  const saveSpecificationSettings = (updatedSpecification: SpecificationSettings): void => {
    SpecificationApi.updateSpecification(specificationKey, updatedSpecification).then(response => {
      specificationUpdated(response.data);
      validateSpecification();
      enqueueSnackbar(intl.formatMessage(
        {
          id: 'specification.configuration.saveSuccess',
          defaultMessage: 'Specification {name} has been updated'
        },
        { name: updatedSpecification.name }
      ), { variant: "success" });
    }).catch((error: AxiosError) => {
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'specification.configuration.saveError',
        defaultMessage: 'Failed to update specification'
      })), { variant: "error" });
    });
  };

  const saveSessionPath = (updatedSessionPath: string) => {
    saveSpecificationSettings({ ...specification, sessionPath: updatedSessionPath });
  };

  const saveConformanceMode = (updatedConformanceMode: ConformanceMode) => {
    saveSpecificationSettings({ ...specification, conformanceMode: updatedConformanceMode });
  };

  const renderContent = () => {
    if (!sessionPath) {
      return (
        <Grid item xs={12}>
          <MessageBox
            message={intl.formatMessage({
              id: 'specification.configuration.noSessionPath',
              defaultMessage: 'Session template has not been set.'
            })}
            level="info"
          />
        </Grid>
      );
    }
    if (sessionMissing) {
      return (
        <Grid item xs={12}>
          <MessageBox
            message={intl.formatMessage({
              id: 'specification.configuration.sessionMissing',
              defaultMessage: 'Session template {path} could not be found'
            }, { path: sessionPath })}
            level="warning"
          />
        </Grid>
      );
    }

    if (!sessionSchema) {
      return <Loading />;
    }

    return (
      <>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              variant="scrollable"
            >
              <InlineIconTab
                label={intl.formatMessage({
                  id: 'specification.configuration.tabs.dataStores',
                  defaultMessage: 'Data Stores'
                })}
                id="data-stores-tab"
                value="dataStores"
                aria-controls="data-store-tab-panel"
                icon={specificationValidationResult.dataStoresValid ? '' : <WarningIcon />}
              />
              <InlineIconTab
                label={intl.formatMessage({
                  id: 'specification.configuration.tabs.tasks',
                  defaultMessage: 'Tasks'
                })}
                id="tasks-tab"
                value="tasks"
                aria-controls="task-tab-panel"
                icon={specificationValidationResult.tasksValid ? '' : <WarningIcon />}
              />
            </Tabs>
            <Box flexGrow="1" />
            <DefaultButton
              onClick={handleRefresh}
              startIcon={<RefreshIcon />}
            >
              <FormattedMessage id="specification.configuration.refreshButton" defaultMessage="Refresh" />
            </DefaultButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {selectedTab === 'dataStores' && <DataStoreConfigEditor dataStoreSchemas={sessionSchema.dataStores} />}
          {selectedTab === 'tasks' && <TaskConfigEditor sessionSchema={sessionSchema} />}
        </Grid>
      </>
    );
  };

  return (
    <Container maxWidth="lg" id="project-specification-configuration" disableGutters>
      <PaddedPaper>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SpecificationSessionSelector
              sessionPath={specification.sessionPath}
              onUpdateSessionPath={saveSessionPath}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ConformanceModeSelector
              conformanceMode={specification.conformanceMode}
              onUpdateConformanceMode={saveConformanceMode}
            />
          </Grid>
          {renderContent()}
        </Grid>
      </PaddedPaper>
    </Container>
  );

};

export default Configuration;
