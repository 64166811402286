import { StyleSheet } from '@react-pdf/renderer';

import { sharedPdfStyles } from '.';

const schemaPdf = StyleSheet.create({
  summary: {
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 500,
    marginBottom: 5
  },

  classInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fafafa',
    paddingHorizontal: 8,
    paddingBottom: 8
  },
  dataSetSummarySubHeader: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 300,
    marginBottom: 5,
  },
  mappingContainer: {
    justifyContent: 'space-between',
  },
  mappingInfoText: {
    fontSize: 10,
    marginLeft: 8,
    display: 'flex',
    flexDirection: 'column'
  },
  summaryOuterContainer: {
    justifyContent: 'space-around',
    paddingVertical: 4
  },
  tableCell: {
    borderBottom: '1 dashed #112131'
  },
  tableHeadRow: {
    backgroundColor: '#d3d3d3'
  },
  tableRow: {
    backgroundColor: '#ffffff',
    borderBottom: '1 solid #112131',
    padding: 4
  },
  attributeRow: {
    borderBottom: '1 dashed #112131',
    fontSize: 10,
    paddingVertical: 0,
    backgroundColor: '#ffffff',
  },
  topBorder: {
    borderTop: '1 solid #112131'
  }
});

export const schemaPdfStyles = {
  ...sharedPdfStyles,
  ...schemaPdf
};
