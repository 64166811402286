import { FC } from "react";
import { Text } from "@react-pdf/renderer";
import { FormattedMessage } from "react-intl";

import { sharedPdfStyles } from '../../../../../components';
import { InsertDriveFileSvg } from '../../../../../components/pdf/PdfIcons';
import { SchemaReport, taskResultLabel, ValidateSchemaTaskResult } from '../../../../../types';

import { dataStoreNameFromPath } from "../../../../../util";
import { intl } from "../../../../../Internationalization";

import { PdfSection, PdfSectionHeader, PdfTableCell, PdfTableRow } from "../components";
import { SummaryCounts } from './';

interface TaskSummaryProps {
  schemaReport: SchemaReport;
  task: ValidateSchemaTaskResult;
}

const TaskSummary: FC<TaskSummaryProps> = ({ schemaReport, task }) => (
  <PdfSection gutterBottom>
    <PdfSectionHeader>
      <InsertDriveFileSvg style={[sharedPdfStyles.mediumIcon, sharedPdfStyles.iconMarginRight]} />
      <Text style={[sharedPdfStyles.sectionHeaderText]}>
        <FormattedMessage id="submission.validateSchemaPdf.reportSummary.title" defaultMessage="Report Summary" /> - {taskResultLabel(task)}
      </Text>
    </PdfSectionHeader>
    <SummaryCounts
      missing={{
        label: intl.formatMessage({
          id: "submission.validateSchemaPdf.missingClasses",
          defaultMessage: "Classes not supplied: {missingClasses}"
        }, { missingClasses: schemaReport.missingClasses.length }),
        error: !!schemaReport.missingClasses.length
      }}
      excess={{
        label: intl.formatMessage({
          id: "submission.validateSchemaPdf.excessClasses",
          defaultMessage: "Excess Classes: {excessClasses}"
        }, { excessClasses: schemaReport.excessClasses.length }),
        error: !!schemaReport.excessClasses.length
      }}
      invalid={{
        label: intl.formatMessage({
          id: "submission.validateSchemaPdf.invalidClasses",
          defaultMessage: "Classes with errors: {invalidClasses}"
        }, { invalidClasses: schemaReport.attributeMismatches.length }),
        error: !!schemaReport.attributeMismatches.length
      }}
    />
    {schemaReport.targetDataStore && schemaReport.sourceDataStore && (
      <>
        <PdfTableRow header>
          <PdfTableCell header colSpan="half">
            <Text>
              <FormattedMessage id="submission.validateSchemaPdf.taskDataSet.sourceSchema" defaultMessage="Source Schema" />
            </Text>
          </PdfTableCell>
          <PdfTableCell header colSpan="half">
            <Text>
              <FormattedMessage id="submission.validateSchemaPdf.taskDataSet.referenceSchema" defaultMessage="Reference Schema" />
            </Text>
          </PdfTableCell>
        </PdfTableRow>
        <PdfTableRow>
          <PdfTableCell colSpan="half">
            <Text>
              {dataStoreNameFromPath(schemaReport.sourceDataStore)}
            </Text>
          </PdfTableCell>
          <PdfTableCell colSpan="half">
            <Text>
              {dataStoreNameFromPath(schemaReport.targetDataStore)}
            </Text>
          </PdfTableCell>
        </PdfTableRow>
      </>
    )}
  </PdfSection>
);

export default TaskSummary;
