import { FC, useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';

import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SaveIcon from '@mui/icons-material/Save';
import { List, ListSubheader, ListItem, ListItemAvatar, ListItemText, Checkbox } from '@mui/material';

import { extractErrorMessage } from '../../../api/endpoints';
import * as SupplierApi from '../../../api/supplier';
import { DefaultButton, Loading, ActiveAvatar } from '../../../components';
import { UserProfile, SupplierMembershipDetail } from '../../../types';
import { useErrorBlock } from '../../../contexts/error-block';
import { intl } from '../../../Internationalization';

import { SupplierContext } from './SupplierContext';

interface MemberPermissionsProps {
  user: UserProfile;
}

const MemberPermissions: FC<MemberPermissionsProps> = ({ user }) => {
  const { supplierKey } = useContext(SupplierContext);
  const { enqueueSnackbar } = useSnackbar();
  const [supervisorPermissions, setSupervisorPermissions] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>();
  const [supplierMembership, setSupplierMembership] = useState<SupplierMembershipDetail>();
  const { raiseError } = useErrorBlock();

  useEffect(() => {
    SupplierApi.fetchSupplierMembership(supplierKey, user.key).then(response => {
      setSupervisorPermissions(response.data.supervisor);
      setSupplierMembership(response.data);
    }).catch(error => {
      raiseError(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'supplier.memberPermissions.loadError',
          defaultMessage: 'Failed to fetch supplier membership'
        })
      ));
    });
  }, [user, supplierKey, raiseError]);

  const updatePermissions = () => {
    setProcessing(true);
    SupplierApi.grantSupplierMembership(supplierKey, user.key, { supervisor: supervisorPermissions }).then(response => {
      setSupervisorPermissions(response.data.supervisor);
      setSupplierMembership(response.data);
      setProcessing(false);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'supplier.memberPermissions.saveSuccess',
          defaultMessage: 'Supplier membership permissions updated'
        }), { variant: 'success' });
    }).catch(error => {
      setProcessing(false);
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'supplier.memberPermissions.saveError',
          defaultMessage: 'Failed to update supplier membership permissions'
        })), { variant: 'error' });
    });
  };

  return supplierMembership ? (
    <List id="member-permissions">
      <ListSubheader>
        <FormattedMessage id="supplier.memberPermissions.title" defaultMessage="Membership Permissions" />
      </ListSubheader>
      <ListItem>
        <ListItemAvatar>
          <ActiveAvatar activated={supervisorPermissions ? 'active' : 'default'}>
            <SupervisorAccountIcon />
          </ActiveAvatar>
        </ListItemAvatar>
        <ListItemText
          primary={intl.formatMessage({
            id: 'supplier.memberPermissions.supervisor',
            defaultMessage: 'Supervisor'
          })}
          secondary={intl.formatMessage({
            id: 'supplier.memberPermissions.supervisorDescription',
            defaultMessage: 'Permit the user to view all submissions for this supplier'
          })}
        />
        <Checkbox name="supervisorPermissions" onChange={() => setSupervisorPermissions(!supervisorPermissions)} disabled={processing} checked={supervisorPermissions} />
      </ListItem>
      <ListItem>
        <DefaultButton
          name="updatePermissions"
          onClick={updatePermissions}
          disabled={processing}
          startIcon={<SaveIcon />}
        >
          <FormattedMessage id="supplier.memberPermissions.saveButton" defaultMessage="Update Permissions" />
        </DefaultButton>
      </ListItem>
    </List>
  ) :
    <Loading />;
};

export default MemberPermissions;
