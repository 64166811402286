import { FC } from 'react';

import { ProjectMetadata } from '../../types';
import * as ProjectMediaApi from '../../api/projectMedia';

import SizedAvatar, { SizedAvatarProps } from './SizedAvatar';

interface ProjectAvatarProps extends SizedAvatarProps {
  project: ProjectMetadata;
}

const ProjectAvatar: FC<ProjectAvatarProps> = ({ sx = [], project, ...rest }) => (
  <SizedAvatar
    sx={[
      { bgcolor: 'transparent' },
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
    alt={project.name}
    src={ProjectMediaApi.projectImageOrDefault(project)}
    {...rest}
  />
);

export default ProjectAvatar;
