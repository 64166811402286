import { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { pdf } from '@react-pdf/renderer';

import { Dialog, DialogTitle, DialogContent, FormControlLabel, Checkbox } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { DefaultButton, InputTooltip, PaddedDialogActions } from '../../../components';
import { extractErrorMessage } from '../../../api/endpoints';
import { intl } from '../../../Internationalization';

import ResultPdf, { TaskFilters } from './pdf/ResultPdf';
import { SubmissionContext } from './SubmissionContext';

const DEFAULT_TASK_FILTERS: TaskFilters = {
  hideNotFinished: false,
  hideZeroProcessedTasks: false,
  hideZeroProcessedRows: false
};

const DownloadReportPdf: FC = () => {

  const { enqueueSnackbar } = useSnackbar();
  const { submission, project, specification, assignment } = useContext(SubmissionContext);
  const title = `${project.name} - ${specification.name}`;

  const [creatingPdf, setCreatingPdf] = useState<boolean>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [taskFilters, setTaskFilters] = useState<TaskFilters>({ ...DEFAULT_TASK_FILTERS });

  const generatePdf = async () => {
    setCreatingPdf(true);
    try {
      const blob = await pdf(
        <ResultPdf title={title} submission={submission} taskFilters={taskFilters} />
      ).toBlob();
      saveAs(
        blob,
        intl.formatMessage({
          id: 'submission.downloadReportPdf.pdfFilename',
          defaultMessage: 'Submission Report - {assignmentReference}.pdf'
        }, { assignmentReference: assignment.reference })
      );
      setDialogOpen(false);
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'submission.downloadReportPdf.generatePdfError',
          defaultMessage: 'Failed to generate PDF'
        })
      ), { variant: 'error' });
    } finally {
      setCreatingPdf(false);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setTaskFilters({ ...DEFAULT_TASK_FILTERS });
    setDialogOpen(true);
  };

  return (
    <>
      <DefaultButton
        color="secondary"
        startIcon={<PictureAsPdfIcon />}
        onClick={handleOpenDialog}
      >
        <FormattedMessage id="submission.downloadReportPdf.viewReportButton" defaultMessage="View Report…" />
      </DefaultButton>
      <Dialog open={dialogOpen} onClose={handleCloseDialog} id="hide-unprocessed-tasks-dialog" fullWidth maxWidth="sm">
        <DialogTitle>
          <FormattedMessage id="submission.downloadReportPdf.title" defaultMessage="Customise Report PDF" />
        </DialogTitle>
        <DialogContent>
          <InputTooltip
            title={intl.formatMessage({ id: 'submission.downloadReportPdf.taskFilters.hideNotFinished.tooltip', defaultMessage: 'Tasks which have not finished will be omitted from the report.' })}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={taskFilters.hideNotFinished}
                  onChange={(event) => setTaskFilters((prev) => ({ ...prev, hideNotFinished: event.target.checked }))}
                  name="hideNotFinished"
                  color="primary"
                  disabled={creatingPdf}
                />
              }
              label={intl.formatMessage({ id: 'submission.downloadReportPdf.taskFilters.hideNotFinished.label', defaultMessage: 'Hide tasks which have not finished' })}
            />
          </InputTooltip>
          <InputTooltip
            title={intl.formatMessage({ id: 'submission.downloadReportPdf.taskFilters.hideZeroProcessedTasks.tooltip', defaultMessage: 'Tasks which processed no features will be omitted from the report.' })}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={taskFilters.hideZeroProcessedTasks}
                  onChange={(event) => setTaskFilters((prev) => ({ ...prev, hideZeroProcessedTasks: event.target.checked }))}
                  name="hideZeroProcessedTasks"
                  color="primary"
                  disabled={creatingPdf}
                />
              }
              label={intl.formatMessage({ id: 'submission.downloadReportPdf.taskFilters.hideZeroProcessedTasks.label', defaultMessage: 'Hide tasks with no processed features' })}
            />
          </InputTooltip>
          <InputTooltip
            title={intl.formatMessage({ id: 'submission.downloadReportPdf.taskFilters.hideZeroProcessedRows.tooltip', defaultMessage: 'Classes which processed no features will be omitted from the report.' })}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={taskFilters.hideZeroProcessedRows}
                  onChange={(event) => setTaskFilters((prev) => ({ ...prev, hideZeroProcessedRows: event.target.checked }))}
                  name="hideZeroProcessedRows"
                  color="primary"
                  disabled={creatingPdf}
                />
              }
              label={intl.formatMessage({ id: 'submission.downloadReportPdf.taskFilters.hideZeroProcessedRows.label', defaultMessage: 'Hide rows with no processed features' })}
            />
          </InputTooltip>
        </DialogContent>
        <PaddedDialogActions>
          <DefaultButton
            disabled={creatingPdf}
            onClick={handleCloseDialog}
            color="secondary"
            id="cancel-hide-unprocessed-tasks"
          >
            <FormattedMessage id="submission.downloadReportPdf.cancelButton" defaultMessage="Cancel" />
          </DefaultButton>
          <DefaultButton
            disabled={creatingPdf}
            onClick={generatePdf}
            id="submit-hide-unprocessed-tasks"
          >
            <FormattedMessage id="submission.downloadReportPdf.generateButton" defaultMessage="Generate PDF" />
          </DefaultButton>
        </PaddedDialogActions>
      </Dialog>
    </>
  );
};

export default DownloadReportPdf;
