import { format, startOfDay, formatISO, add } from 'date-fns';
import { parse, toSeconds } from 'iso8601-duration';

import { CompleteDateRange, CompleteOffsetDateTimeRange, DateRange, OffsetDateTimeRange } from '../types';

export const getCurrentTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const dateTimeFormat = (date: string) => format(new Date(date), 'PPpp');
export const dateFormat = (date: string) => format(new Date(date), 'PP');

export function toDateQueryOffsetDateTimeRange(dateRange: DateRange): OffsetDateTimeRange {
  return {
    start: dateRange.start && formatISO(startOfDay(dateRange.start)),
    end: dateRange.end && formatISO(startOfDay(add(dateRange.end, { 'days': 1 })))
  };
}

export function toCompleteOffsetDateTimeRange(dateRange: CompleteDateRange): CompleteOffsetDateTimeRange {
  return {
    start: formatISO(startOfDay(dateRange.start)),
    end: formatISO(startOfDay(add(dateRange.end, { 'days': 1 })))
  };
}

export const editablePeriodToIso = (period: string) => 'P' + period.replace(/ /g, "").toUpperCase();
export const isoPeriodToEditable = (period: string) => {
  let parsedPeriod = period.replace('P', '').toLowerCase();
  const numbersFound = parsedPeriod.match(/[0-9]{1,4}/g);
  if (numbersFound && numbersFound.length > 1) {
    numbersFound.forEach((number, index) => {
      if (index > 0) {
        parsedPeriod = parsedPeriod.replace(number, ' ' + number);
      }
    });
  }
  return parsedPeriod;
};

export const editableDurationToIso = (period: string) => 'PT' + period.replace(/ /g, "").toUpperCase();
export const isoDurationToEditable = (period: string) => {
  let parsedPeriod = period.replace('PT', '').toLowerCase();
  const numbersFound = parsedPeriod.match(/[0-9]{1,3}/g);
  if (numbersFound && numbersFound.length > 1) {
    numbersFound.forEach((number, index) => {
      if (index > 0) {
        parsedPeriod = parsedPeriod.replace(number, ' ' + number);
      }
    });
  }

  return parsedPeriod;
};

/***
 * Parses a Duration from a string using the iso8601-duration library.
 * Catches any RangeError objects thrown by the parse method and instead returns
 * an invalid Duration (with no components defined).
 */
export const parseDuration = (value: string): Duration => {
  try {
    return parse(value);
  } catch (e) {
    if (e instanceof RangeError) {
      return {};
    } else {
      throw e;
    }
  }
};

/**
 * Compares two Duration instances to determine which is longer.
 * @returns -1 if first is greater than second; 0 if Durations are equal; +1 if second is greater than first.
 */
export const compareDurations = (first: Duration, second: Duration) => Math.sign(toSeconds(second) - toSeconds(first));
