import { FC, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import { Container, Typography, FormGroup, FormControlLabel, Switch, TextField, Box } from '@mui/material';

import * as Saml2IdentityProviderApi from '../../../../api/saml2IdentityProvider';
import { PaddedPaper, Upload, MessageBox } from '../../../../components';
import { extractErrorMessage, FileUploadConfig } from '../../../../api/endpoints';
import { intl } from '../../../../Internationalization';
import { useMediaUpload } from '../../../../hooks';
import { Saml2IdentityProviderDetail } from '../../../../types';

import { Saml2IdentityProviderContext } from './Saml2IdentityProviderContext';

const Saml2IdentityProviderSigning: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { identityProvider, identityProviderUpdated } = useContext(Saml2IdentityProviderContext);

  const [upload, uploading, uploadProgress] = useMediaUpload<Saml2IdentityProviderDetail>({
    onUpload: (files: File[], config: FileUploadConfig) => (
      Saml2IdentityProviderApi.updateIdpCertificate(identityProvider.key, files[0], config)
    ),
    onUploadComplete: (settings: any) => identityProviderUpdated(settings)
  });

  const [processing, setProcessing] = useState<boolean>(false);

  const updateVerifyResponse = (checked: boolean) => {
    setProcessing(true);
    Saml2IdentityProviderApi.updateIdentityProvider(
      identityProvider.key,
      { ...identityProvider, verifyResponses: checked }
    ).then(response => {
      identityProviderUpdated(response.data);
      enqueueSnackbar(
        response.data.verifyResponses ?
          intl.formatMessage(
            {
              id: 'saml2.identityProviderSigning.saveSuccess.verifyOn',
              defaultMessage: 'Responses will be validated'
            }) :
          intl.formatMessage(
            {
              id: 'saml2.identityProviderSigning.saveSuccess.verifyOff',
              defaultMessage: 'Responses will not be validated'
            }
          ),
        { variant: "success" }
      );
    }).catch(error => {
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'saml2.identityProviderSigning.saveError',
        defaultMessage: 'Failed to update verification response'
      })), { variant: 'error' });
    }).finally(() => {
      setProcessing(false);
    });
  };

  return (
    <Container maxWidth="md" id="system-identity-provider-saml2-identity-signing" disableGutters>
      <PaddedPaper>
        <Typography variant="h5" gutterBottom>
          <FormattedMessage id="saml2.identityProviderSigning.title" defaultMessage="Identity Provider Signing" />
        </Typography>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                disabled={processing || !identityProvider.idpCertificate}
                checked={identityProvider.verifyResponses}
                onChange={(event) => updateVerifyResponse(event.target.checked)}
                name="verifyResponses"
              />
            }
            label={intl.formatMessage({
              id: 'saml2.identityProviderSigning.verifyResponses.label',
              defaultMessage: 'Validate Responses?'
            })}
          />
        </FormGroup>
        <Box my={2}>
          {
            identityProvider.idpCertificate ?
              <TextField
                rows={10}
                variant="outlined"
                fullWidth
                value={identityProvider.idpCertificate}
                multiline
                disabled
                label={intl.formatMessage({
                  id: 'saml2.identityProviderSigning.identityProviderCertificate.label',
                  defaultMessage: 'Identity Provider Certificate'
                })}
              />
              :
              <MessageBox
                level="info"
                message={intl.formatMessage({
                  id: 'saml2.identityProviderSigning.noCertificate',
                  defaultMessage: 'No identity provider certificate, please upload one below'
                })}
              />
          }
        </Box>
        <Upload accept=".pem, .txt" onDrop={upload} uploading={uploading} progress={uploadProgress} />
      </PaddedPaper>
    </Container>
  );
};

export default Saml2IdentityProviderSigning;
