import { FC, PropsWithChildren, } from 'react';
import { Divider, styled } from '@mui/material';

const FilterContainerContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  padding: theme.spacing(0.5, 1),
  [theme.breakpoints.down('lg')]: {
    flexGrow: 1,
    flexDirection: 'column',
    '& > .FilterDateRange-root': {
      width: '100%',
    }
  },
  '& > .MuiAutocomplete-root, & > .MuiFormControl-root': {
    minWidth: 200,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },

}));

const FilterContainer: FC<PropsWithChildren> = ({ children }) => (
  <>
    <Divider variant="fullWidth" />
    <FilterContainerContentWrapper>
      {children}
    </FilterContainerContentWrapper>
  </>
);

export default FilterContainer;
