import { FC, useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ValidateFieldsError } from 'async-validator';

import { Dialog, DialogTitle, DialogContent, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import * as IdentityProvidersApi from '../../../api/identityProviders';
import { extractErrorMessage } from '../../../api/endpoints';

import { IDENTITY_PROVIDER_TYPE_METADATA, identityProviderTypes, IdentityProviderType, IdentityProviderSettings } from '../../../types';
import { ValidatedTextField, PaddedDialogActions, DefaultButton } from '../../../components';
import { ApplicationContext } from '../../../contexts/application';

import { validate } from '../../../util';
import { intl } from '../../../Internationalization';

import { toIdentityProviderUrl } from './IdentityProviders';

interface NewIdentityProviderProps {
  onCancel: () => void;
}

const NewIdentityProvider: FC<NewIdentityProviderProps> = ({ onCancel }) => {
  const navigate = useNavigate();
  const { refresh } = useContext(ApplicationContext);
  const { enqueueSnackbar } = useSnackbar();
  const [processing, setProcessing] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const [name, setName] = useState<string>('');
  const [key, setKey] = useState<string>('');
  const [type, setType] = useState(IdentityProviderType.SAML2);

  const validateAndSubmit = async () => {
    setProcessing(true);
    try {
      const { defaults } = IDENTITY_PROVIDER_TYPE_METADATA[type];
      createIdentityProvider(
        await validate(
          IdentityProvidersApi.CREATE_IDENTITY_PROVIDER_SETTINGS_VALIDATOR,
          { ...defaults, name, key }
        )
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const createIdentityProvider = async (identityProvider: IdentityProviderSettings) => {
    try {
      const { createApi } = IDENTITY_PROVIDER_TYPE_METADATA[type];
      const response = await createApi(identityProvider);
      refresh();
      enqueueSnackbar(intl.formatMessage({
        id: 'identityProvider.create.createSuccess',
        defaultMessage: 'Identity provider created'
      }), { variant: "success" });
      navigate(toIdentityProviderUrl(response.data));
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'identityProvider.create.createError',
        defaultMessage: 'Failed to create new identity provider'
      })), { variant: 'error' });
      setProcessing(false);
    }
  };

  return (
    <Dialog id="new-identity-provider-dialog" onClose={onCancel} aria-labelledby="new-identity-provider-dialog-title" open={true} fullWidth>
      <DialogTitle id="new-identity-provider-dialog-title">
        <FormattedMessage id="identityProvider.create.title" defaultMessage="Add Identity Provider" />
      </DialogTitle>
      <DialogContent dividers={true}>
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="name"
          label={intl.formatMessage({
            id: 'identityProvider.create.name.label',
            defaultMessage: 'Name'
          })}
          value={name}
          onChange={event => setName(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="key"
          label={intl.formatMessage({
            id: 'identityProvider.create.key.label',
            defaultMessage: 'Key'
          })}
          value={key}
          onChange={event => setKey(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <FormControl margin="normal" component="fieldset">
          <FormLabel component="legend">
            <FormattedMessage id="identityProvider.create.type.label" defaultMessage="Type" />
          </FormLabel>
          <RadioGroup
            name="type"
            value={type}
            onChange={event => setType(IdentityProviderType[event.target.value as keyof typeof IdentityProviderType])}
          >
            {identityProviderTypes.map((identityProviderKey) => (
              <FormControlLabel
                key={identityProviderKey}
                value={identityProviderKey}
                control={<Radio />}
                label={IDENTITY_PROVIDER_TYPE_METADATA[identityProviderKey].label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton
          id="cancel-add-identity-provider"
          color="secondary"
          onClick={onCancel}
          disabled={processing}
        >
          <FormattedMessage id="identityProvider.create.cancelButton" defaultMessage="Cancel" />
        </DefaultButton>
        <DefaultButton
          id="add-new-identity-provider"
          onClick={validateAndSubmit}
          disabled={processing}
          startIcon={<AddIcon />}
        >
          <FormattedMessage id="identityProvider.create.addButton" defaultMessage="Add Identity Provider" />
        </DefaultButton>
      </PaddedDialogActions>
    </Dialog>
  );
};

export default NewIdentityProvider;
