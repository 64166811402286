import { FC } from 'react';
import { Theme, useTheme } from '@mui/material';

import ColorAvatar, { ColorAvatarProps } from './ColorAvatar';

export type ActiveAvatarState = 'active' | 'inactive' | 'default';

export interface ActiveAvatarProps extends ColorAvatarProps {
  activated: ActiveAvatarState;
}

const ActiveAvatar: FC<ActiveAvatarProps> = ({ activated, ...rest }) => {
  const theme = useTheme<Theme>();
  const color = activated === 'active' ? theme.palette.success.main : (activated === 'inactive' ? theme.palette.error.main : theme.palette.grey[700]);

  return (
    <ColorAvatar color={color} {...rest} />
  );
};

export default ActiveAvatar;
