import { FC } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import { AppDecorator } from '../../components';
import { ErrorBlock } from '../../contexts/error-block';
import { intl } from '../../Internationalization';
import Users from './Users';
import User from './user/User';

const UsersRouting: FC = () => (
  <AppDecorator
    title={intl.formatMessage({
      id: 'users.routing.title',
      defaultMessage: 'Users'
    })}
  >
    <ErrorBlock>
      <Routes>
        <Route index element={<Users />} />
        <Route path=":userKey/*" element={<User />} />
        <Route path="*" element={<Navigate to="." replace />} />
      </Routes>
    </ErrorBlock>
  </AppDecorator>
);

export default UsersRouting;
