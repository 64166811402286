import React from 'react';
import { OAuth2IdentityProviderDetail } from '../../../../types';

export interface OAuth2IdentityProviderContextValue {
  identityProvider: OAuth2IdentityProviderDetail;
  identityProviderUpdated: (settings: OAuth2IdentityProviderDetail) => void;
}

const OAuth2IdentityProviderContextDefaultValue = {} as OAuth2IdentityProviderContextValue;
export const OAuth2IdentityProviderContext = React.createContext(
  OAuth2IdentityProviderContextDefaultValue
);

