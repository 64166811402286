import { FC } from "react";
import { Text } from "@react-pdf/renderer";

import { sharedPdfStyles } from '../../../../../components';
import { CheckCircleSuccessSvg, ReportProblemOutlinedWarningSvg } from '../../../../../components/pdf/PdfIcons';
import { PdfSectionHeader, PdfTableCell } from "../components";

interface SummaryIconProps {
  errors: boolean;
}

const SummaryIcon: FC<SummaryIconProps> = ({ errors }) => (
  errors ? (
    <ReportProblemOutlinedWarningSvg style={[sharedPdfStyles.mediumIcon, sharedPdfStyles.iconMarginRight]} />
  ) : (
    <CheckCircleSuccessSvg style={[sharedPdfStyles.mediumIcon, sharedPdfStyles.iconMarginRight]} />
  )
);

interface SummaryCount {
  label: string;
  error: boolean;
}

interface SummaryCountsProps {
  missing: SummaryCount;
  excess: SummaryCount;
  invalid: SummaryCount;
}

const SummaryCounts: FC<SummaryCountsProps> = ({ missing, excess, invalid }) => (
  <PdfSectionHeader>
    <PdfTableCell colSpan="third" icon style={[{ justifyContent: 'center' }]}>
      <SummaryIcon errors={missing.error} />
      <Text>
        {missing.label}
      </Text>
    </PdfTableCell>
    <PdfTableCell colSpan="third" icon style={[{ justifyContent: 'center' }]}>
      <SummaryIcon errors={excess.error} />
      <Text>
        {excess.label}
      </Text>
    </PdfTableCell>
    <PdfTableCell colSpan="third" icon style={[{ justifyContent: 'center' }]}>
      <SummaryIcon errors={invalid.error} />
      <Text>
        {invalid.label}
      </Text>
    </PdfTableCell>
  </PdfSectionHeader>
);

export default SummaryCounts;
