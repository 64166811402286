import { FC, useCallback, useContext, useEffect, useState } from 'react';

import * as GlobalStatsApi from '../../../api/globalStats';
import { extractErrorMessage } from '../../../api/endpoints';
import { FilterBar, FilterContainer } from '../../../components';
import { getCurrentTimeZone, toCompleteOffsetDateTimeRange } from '../../../util';
import { GlobalActivity, DailyActivity, ProjectActivity, PeriodStatsRequest, CompleteDateRange } from '../../../types';
import { intl } from '../../../Internationalization';
import { useErrorBlock } from '../../../contexts/error-block';
import FilterCompleteDateRange from '../../../components/browse-table/FilterCompleteDateRange';

import { DashboardContext } from '../DashboardContext';
import ChartsSkeleton from '../ChartsSkeleton';

import GlobalStats from './GlobalStats';
import GlobalCharts from './GlobalCharts';

const Global: FC = () => {
  const { raiseError } = useErrorBlock();
  const { updateGlobalFilters, globalFilters: { dateRange } } = useContext(DashboardContext);

  const [globalActivity, setGlobalActivity] = useState<GlobalActivity>();
  const [dailyActivity, setDailyActivity] = useState<DailyActivity[]>();
  const [projectActivity, setProjectActivity] = useState<ProjectActivity[]>();

  useEffect(() => {
    const loadGlobalActivity = async () => {
      if (!dateRange.start || !dateRange.end) {
        return;
      }

      const request: PeriodStatsRequest = {
        period: toCompleteOffsetDateTimeRange(dateRange),
        timeZone: getCurrentTimeZone()
      };

      try {
        const [
          { data: globalActivityData },
          { data: dailyActivityData },
          { data: projectActivityData }
        ] = await Promise.all([
          GlobalStatsApi.globalActivity(request),
          GlobalStatsApi.dailyActivity(request),
          GlobalStatsApi.projectActivity(request)
        ]);

        setGlobalActivity(globalActivityData);
        setDailyActivity(dailyActivityData);
        setProjectActivity(projectActivityData);
      } catch (error: any) {
        raiseError(extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'dashboard.global.loadError',
            defaultMessage: 'Failed to load dashboard data'
          })
        ));
      }
    };

    loadGlobalActivity();
  }, [dateRange, raiseError]);

  const onRangeChange = useCallback((newDateRange: CompleteDateRange) => {
    updateGlobalFilters({ dateRange: newDateRange });
  }, [updateGlobalFilters]);

  if (!globalActivity || !dailyActivity || !projectActivity) {
    return (
      <ChartsSkeleton />
    );
  }

  return (
    <div id="dashboard-global">
      <FilterBar
        barFilters={
          <FilterContainer>
            <FilterCompleteDateRange
              range={dateRange}
              onRangeUpdated={onRangeChange}
              rangeLimit={{ years: 1 }}
            />
          </FilterContainer>
        }
      />
      <GlobalStats globalActivity={globalActivity} />
      <GlobalCharts projectActivity={projectActivity} dailyActivity={dailyActivity} />
    </div>
  );
};

export default Global;
