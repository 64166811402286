import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Container, TableRow, TableCell, TableBody } from "@mui/material";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import DateRangeIcon from '@mui/icons-material/DateRange';

import * as AccountEventsApi from '../../../api/accountEvents';
import {
  FilterPagination, TableLoadingRow, TableInfoRow, NamedAccountAvatar, FilterContainer, FilterBar, BrowseTable,
  StyledTableHead, FilterDateRange, UserAutocomplete
} from "../../../components";
import { useBrowseRequest } from "../../../hooks";
import { intl } from "../../../Internationalization";
import { AccountEventType, ACCOUNT_EVENT_TYPE_METADATA, DateRange, UserDetail } from "../../../types";
import { dateTimeFormat, toDateQueryOffsetDateTimeRange } from "../../../util";
import AccountEventTypeSelector, { AccountEventTypeSelections, toAccountEventTypeArray } from "./AccountEventTypeSelector";

const PAGE_SIZE = 10;

interface AccountEventsProps {
  userConstraint?: UserDetail;
}

const AccountEvents: FC<AccountEventsProps> = ({ userConstraint = null }) => {
  const [dateRange, setDateRange] = useState<DateRange>({});
  const [userFilter, setUserFilter] = useState<UserDetail | null>(userConstraint);
  const [performedByFilter, setPerformedByFilter] = useState<UserDetail | null>(null);

  const [eventTypeSelections, setEventTypeSelections] = useState<AccountEventTypeSelections>(
    Object.keys(AccountEventType).reduce((acc, curr) => ({ ...acc, [curr]: false }), {}) as AccountEventTypeSelections
  );

  const { request, response, processing, setPage, updateRequest } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, userKey: userConstraint?.key },
    onRequest: AccountEventsApi.getAccountEvents
  });
  const COL_COUNT = userConstraint ? 3 : 4;

  const onRangeChange = (selectedDateRange: DateRange) => {
    setDateRange(selectedDateRange);
    updateRequest({ performedAt: toDateQueryOffsetDateTimeRange(selectedDateRange) });
  };

  const onUserChange = (selectedUser: UserDetail | null) => {
    updateRequest({ userKey: selectedUser?.key });
    setUserFilter(selectedUser);
  };
  const onPerformedByChange = (selectedUser: UserDetail | null) => {
    updateRequest({ performedByUserKey: selectedUser?.key });
    setPerformedByFilter(selectedUser);
  };

  const handleEventTypeSelectionsChange = (newEventTypeSelections: AccountEventTypeSelections) => {
    setEventTypeSelections(newEventTypeSelections);
    updateRequest({ types: toAccountEventTypeArray(newEventTypeSelections) });
  };

  const renderTableRows = () => {
    if (processing) {
      return (
        <TableLoadingRow colSpan={COL_COUNT} />
      );
    }

    if (!response || !response.results.length) {
      return (
        <TableInfoRow
          colSpan={COL_COUNT}
          size="medium"
          message={intl.formatMessage({
            id: 'storage.accountEvents.noEvents',
            defaultMessage: 'No account events to display',
          })}
        />
      );
    }

    return response.results.map(({ type, performedAt, performedBy, user }) => (
      <TableRow key={user.key + '-' + type + '-' + performedAt}>
        <TableCell>{ACCOUNT_EVENT_TYPE_METADATA[type].label}</TableCell>
        {
          !userConstraint &&
          <TableCell>
            <NamedAccountAvatar user={user} showEmail />
          </TableCell>
        }
        <TableCell>
          <NamedAccountAvatar user={performedBy} showEmail />
        </TableCell>
        <TableCell>{dateTimeFormat(performedAt)}</TableCell>
      </TableRow>
    ));
  };

  return (
    <Container maxWidth="lg" id="account-events" disableGutters>
      <FilterBar
        actions={
          <FilterPagination page={request.page} size={request.size} total={response?.total} disabled={processing} setPage={setPage} />
        }
        filterGroups={[
          {
            name: "eventType",
            title: intl.formatMessage({
              id: 'storage.accountEvents.filterGroup.eventType.title',
              defaultMessage: 'Event Type'
            }),
            icon: AssignmentTurnedInIcon,
            component: (
              <FilterContainer>
                <AccountEventTypeSelector selections={eventTypeSelections} onSelectionsUpdated={handleEventTypeSelectionsChange} />
              </FilterContainer>
            )
          },
          {
            name: "participants",
            title: intl.formatMessage({
              id: 'storage.accountEvents.filterGroup.participants.title',
              defaultMessage: 'Participants'
            }),
            icon: PersonSearchIcon,
            component: (
              <FilterContainer>
                {
                  !userConstraint &&
                  <UserAutocomplete
                    label={intl.formatMessage({
                      id: 'storage.accountEvents.userFilter.label',
                      defaultMessage: 'User'
                    })}
                    name="user"
                    value={userFilter}
                    onChange={onUserChange}
                    variant="standard"
                    includeAuxiliaryUser
                    autoDropdownWidth
                  />
                }
                <UserAutocomplete
                  label={intl.formatMessage({
                    id: 'storage.accountEvents.performedByFilter.label',
                    defaultMessage: 'Performed By'
                  })}
                  name="performedBy"
                  value={performedByFilter}
                  onChange={onPerformedByChange}
                  variant="standard"
                  includeAuxiliaryUser
                  autoDropdownWidth
                />
              </FilterContainer>
            )
          },
          {
            name: "performedAt",
            title: intl.formatMessage({
              id: 'storage.accountEvents.filterGroup.performedAt.title',
              defaultMessage: 'Performed At'
            }),
            icon: DateRangeIcon,
            component: (
              <FilterContainer>
                <FilterDateRange range={dateRange} onRangeUpdated={onRangeChange} />
              </FilterContainer>
            )
          },
        ]}
      />
      <BrowseTable>
        <StyledTableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="storage.accountEvents.eventTypeColumn" defaultMessage="Event Type" />
            </TableCell>
            {
              !userConstraint &&
              <TableCell>
                <FormattedMessage id="storage.accountEvents.userColumn" defaultMessage="User" />
              </TableCell>
            }
            <TableCell>
              <FormattedMessage id="storage.accountEvents.performedByColumn" defaultMessage="Performed By" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="storage.accountEvents.timeStampColumn" defaultMessage="Time Stamp" />
            </TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {renderTableRows()}
        </TableBody>
      </BrowseTable>
    </Container>
  );
};

export default AccountEvents;
