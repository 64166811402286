import { FC } from 'react';

import { useTheme } from '@mui/material';
import { ClassNames } from '@emotion/react';

import { Field } from '../../types';
import { intl } from '../../Internationalization';

import StyledEditor, { StyledEditorProps } from './StyledEditor';
import { FieldToolbarButton, templateEditorDecorators } from './editor-components';

export interface MailSubjectEditorProps extends Omit<StyledEditorProps, "customDecorators" | "toolbarCustomButtons" | "toolbar" | "handlePastedText" | "handleReturn"> {
  textFields: Field[];
}

const MailSubjectEditor: FC<MailSubjectEditorProps> = ({ textFields, toolbarClassName, wrapperClassName, editorClassName, ...rest }) => {
  const theme = useTheme();

  return (
    <ClassNames>
      {({ css, cx }) => (
        <StyledEditor
          {...rest}
          toolbarClassName={cx([
            css({
              '&&': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                backgroundColor: 'transparent',
                border: 0,
                marginBottom: 0,
                padding: theme.spacing(1),
                '& .AddFieldButton': {
                  whiteSpace: 'nowrap'
                }
              }
            }),
            toolbarClassName
          ])}
          wrapperClassName={cx([
            css({
              display: 'flex',
              flexDirection: 'row-reverse',
              marginBottom: 0,
              borderBottom: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0
            }),
            wrapperClassName
          ])}
          editorClassName={cx([
            css({
              flexGrow: 1,
              display: 'flex',
              padding: theme.spacing(0, 1),
              '&::before': {
                display: 'flex',
                alignItems: 'center',
                content: `'${intl.formatMessage({ id: 'components.editor.subject', defaultMessage: 'Subject' })}: '`,
                fontWeight: 500,
                marginRight: theme.spacing(1),
              },
              '& .DraftEditor-root': {
                flexGrow: 1
              }
            }),
            editorClassName
          ])}
          customDecorators={templateEditorDecorators(textFields)}
          toolbarCustomButtons={[<FieldToolbarButton fields={textFields} />]}
          toolbar={{
            options: [],
            inline: { options: [] }
          }}
          handlePastedText={() => true}
          handleReturn={() => true}
        />
      )}
    </ClassNames>
  );
};

export default MailSubjectEditor;
