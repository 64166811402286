import { FC, useContext } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';

import { AppDecorator, AppTabs, HeaderBreadcrumb, RouterTabs, RouterTab } from '../../../components';
import { intl } from '../../../Internationalization';
import { useRouterTab } from '../../../hooks';

import ProjectHeader from './ProjectHeader';
import ProjectSettings from './ProjectSettings';
import { ProjectContext } from './ProjectContext';
import SpecificationsRouting from './SpecificationsRouting';

export const toSpecificationLink = (projectKey: string, specificationKey: string) => (
  `/projects/${projectKey}/specifications/${specificationKey}`
);

const ProjectRouting: FC = () => {
  const { project } = useContext(ProjectContext);
  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="project-tabs" value={routerTab}>
      <RouterTab
        id="specifications-tab"
        value="specifications"
        label={intl.formatMessage({
          id: 'project.routing.tabs.specifications',
          defaultMessage: 'Specifications'
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="settings-tab"
        value="settings"
        label={intl.formatMessage({
          id: 'project.routing.tabs.settings',
          defaultMessage: 'Settings'
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  const renderBreadcrumb = () => (
    <HeaderBreadcrumb>
      <Typography>
        <WorkIcon
          titleAccess={intl.formatMessage({
            id: 'project.routing.breadcrumb.project.titleAccess',
            defaultMessage: 'Project'
          })}
        />
        {project.name}
      </Typography>
    </HeaderBreadcrumb>
  );

  return (
    <AppDecorator renderTabs={renderTabs} renderBreadcrumb={renderBreadcrumb}>
      <ProjectHeader />
      <AppTabs />
      <Box p={3}>
        <Routes>
          <Route path="specifications/*" element={<SpecificationsRouting />} />
          <Route path="settings" element={<ProjectSettings />} />
          <Route path="*" element={<Navigate to="specifications/" replace />} />
        </Routes>
      </Box>
    </AppDecorator>
  );
};

export default ProjectRouting;
