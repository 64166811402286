import {
  BigQueryIcon,
  UxAccessFileIcon,
  UxAutoCadIcon,
  UxCsvIcon,
  UxBentlyV8Icon,
  UxGeoPackageIcon,
  UxGlobeIcon,
  UxGlobeNetworkIcon,
  UxMariaDbDatabaseIcon,
  UxMultipleLayersIcon,
  UxMySqlDbDatabaseIcon,
  UxOracleDatabaseIcon,
  UxPostgresDatabaseIcon,
  UxSqlServerIcon,
  UxTableIcon,
  UxSitemapIcon,
  UxJsonIcon,
  UxConformanceReportIcon,
  UxXmlIcon
} from '../components/icons';
import { DataStoreParameterType, TaskConfigKind } from './specification';
import { TaskResultKind, TASK_KIND_METADATA, TaskResult } from './sessionResult';
import { AssignmentMetadata, AuditEventDetail, PageResponse } from '.';

export interface DataStoreTypeMetadata {
  label: string;
  icon: React.ComponentType<any>;
}

export const DATA_STORE_TYPE_METADATA: { [key: string]: DataStoreTypeMetadata } = {
  "Autodesk AutoCAD DWG/DXF": { label: "Autodesk AutoCAD DWG/DXF", icon: UxAutoCadIcon },
  "Bentley MicroStation Design (V8)": { label: "Bentley MicroStation Design", icon: UxBentlyV8Icon },
  "BigQuery": { label: "Google BigQuery", icon: BigQueryIcon },
  "ContextualDataStore": { label: "ContextualDataStore", icon: UxTableIcon },
  "DSV": { label: "Delimiter Separated Values", icon: UxCsvIcon },
  "ESRI Shape (GDAL)": { label: "Esri Shapefile", icon: UxGlobeIcon },
  "Esri ArcGIS Server": { label: "Esri ArcGIS Server", icon: UxGlobeNetworkIcon },
  "Esri Enterprise Geodatabase": { label: "Esri Enterprise Geodatabase", icon: UxGlobeNetworkIcon },
  "Esri File Geodatabase (GDAL)": { label: "Esri File Geodatabase", icon: UxGlobeNetworkIcon },
  "Esri Geodatabase File (FILEGDB)": { label: "Esri File Geodatabase", icon: UxGlobeNetworkIcon },
  "Esri Shape": { label: "Esri Shapefile", icon: UxGlobeIcon },
  "MapInfo TAB (MFAL)": { label: "MapInfo TAB MFAL", icon: UxTableIcon },
  "MapInfo TAB (MITAB)": { label: "MapInfo TAB MITAB", icon: UxTableIcon },
  "MapInfo Tab (GDAL)": { label: "MapInfo Tab", icon: UxTableIcon },
  "MariaDB (JDBC)": { label: "MariaDB", icon: UxMariaDbDatabaseIcon },
  "MySQL (JDBC)": { label: "MySQL", icon: UxMySqlDbDatabaseIcon },
  "Microsoft Access (GDAL)": { label: "Microsoft Access", icon: UxAccessFileIcon },
  "Microsoft SQL Server Spatial": { label: "Microsoft SQL Server Spatial", icon: UxSqlServerIcon },
  "OGC GeoPackage": { label: "OGC GeoPackage", icon: UxGeoPackageIcon },
  "Oracle": { label: "Oracle", icon: UxOracleDatabaseIcon },
  "PostgreSQL (GDAL)": { label: "PostgreSQL", icon: UxPostgresDatabaseIcon },
  "Postgres (JDBC)": { label: "PostgreSQL", icon: UxPostgresDatabaseIcon },
  "Sqlserver (JDBC)": { label: "Microsoft SQL Server", icon: UxSqlServerIcon },
  "WFS": { label: "Web Feature Service", icon: UxMultipleLayersIcon },
  "SchemaOnly": { label: "Schema Only", icon: UxSitemapIcon },
  "GeoJSON": { label: "GeoJSON", icon: UxJsonIcon },
  "GML": { label: "GML", icon: UxXmlIcon },
  "NonConformanceReport": { label: "Non-Conformance Report", icon: UxConformanceReportIcon },
  "FMEServer": { label: "FME Server", icon: UxTableIcon },
};

export interface SessionSchema {
  path: string;
  dataStores: DataStoreSchema[];
  tasks: Task[];
}

export enum TaskKind {
  ApplyActionMap = 'ApplyActionMap',
  ApplyActions = 'ApplyActions',
  CheckRules = 'CheckRules',
  CopyTo = 'CopyTo',
  Commit = "Commit",
  OpenData = 'OpenData',
  Pause = "Pause",
  BuildTopology = 'BuildTopology',
  DiscoverRules = 'DiscoverRules',
  OpenSchema = 'OpenSchema',
  TransformSchema = 'TransformSchema',
  ValidateSchema = 'ValidateSchema'
}

export const taskMetaData = (task: Task) => {
  const taskKindKey = TASK_RESULT_KINDS[task.kind];
  return TASK_KIND_METADATA[taskKindKey];
};

export const taskResultMetaData = (taskResult: TaskResult) => {
  return TASK_KIND_METADATA[taskResult.kind];
};

export const TASK_RESULT_KINDS: { [key in TaskKind]: TaskResultKind } = {
  [TaskKind.ApplyActionMap]: TaskResultKind.ApplyActionMapTask,
  [TaskKind.ApplyActions]: TaskResultKind.ApplyActionTask,
  [TaskKind.CheckRules]: TaskResultKind.CheckRulesTask,
  [TaskKind.CopyTo]: TaskResultKind.CopyToTask,
  [TaskKind.Commit]: TaskResultKind.CommitTask,
  [TaskKind.OpenData]: TaskResultKind.OpenDataTask,
  [TaskKind.Pause]: TaskResultKind.PauseTask,
  [TaskKind.BuildTopology]: TaskResultKind.BuildTopologyTask,
  [TaskKind.DiscoverRules]: TaskResultKind.DiscoverRulesTask,
  [TaskKind.OpenSchema]: TaskResultKind.OpenSchemaTask,
  [TaskKind.TransformSchema]: TaskResultKind.TransformSchemaTask,
  [TaskKind.ValidateSchema]: TaskResultKind.ValidateSchemaTask
};

export const TASK_CONFIG_KINDS: { [key in TaskKind]?: TaskConfigKind } = {
  [TaskKind.ApplyActionMap]: TaskConfigKind.ApplyActionMapTask,
  [TaskKind.CheckRules]: TaskConfigKind.CheckRulesTask,
  [TaskKind.ValidateSchema]: TaskConfigKind.ValidateSchemaTask,
  [TaskKind.Pause]: TaskConfigKind.PauseTask
};

export type Task = OpenDataTask | CopyToTask | CommitTask |
  BuildTopologyTask | DiscoverRulesTask | PauseTask |
  ApplyActionMapTask | ApplyActionsTask | CheckRulesTask | OpenSchemaTask |
  TransformSchemaTask | ValidateSchemaTask;

interface TaskBase {
  kind: TaskKind;
  customLabel: string;
  id: string;
  taskIdentifier: string;
}
export interface OpenDataClass {
  name: string;
  disabled: boolean;
}

export interface TransferTask extends TaskBase {
  classes: OpenDataClass[];
  parameters: { [key: string]: string };
  datastore: string;
}

export interface OpenDataTask extends TransferTask {
  kind: TaskKind.OpenData;
  openedDataBufferDistance: number;
  restrictToOpenedDataExtent?: boolean;
  topologyEnabled: boolean;
}

export interface CopyToTask extends TransferTask {
  kind: TaskKind.CopyTo;
}

export interface CommitTask extends TransferTask {
  kind: TaskKind.Commit;
}

export interface BuildTopologyTask extends TaskBase {
  kind: TaskKind.BuildTopology;
  classes: string[];
  model: string;
  snappingType: string;
  tolerance: number;
}

export interface DiscoverRulesTask extends TaskBase {
  kind: TaskKind.DiscoverRules;
  limit: number;
  discoverySpec: number;
}

export interface PauseTask extends TaskBase {
  kind: TaskKind.Pause;
}

export interface RulesTask extends TaskBase {
  filterRule: string;
}

export interface ApplyActionMapTask extends RulesTask {
  kind: TaskKind.ApplyActionMap;
  actionmap: string;
}

export interface ApplyActionsTask extends RulesTask {
  kind: TaskKind.ApplyActions;
  actions: string[];
}

export interface CheckRulesTask extends RulesTask {
  kind: TaskKind.CheckRules;
  rules: string[];
}

export interface OpenSchemaTask extends TransferTask {
  kind: TaskKind.OpenSchema;
}

export interface TransformSchemaTask extends TaskBase {
  kind: TaskKind.TransformSchema;
  nonSpatialFilter?: string;
  sourceDatastore: string;
  targetDatastore: string;
  mappingDatastore: string;
}

export interface ValidateSchemaTask extends TaskBase {
  kind: TaskKind.ValidateSchema;
  sourceDatastore: string;
  targetDatastore: string;
}

export interface SubmissionSchema {
  source: DataStoreSchema[];
  target: DataStoreSchema[];
  mappings: DataStoreMapping[];
}

export interface DataStoreMapping {
  path: string;
  importMappings: DataStoreClassMapping[];
}

export interface DataStoreClassMapping {
  source: string;
  target: string;
  attributes: MappedAttributes;
}

export type MappedAttributes = { [name: string]: string }

export type DataStoreSchemaKey = "importSchema" | "exportSchema";

export interface DataStoreSchema {
  path: string;
  importSchema?: Schema;
  exportSchema?: Schema;
  modifiesImportFile: boolean;
}

export interface OneIntegrateDataStoreParameter {
  name: string;
  type: DataStoreParameterType;
}

export interface OneIntegrateDataStoreType {
  name: string;
  uploadable: boolean;
  supportsModifiedImportFiles: boolean;
  downloadable: boolean;
  inputParameters: Record<string, OneIntegrateDataStoreParameter>;
}

export interface Schema {
  type: OneIntegrateDataStoreType;
  classes: DataStoreClassSchema[];
}

export interface DataStoreClassSchema {
  name: string;
  attributes: SchemaAttribute[];
}

export interface SchemaAttribute {
  name: string;
  dataType: string;
  mappedType: string;
}

export interface SavedMappingSettings {
  name: string;
  mappings: DataStoreMapping[];
}

export interface SavedMappingSummary {
  key: string;
  name: string;
  assignment: AssignmentMetadata;
  createEvent: AuditEventDetail;
}

export interface SavedMappingDetail extends SavedMappingSummary {
  mappings: DataStoreMapping[];
  inputs: SavedMappingInputDetail[];
}

export interface SavedMappingInputDetail {
  dataStorePath: string;
  filename: string;
}

export interface EvaluatedSavedMapping extends SavedMappingSummary {
  mapped: number;
}

export interface MappingRatios extends SavedMappingSummary {
  natural: number;
  predefined: number;
  saved: EvaluatedSavedMapping[];
}

export enum SchemaMapperType {
  PREDEFINED = 'PREDEFINED',
  NATURAL = 'NATURAL',
  SAVED = 'SAVED'
}

export interface ApplyMappingRequest {
  type: SchemaMapperType;
  key?: string;
}

export interface SavedMappingResponse extends PageResponse<SavedMappingSummary> { }

export const MAX_MAPPINGS = 10;
