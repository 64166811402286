import React, { FC, Fragment, useState } from 'react';
import { EditorState, Modifier } from 'draft-js';
import { FormattedMessage } from 'react-intl';

import { Field } from '../../../types';
import { intl } from '../../../Internationalization';

import { Option } from './Option';
import FieldMenu from './FieldMenu';

export interface FieldToolbarButtonProps {
  onChange?: (editorState: EditorState) => void;
  editorState?: EditorState;
  fields: Field[];
}

const FieldToolbarButton: FC<FieldToolbarButtonProps> = ({ editorState, onChange, fields }) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const insertField = (field: Field): void => {
    if (!editorState || !onChange) {
      return;
    }

    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      `\${${field.expression}}`,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, 'insert-fragment'));
  };

  return (
    <Fragment>
      <Option
        onClick={handleClick}
        title={intl.formatMessage({
          id: 'components.editor.fieldToolbarButton.addField',
          defaultMessage: 'Add field'
        })}
        value="field"
        className="AddFieldButton"
      >
        <FormattedMessage id="components.editor.fieldToolbarButton.addField" defaultMessage="Add field" />
      </Option>
      <FieldMenu
        id="subject-editor-add-field-menu"
        fields={fields}
        anchorElement={anchorElement}
        onClose={handleClose}
        onFieldSelected={insertField}
      />
    </Fragment>
  );
};

export default FieldToolbarButton;
