import React, { FC } from 'react';
import { Stepper, StepConnector, stepConnectorClasses, Step, StepLabel, styled } from '@mui/material';

import { StepIconProps } from '@mui/material/StepIcon';

export interface SimpleStep {
  icon: React.ElementType;
  label: string;
  renderStep: () => JSX.Element | null;
}

interface SubmissionStepperProps {
  steps: SimpleStep[];
  activeStep: number;
  errorStep?: number;
  renderError: () => JSX.Element | null;
}

interface StepIconsRootProps {
  active?: boolean;
  completed?: boolean;
  error: boolean;
}

const StepIconRoot = styled('div')<StepIconsRootProps>(
  ({ theme, active, completed, error }) => ({
    color: theme.palette.getContrastText(theme.palette.secondary.light),
    backgroundColor: theme.palette.secondary.light,
    zIndex: 1,
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(active && {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.getContrastText(theme.palette.secondary.dark),
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(completed && {
      color: theme.palette.getContrastText(theme.palette.secondary.main),
      backgroundColor: theme.palette.secondary.main,
    }),
    ...(error && {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.getContrastText(theme.palette.error.main),
    })
  })
);

const colorlibStepIcon = (StepIcon: React.ElementType, error: boolean) => (props: StepIconProps) => {
  const { active, completed } = props;
  return (
    <StepIconRoot active={active} completed={completed} error={error}>
      <StepIcon />
    </StepIconRoot>
  );
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}));

const SimpleStepper: FC<SubmissionStepperProps> = ({ activeStep, steps, errorStep, renderError }) => {
  const currentStep = steps[activeStep];

  const hasError = () => (errorStep !== undefined);
  const renderContent = () => hasError() ? renderError() : currentStep.renderStep();

  if (!currentStep) {
    return null;
  }

  return (
    <>
      <Stepper
        sx={{ bgcolor: 'transparent', p: 3 }}
        alternativeLabel
        activeStep={hasError() ? errorStep : activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((step, stepIndex) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={colorlibStepIcon(step.icon, errorStep === stepIndex)}>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {renderContent()}
    </>
  );
};

export default SimpleStepper;
