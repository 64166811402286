import React, { useContext, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';

import { Container, Typography, Grid } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';

import { extractErrorMessage } from '../../../api/endpoints';
import * as OneIntegrateServerSettingsApi from '../../../api/oneIntegrateServerSettings';
import * as OneIntegrateServerConnectionApi from '../../../api/oneIntegrateServerConnection';
import { PaddedPaper, ValidatedTextField, ValidatedPasswordField, FormButtons, DefaultButton, MessageBox, ConfirmDialog } from '../../../components';
import { OneIntegrateServerSettings } from '../../../types';
import { validate, normalizeUri } from '../../../util';
import { intl } from '../../../Internationalization';

import { ServiceConfigurationContext } from './ServiceConfigurationContext';

const OneIntegrateServerSettingsForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { oneIntegrateServerSettings, oneIntegrateServerSettingsUpdated } = useContext(ServiceConfigurationContext);
  const [baseUri, setBaseUri] = useState<string>(oneIntegrateServerSettings.baseUri);
  const [password, setPassword] = useState<string>(oneIntegrateServerSettings.password);
  const [username, setUsername] = useState<string>(oneIntegrateServerSettings.username);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>({});
  const [processing, setProcessing] = useState<boolean>(false);
  const [confirmClearCapabilitiesCache, setConfirmClearCapabilitiesCache] = useState<boolean>(false);

  useEffect(() => {
    setBaseUri(oneIntegrateServerSettings.baseUri);
    setPassword(oneIntegrateServerSettings.password);
    setUsername(oneIntegrateServerSettings.username);
  }, [oneIntegrateServerSettings]);

  const collectSettings = (): OneIntegrateServerSettings => ({
    baseUri: normalizeUri(baseUri),
    username,
    password
  });

  const validateAndPerform = (onValidated: (updatedSettings: OneIntegrateServerSettings) => void) => {
    setFieldErrors({});
    const updatedSettings = collectSettings();
    validate(OneIntegrateServerSettingsApi.ONE_INTEGRATE_SERVER_SETTINGS_VALIDATOR, updatedSettings)
      .then(() => onValidated(updatedSettings))
      .catch((newFieldErrors: ValidateFieldsError) => {
        setProcessing(false);
        setFieldErrors(newFieldErrors);
      });
  };

  const validateAndSaveSettings = () => {
    setProcessing(true);
    validateAndPerform(saveSettings);
  };

  const validateAndTestConnection = () => {
    setProcessing(true);
    validateAndPerform(testConnection);
  };

  const saveSettings = (updatedSettings: OneIntegrateServerSettings) => {
    OneIntegrateServerSettingsApi.updateServerSettings(updatedSettings).then(response => {
      setProcessing(false);
      oneIntegrateServerSettingsUpdated(response.data);
      enqueueSnackbar(intl.formatMessage({
        id: 'serviceConfiguration.oneIntegrate.serverSettings.saveSuccess',
        defaultMessage: '1Integrate server settings updated'
      }), { variant: "success" });
    }).catch(error => {
      setProcessing(false);
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'serviceConfiguration.oneIntegrate.serverSettings.saveError',
        defaultMessage: 'Failed to update 1Integrate server settings'
      })), { variant: "error" });
    });
  };

  const testConnection = (updatedSettings: OneIntegrateServerSettings) => {
    OneIntegrateServerConnectionApi.testServerConnection(updatedSettings).then(() => {
      setProcessing(false);
      enqueueSnackbar(intl.formatMessage({
        id: 'serviceConfiguration.oneIntegrate.serverSettings.testSuccess',
        defaultMessage: '1Integrate server connection successful'
      }), { variant: "success" });
    }).catch((error) => {
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'serviceConfiguration.oneIntegrate.serverSettings.testError',
        defaultMessage: 'Failed to connect to 1Integrate server'
      })), { variant: "error" });
      setProcessing(false);
    });
  };

  const clearCapabilitiesCache = () => {
    setConfirmClearCapabilitiesCache(false);
    setProcessing(true);
    OneIntegrateServerConnectionApi.evictCapabilities().then(() => {
      setProcessing(false);
      enqueueSnackbar(intl.formatMessage({
        id: 'serviceConfiguration.oneIntegrate.serverSettings.clearSuccess',
        defaultMessage: 'Successfully cleared capabilities cache'
      }), { variant: "success" });
    }).catch((error) => {
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'serviceConfiguration.oneIntegrate.serverSettings.clearError',
        defaultMessage: 'Failed to clear capabilities cache'
      })), { variant: "error" });
      setProcessing(false);
    });
  };

  return (
    <Container maxWidth="md" disableGutters id="system-one-integrate-server-settings">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PaddedPaper>
            <Typography variant="h5" gutterBottom>
              <FormattedMessage id="serviceConfiguration.oneIntegrate.serverSettings.title" defaultMessage="1Integrate Server Settings" />
            </Typography>
            <ValidatedTextField
              fieldErrors={fieldErrors}
              disabled={processing}
              name="baseUri"
              label={intl.formatMessage({
                id: 'serviceConfiguration.oneIntegrate.serverSettings.baseUri.label',
                defaultMessage: 'Base URI'
              })}
              value={baseUri}
              onChange={(event) => setBaseUri(event.target.value)}
              margin="normal"
              variant="outlined"
            />
            <ValidatedTextField
              fieldErrors={fieldErrors}
              disabled={processing}
              name="username"
              label={intl.formatMessage({
                id: 'serviceConfiguration.oneIntegrate.serverSettings.username.label',
                defaultMessage: 'Username'
              })}
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              margin="normal"
              variant="outlined"
            />
            <ValidatedPasswordField
              fieldErrors={fieldErrors}
              disabled={processing}
              name="password"
              label={intl.formatMessage({
                id: 'serviceConfiguration.oneIntegrate.serverSettings.password.label',
                defaultMessage: 'Password'
              })}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              margin="normal"
              variant="outlined"
            />
            <FormButtons>
              <DefaultButton
                name="saveSettings"
                onClick={validateAndSaveSettings}
                disabled={processing}
                startIcon={<SaveIcon />}
              >
                <FormattedMessage id="serviceConfiguration.oneIntegrate.serverSettings.saveButton" defaultMessage="Save Settings" />
              </DefaultButton>
              <DefaultButton
                name="testConnection"
                onClick={validateAndTestConnection}
                disabled={processing}
                startIcon={<SettingsInputAntennaIcon />}
                color="secondary"
              >
                <FormattedMessage id="serviceConfiguration.oneIntegrate.serverSettings.testButton" defaultMessage="Test Connection" />
              </DefaultButton>
            </FormButtons>
          </PaddedPaper>
        </Grid>
        <Grid item xs={12}>
          <PaddedPaper>
            <Typography variant="h5" gutterBottom>
              <FormattedMessage id="serviceConfiguration.oneIntegrate.serverSettings.clearCacheTitle" defaultMessage="Clear Capabilities Cache" />
            </Typography>
            <MessageBox
              message={intl.formatMessage({
                id: 'serviceConfiguration.oneIntegrate.serverSettings.clearCache',
                defaultMessage: 'This feature will clear the cache of 1Integrate capabilities, forcing a refresh.'
              })}
              level="info"
            />
            <FormButtons>
              <DefaultButton
                name="clearCache"
                onClick={() => setConfirmClearCapabilitiesCache(true)}
              >
                <FormattedMessage id="serviceConfiguration.oneIntegrate.serverSettings.clearCacheButton" defaultMessage="Clear Cache" />
              </DefaultButton>
            </FormButtons>
            <ConfirmDialog
              id="confirm-clear-cache"
              title={intl.formatMessage({
                id: 'serviceConfiguration.oneIntegrate.serverSettings.confirmClearCache.title',
                defaultMessage: 'Clear Capabilities Cache'
              })}
              text={intl.formatMessage({
                id: 'serviceConfiguration.oneIntegrate.serverSettings.confirmClearCache.text',
                defaultMessage: 'Are you sure you wish to clear the capabilities cache? Capabilities will be refreshed on next request.'
              })}
              confirmBtnText={intl.formatMessage({
                id: 'serviceConfiguration.oneIntegrate.serverSettings.confirmClearCache.confirmButton',
                defaultMessage: 'Clear Cache'
              })}
              confirmAction={clearCapabilitiesCache}
              closeAction={() => setConfirmClearCapabilitiesCache(false)}
              isOpen={confirmClearCapabilitiesCache}
            />
          </PaddedPaper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OneIntegrateServerSettingsForm;
