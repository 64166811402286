import React from 'react';

export interface OptionProps {
  className?: string;
  title: string;
  value: string;
  disabled?: boolean;
  children: React.ReactElement | string;
  onClick: (event: React.MouseEvent<HTMLElement>, value: string) => void;
}

export const Option = ({ className, title, disabled, onClick, value, children }: OptionProps) => (
  <div
    className={`rdw-option-wrapper ${className} ${disabled && 'rdw-option-disabled'}`}
    onClick={e => disabled ? null : onClick(e, value)}
    title={title}
  >
    {children}
  </div>
);
