import React, { ComponentType } from 'react';
import { TableCell, TableCellProps } from '@mui/material';
import { StringKey } from '../../util/types';

export type ColumnContent = JSX.Element | string;

export interface ColumnStyle {
  component?: ComponentType<TableCellProps>;
  componentProps?: TableCellProps;
}

interface ConditionalTableCellsProps<T extends Record<string, ColumnContent>> {
  values: T;
  selections: (StringKey<T>)[];
  styles: Partial<Record<StringKey<T>, ColumnStyle>>;
}

function ConditionalTableCells<T extends { [key: string]: ColumnContent }>({ values, selections, styles }: ConditionalTableCellsProps<T>) {
  return (
    <>
      {
        selections.map(
          cellKey => {
            const { component: CellComponent = TableCell, componentProps = {} } = (styles[cellKey] || {})!;
            return (
              <CellComponent key={cellKey} {...componentProps}>
                {values[cellKey]}
              </CellComponent>
            );
          }
        )
      }
    </>
  );
}

export default ConditionalTableCells;
