import { FC } from 'react';
import { BoxProps } from '@mui/material';

import ButtonRow from './ButtonRow';

const FormButtons: FC<BoxProps> = ({ mt = 2, ...props }) => (
  <ButtonRow mt={mt} {...props} />
);

export default FormButtons;
