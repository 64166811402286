import React from "react";
import { DashboardState, GlobalFilters, SpecificationFilters } from "./Dashboard";

export interface DashboardContextValue extends DashboardState {
  updateGlobalFilters: (globalFilters: GlobalFilters, callback?: () => void) => void;
  updateSpecificationFilters: (specificationFilters: SpecificationFilters, callback?: () => void) => void;
}

const DashboardContextDefaultValue = {} as DashboardContextValue;
export const DashboardContext = React.createContext(
  DashboardContextDefaultValue
);
