import { FC, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";

import { TextField, TextFieldProps } from "@mui/material";

import { onEnterCallback } from "../../util";

const DEBOUNCE_DELAY = 300;

interface FilterTextFieldProps extends Omit<TextFieldProps, "onKeyDown" | "onChange"> {
  onSearch: (filter: string) => void;
  value: string;
}

const FilterTextField: FC<FilterTextFieldProps> = ({ onSearch, value, disabled, ...rest }) => {
  const [filter, setFilter] = useState("");
  useEffect(() => {
    setFilter(value);
  }, [value]);

  const debouncedSearch = useRef(debounce(onSearch, DEBOUNCE_DELAY));
  useEffect(() => {
    debouncedSearch.current(filter);
  }, [filter]);

  const searchNow = () => {
    onSearch(filter);
    debouncedSearch.current.cancel();
  };
  const submitOnEnter = onEnterCallback(searchNow);

  return (
    <TextField
      value={filter}
      disabled={disabled}
      onChange={(e) => setFilter(e.target.value)}
      onKeyDown={submitOnEnter}
      {...rest}
    />
  );
};

export default FilterTextField;
