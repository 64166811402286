import React, { FC, CSSProperties } from 'react';
import clsx from 'clsx';
import { Paper, Avatar } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { PaperProps } from '@mui/material/Paper';

interface AvatarPaperProps extends PaperProps {
  icon: React.ComponentType<SvgIconProps>;
  iconColor: CSSProperties['backgroundColor'];
}

const AvatarPaper: FC<AvatarPaperProps> = ({ sx = [], children, icon: Icon, iconColor, className, ...rest }) => (
  <Paper
    sx={[{
      mt: 3, p: (theme) => theme.spacing(1, 3, 1.5), height: (theme) => `calc(100% - ${theme.spacing(3)})`
    },
    ...(Array.isArray(sx) ? sx : [sx])
    ]}
    {...rest}
  >
    <Avatar
      className={clsx("Avatar", className)}
      sx={{
        mt: -4,
        mr: 1,
        bgcolor: iconColor,
        float: 'left',
        height: (theme) => theme.spacing(8),
        width: (theme) => theme.spacing(8)
      }}
    >
      <Icon fontSize="large" />
    </Avatar>
    {children}
  </Paper>
);

export default AvatarPaper;
