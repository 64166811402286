import { FC } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Typography } from '@mui/material';

import UploadDropzone, { UploadDropzoneProps, renderProgressText } from './UploadDropzone';

const Upload: FC<UploadDropzoneProps> = ({ progress, uploading, ...rest }) => (
  <UploadDropzone progress={progress} uploading={uploading} {...rest}>
    <Box flexDirection="column" display="flex" alignItems="center">
      <CloudUploadIcon fontSize="large" />
      <Typography variant="body2">
        {renderProgressText(uploading, progress)}
      </Typography>
    </Box>
  </UploadDropzone>
);

export default Upload;
