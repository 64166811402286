import { FC, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Grid, CardContent, Card, CardHeader, Typography, Container } from '@mui/material';

import * as SpecificationMediaApi from '../../../../api/specificationMedia';
import { PaddedPaper, FilesList, NoContentPlaceholder } from '../../../../components';
import { intl } from '../../../../Internationalization';

import { MyAssignmentContext } from '../MyAssignmentContext';

import SpecificationInformationSkeleton from './SpecificationInformationSkeleton';

const SpecificationInformation: FC = () => {
  const { specification } = useContext(MyAssignmentContext);

  if (!specification) {
    return <SpecificationInformationSkeleton />;
  }

  if (!specification.showInformation) {
    return (
      <Container maxWidth="md">
        <NoContentPlaceholder
          message={intl.formatMessage({
            id: 'myAssignment.information.noInformation',
            defaultMessage: 'No information to display'
          })}
        />
      </Container>
    );
  }

  return (
    <Grid container spacing={3} id="my-assignment-information">
      <Grid item sm={12} md={6}>
        <PaddedPaper>
          <Box mb={2}>
            <Typography variant="h5">
              <FormattedMessage id="myAssignment.information.documentation.title" defaultMessage="Documentation" />
            </Typography>
          </Box>
          <div dangerouslySetInnerHTML={{ __html: specification.documentation }} />
        </PaddedPaper>
      </Grid>
      <Grid item sm={12} md={6}>
        <Card>
          <CardHeader
            title={intl.formatMessage({
              id: 'myAssignment.information.documents.title',
              defaultMessage: 'Documents'
            })}
          />
          <CardContent>
            <FilesList
              documents={specification.documents}
              downloadURL={(file) => SpecificationMediaApi.downloadSpecificationDocumentUri(specification.key, file)}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SpecificationInformation;
