import { useState, useEffect, FC } from 'react';
import { Container } from '@mui/material';

import * as MailSenderSettingsApi from '../../../../api/mailSenderSettings';
import { extractErrorMessage } from '../../../../api/endpoints';
import { MailSenderSettings } from '../../../../types';
import { PaperFormSkeleton } from '../../../../components';
import { useErrorBlock } from '../../../../contexts/error-block';
import { intl } from '../../../../Internationalization';
import SenderSettingsForm from './SenderSettingsForm';

const SenderSettings: FC = () => {
  const { raiseError } = useErrorBlock();
  const [senderSettings, setSenderSettings] = useState<MailSenderSettings>();

  useEffect(() => {
    const fetchSenderSettings = async () => {
      try {
        const { data } = await MailSenderSettingsApi.getSenderSettings();
        setSenderSettings(data);
      } catch (error: any) {
        raiseError(extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'mail.senderSettings.loadError',
            defaultMessage: 'Failed to load mail sender settings'
          })
        ));
      }
    };

    fetchSenderSettings();
  }, [raiseError]);

  return (
    <Container maxWidth="md" disableGutters>
      {
        senderSettings ?
          <SenderSettingsForm senderSettings={senderSettings} />
          :
          <PaperFormSkeleton contentRows={7} />
      }
    </Container>
  );
};

export default SenderSettings;
