import { FC, useCallback, useState, useContext, useEffect, PropsWithChildren } from 'react';
import { useSnackbar } from 'notistack';

import { intl } from '../../Internationalization';
import * as SubmissionsApi from '../../api/submissions';
import * as MyNotificationsApi from '../../api/myNotifications';
import { extractErrorMessage } from '../../api/endpoints';
import { useInterval } from '../../hooks';

import { AuthenticatedContext } from '../authentication';

import { AppBarStatsContext } from './context';

const POLL_INTERVAL = 60000;

const AppBarStats: FC<PropsWithChildren> = ({ children }) => {
  const { me } = useContext(AuthenticatedContext);
  const { enqueueSnackbar } = useSnackbar();
  const [openSubmissions, setOpenSubmissions] = useState<number>(0);
  const [unreadNotifications, setUnreadNotifications] = useState<number>(0);

  const refresh = useCallback(async () => {
    try {

      const [
        submissionsResponse,
        notificationResponse
      ] = await Promise.all([
        SubmissionsApi.getSubmissions({
          userKey: me.key,
          closed: false,
          size: 1,
          page: 0,
        }),
        MyNotificationsApi.getMyNotifications({
          size: 1,
          page: 0,
          read: false
        })
      ]);

      setOpenSubmissions(submissionsResponse.data.total);
      setUnreadNotifications(notificationResponse.data.total);
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'contexts.appBarStats.loadError',
          defaultMessage: 'Failed to load application metrics'
        })
      ), { variant: "error" });
    }
  }, [me.key, enqueueSnackbar]);

  useInterval(refresh, POLL_INTERVAL);
  useEffect(() => { refresh(); }, [refresh]);

  return (
    <AppBarStatsContext.Provider value={{ refresh, openSubmissions, unreadNotifications }}>
      {children}
    </AppBarStatsContext.Provider>
  );
};

export default AppBarStats;
