import { FC, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import * as DeletionJobApi from '../../../../../api/deletionJob';
import { extractErrorMessage } from "../../../../../api/endpoints";
import { ActiveAvatar, SecureConfirmDialog, DefaultButton, ListItemActionArea, PaddedPaper } from "../../../../../components";
import { DeletionJobEntity } from "../../../../../types";
import { intl } from "../../../../../Internationalization";
import { toDeletionJobUrl } from "../../../../system/storage/DeletionJobs";

import { SpecificationContext } from "../SpecificationContext";

const SpecificationManagement: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { specification } = useContext(SpecificationContext);
  const navigate = useNavigate();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const handleDelete = async () => {
    setProcessing(true);
    try {
      const { data: deletionJob } = await DeletionJobApi.createDeletionJob({
        entity: DeletionJobEntity.SPECIFICATION, entityKey: specification.key
      });
      enqueueSnackbar(intl.formatMessage({
        id: 'specification.management.deletionStarted',
        defaultMessage: 'Deletion task started'
      }), { variant: 'success' });
      navigate(toDeletionJobUrl(deletionJob));
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'specification.management.deleteError',
        defaultMessage: 'Failed to start specification deletion task'
      })), { variant: "error" });
      if (error.response.status === 400) {
        setDeleteDialogOpen(false);
      }
      setProcessing(false);
    }
  };

  return (
    <PaddedPaper>
      <Typography variant="h5" component="h3" gutterBottom>
        <FormattedMessage
          id="specification.management.title"
          defaultMessage="Manage Specification"
        />
      </Typography>
      <List>
        <ListItem>
          <ListItemAvatar>
            <ActiveAvatar activated="inactive">
              <DeleteForeverIcon />
            </ActiveAvatar>
          </ListItemAvatar>
          <ListItemText
            primary={intl.formatMessage({
              id: 'specification.management.deleteSpecification',
              defaultMessage: 'Delete Specification'
            })}
            secondary={intl.formatMessage({
              id: 'specification.management.deleteSpecification.description',
              defaultMessage: "Permanently remove the specification and all of its associated assignments and submissions"
            })}
          />
          <ListItemActionArea>
            <DefaultButton
              id="delete-specification-button"
              color="grey"
              onClick={() => setDeleteDialogOpen(true)}
            >
              <FormattedMessage
                id="specification.management.deleteSpecificationButton"
                defaultMessage="Delete Specification"
              />
            </DefaultButton>
          </ListItemActionArea>
        </ListItem>
      </List>
      <SecureConfirmDialog
        id="confirm-delete-specification"
        disabled={processing}
        confirmCode={specification.key}
        confirmCodeLabel={intl.formatMessage({
          id: 'specification.management.confirmDeleteSpecification.confirmCodeLabel',
          defaultMessage: 'Specification Key'
        })}
        isOpen={deleteDialogOpen}
        title={intl.formatMessage({
          id: 'specification.management.confirmDeleteSpecification.title',
          defaultMessage: 'Delete Specification?'
        })}
        confirmBtnText={intl.formatMessage({
          id: 'specification.management.confirmDeleteSpecification.button',
          defaultMessage: 'Delete Specification'
        })}
        confirmAction={handleDelete}
        closeAction={() => setDeleteDialogOpen(false)}
      >
        <Typography variant="body1" component="p" gutterBottom>
          <FormattedMessage
            id="project.management.confirmDeleteSpecification.warningMessage"
            defaultMessage="Are you absolutely sure you want to delete this specification? This will delete the specification and the associated assignments and submissions permanently."
          />
        </Typography>
        <Box my={2}>
          <Typography>
            <strong>
              <FormattedMessage
                id="project.management.confirmDeleteSpecification.specificationKeyLabel"
                defaultMessage="Specification Key:"
              />
            </strong> {specification.key}
          </Typography>
          <Typography>
            <strong>
              <FormattedMessage
                id="project.management.confirmDeleteSpecification.specificationNameLabel"
                defaultMessage="Specification Name:"
              />
            </strong> {specification.name}
          </Typography>
        </Box>
        <Typography variant="body1" component="p">
          <FormattedMessage
            id="project.management.confirmDeleteSpecification.confirmMessage"
            defaultMessage="Type the specification key into the box below to confirm this action."
          />
        </Typography>
      </SecureConfirmDialog>
    </PaddedPaper>
  );
};

export default SpecificationManagement;
