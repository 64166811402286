import React, { useState, useContext } from 'react';
import { AxiosError } from 'axios';
import { FormattedMessage } from 'react-intl';
import { Typography, List, ListItem, ListItemAvatar, ListItemText, Divider, Checkbox, ListSubheader } from '@mui/material';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useSnackbar } from 'notistack';

import * as UserAPI from '../../../../api/user';
import { ConfirmDialog, DefaultButton, MessageBox, ActiveAvatar } from '../../../../components';
import { UserDetail, ReceiverPermissionsRequest, ReceiverPermissionsDetail } from '../../../../types';
import { AuthenticatedContext } from '../../../../contexts/authentication';
import { extractErrorMessage } from '../../../../api/endpoints';
import { intl } from '../../../../Internationalization';

const DEFAULT_PERMISSIONS: ReceiverPermissionsRequest = {
  admin: false
};

interface ReceiverAdministrationProps {
  user: UserDetail;
  userUpdated: (profile: UserDetail) => void;
}

export default function ReceiverAdministration(props: ReceiverAdministrationProps) {
  const { user, userUpdated } = props;
  const meContext = useContext(AuthenticatedContext);
  const { enqueueSnackbar } = useSnackbar();

  const notAdmin = !meContext.me.receiverPermissions?.admin;
  const performingOnSelf = meContext.me.key === props.user.key;
  const disableForm = notAdmin || performingOnSelf;

  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [receiverPermissions, setReceiverPermissions] = useState<ReceiverPermissionsDetail | undefined>(user.receiverPermissions);
  const [processing, setProcessing] = useState(false);

  const updateAdmin = (event: React.ChangeEvent, admin: boolean) => setReceiverPermissions({ ...receiverPermissions, admin });

  const updatePermissions = async () => {
    if (receiverPermissions) {
      setProcessing(true);
      try {
        const response = await UserAPI.updateReceiverPermissions(user.key, receiverPermissions);
        handleResponse(response.data);
        enqueueSnackbar(intl.formatMessage({
          id: 'user.permissions.saveSuccess',
          defaultMessage: 'Receiver permissions have been updated.'
        }), { variant: 'success' });
      } catch (e) {
        handleError(e as AxiosError);
      }
    }
  };

  const handleConfirmAction = async () => {
    setProcessing(true);
    setConfirmationDialog(false);
    try {
      if (receiverPermissions) {
        const response = await UserAPI.revokeReceiverPermissions(user.key);
        handleResponse(response.data);
        enqueueSnackbar(intl.formatMessage({
          id: 'user.permissions.revokeReceiverPermissionSuccess',
          defaultMessage: 'Receiver permissions have been revoked.'
        }), { variant: 'success' });
      } else {
        const response = await UserAPI.updateReceiverPermissions(user.key, DEFAULT_PERMISSIONS);
        handleResponse(response.data);
        enqueueSnackbar(intl.formatMessage({
          id: 'user.permissions.grantReceiverPermissionSuccess',
          defaultMessage: 'Receiver permissions have been granted.'
        }), { variant: 'success' });
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  const handleResponse = (updatedUser: UserDetail) => {
    setProcessing(false);
    userUpdated(updatedUser);
    setReceiverPermissions(updatedUser.receiverPermissions);
  };

  const handleError = (error: AxiosError) => {
    setProcessing(false);
    enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
      id: 'user.permissions.saveError',
      defaultMessage: "Failed to update {name}''s permissions."
    }, {
      name: user.name
    })), { variant: "error" });
  };

  const renderReceiverPermissions = () => {
    if (!receiverPermissions) {
      return null;
    }
    return (
      <>
        <Divider />
        <ListSubheader>
          <FormattedMessage id="user.permissions.receiverTitle" defaultMessage="Configure Receiver Permissions" />
        </ListSubheader>
        <ListItem>
          <ListItemAvatar>
            <ActiveAvatar activated={receiverPermissions.admin ? 'active' : 'default'}>
              <SupervisorAccountIcon />
            </ActiveAvatar>
          </ListItemAvatar>
          <ListItemText
            primary={intl.formatMessage({
              id: 'user.permissions.siteAdministrator',
              defaultMessage: "Site Administrator"
            })}
            secondary={intl.formatMessage({
              id: 'user.permissions.siteAdministrator.description',
              defaultMessage: "Permit user to control permissions and modify site settings"
            })}
          />
          <Checkbox onChange={updateAdmin} disabled={disableForm || processing} checked={receiverPermissions.admin} />
        </ListItem>
        <ListItem>
          <DefaultButton id="update-permissions" onClick={updatePermissions} disabled={disableForm || processing}>
            <FormattedMessage id="user.permissions.saveButton" defaultMessage="Update Permissions" />
          </DefaultButton>
        </ListItem>
      </>
    );
  };

  const renderMessageBox = () => {
    if (notAdmin) {
      return (
        <MessageBox
          level="warning"
          message={intl.formatMessage({
            id: 'user.permissions.notAdmin',
            defaultMessage: 'Only administrators are permitted to modify permissions.'
          })}
        />
      );
    }
    if (performingOnSelf) {
      return (
        <MessageBox
          level="info"
          message={intl.formatMessage({
            id: 'user.permissions.performingOnSelf',
            defaultMessage: 'You are not permitted to modify your own permissions.'
          })}
        />
      );
    }
    return null;
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>
        <FormattedMessage id="user.permissions.adminTitle" defaultMessage="Receiver Administration" />
      </Typography>
      {renderMessageBox()}
      <List>
        <ListItem>
          <ListItemAvatar>
            <ActiveAvatar activated={receiverPermissions ? 'active' : 'default'}>
              <PersonOutlineIcon />
            </ActiveAvatar>
          </ListItemAvatar>
          <ListItemText
            primary={intl.formatMessage({
              id: 'user.permissions.receiverUser',
              defaultMessage: 'Receiver User'
            })}
            secondary={intl.formatMessage({
              id: 'user.permissions.receiverUser.description',
              defaultMessage: 'Grant the user receiver permissions'
            })}
          />
          <DefaultButton
            id="toggle-receiver-permissions"
            color="grey"
            onClick={() => setConfirmationDialog(true)}
            disabled={disableForm || processing}
          >
            {
              receiverPermissions ?
                <FormattedMessage id="user.permissions.receiverPermissionToggleButton.revoke" defaultMessage="Revoke" /> :
                <FormattedMessage id="user.permissions.receiverPermissionToggleButton.grant" defaultMessage="Grant" />
            }
          </DefaultButton>
        </ListItem>
        {renderReceiverPermissions()}
      </List>
      <ConfirmDialog
        id="confirm-permission-toggle"
        isOpen={confirmationDialog}
        title={
          receiverPermissions ?
            intl.formatMessage({
              id: 'user.permissions.confirmReceiverPermissionToggle.revokeTitle',
              defaultMessage: 'Revoke permissions'
            }) :
            intl.formatMessage({
              id: 'user.permissions.confirmReceiverPermissionToggle.grantTitle',
              defaultMessage: 'Grant permissions'
            })
        }
        text={
          receiverPermissions ?
            intl.formatMessage({
              id: 'user.permissions.confirmReceiverPermissionToggle.revokeText',
              defaultMessage: 'Are you sure you wish to revoke receiver permissions for {name}?'
            }, { name: user.name }) :
            intl.formatMessage({
              id: 'user.permissions.confirmReceiverPermissionToggle.grantText',
              defaultMessage: 'Are you sure you wish to grant receiver permissions for {name}?'
            }, { name: user.name })
        }
        confirmBtnText={
          receiverPermissions ?
            intl.formatMessage({
              id: 'user.permissions.confirmReceiverPermissionToggle.revokeButton',
              defaultMessage: 'Revoke'
            }) :
            intl.formatMessage({
              id: 'user.permissions.confirmReceiverPermissionToggle.grantButton',
              defaultMessage: 'Grant'
            })
        }
        confirmAction={handleConfirmAction}
        closeAction={() => setConfirmationDialog(false)}
      />
    </>
  );
}
