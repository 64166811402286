import { AxiosPromise } from "axios";
import { OneIntegrateServerSettings } from "../types";
import { AXIOS } from "./endpoints";

export function testServerConnection(settings: OneIntegrateServerSettings): AxiosPromise<OneIntegrateServerSettings> {
  return AXIOS.post('/1integrate/server/test', settings);
}

export function evictCapabilities(): AxiosPromise<void> {
  return AXIOS.post('/1integrate/capabilities/evict');
}
