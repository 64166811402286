import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TableRow, TableCell, FormControlLabel, Checkbox, Typography, TextField, InputAdornment } from '@mui/material';

import { TaskConfig, SessionSchema, Task, ConformanceTaskConfig, ConformanceMode, TaskKind } from '../../../../../../types';
import TaskLabelCell from './TaskLabelCell';

interface ConformanceTaskRowProps {
  task: Task;
  taskConfig: ConformanceTaskConfig;
  sessionSchema: SessionSchema;
  conformanceMode: ConformanceMode;
  updateTaskConfig: (taskConfig: TaskConfig) => void;
}

const isValidationTask = (task: Task, taskConfig: ConformanceTaskConfig, conformanceMode: ConformanceMode) => {
  if (conformanceMode === ConformanceMode.ALL_TASKS) {
    return true;
  }
  if (conformanceMode === ConformanceMode.CHECK_RULES_TASKS) {
    return task.kind === TaskKind.CheckRules;
  }
  return taskConfig.validationTask;
};

const ConformanceTaskRow: FC<ConformanceTaskRowProps> = ({ task, taskConfig, sessionSchema, conformanceMode, updateTaskConfig }) => {
  const [passThresholdString, setPassThresholdString] = useState<string>(taskConfig.passThreshold.toFixed(2));
  const checked = isValidationTask(task, taskConfig, conformanceMode);

  return (
    <TableRow>
      <TaskLabelCell task={task} sessionSchema={sessionSchema} />
      <TableCell colSpan={2}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={conformanceMode !== ConformanceMode.SELECTED_TASKS}
              checked={checked}
              onChange={() => {
                updateTaskConfig({ ...taskConfig, validationTask: !taskConfig.validationTask });
              }}
            />
          }
          label={
            <Typography variant="body2">
              <FormattedMessage
                id="specification.configuration.taskConfig.conformanceResults.label"
                defaultMessage="Record conformance results"
              />
            </Typography>
          }
        />
      </TableCell>
      <TableCell>
        <TextField
          name="passThreshold"
          fullWidth
          disabled={!checked}
          value={passThresholdString}
          onChange={event => setPassThresholdString(event.target.value)}
          onBlur={() => {
            const passThreshold = Math.max(0, Math.min(100, Math.trunc(Number(passThresholdString) * 100 || 0) / 100));
            updateTaskConfig({ ...taskConfig, passThreshold });
            setPassThresholdString(passThreshold.toFixed(2));
          }}
          InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
          variant="standard"
        />
      </TableCell>
    </TableRow>
  );
};

export default ConformanceTaskRow;
