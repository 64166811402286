import Schema from 'async-validator';
import { AxiosPromise } from 'axios';

import { UserProfile, UserDetail, UserProfileRequest, ReceiverPermissionsRequest, CreateUserRequest, PasswordResetLink, ActivationLink } from '../types';
import { NOT_BLANK_REGEX, duplicateValidator, nameLengthValidator } from '../util';
import { intl } from '../Internationalization';

import { AXIOS } from './endpoints';
import { userByEmail } from './userLookup';

export function getUserDetails(userKey: string): AxiosPromise<UserDetail> {
  return AXIOS.get(`/users/${userKey}`);
}

export function suspendUser(userKey: string): AxiosPromise<UserDetail> {
  return AXIOS.post(`/users/${userKey}/suspend`);
}

export function unsuspendUser(userKey: string): AxiosPromise<UserDetail> {
  return AXIOS.post(`/users/${userKey}/unsuspend`);
}

export function updateReceiverPermissions(userKey: string, receiverPermissions: ReceiverPermissionsRequest): AxiosPromise<UserDetail> {
  return AXIOS.post(`/users/${userKey}/receiver_permissions`, receiverPermissions);
}

export function revokeReceiverPermissions(userKey: string): AxiosPromise<UserDetail> {
  return AXIOS.delete(`/users/${userKey}/receiver_permissions`);
}

export function resendActivationEmail(userKey: string): AxiosPromise<UserDetail> {
  return AXIOS.post(`/users/${userKey}/resend_activation_email`);
}

export function generatePasswordResetLink(userKey: string): AxiosPromise<PasswordResetLink> {
  return AXIOS.post(`/users/${userKey}/generate_password_reset_link`);
}

export function generateActivationLink(userKey: string): AxiosPromise<ActivationLink> {
  return AXIOS.post(`/users/${userKey}/generate_activation_link`);
}

export function createUser(request: CreateUserRequest): AxiosPromise<UserProfile> {
  return AXIOS.post('/users', request);
}

export function updateUserProfile(userKey: string, request: UserProfileRequest): AxiosPromise<UserDetail> {
  return AXIOS.post(`/users/${userKey}/profile`, request);
}

export function userProfileValidator(getExistingEmail?: () => string): Schema {
  return new Schema({
    email: [
      {
        required: true,
        type: "email",
        message: intl.formatMessage({
          id: 'user.validator.email.required',
          defaultMessage: 'Please provide a valid email'
        }),
      },
      duplicateValidator({
        regex: NOT_BLANK_REGEX,
        existingValue: getExistingEmail,
        checkUnique: userByEmail,
        alreadyExistsMessage: intl.formatMessage({
          id: 'user.validator.email.unique',
          defaultMessage: 'A user with this email already exists'
        }),
        errorMessage: intl.formatMessage({
          id: 'user.validator.email.checkUniqueError',
          defaultMessage: 'There was a problem verifying the user email'
        }),
      })
    ],
    name: [
      {
        required: true,
        message: intl.formatMessage({
          id: 'user.validator.name.required',
          defaultMessage: 'Please provide a name'
        }),
      },
      nameLengthValidator
    ]
  });
}
