import { AxiosPromise } from 'axios';

import {  KeySuggestion } from '../types';
import { AXIOS, wrapUniquePromise } from './endpoints';

export function projectKeySuggestion(name: string): AxiosPromise<KeySuggestion> {
  return AXIOS.get('/projectKeySuggestion', { params: { name } });
}

export function projectByName(name: string): Promise<boolean> {
  return wrapUniquePromise(AXIOS.head('/projectByName', {
    params: { name }
  }));
}
