import { FormattedMessage } from 'react-intl';
import { Typography, Box, CircularProgress } from '@mui/material';

const FullScreenLoading = () => (
  <Box
    sx={{
      p: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      flexDirection: "column"
    }}
  >
    <CircularProgress sx={{ m: 4, color: 'text.secondary' }} size={100} />
    <Typography variant="h4" color="textSecondary">
      <FormattedMessage id="components.fullScreenLoading.loading" defaultMessage="Loading" />&hellip;
    </Typography>
  </Box>
);

export default FullScreenLoading;
