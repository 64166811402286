import { FC, useCallback } from "react";

import * as AssignmentApi from '../../api/assignment';
import { UserSummary } from "../../types";
import { NamedAccountAvatar } from "../avatars";

import FilteredAutocomplete, { BasicFilteredAutocompleteProps } from "./FilteredAutocomplete";

interface AssignmentPermissionsAutocompleteProps extends BasicFilteredAutocompleteProps<UserSummary> {
  assignmentKey: string;
}

const fetchOptions = async (assignmentKey: string, filter: string) => {
  const response = await AssignmentApi.getPermissions(assignmentKey, { page: 0, size: 100, filter, exists: false });
  return response.data.results;
};

const renderOption = (user: UserSummary) => <NamedAccountAvatar user={user} showEmail />;
const getOptionSelected = (option: UserSummary, value: UserSummary) => option.key === value.key;
const getOptionLabel = (user: UserSummary) => `${user.name} (${user.email})`;

const AssignmentPermissionsAutocomplete: FC<AssignmentPermissionsAutocompleteProps> = ({ assignmentKey, ...rest }) => {
  const fetchAssignmentPermissions = useCallback((filter: string) => fetchOptions(assignmentKey, filter), [assignmentKey]);
  return (
    <FilteredAutocomplete
      {...rest}
      fetchOptions={fetchAssignmentPermissions}
      renderOption={renderOption}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
    />
  );
};

export default AssignmentPermissionsAutocomplete;
