import { AXIOS } from './endpoints';
import { AxiosPromise } from 'axios';

import { FolderContents, SessionSchema } from '../types';

export function listFolderContents(path: string): AxiosPromise<FolderContents> {
  return AXIOS.get('/sessions', {
    params: { path }
  });
}

export function fetchSessionSchema(path: string): AxiosPromise<SessionSchema> {
  return AXIOS.get('/session', {
    params: { path }
  });
}
