import { FC, useCallback, useEffect, useState } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from "react-intl";

import { Box, Container, LinearProgress, Link, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Typography } from "@mui/material";
import StorageIcon from '@mui/icons-material/Storage';
import SettingsIcon from "@mui/icons-material/Settings";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

import * as DeletionJobApi from '../../../api/deletionJob';
import { extractErrorMessage } from "../../../api/endpoints";
import * as DeletionJobMediaApi from "../../../api/deletionJobMedia";
import { AccountAvatar, ActiveAvatar, AppDecorator, DefaultButton, HeaderBreadcrumb, PaddedPaper } from "../../../components";
import { useErrorBlock } from "../../../contexts/error-block";
import { intl } from "../../../Internationalization";
import { DeletionJobDetail, DeletionJobState, DELETION_JOB_ENTITY_METADATA, DELETION_JOB_STATE_METADATA } from "../../../types";

import { useInterval } from "../../../hooks";
import { dateTimeFormat } from "../../../util";

import DeletionJobSkeleton from "./DeletionJobSkeleton";

const POLL_INTERVAL = 5000;

interface DeletionJobProps {
  deletionJobId: number;
}

const DeletionJob: FC<DeletionJobProps> = ({ deletionJobId }) => {

  const { raiseError } = useErrorBlock();
  const [deletionJob, setDeletionJob] = useState<DeletionJobDetail>();

  const readDeletionJob = useCallback(async () => {
    try {
      const { data } = await DeletionJobApi.fetchDeletionJob(deletionJobId);
      setDeletionJob(data);
    } catch (error: any) {
      raiseError(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'storage.deletionJob.loadError',
          defaultMessage: 'Failed to fetch deletion job',
        })
      ));
    }
  }, [raiseError, deletionJobId]);

  useEffect(() => {
    readDeletionJob();
  }, [readDeletionJob]);

  const pollDeletionJob = () => {
    if (deletionJob?.state === DeletionJobState.RUNNING) {
      readDeletionJob();
    }
  };

  useInterval(pollDeletionJob, POLL_INTERVAL);

  const renderBreadcrumb = () => (
    <HeaderBreadcrumb>
      <Link component={RouterLink} to="/system/storage/deletion_jobs">
        <Typography>
          <StorageIcon />
          Deletion Jobs
        </Typography>
      </Link>
      {
        deletionJob &&
        <Typography>
          <SettingsIcon />
          {DELETION_JOB_ENTITY_METADATA[deletionJob.entity].label} ({deletionJob.entityKey}) @&nbsp;
          {dateTimeFormat(deletionJob.startedAt)}
        </Typography>
      }
    </HeaderBreadcrumb>
  );

  const renderContent = () => {
    if (!deletionJob) {
      return <DeletionJobSkeleton />;
    }

    const StateIcon = DELETION_JOB_STATE_METADATA[deletionJob.state].avatarIcon;
    const { submissionsDeleted, submissionsRemaining } = deletionJob;
    const submissionsTotal = submissionsDeleted + submissionsRemaining;
    const progress = submissionsDeleted / submissionsTotal * 100;
    const createdBy = deletionJob.createEvent.performedBy;

    return (
      <PaddedPaper>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h5" gutterBottom>
              <FormattedMessage id="storage.deletionJob.title" defaultMessage="Deletion Job" />
            </Typography>
          </Box>
          <Box>
            <DefaultButton
              name="downloadLog"
              download
              color="secondary"
              size="small"
              disabled={!deletionJob.log}
              startIcon={<DescriptionOutlinedIcon />}
              href={deletionJob.log && DeletionJobMediaApi.downloadDeletionJobLogFileUri(deletionJob.id, deletionJob.log)}
            >
              <FormattedMessage id="storage.deletionJob.downloadLogButton" defaultMessage="log" />
            </DefaultButton>
          </Box>
        </Box>
        <List>
          <ListItem>
            <ListItemIcon>
              <ActiveAvatar activated="default">
                <SettingsIcon />
              </ActiveAvatar>
            </ListItemIcon>
            <ListItemText
              primary={
                intl.formatMessage(
                  {
                    id: 'storage.deletionJob.details',
                    defaultMessage: '{entity} ({entityKey})'
                  },
                  {
                    entity: DELETION_JOB_ENTITY_METADATA[deletionJob.entity].label,
                    entityKey: deletionJob.entityKey
                  }
                )
              }
              secondary={dateTimeFormat(deletionJob.createEvent.performedAt)}
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <AccountAvatar user={createdBy} />
            </ListItemAvatar>
            <ListItemText
              primary={createdBy.name}
              secondary={createdBy.email}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ActiveAvatar activated={DELETION_JOB_STATE_METADATA[deletionJob.state].activeAvatarState}>
                <StateIcon />
              </ActiveAvatar>
            </ListItemIcon>
            <ListItemText
              primary={
                intl.formatMessage(
                  {
                    id: 'storage.deletionJob.status',
                    defaultMessage: 'State - {state}'
                  },
                  {
                    state: DELETION_JOB_STATE_METADATA[deletionJob.state].label
                  }
                )
              }
            />
          </ListItem>
        </List>
        {
          deletionJob.state === DeletionJobState.RUNNING &&
          <>
            <Typography variant="h6" gutterBottom>
              <FormattedMessage id="storage.deletionJob.processing" defaultMessage="Processing:" />
            </Typography>
            <Box mb={1}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Typography variant="body2" align="center" gutterBottom>
              <FormattedMessage
                id="storage.deletionJob.progress"
                values={{ submissionsDeleted, submissionsTotal }}
                defaultMessage="{submissionsDeleted} of {submissionsTotal} submissions deleted"
              />
            </Typography>
          </>
        }
      </PaddedPaper>
    );
  };

  return (
    <AppDecorator renderTabs={undefined} renderBreadcrumb={renderBreadcrumb}>
      <Container maxWidth="md" disableGutters id="system-storage-deletion-job">
        {renderContent()}
      </Container>
    </AppDecorator>
  );
};

export default DeletionJob;
