import { AXIOS } from './endpoints';
import { AxiosPromise } from 'axios';
import Schema from "async-validator";

import { MailSenderSettings } from '../types/mail';
import { internetAddressValidator, notTrimmableValidator, portValidator } from '../util';
import { intl } from '../Internationalization';

export function getSenderSettings(): AxiosPromise<MailSenderSettings> {
  return AXIOS.get(`mail/sender`);
}

export function updateSenderSettings(senderSettings: MailSenderSettings): AxiosPromise<MailSenderSettings> {
  return AXIOS.post(`mail/sender`, senderSettings);
}

export const SENDER_SETTINGS_VALIDATOR = new Schema({
  from: [
    {
      type: "string",
      required: true,
      message: intl.formatMessage({
        id: 'mail.validator.from.required',
        defaultMessage: 'Please provide an email address'
      })
    },
    notTrimmableValidator,
    internetAddressValidator
  ],
  protocol: {
    type: "string",
    required: true,
    message: intl.formatMessage({
      id: 'mail.validator.protocol.required',
      defaultMessage: 'Please select a protocol'
    })
  },
  host: {
    type: "string",
    required: true,
    message: intl.formatMessage({
      id: 'mail.validator.host.required',
      defaultMessage: 'Please provide the host name or address'
    })
  },
  port: portValidator,
  security: {
    type: "string",
    required: true,
    message: intl.formatMessage({
      id: 'mail.validator.security.required',
      defaultMessage: 'Please select a security option'
    })
  },
});
