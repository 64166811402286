import { FC, useContext } from 'react';
import { Link as RouterLink, Routes, Route, Navigate } from 'react-router-dom';
import { Typography, Link } from '@mui/material';

import AssignmentIcon from '@mui/icons-material/Assignment';
import WorkIcon from '@mui/icons-material/Work';
import BallotIcon from '@mui/icons-material/Ballot';

import { HeaderBreadcrumb, AppDecorator, RouterTab, RouterTabs } from '../../../../../components';
import { intl } from '../../../../../Internationalization';
import { useRouterTab } from '../../../../../hooks';

import { AssignmentContext } from './AssignmentContext';
import Permissions from './Permissions';
import AssignmentConfiguration from './AssignmentConfiguration';

const AssignmentRouting: FC = () => {
  const { project, specification, assignment } = useContext(AssignmentContext);

  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="assignment-tabs" value={routerTab}>
      <RouterTab
        id="settings-tab"
        value="settings"
        label={intl.formatMessage({
          id: 'assignment.routing.tabs.settings',
          defaultMessage: 'Settings'
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="permissions-tab"
        value="permissions"
        label={intl.formatMessage({
          id: 'assignment.routing.tabs.permissions',
          defaultMessage: 'Permissions'
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  const renderBreadcrumb = () => (
    <HeaderBreadcrumb>
      <Link component={RouterLink} to={`/projects/${project.key}/specifications`}>
        <WorkIcon
          titleAccess={intl.formatMessage({
            id: 'assignment.routing.breadcrumb.project.titleAccess',
            defaultMessage: 'Project'
          })}
        />
        {project.name}
      </Link>
      <Link component={RouterLink} to={`/projects/${project.key}/specifications/${specification.key}/assignments`}>
        <BallotIcon
          titleAccess={intl.formatMessage({
            id: 'assignment.routing.breadcrumb.specification.titleAccess',
            defaultMessage: 'Specification'
          })}
        />
        {specification.name}
      </Link>
      <Typography>
        <AssignmentIcon
          titleAccess={intl.formatMessage({
            id: 'assignment.routing.breadcrumb.assignment.titleAccess',
            defaultMessage: 'Assignment'
          })}
        />
        {assignment.reference}
      </Typography>
    </HeaderBreadcrumb>
  );

  return (
    <AppDecorator renderBreadcrumb={renderBreadcrumb} renderTabs={renderTabs}>
      <Routes>
        <Route path="settings" element={<AssignmentConfiguration />} />
        <Route path="permissions" element={<Permissions />} />
        <Route path="*" element={<Navigate to="settings" replace />} />
      </Routes>
    </AppDecorator>
  );
};

export default AssignmentRouting;
