import React from 'react';

export interface AppBarStatsContextValue {
  refresh: () => void;
  openSubmissions: number;
  unreadNotifications: number;
}

const AppBarStatsContextDefaultValue = {} as AppBarStatsContextValue;
export const AppBarStatsContext = React.createContext(
  AppBarStatsContextDefaultValue
);

export interface AppBarStatsContextProps {
  appBarStatsContext: AppBarStatsContextValue;
}

export function withAppBarStatsContext<T extends AppBarStatsContextProps>(Component: React.ComponentType<T>) {
  return class extends React.Component<Omit<T, keyof AppBarStatsContextProps>> {
    render() {
      return (
        <AppBarStatsContext.Consumer>
          {appBarStatsContext => <Component {...this.props as T} appBarStatsContext={appBarStatsContext} />}
        </AppBarStatsContext.Consumer>
      );
    }
  };
}
