import React from "react";

import { AssignmentDetail } from "../../../../../types";
import { SpecificationContextValue } from "../SpecificationContext";

export interface AssignmentContextValue extends SpecificationContextValue {
  assignmentUpdated: (assignment: AssignmentDetail) => void;
  assignmentKey: string;
  assignment: AssignmentDetail;
}

const AssignmentContextDefaultValue = {} as AssignmentContextValue;
export const AssignmentContext = React.createContext(
  AssignmentContextDefaultValue
);
