import React, { FC, useContext } from "react";
import { FormattedMessage } from "react-intl";

import { Divider, ListSubheader } from "@mui/material";

import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleIcon from '@mui/icons-material/People';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkIcon from '@mui/icons-material/Work';

import { intl } from "../../../Internationalization";
import { AuthenticatedContext } from "../../../contexts/authentication";

import SidebarMenuItem from './SidebarMenuItem';

const ReceiverMenuItems: FC = () => {
  const authenticatedContext = useContext(AuthenticatedContext);
  const receiverPermissions = authenticatedContext.me.receiverPermissions;

  if (receiverPermissions) {
    return (
      <>
        <ListSubheader>
          <FormattedMessage id="components.layout.sidebarMenu.sectionTitle.administration" defaultMessage="Administration" />
        </ListSubheader>
        <SidebarMenuItem
          id="navigate-projects"
          icon={WorkIcon}
          label={intl.formatMessage({
            id: 'components.layout.sidebarMenu.item.projects',
            defaultMessage: 'Projects'
          })}
          linkUrl="/projects"
        />
        <SidebarMenuItem
          id="navigate-suppliers"
          icon={LocalShippingIcon}
          label={intl.formatMessage({
            id: 'components.layout.sidebarMenu.item.suppliers',
            defaultMessage: 'Suppliers'
          })}
          linkUrl="/suppliers"
        />
        <SidebarMenuItem
          id="navigate-users"
          icon={PeopleIcon}
          label={intl.formatMessage({
            id: 'components.layout.sidebarMenu.item.users',
            defaultMessage: 'Users'
          })}
          linkUrl="/users"
        />
        <SidebarMenuItem
          id="navigate-dashboard"
          icon={DashboardIcon}
          label={intl.formatMessage({
            id: 'components.layout.sidebarMenu.item.dashboard',
            defaultMessage: 'Dashboard'
          })}
          linkUrl="/dashboard"
        />
        <Divider />
      </>
    );
  }

  return null;
};

export default ReceiverMenuItems;
