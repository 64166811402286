import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text, View } from '@react-pdf/renderer';

import { DataTaskResult } from '../../../../../types';
import { PdfTableRow, PdfTableCell } from '../components';
import { OmittedRowsMessage } from './TaskResultSections';

export const DataTaskHeader: FC = () => (
  <PdfTableRow header>
    <PdfTableCell colSpan="third" header>
      <Text>
        <FormattedMessage id="submission.resultsPdf.dataTaskResult.classHeader" defaultMessage="Class" />
      </Text>
    </PdfTableCell>
    <PdfTableCell colSpan="third" header number>
      <Text>
        <FormattedMessage id="submission.resultsPdf.dataTaskResult.featureHeader" defaultMessage="Features" />
      </Text>
    </PdfTableCell>
    <PdfTableCell colSpan="third" header number>
      <Text>
        <FormattedMessage id="submission.resultsPdf.dataTaskResult.errorHeader" defaultMessage="Errors" />
      </Text>
    </PdfTableCell>
  </PdfTableRow>
);

interface DataTaskResultContentProps {
  task: DataTaskResult;
  hideZeroProcessedRows: boolean;
}

export const DataTaskResultContent: FC<DataTaskResultContentProps> = ({ task: { classes }, hideZeroProcessedRows }) => {
  const filteredRows = hideZeroProcessedRows ? classes.filter(({ processed }) => processed !== 0) : classes;
  const filteredRowCount = classes.length - filteredRows.length;
  return (
    <View>
      {
        filteredRows.map(({ name, processed, errors }) => (
          <PdfTableRow key={name}>
            <PdfTableCell colSpan="third">
              <Text>{name}</Text>
            </PdfTableCell>
            <PdfTableCell colSpan="third" number>
              <Text>
                <FormattedMessage id="submission.resultsPdf.dataTaskResult.featureData" defaultMessage="{processed} features processed" values={{ processed }} />
              </Text>
            </PdfTableCell>
            <PdfTableCell colSpan="third" number>
              <Text>{errors}</Text>
            </PdfTableCell>
          </PdfTableRow>
        ))
      }
      <OmittedRowsMessage omittedRowCount={filteredRowCount} />
    </View>
  );
};
