import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text, View } from '@react-pdf/renderer';

import { resultsPdfStyles } from '../../../../../components';
import { TaskResult, TaskResultKind, SubmissionDetail, TaskState } from '../../../../../types';

import { TaskFilters } from '../ResultPdf';
import { PdfTableCell, PdfTableRow } from '../components';

import { TaskTables } from '.';

interface TaskPanelSectionsProps {
  submission: SubmissionDetail;
  taskFilters: TaskFilters;
}

const TaskResultSections: FC<TaskPanelSectionsProps> = ({ submission, taskFilters }) => {
  const taskResults = submission.taskResults.filter((taskResult) => {
    return !OMITTED_TASKS_KINDS.includes(taskResult.kind) && taskResult.status !== TaskState.SKIPPED;
  });

  const filteredTasks = taskResults.filter((taskResult: TaskResult) => {
    if (taskFilters.hideNotFinished && taskResult.status !== TaskState.FINISHED) {
      return false;
    }

    if (
      taskFilters.hideZeroProcessedTasks && taskResult.status === TaskState.FINISHED &&
      taskResult.processed === 0 && PROCESSING_TASK_KINDS.includes(taskResult.kind)
    ) {
      return false;
    }

    return true;
  });

  const omittedTaskCount = taskResults.length - filteredTasks.length;

  return (
    <>
      <TaskTables submission={submission} tasks={filteredTasks} hideZeroProcessedRows={taskFilters.hideZeroProcessedRows} />
      <OmittedTaskMessage omittedTaskCount={omittedTaskCount} />
    </>
  );
};

export default TaskResultSections;

const OMITTED_TASKS_KINDS = [
  TaskResultKind.PauseTask,
  TaskResultKind.BuildTopologyTask,
  TaskResultKind.DiscoverRulesTask
];

const PROCESSING_TASK_KINDS = [
  TaskResultKind.CheckRulesTask,
  TaskResultKind.ApplyActionTask,
  TaskResultKind.ApplyActionMapTask
];

interface OmittedTaskMessageProps {
  omittedTaskCount: number;
}

const OmittedTaskMessage: FC<OmittedTaskMessageProps> = ({ omittedTaskCount }) => {
  if (!omittedTaskCount) {
    return null;
  }

  return (
    <View>
      <Text style={[resultsPdfStyles.footerSummary, resultsPdfStyles.borderTop]}>
        <FormattedMessage
          id="submission.resultsPdf.omittedTasks"
          defaultMessage="There {count, plural, =1 {was 1 task} other {were # tasks}} omitted from the report."
          values={{
            count: omittedTaskCount
          }}
        />
      </Text>
    </View>
  );
};

interface OmittedRowsMessageProps {
  omittedRowCount: number;
}

export const OmittedRowsMessage: FC<OmittedRowsMessageProps> = ({ omittedRowCount }) => {
  if (!omittedRowCount) {
    return null;
  }

  return (
    <View>
      <PdfTableRow>
        <PdfTableCell>
          <Text>
            <FormattedMessage
              id="submission.resultsPdf.omittedRows"
              defaultMessage="There {count, plural, =1 {was 1 row} other {were # rows}} with no processed features omitted from this task."
              values={{
                count: omittedRowCount
              }}
            />
          </Text>
        </PdfTableCell>
      </PdfTableRow>
    </View>
  );
};
