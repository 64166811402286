import { ElementType } from 'react';
import Button, { ButtonProps, ButtonTypeMap } from '@mui/material/Button';

export default function DefaultButton<
  D extends ElementType = ButtonTypeMap['defaultComponent'],
  P = {}
>({
  children,
  ...buttonProps
}: ButtonProps<D, P>) {
  return (
    <Button variant="contained" {...buttonProps}>
      {children}
    </Button>
  );
}
