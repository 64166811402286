import { AxiosPromise } from 'axios';

import { SuppliersResponse, SuppliersRequest, CreateSupplierRequest, SupplierDetail } from '../types';
import { AXIOS, RequestConfig } from './endpoints';

export function createSupplier(request: CreateSupplierRequest): AxiosPromise<SupplierDetail> {
  return AXIOS.post('/suppliers', request);
}

export function getSuppliers(request: SuppliersRequest, config?: RequestConfig): AxiosPromise<SuppliersResponse> {
  return AXIOS.get('/suppliers', {
    ...config,
    params: request
  });
}
