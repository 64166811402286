import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { pdf } from '@react-pdf/renderer';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
  Accordion, AccordionSummary, Paper, AccordionDetails, TableHead,
  Table, TableCell, TableRow, TableBody, Grid, Tooltip, Typography,
} from '@mui/material';
import TimelapseIcon from '@mui/icons-material/Timelapse';

import * as SubmissionAPI from '../../../api/submission';
import { extractErrorMessage } from '../../../api/endpoints';
import { MessageBox, IconBox, SubmissionOutcomeIcon, DefaultButton, MinWidthTableCell, TableInfoRow } from '../../../components';
import { WarningIcon, ErrorIcon, SuccessIcon, TimerWarningIcon, PassedIcon, FailedIcon } from '../../../components/icons';
import {
  TaskResult, TaskResultKind, CheckRulesTaskResult, ApplyActionMapTaskResult, ApplyActionTaskResult, TaskState, DataTaskResult,
  taskResultMetaData, SubmissionDetail, TaskConformance, SubmissionState, SubmissionOutcome, TaskResultBase, taskResultLabel,
  ValidateSchemaTaskResult, SchemaReport,
} from '../../../types';

import { milliesToTimeFormat } from '../../../util';
import { intl } from '../../../Internationalization';

import { getTaskStateMetadata, getPercentageRemainder, substringRuleNameFromPath } from './taskResultUtils';
import SchemaValidationReportPdf from './pdf/SchemaValidationReportPdf';

const requiresAccordion = (task: TaskResult) => {
  switch (task.kind) {
    case TaskResultKind.PauseTask:
    case TaskResultKind.BuildTopologyTask:
    case TaskResultKind.DiscoverRulesTask:
      return false;
    case TaskResultKind.CopyToTask:
    case TaskResultKind.CommitTask:
    case TaskResultKind.OpenDataTask:
      if (task.classes && task.classes.length > 0) {
        return true;
      }
      return false;
    case TaskResultKind.CheckRulesTask:
      if (task.rules && task.rules.length > 0) {
        return true;
      }
      return false;
    case TaskResultKind.ApplyActionMapTask:
      if (task.actionmaps && task.actionmaps.length > 0) {
        return true;
      }
      return false;
    case TaskResultKind.ApplyActionTask:
      if (task.actions && task.actions.length > 0) {
        return true;
      }
      return false;
    case TaskResultKind.ValidateSchemaTask:
      return true;
  }
};

const renderTaskPanelDetails = (task: TaskResult, submission: SubmissionDetail, taskConformance?: TaskConformance) => {
  switch (task.kind) {
    case TaskResultKind.PauseTask:
    case TaskResultKind.BuildTopologyTask:
    case TaskResultKind.DiscoverRulesTask:
      return null;
    case TaskResultKind.CopyToTask:
    case TaskResultKind.CommitTask:
    case TaskResultKind.OpenDataTask:
      return (
        <DataTaskResultContent task={task} />
      );
    case TaskResultKind.CheckRulesTask:
      return (
        <CheckRulesTaskResultContent task={task} />
      );
    case TaskResultKind.ApplyActionMapTask:
      return (
        <ApplyActionMapTaskResultContent task={task} taskConformance={taskConformance} />
      );
    case TaskResultKind.ApplyActionTask:
      return (
        <ApplyActionTaskResultContent task={task} />
      );
    case TaskResultKind.ValidateSchemaTask:
      return (
        <ValidateSchemaTaskResultContent task={task} submission={submission} />
      );
  }
};

const taskPanelDisabled = (state: TaskState) => state === TaskState.NOT_STARTED;

interface AccordionContentProps {
  task: TaskResult;
  taskConformance?: TaskConformance;
  outcome?: SubmissionOutcome;
}

const AccordionContent: FC<AccordionContentProps> = ({ task, taskConformance, outcome }) => {
  const TaskIcon = taskResultMetaData(task).icon;
  const taskState = getTaskStateMetadata(task, taskConformance);
  const TaskStateIcon = taskState.icon;

  return (
    <Grid container spacing={1} className="task-result-header">
      <Grid item xs={3}>
        <IconBox>
          <TaskIcon fontSize="small" />&nbsp;{taskResultLabel(task)}
        </IconBox>
      </Grid>
      <Grid item xs={4}>
        <IconBox>
          {
            (task.status === TaskState.RUNNING && !outcome) ?
              <CircularProgress size={20} color="secondary" /> :
              <TaskStateIcon fontSize="small" />
          }&nbsp;{taskState.label}
        </IconBox>
      </Grid>
      {taskConformance && (
        <Grid item xs={4}>
          <IconBox>
            {
              taskConformance.passed ?
                <PassedIcon
                  fontSize="small"
                  titleAccess={intl.formatMessage({
                    id: 'submission.taskResults.taskSummary.taskPassed.titleAccess',
                    defaultMessage: 'Task conformance threshold passed'
                  })}
                /> :
                <FailedIcon
                  fontSize="small"
                  titleAccess={intl.formatMessage({
                    id: 'submission.taskResults.taskSummary.taskNotPassed.titleAccess',
                    defaultMessage: 'Task conformance threshold not passed'
                  })}
                />
            }
            <FormattedMessage
              id="submission.taskResults.taskSummary.taskConformance"
              defaultMessage="{conformancePercentage}% / {passThreshold}% required"
              values={{ conformancePercentage: taskConformance.conformancePercentage, passThreshold: taskConformance.passThreshold }}
            />
          </IconBox>
        </Grid>
      )}
    </Grid>
  );
};

interface TaskResultsSummaryIconProps {
  submission: SubmissionDetail;
}

const TaskResultsSummaryIcon: FC<TaskResultsSummaryIconProps> = ({ submission }) => {
  if (submission.outcome) {
    return (
      <SubmissionOutcomeIcon submission={submission} size="small" />
    );
  }

  if (submission.state === SubmissionState.PAUSED || submission.state === SubmissionState.PROCESSED) {
    return (
      <TimerWarningIcon
        fontSize="small"
        titleAccess={intl.formatMessage({
          id: 'submission.taskResults.taskSummary.state.paused.titleAccess',
          defaultMessage: 'Task paused'
        })}
      />
    );
  }

  return (
    <CircularProgress
      size={20}
      color="secondary"
      aria-label={intl.formatMessage({
        id: 'submission.taskResults.taskSummary.state.inProgress.titleAccess',
        defaultMessage: 'Task in progress'
      })}
    />
  );
};

interface TaskResultProps {
  submission: SubmissionDetail;
}

const TaskResults: React.FC<TaskResultProps> = ({ submission }) => {
  const { taskResults, state, outcome } = submission;
  const { finishedCount, total } = aggregateTaskCounts(taskResults);

  if (!taskResults.length) {
    return (
      <MessageBox
        message={intl.formatMessage({ id: 'submission.taskResults.noTasks', defaultMessage: 'There are no tasks' })}
        level="info"
      />
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper
          sx={{
            pr: 5,
            pl: 2,
            minHeight: 48,
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            borderRadius: 0,
          }}
        >
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={3}>
              <IconBox>
                <TimelapseIcon fontSize="small" />
                {
                  state === SubmissionState.FINISHED ?
                    intl.formatMessage({
                      id: 'submission.taskResults.processingTime',
                      defaultMessage: 'Processing time: {time}'
                    }, { time: milliesToTimeFormat(taskResults.map(({ duration }) => duration).reduce((a, b) => a + b, 0)) }) :
                    intl.formatMessage({
                      id: 'submission.taskResults.processing',
                      defaultMessage: 'Processing submission'
                    })
                }
              </IconBox>
            </Grid>
            <Grid item xs={4}>
              <IconBox>
                <TaskResultsSummaryIcon submission={submission} />
                <FormattedMessage id="submission.taskResults.processedCount" defaultMessage="Processed {finishedCount} of {total} tasks" values={{ finishedCount, total }} />
              </IconBox>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {taskResults.filter((task, index) => index < taskResults.length - 1).map((task) => {
          const accordionDetails = requiresAccordion(task);
          const disabled = taskPanelDisabled(task.status);

          if (accordionDetails) {
            const taskConformance = submission.taskConformance.find(({ taskIdentifier }) => taskIdentifier === task.taskIdentifier);
            return (
              <Accordion key={task.taskIdentifier} disabled={disabled} className={`task-result task-type-${task.kind}`}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      titleAccess={intl.formatMessage({
                        id: 'submission.taskResults.expandResult.titleAccess',
                        defaultMessage: 'Expand task result'
                      })}
                    />
                  }
                  sx={taskConformance && { bgcolor: taskConformance.passed ? 'highlight.success' : 'highlight.failure' }}
                >
                  <AccordionContent task={task} taskConformance={taskConformance} outcome={outcome} />
                </AccordionSummary>
                <AccordionDetails className="task-result-detail">
                  {renderTaskPanelDetails(task, submission, taskConformance)}
                </AccordionDetails>
              </Accordion>
            );
          }

          return (
            <Paper
              key={task.taskIdentifier}
              className="TaskResults-taskResult"
              sx={[{
                pr: 5,
                pl: 2,
                minHeight: 48,
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                borderRadius: 0,
                '&:before': {
                  content: '""',
                  top: -1,
                  left: 0,
                  right: 0,
                  height: '1px',
                  position: 'absolute',
                  backgroundColor: ' rgba(0, 0, 0, 0.12)',
                },
              },
              disabled ? {
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
                color: 'rgba(0, 0, 0, 0.37)'
              } : {}
              ]}
            >
              <AccordionContent task={task} outcome={outcome} />
            </Paper>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default TaskResults;

const aggregateTaskCounts = (tasks: TaskResult[]) => {
  const finishedCount = tasks.map(({ status }) => status).filter((status) => status === TaskState.FINISHED).length;

  return {
    finishedCount,
    total: tasks.length - 1
  };
};

interface TaskResultContentProps<T extends TaskResultBase> {
  task: T;
}

const DataTaskResultContent: FC<TaskResultContentProps<DataTaskResult>> = ({ task: { classes } }) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>
          <FormattedMessage id="submission.taskResults.dataTaskResult.classHeader" defaultMessage="Class" />
        </TableCell>
        <TableCell>
          <FormattedMessage id="submission.taskResults.dataTaskResult.featuresHeader" defaultMessage="Features" />
        </TableCell>
        <TableCell>
          <FormattedMessage id="submission.taskResults.dataTaskResult.errorsHeader" defaultMessage="Errors" />
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {classes.map(({ name, errors, processed }) => (
        <TableRow key={name}>
          <TableCell>{name}</TableCell>
          <TableCell>
            <FormattedMessage
              id="submission.taskResults.dataTaskResult.featuresData"
              defaultMessage="{processed} features processed"
              values={{ processed }}
            />
          </TableCell>
          <TableCell>{errors}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const renderPathToolTip = (path: string) => (
  <Tooltip title={path}>
    <Typography variant="body1">
      {
        substringRuleNameFromPath(path)
      }
    </Typography>
  </Tooltip>
);

interface RuleValidityCellProps {
  count: number;
  errors: number;
  processed: number;
}

const RuleValidityCell: FC<RuleValidityCellProps> = ({ count, errors, processed }) => (
  <TableCell>
    <IconBox>
      <RuleResultIcon
        count={count}
        errors={errors}
      />&nbsp;
      <FormattedMessage
        id="submission.taskResults.applyActionMapTaskResult.resultData"
        defaultMessage="{valid} / {processed} features valid ({percentage}%)"
        values={{
          valid: processed - count,
          processed,
          percentage: getPercentageRemainder(count, processed).toFixed(2)
        }}
      />
    </IconBox>
  </TableCell>
);

const RuleProcessedCell: FC<RuleValidityCellProps> = ({ count, errors, processed }) => (
  <TableCell>
    <IconBox>
      <RuleResultIcon
        count={count}
        errors={errors}
      />&nbsp;
      <FormattedMessage
        id="submission.taskResults.applyActionMapTaskResult.processedResult"
        defaultMessage="{valid} / {processed} features processed ({percentage}%)"
        values={{
          valid: processed - count,
          processed,
          percentage: getPercentageRemainder(count, processed).toFixed(2)
        }}
      />
    </IconBox>
  </TableCell>
);

interface ApplyActionMapTaskResultContentProps extends TaskResultContentProps<ApplyActionMapTaskResult> {
  taskConformance?: TaskConformance;
}

const ApplyActionMapTaskResultContent: FC<ApplyActionMapTaskResultContentProps> = ({ task: { actionmaps }, taskConformance }) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>
          <FormattedMessage
            id="submission.taskResults.applyActionMapTaskResult.ruleHeader"
            defaultMessage="Rule"
          />
        </TableCell>
        <TableCell>
          <FormattedMessage
            id="submission.taskResults.applyActionMapTaskResult.actionHeader"
            defaultMessage="Action"
          />
        </TableCell>
        <TableCell>
          <FormattedMessage
            id="submission.taskResults.applyActionMapTaskResult.resultHeader"
            defaultMessage="Result"
          />
        </TableCell>
        <TableCell>
          <FormattedMessage
            id="submission.taskResults.applyActionMapTaskResult.errorsHeader"
            defaultMessage="Errors"
          />
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {actionmaps.map(({ ruleSummary, actionSummary }, index) => (
        <TableRow key={index}>
          <TableCell>{renderPathToolTip(ruleSummary.path)}</TableCell>
          <TableCell>{renderPathToolTip(actionSummary.path)}</TableCell>
          {

            taskConformance ?
              <RuleValidityCell count={ruleSummary.count} errors={ruleSummary.errors} processed={ruleSummary.processed} /> :
              <RuleProcessedCell count={ruleSummary.count} errors={ruleSummary.errors} processed={ruleSummary.processed} />
          }
          <TableCell>{ruleSummary.errors}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const ApplyActionTaskResultContent: FC<TaskResultContentProps<ApplyActionTaskResult>> = ({ task: { actions } }) => (
  <Grid container>
    <Grid item xs={12}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="submission.taskResults.applyActionTaskResult.actionHeader"
                defaultMessage="Action"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="submission.taskResults.applyActionTaskResult.processedHeader"
                defaultMessage="Processed"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="submission.taskResults.applyActionTaskResult.errorsHeader"
                defaultMessage="Errors"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actions.map(action => (
            <TableRow key={action.path}>
              <TableCell>{renderPathToolTip(action.path)}</TableCell>
              <TableCell>{action.processed}</TableCell>
              <TableCell>{action.errors}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  </Grid>
);

interface ValidateSchemaTaskResultContentProps extends TaskResultContentProps<ValidateSchemaTaskResult> {
  submission: SubmissionDetail;
}

const ValidateSchemaTaskResultContent: FC<ValidateSchemaTaskResultContentProps> = ({ task, submission }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [processing, setProcessing] = useState<boolean>(false);
  const [schemaReport, setSchemaReport] = useState<SchemaReport>();

  useEffect(() => {
    const fetchSchemaReport = async () => {
      if (task.status === TaskState.FINISHED) {
        setProcessing(true);
        try {
          const { data: schemaReportData } = await SubmissionAPI.schemaReport(submission.reference, task.taskIdentifier);
          setSchemaReport(schemaReportData);
        } catch (error: any) {
          if (axios.isAxiosError(error) && error.response?.status === 404) {
            return;
          }
          enqueueSnackbar(extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'submission.taskResults.validateSchemaTaskResult.fetchError',
              defaultMessage: 'Failed to fetch schema validation report'
            })
          ), { variant: "error" });
        } finally {
          setProcessing(false);
        }
      }
    };

    fetchSchemaReport();
  }, [enqueueSnackbar, submission.reference, task.taskIdentifier, task.status]);

  const downloadReportPdf = async () => {
    if (!schemaReport) {
      return;
    }
    setProcessing(true);
    try {
      const blob = await pdf(
        <SchemaValidationReportPdf submission={submission} schemaReport={schemaReport} task={task} />
      ).toBlob();
      saveAs(
        blob,
        intl.formatMessage({
          id: 'submission.taskResults.validateSchemaTaskResult.pdfFilename',
          defaultMessage: 'Validate Schema Report - {assignmentReference}.pdf'
        }, { assignmentReference: submission.assignment.reference })
      );
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'submission.taskResults.validateSchemaTaskResult.downloadReportError',
          defaultMessage: 'Failed to download schema validation report PDF'
        })
      ), { variant: "error" });
    } finally {
      setProcessing(false);
    }
  };

  const renderTableContent = () => {
    if (!schemaReport) {
      return (
        <TableInfoRow
          size="medium"
          colSpan={5}
          message={intl.formatMessage({
            id: "submission.taskResults.validateSchemaTaskResult.resultsNotAvailable",
            defaultMessage: "Validation result not available"
          })}
        />
      );
    }

    return (
      <TableRow>
        <TableCell align="right">
          {task.processed}
        </TableCell>
        <TableCell align="right">
          <Tooltip
            title={schemaReport.missingClasses.join(', ')}
          >
            <span>
              {schemaReport.missingClasses.length}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell align="right">
          <Tooltip title={schemaReport.excessClasses.join(', ')}>
            <span>
              {schemaReport.excessClasses.length}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell align="right">
          <Tooltip
            title={schemaReport.attributeMismatches.map((invalidAttribute) => invalidAttribute.className).join(', ')}
          >
            <span>
              {schemaReport.attributeMismatches.length}
            </span>
          </Tooltip>
        </TableCell>
        <MinWidthTableCell>
          <DefaultButton
            onClick={downloadReportPdf}
            disabled={processing}
            size="small"
            startIcon={<CloudDownloadIcon />}
          >
            <FormattedMessage
              id="submission.taskResults.validateSchemaTaskResult.downloadReportButton"
              defaultMessage="Report"
            />
          </DefaultButton>
        </MinWidthTableCell>
      </TableRow>
    );
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="right">
            <FormattedMessage
              id="submission.taskResults.validateSchemaTaskResult.processedHeader"
              defaultMessage="Processed"
            />
          </TableCell>
          <TableCell align="right">
            <FormattedMessage
              id="submission.taskResults.validateSchemaTaskResult.missingHeader"
              defaultMessage="Missing Classes"
            />
          </TableCell>
          <TableCell align="right">
            <FormattedMessage
              id="submission.taskResults.validateSchemaTaskResult.excessHeader"
              defaultMessage="Excess Classes"
            />
          </TableCell>
          <TableCell align="right">
            <FormattedMessage
              id="submission.taskResults.validateSchemaTaskResult.invalidHeader"
              defaultMessage="Invalid Classes"
            />
          </TableCell>
          <MinWidthTableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {renderTableContent()}
      </TableBody>
    </Table>
  );
};

interface RuleResultIconProps {
  count: number;
  errors: number;
}

const RuleResultIcon: FC<RuleResultIconProps> = ({ count, errors }) => {
  if (errors > 0) {
    return (
      <ErrorIcon fontSize="small" />);
  }

  if (count > 0) {
    return (
      <WarningIcon fontSize="small" />
    );
  }

  return (
    <SuccessIcon fontSize="small" />
  );
};

const CheckRulesTaskResultContent: FC<TaskResultContentProps<CheckRulesTaskResult>> = ({ task: { rules } }) => (
  <Grid container>
    <Grid item xs={12}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="submission.taskResults.checkRulesTaskResult.ruleHeader"
                defaultMessage="Rule"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="submission.taskResults.checkRulesTaskResult.resultHeader"
                defaultMessage="Result"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="submission.taskResults.checkRulesTaskResult.errorsHeader"
                defaultMessage="Errors"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rules.map(({ path, count, errors, processed }) => (
            <TableRow key={path}>
              <TableCell>{renderPathToolTip(path)}</TableCell>
              <RuleValidityCell count={count} errors={errors} processed={processed} />
              <TableCell>{errors}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  </Grid>
);
