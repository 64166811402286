/*
* Keys for these enums map to folder item types in the 1Integrate REST API which are not fully documented.
* For the possible values, see com.onespatial.radius.studio.controller.folder.FolderItemDTO#summariseType.
*/
export enum FolderItemType {
  GEOMETRY_DATASTORE = "geometry_datastore",
  METADATA_STORE = "metadata_store",
  RULE_TEMPLATE = "rule_template",
  ACTION_TEMPLATE = "action_template",
  RUNNING_SESSION = "running_session",
  PAUSED_SESSION = "paused_session",
  PARTITIONED_SESSION = "partitioned_session",
  FINISHED_SESSION = "finished_session",
  FOLDER = "folder",
  ACTION = "action",
  RULE = "rule",
  DATASTORE = "datastore",
  ACTION_MAP = "action_map",
  DISCOVERY_SPEC = "discovery_spec",
  SESSION = "session",
  SESSION_TEST = "session_test"
}

export interface FolderItem {
  name: string;
  type: FolderItemType;
  isEmpty: boolean;
}

export interface FolderContents {
  description: string;
  comments: string;
  created: number;
  createdBy: string;
  updated: number;
  updatedBy: string;
  contents: FolderItem[];
}
