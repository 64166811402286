import React, { FC } from 'react';

import { UserProfile } from '../../types';
import * as UserMediaApi from '../../api/userMedia';

import ImageAvatar, { ImageAvatarProps } from './ImageAvatar';

interface AccountAvatarProps extends Omit<ImageAvatarProps, "label" | "color"> {
  user: UserProfile;
}

const AccountAvatar: FC<AccountAvatarProps> = ({ user: { name, key, avatar: { color, image } }, ...rest }) => (
  <ImageAvatar
    label={name}
    alt={name}
    color={color}
    src={image && UserMediaApi.downloadImageUri(key, image)}
    {...rest}
  />
);

export default AccountAvatar;
