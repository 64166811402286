import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { TableRow, TableCell, FormControlLabel, Checkbox, Typography } from '@mui/material';

import { TaskConfig, SessionSchema, Task, PauseConfig } from '../../../../../../types';
import TaskLabelCell from './TaskLabelCell';

interface PauseTaskRowProps {
  task: Task;
  taskConfig: PauseConfig;
  sessionSchema: SessionSchema;
  updateTaskConfig: (taskConfig: TaskConfig) => void;
}

const PauseTaskRow: FC<PauseTaskRowProps> = ({ task, taskConfig, sessionSchema, updateTaskConfig }) => {
  return (
    <TableRow>
      <TaskLabelCell task={task} sessionSchema={sessionSchema} />
      <TableCell colSpan={3}>
        <FormControlLabel
          control={
            <Checkbox
              checked={taskConfig.enforcePassThresholds}
              onChange={() => {
                updateTaskConfig({ ...taskConfig, enforcePassThresholds: !taskConfig.enforcePassThresholds });
              }}
            />
          }
          label={
            <Typography variant="body2">
              <FormattedMessage id="specification.configuration.taskConfig.passThreshold.label" defaultMessage="Require all previous tasks to pass" />
            </Typography>
          }
        />
      </TableCell>
    </TableRow>
  );
};

export default PauseTaskRow;
