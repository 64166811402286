import { AxiosPromise } from 'axios';
import Schema from 'async-validator';

import { AXIOS, FileUploadConfig } from './endpoints';
import { Saml2IdentityProviderSettings, Saml2IdentityProviderDetail, CreateSaml2IdentityProviderRequest } from '../types';
import { createFormDataFromFile } from './media';
import { absoluteHttpUriValidator } from '../util';
import { intl } from '../Internationalization';

export function createIdentityProvider(request: CreateSaml2IdentityProviderRequest): AxiosPromise<Saml2IdentityProviderDetail> {
  return AXIOS.post('/identity_providers/saml2', request);
}

export function getIdentityProvider(identityProviderKey: string): AxiosPromise<Saml2IdentityProviderDetail> {
  return AXIOS.get(`/identity_providers/saml2/${identityProviderKey}`);
}

export function updateIdentityProvider(
  identityProviderKey: string, request: Saml2IdentityProviderSettings
): AxiosPromise<Saml2IdentityProviderDetail> {
  return AXIOS.post(`/identity_providers/saml2/${identityProviderKey}`, request);
}

export function deleteIdentityProvider(identityProviderKey: string): AxiosPromise<void> {
  return AXIOS.delete(`/identity_providers/saml2/${identityProviderKey}`);
}

export function regenerateKeys(identityProviderKey: string): AxiosPromise<Saml2IdentityProviderDetail> {
  return AXIOS.post(`/identity_providers/saml2/${identityProviderKey}/regenerate_keys`);
}

export function updateIdpCertificate(
  identityProviderKey: string, file: File, config: FileUploadConfig
): AxiosPromise<Saml2IdentityProviderDetail> {
  return AXIOS.post(`/identity_providers/saml2/${identityProviderKey}/idp_certificate`, createFormDataFromFile(file), {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    ...(config || {})
  });
}

export const SAML2_IDENTITY_PROVIDER_SERVICE_SETTINGS_VALIDATOR = new Schema({
  entityId: [{
    required: true,
    message: intl.formatMessage({
      id: 'saml2.validator.entityId.required',
      defaultMessage: 'Please provide an entity id'
    })
  }],
  ssoServiceLocation: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'saml2.validator.ssoServiceLocation.required',
        defaultMessage: 'Please provide an SSO service location'
      })
    },
    absoluteHttpUriValidator
  ]
});
