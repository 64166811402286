import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { Divider, List, ListSubheader } from "@mui/material";

import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PersonIcon from '@mui/icons-material/Person';

import { intl } from "../../../Internationalization";

import SidebarMenuItem from './SidebarMenuItem';
import ReceiverMenuItems from './ReceiverMenuItems';
import ReceiverAdminMenuItems from './ReceiverAdminMenuItems';

const SidebarMenu: FC = () => (
  <List disablePadding>
    <ListSubheader>
      <FormattedMessage id="components.layout.sidebarMenu.sectionTitle.activities" defaultMessage="Activities" />
    </ListSubheader>
    <SidebarMenuItem
      id="navigate-my-assignments"
      icon={AssignmentIcon}
      label={intl.formatMessage({
        id: 'components.layout.sidebarMenu.item.myAssignments',
        defaultMessage: 'My Assignments'
      })}
      linkUrl="/my_assignments"
    />
    <SidebarMenuItem
      id="navigate-submissions"
      icon={AssessmentIcon}
      label={intl.formatMessage({
        id: 'components.layout.sidebarMenu.item.submissions',
        defaultMessage: 'Submissions'
      })}
      linkUrl="/submissions"
    />
    <Divider />
    <ReceiverMenuItems />
    <ListSubheader>
      <FormattedMessage id="components.layout.sidebarMenu.sectionTitle.settings" defaultMessage="Settings" />
    </ListSubheader>
    <SidebarMenuItem
      id="navigate-account"
      icon={PersonIcon}
      label={intl.formatMessage({
        id: 'components.layout.sidebarMenu.item.myAccount',
        defaultMessage: 'My Account'
      })}
      linkUrl="/account"
    />
    <ReceiverAdminMenuItems />
    <Divider />
  </List>
);

export default SidebarMenu;
