import { PageResponse, SimplePageRequest } from "./shared";
import { Avatar } from "./avatar";
import { AccountEventSummary } from "./accountEvent";

export interface UsersRequest extends SimplePageRequest {
  filter?: string;
  receiver?: boolean;
  activated?: boolean;
  suspended?: boolean;
  includeAuxiliaryUser?: boolean;
}

export enum UserType {
  LOCAL = "LOCAL",
  LDAP = "LDAP",
  AUXILIARY = "AUXILIARY"
}

export const isLdapUser = (user: UserProfile) => {
  return user.type === UserType.LDAP;
};

export interface UserProfile {
  key: string;
  type: UserType;
  name: string;
  email: string;
  locale?: UserLocale;
  avatar: Avatar;
}

export interface UserProfileRequest {
  name: string;
  email: string;
  locale?: string;
}

export interface CreateUserRequest {
  name: string;
  email: string;
  activate: boolean;
}

export interface UserSummary extends UserProfile {
  receiverPermissions?: ReceiverPermissionsDetail;
  created: AccountEventSummary;
  activated?: AccountEventSummary;
  suspended?: AccountEventSummary;
  loggedIn?: AccountEventSummary;
}

export interface ReceiverPermissionsRequest {
  admin: boolean;
}

export interface ReceiverPermissionsDetail {
  admin: boolean;
}

export interface UserDetail extends UserSummary {
}

export interface UsersResponse extends PageResponse<UserSummary> {
}

export interface PasswordResetLink {
  link: string;
}

export interface ActivationLink {
  link: string;
}

export const USER_LOCALES = ['en-US', 'en-GB', 'es-ES', 'cy-GB'] as const;
export type UserLocale = typeof USER_LOCALES[number];

interface UserLocaleMetadata {
  label: string;
}

export const USER_LOCALE_METADATA: Record<UserLocale, UserLocaleMetadata> = {
  'en-US': { label: "English (US)" },
  'en-GB': { label: "English (UK)" },
  'es-ES': { label: "Español (España)" },
  'cy-GB': { label: "Cymraeg (UK)" }
};
