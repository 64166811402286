import React, { PropsWithChildren, useRef, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { ValidateFieldsError } from 'async-validator';

import {
  Autocomplete, AutocompleteRenderOptionState, Box, TextFieldProps, CircularProgress, TextField, FormHelperText,
  SxProps, SelectProps
} from '@mui/material';
import { FilterOptionsState } from '@mui/material/useAutocomplete';

import { extractErrorMessage } from '../../api/endpoints';
import { calculateTooltipVariantTopOffset, InputTooltip } from '../';

export interface BasicFilteredAutocompleteProps<T> {
  id?: string;
  value: T | null;
  onChange: (value: T | null) => void;
  className?: string;
  label: string;
  name: string;
  fieldErrors?: ValidateFieldsError;
  margin?: TextFieldProps['margin'];
  variant?: TextFieldProps['variant'];
  disabled?: boolean;
  filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[];
  tooltip?: string;
  fullWidth?: boolean;
  autoDropdownWidth?: SelectProps['autoWidth'];
  sx?: SxProps;
}

export interface FilteredAutocompleteProps<T, C extends T> extends BasicFilteredAutocompleteProps<T> {
  fetchOptions: (filter: string) => Promise<C[]>;
  renderOption: (option: T, state: AutocompleteRenderOptionState) => React.ReactNode;
  getOptionSelected: (option: T, value: T) => boolean;
  getOptionLabel: (option: T) => string;
}

function FilteredAutocomplete<T, C extends T>(props: PropsWithChildren<FilteredAutocompleteProps<T, C>>) {
  const {
    id, value, onChange, className, label, name, fieldErrors, margin, variant, disabled, tooltip, fullWidth,
    fetchOptions, renderOption, getOptionSelected, filterOptions, getOptionLabel, sx, autoDropdownWidth
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const errors = fieldErrors && fieldErrors[name];

  const renderErrors = () => {
    return errors?.map((e, i) => (<FormHelperText key={i} data-error-for={name}>{e.message}</FormHelperText>));
  };

  const loadFunction = useRef(fetchOptions);

  const loadOptions = useRef(
    (async (query: string, updateOptions: (response: C[]) => void) => {
      setLoading(true);
      try {
        updateOptions(await loadFunction.current(query));
      } catch (error: any) {
        enqueueSnackbar(extractErrorMessage(error, 'Failed to fetch autocomplete data'), { variant: 'error' });
      } finally {
        setLoading(false);
      }
    }));

  const debouncedLoader = useRef(
    debounce(loadOptions.current, 300)
  );

  useEffect(() => {
    setSearchValue('');
  }, [fetchOptions]);

  useEffect(() => {
    let active = true;

    const updateOptions = (response: C[]) => {
      if (active) {
        setOptions(response);
      }
    };

    loadFunction.current = fetchOptions;
    debouncedLoader.current(searchValue, updateOptions);

    return () => {
      active = false;
    };
  }, [searchValue, fetchOptions]);

  const autocomplete = (
    <Autocomplete
      id={id}
      value={value}
      className={className}
      open={open}
      filterOptions={filterOptions ? filterOptions : (x) => x}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setSearchValue(newInputValue);
      }}
      onOpen={() => {
        setOpen(true);
        loadOptions.current(searchValue, setOptions);
      }}
      onClose={() => {
        setOpen(false);
        setSearchValue('');
        setOptions([]);
      }}
      isOptionEqualToValue={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      disabled={disabled}
      fullWidth={fullWidth}
      componentsProps={{
        paper: autoDropdownWidth ? {
          sx: { width: "fit-content" }
        } : {}
      }}
      renderInput={(params) => (
        <TextField
          error={!!errors}
          name={name}
          {...params}
          label={label}
          variant={variant}
          margin={margin}
          sx={sx}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(optionProps, v, s) => (
        <Box component="li" {...optionProps}>
          {renderOption(v, s)}
        </Box>
      )}
    />
  );

  const renderContent = () => {
    if (tooltip) {
      return (
        <InputTooltip
          title={tooltip}
          ml={2}
          mt={calculateTooltipVariantTopOffset(margin, variant)}
        >
          {autocomplete}
        </InputTooltip>
      );
    }

    return autocomplete;
  };

  return (
    <>
      {renderContent()}
      {renderErrors()}
    </>
  );
}

export default FilteredAutocomplete;
