import { FC, PropsWithChildren } from "react";
import { FormattedMessage } from "react-intl";

import { Box, Table, TableBody, TableCell, TableRow } from "@mui/material";

import { StyledTableHead } from "../../../../../components";

const CopySavedMappingTable: FC<PropsWithChildren> = ({ children }) => (
  <Box maxHeight={300} overflow="auto">
    <Table size="small">
      <StyledTableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage id="myAssignment.savedMapping.copy.mappingTable.mappingDataSetColumn" defaultMessage="Mapping Data Set (copy from)" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="myAssignment.savedMapping.copy.mappingTable.assignmentDataSetColumn" defaultMessage="Assignment Data Set (copy to)" />
          </TableCell>
        </TableRow>
      </StyledTableHead>
      <TableBody>
        {children}
      </TableBody>
    </Table>
  </Box>
);

export default CopySavedMappingTable;
