import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Dialog, DialogTitle, Tab, Tabs, Divider } from '@mui/material';

import { intl } from '../../../../../Internationalization';
import NewAssignmentForm from './NewAssignmentForm';
import CopyAssignmentsForm from './CopyAssignmentsForm';

type AddAssignmentDialogTabs = 'SINGLE' | 'COPY';

interface AddAssignmentDialogProps {
  onClose: () => void;
  onRefreshAssignments: () => void;
}

const AddAssignmentDialog: FC<AddAssignmentDialogProps> = ({ onClose, onRefreshAssignments }) => {
  const [tab, setTab] = useState<AddAssignmentDialogTabs>('SINGLE');
  return (
    <Dialog id="add-assignment-dialog" onClose={onClose} aria-labelledby="add-assignment-dialog-title" open={true} fullWidth>
      <DialogTitle id="add-assignment-dialog-title">
        <FormattedMessage id="assignment.create.title" defaultMessage="Add Assignment" />
      </DialogTitle>
      <Divider />
      <Tabs
        value={tab}
        onChange={(_, newTab) => setTab(newTab)}
        variant="fullWidth"
      >
        <Tab
          label={intl.formatMessage({
            id: 'assignment.add.tabs.singleAssignment',
            defaultMessage: 'Single Assignment'
          })}
          value="SINGLE"
        />
        <Tab
          label={intl.formatMessage({
            id: 'assignment.add.tabs.copyAssignments',
            defaultMessage: 'Copy Assignments'
          })}
          value="COPY"
        />
      </Tabs>
      {
        tab === 'SINGLE' ?
          <NewAssignmentForm onClose={onClose} /> :
          <CopyAssignmentsForm onClose={onClose} onRefreshAssignments={onRefreshAssignments} />
      }
    </Dialog>
  );
};

export default AddAssignmentDialog;
