import { FC } from 'react';

import { extractInitials } from '../../util';

import ColorAvatar, { ColorAvatarProps } from './ColorAvatar';

export interface ImageAvatarProps extends Omit<ColorAvatarProps, "variant"> {
  label: string;
  logo?: boolean;
}

const ImageAvatar: FC<ImageAvatarProps> = ({ sx = [], label, color, src, logo, ...rest }) => (
  <ColorAvatar
    sx={[
      logo ? {
        '& .MuiAvatar-img': {
          objectFit: "contain",
          padding: "2px"
        }
      } : {},
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
    variant={logo ? "rounded" : "circular"}
    color={src ? "#fff" : color}
    src={src}
    {...rest}
  >
    {!src && extractInitials(label)}
  </ColorAvatar>
);

export default ImageAvatar;
