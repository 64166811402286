import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Card, CardActionArea, CardActionAreaProps } from '@mui/material';

interface NavigationCardProps extends CardActionAreaProps<typeof Link> { }

const NavigationCard: FC<NavigationCardProps> = ({ sx = [], children, ...props }) => (
  <CardActionArea component={Link} sx={[{ height: '100%' }, ...(Array.isArray(sx) ? sx : [sx])]} {...props}>
    <Card sx={{ height: '100%' }}>
      {children}
    </Card>
  </CardActionArea>
);

export default NavigationCard;
