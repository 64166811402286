import Schema from 'async-validator';
import { AxiosPromise } from 'axios';

import { intl } from '../Internationalization';
import { ReceiverMappingsRequest, ReceiverMappingsResponse, ReceiverMappingSettings, ReceiverMappingDetail } from '../types';
import { duplicateValidator, NOT_BLANK_REGEX } from '../util';

import * as ReceiverMappingLookupApi from './receiverMappingLookup';
import { AXIOS, RequestConfig } from './endpoints';

export function getReceiverMappings(request: ReceiverMappingsRequest, config?: RequestConfig): AxiosPromise<ReceiverMappingsResponse> {
  return AXIOS.get('/receiver_mappings', {
    ...config,
    params: request
  });
}

export function createReceiverMapping(request: ReceiverMappingSettings): AxiosPromise<ReceiverMappingDetail> {
  return AXIOS.post('/receiver_mappings', request);
}

export const receiverMappingValidator = (getIdentityProviderKey: () => string): Schema => {
  return new Schema({
    group: [{
      required: true,
      pattern: NOT_BLANK_REGEX,
      message: intl.formatMessage({
        id: 'receiverMapping.validator.group.required',
        defaultMessage: 'Please provide a group identifier for this mapping'
      })
    },
    duplicateValidator({
      regex: NOT_BLANK_REGEX,
      checkUnique: (group) => ReceiverMappingLookupApi.receiverMappingByGroup(getIdentityProviderKey(), group),
      alreadyExistsMessage: intl.formatMessage({
        id: 'receiverMapping.validator.group.unique',
        defaultMessage: 'A mapping with this group identifier already exists'
      }),
      errorMessage: intl.formatMessage({
        id: 'receiverMapping.validator.group.checkUniqueError',
        defaultMessage: 'There was a problem verifying the receiver mapping'
      })
    })]
  });
};
