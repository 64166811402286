import { FC, useContext, } from 'react';
import { FormattedMessage } from 'react-intl';

import {  Paper, Typography, Divider, Box } from '@mui/material';

import { ReceiverOnly, DownloadLogButton, FlexButtons } from '../../../components';

import RemoveSubmissionMedia from './RemoveSubmissionMedia';
import SubmissionFiles from './SubmissionFiles';
import { SubmissionContext } from './SubmissionContext';
import DownloadReportPdf from './DownloadReportPdf';

const SubmissionData: FC = () => {
  const { submission } = useContext(SubmissionContext);

  return (
    <>
      <Paper sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Typography gutterBottom variant="h5" component="h6">
          <FormattedMessage id="submission.submissionData.title" defaultMessage="Submission Data" />
        </Typography>
        <SubmissionFiles />
        <Box flexGrow={1} />
        <Divider />
        <FlexButtons>
          <ReceiverOnly>
            <RemoveSubmissionMedia />
            <DownloadLogButton submission={submission} />
          </ReceiverOnly>
          <DownloadReportPdf />
        </FlexButtons>
      </Paper>
    </>
  );
};

export default SubmissionData;
