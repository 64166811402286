import { FC, PropsWithChildren } from "react";
import { Box } from '@mui/material';

const IconBox: FC<PropsWithChildren> = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      '& > :first-child': {
        mr: 1
      }
    }}
  >
    {children}
  </Box>
);

export default IconBox;

