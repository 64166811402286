import { FC, useCallback } from 'react';
import { Box } from '@mui/material';

import * as MyAssignmentsApi from '../../api/myAssignments';
import {
  FilterPagination, CardGrid, AssignmentCard, FilterBar, NoContentPlaceholder, StandardPagination, FilterSearch, BrowseImageCardsSkeleton
} from '../../components';
import { AssignmentDetail } from '../../types';
import { intl } from '../../Internationalization';
import { useBrowseRequest } from '../../hooks';

export const toMyAssignmentURL = (assignment: AssignmentDetail) => `/my_assignments/${assignment.key}/`;

const PAGE_SIZE = 12;

const MyAssignments: FC = () => {
  const { request, response, processing, updateRequest, setPage } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, filter: "" },
    onRequest: MyAssignmentsApi.getMyAssignments,
  });

  const handleFilterUpdate = useCallback((filter: string) => updateRequest({ filter }), [updateRequest]);

  const renderAssignmentCards = () => {
    if (!response) {
      return <BrowseImageCardsSkeleton />;
    }

    if (!response?.results.length) {
      return (
        <NoContentPlaceholder
          message={intl.formatMessage({
            id: 'myAssignments.noAssignments', defaultMessage: 'You have no assignments matching the filter'
          })}
        />
      );
    }

    return (
      <>
        <CardGrid>
          {response.results.map(assignment => {
            const { specification, supplier } = assignment;
            return (
              <AssignmentCard
                key={assignment.key}
                assignmentKey={assignment.key}
                reference={assignment.reference}
                project={specification.project}
                specification={specification}
                assignmentUrl={toMyAssignmentURL(assignment)}
                supplier={supplier}
              />
            );
          })}
        </CardGrid>
        <StandardPagination
          size={request.size}
          page={request.page}
          total={response.total}
          onChange={setPage}
        />
      </>
    );
  };

  return (
    <Box p={3} id="my-assignments">
      <FilterBar
        startInput={
          <FilterSearch
            placeholder={intl.formatMessage({
              id: 'myAssignments.filterSearch.placeholder',
              defaultMessage: 'Filter assignments…'
            })}
            onSearch={handleFilterUpdate}
          />
        }
        actions={
          <FilterPagination page={request.page} size={request.size} total={response?.total} disabled={processing} setPage={setPage} />
        }
      />
      {renderAssignmentCards()}
    </Box>
  );
};

export default MyAssignments;
