import { FC, useContext } from 'react';

import { Grid, Typography, Box } from '@mui/material';

import { AppBreadcrumb, AppDecorationContext } from '../../components';

interface ContentHeaderProps {
  id: string;
  title: string;
  avatar: JSX.Element;
}

const ContentHeader: FC<ContentHeaderProps> = ({ id, title, avatar }) => {
  const { renderBreadcrumb } = useContext(AppDecorationContext);
  return (
    <Box
      id={id}
      sx={{
        p: 2,
        bgcolor: 'secondary.light',
        color: (theme) => theme.palette.getContrastText(theme.palette.secondary.light)
      }}
    >
      <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
        <Grid item>
          {avatar}
        </Grid>
        <Grid item>
          <Typography component="h1" variant="h5" color="inherit" gutterBottom={!!renderBreadcrumb}>
            {title}
          </Typography>
          <AppBreadcrumb />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentHeader;
