import { FC, useContext } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { AppDecorator, AppTabs, HeaderBreadcrumb, RouterTab, RouterTabs } from '../../../components';
import { intl } from '../../../Internationalization';
import { useRequiredParams, useRouterTab } from '../../../hooks';

import MyAssignmentHeader from './MyAssignmentHeader';
import { MyAssignmentContext } from './MyAssignmentContext';
import MyDashboard from './dashboard/MyDashboard';
import SpecificationInformation from './information/SpecificationInformation';
import MySubmissions from './submissions/MySubmissions';
import MySubmission from './submissions/submission/MySubmission';
import { SavedMappingsRouting } from './saved-mappings';

// Key is required on MyAssignment component to remount it when the assignment key changes
const CurrentMySubmission: FC = () => {
  const { submissionReference } = useRequiredParams<{ submissionReference: string }>();
  return (
    <MySubmission key={submissionReference} submissionReference={submissionReference} />
  );
};

export const toMySubmissionsLink = (assignmentKey: string) => {
  return `/my_assignments/${assignmentKey}/submissions`;
};

const MyAssignmentRouting: FC = () => {
  const { routerTab, resolveTabRoute } = useRouterTab();
  const { assignment } = useContext(MyAssignmentContext);

  const renderTabs = () => (
    <RouterTabs id="my-assignments-tabs" value={routerTab}>
      <RouterTab
        id="submissions-tab"
        value="submissions"
        label={intl.formatMessage({
          id: 'myAssignment.routing.tabs.submissions',
          defaultMessage: 'Submissions'
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="information-tab"
        value="information"
        label={intl.formatMessage({
          id: 'myAssignment.routing.tabs.information',
          defaultMessage: 'Information'
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="dashboard-tab"
        value="dashboard"
        label={intl.formatMessage({
          id: 'myAssignment.routing.tabs.dashboard',
          defaultMessage: 'Dashboard'
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="saved-mappings-tab"
        value="saved_mappings"
        label={intl.formatMessage({
          id: 'myAssignment.routing.tabs.savedMappings',
          defaultMessage: 'Saved Mappings'
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  const renderBreadcrumb = () => (
    <HeaderBreadcrumb>
      <Typography>
        <AssignmentIcon
          titleAccess={intl.formatMessage({
            id: 'myAssignment.breadcrumb.assignment.titleAccess',
            defaultMessage: 'Assignment'
          })}
        />
        {assignment.reference}
      </Typography>
    </HeaderBreadcrumb>
  );

  return (
    <AppDecorator renderBreadcrumb={renderBreadcrumb} renderTabs={renderTabs}>
      <MyAssignmentHeader />
      <AppTabs />
      <Box p={3}>
        <Routes>
          <Route path="submissions" element={<MySubmissions />} />
          <Route path="submissions/:submissionReference/*" element={<CurrentMySubmission />} />
          <Route path="information" element={<SpecificationInformation />} />
          <Route path="dashboard" element={<MyDashboard />} />
          <Route path="saved_mappings/*" element={<SavedMappingsRouting />} />
          <Route path="*" element={<Navigate to="submissions" replace />} />
        </Routes>
      </Box>
    </AppDecorator>
  );
};

export default MyAssignmentRouting;
