import { useContext, useState, useEffect } from 'react';
import { ValidateFieldsError } from 'async-validator';
import { AxiosError } from 'axios';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';

import { Container, Typography, Checkbox, FormControlLabel } from '@mui/material';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import SaveIcon from '@mui/icons-material/Save';

import * as LdapServerConnectionApi from '../../../../api/ldapServerConnection';
import * as LdapServerSettingsApi from '../../../../api/ldapServerSettings';
import { extractErrorMessage } from '../../../../api/endpoints';
import { PaddedPaper, ValidatedTextField, ValidatedPasswordField, FormButtons, DefaultButton, InputTooltip } from '../../../../components';
import { LdapServerSettings } from '../../../../types';
import { validate, normalizeUri } from '../../../../util';
import { intl } from '../../../../Internationalization';

import { LdapContext } from '../LdapContext';

const ServerSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { settings, settingsUpdated } = useContext(LdapContext);
  const [serverUrl, setServerUrl] = useState<string>(settings.serverUrl);
  const [baseDn, setBaseDn] = useState<string>(settings.baseDn);
  const [username, setUsername] = useState<string>(settings.username);
  const [password, setPassword] = useState<string>(settings.password);
  const [anonymous, setAnonymous] = useState<boolean>(settings.anonymous);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>({});
  const [processingTest, setProcessingTest] = useState<boolean>(false);
  const [processingSave, setProcessingSave] = useState<boolean>(false);

  const testConnection = (updatedSettings: LdapServerSettings) => {
    LdapServerConnectionApi.testServerConnection(updatedSettings).then(() => {
      setProcessingTest(false);
      enqueueSnackbar(intl.formatMessage({
        id: 'ldap.serverSettings.testConnectionSuccess',
        defaultMessage: 'LDAP server connection successful'
      }), { variant: "success" });
    }).catch((error: AxiosError) => {
      enqueueSnackbar(intl.formatMessage({
        id: 'ldap.serverSettings.testConnectionError',
        defaultMessage: 'Failed to connect to LDAP server'
      }), { variant: "error" });
      setProcessingTest(false);
    });
  };

  const saveSettings = (updatedSettings: LdapServerSettings) => {
    LdapServerSettingsApi.updateServerSettings(updatedSettings).then(response => {
      setProcessingSave(false);
      settingsUpdated(response.data);
      enqueueSnackbar(intl.formatMessage({
        id: 'ldap.serverSettings.saveConnectionSuccess',
        defaultMessage: 'LDAP server settings updated'
      }), { variant: "success" });
    }).catch(error => {
      setProcessingSave(false);
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'ldap.serverSettings.saveConnectionError',
        defaultMessage: 'Failed to update LDAP server settings'
      })), { variant: "error" });
    });
  };

  const validateAndSaveSettings = () => {
    setProcessingSave(true);
    validateAndPerform(saveSettings);
  };

  const validateAndTestConnection = () => {
    setProcessingTest(true);
    validateAndPerform(testConnection);
  };

  useEffect(() => {
    setServerUrl(settings.serverUrl);
    setBaseDn(settings.baseDn);
    setUsername(settings.username);
    setPassword(settings.password);
    setAnonymous(settings.anonymous);
  }, [settings]);

  const collectSettings = (): LdapServerSettings => ({
    serverUrl: normalizeUri(serverUrl),
    baseDn,
    username,
    password,
    anonymous
  });

  const validateAndPerform = (onValidated: (updatedSettings: LdapServerSettings) => void) => {
    setFieldErrors({});
    const updatedSettings = collectSettings();
    validate(LdapServerSettingsApi.LDAP_SERVER_SETTINGS_VALIDATOR, updatedSettings)
      .then(() => onValidated(updatedSettings))
      .catch((newFieldErrors: ValidateFieldsError) => {
        setProcessingSave(false);
        setProcessingTest(false);
        setFieldErrors(newFieldErrors);
      });
  };

  const processing = processingTest || processingSave;

  return (
    <Container maxWidth="md" id="system-ldap-server-settings" disableGutters>
      <PaddedPaper>
        <Typography variant="h5" gutterBottom>
          <FormattedMessage id="ldap.serverSettings.title" defaultMessage="Server Settings" />
        </Typography>
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="serverUrl"
          label={intl.formatMessage({
            id: 'ldap.serverSettings.serverUrl.label',
            defaultMessage: 'Server URL'
          })}
          tooltip={intl.formatMessage({
            id: 'ldap.serverSettings.serverUrl.tooltip',
            defaultMessage: 'The URL of the LDAP server, e.g. ldap://ldap.example.com:389'
          })}
          value={serverUrl}
          onChange={(event) => setServerUrl(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="baseDn"
          label={intl.formatMessage({
            id: 'ldap.serverSettings.baseDn.label',
            defaultMessage: 'Base DN'
          })}
          tooltip={intl.formatMessage({
            id: 'ldap.serverSettings.baseDn.tooltip',
            defaultMessage: 'The base DN for lookups'
          })}
          value={baseDn}
          onChange={(event) => setBaseDn(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <InputTooltip
          title={intl.formatMessage({ id: 'ldap.serverSettings.anonymous.tooltip', defaultMessage: 'Controls whether the connection to the LDAP server is anonymous' })}
        >
          <FormControlLabel
            control={
              <Checkbox
                name="anonymous"
                disabled={processing}
                checked={anonymous}
                onChange={(event, checked) => setAnonymous(checked)}
                value="primary"
              />
            }
            label={intl.formatMessage({
              id: 'ldap.serverSettings.anonymous.label',
              defaultMessage: 'Anonymous'
            })}
          />
        </InputTooltip>
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing || anonymous}
          name="username"
          label={intl.formatMessage({
            id: 'ldap.serverSettings.username.label',
            defaultMessage: 'Username'
          })}
          tooltip={intl.formatMessage({
            id: 'ldap.serverSettings.username.tooltip',
            defaultMessage: 'The username of the user to authenticate as when executing queries'
          })}
          value={username}
          onChange={event => setUsername(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <ValidatedPasswordField
          fieldErrors={fieldErrors}
          disabled={processing || anonymous}
          name="password"
          label={intl.formatMessage({
            id: 'ldap.serverSettings.password.label',
            defaultMessage: 'Password'
          })}
          tooltip={intl.formatMessage({
            id: 'ldap.serverSettings.password.tooltip',
            defaultMessage: 'The password for the user specified above'
          })}
          value={password}
          onChange={event => setPassword(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <FormButtons>
          <DefaultButton
            name="updateServerSettings"
            onClick={validateAndSaveSettings}
            disabled={processingTest}
            startIcon={<SaveIcon />}
          >
            <FormattedMessage id="ldap.serverSettings.saveButton" defaultMessage="Save Settings" />
          </DefaultButton>
          <DefaultButton
            name="testConnection"
            onClick={validateAndTestConnection}
            disabled={processingSave}
            startIcon={<SettingsInputAntennaIcon />}
            color="secondary"
          >
            <FormattedMessage id="ldap.serverSettings.testButton" defaultMessage="Test Connection" />
          </DefaultButton>
        </FormButtons>
      </PaddedPaper>
    </Container>
  );
};

export default ServerSettings;
