import { FC, useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import * as AssignmentApi from '../../../../../api/assignment';
import { extractErrorMessage } from "../../../../../api/endpoints";
import { AssignmentDetail } from "../../../../../types";
import { ErrorBlockContext } from "../../../../../contexts/error-block";
import { intl } from "../../../../../Internationalization";
import { useRequiredParams } from "../../../../../hooks";

import { toSpecificationLink } from "../../ProjectRouting";

import { SpecificationContext } from "../SpecificationContext";
import { AssignmentContext } from './AssignmentContext';
import AssignmentRouting from "./AssignmentRouting";
import AssignmentSkeleton from "./AssignmentSkeleton";

const Assignment: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { assignmentKey } = useRequiredParams<{ assignmentKey: string }>();

  const { raiseError } = useContext(ErrorBlockContext);
  const specificationContext = useContext(SpecificationContext);
  const [assignment, setAssignment] = useState<AssignmentDetail>();
  const [navigateToSpecification, setNavigateToSpecification] = useState<boolean>(false);

  useEffect(() => {
    const loadAssignment = async () => {
      try {
        const updatedAssignmentData = (await AssignmentApi.getAssignment(assignmentKey)).data;

        if (updatedAssignmentData.specification.key === specificationContext.specificationKey) {
          setAssignment(updatedAssignmentData);
        } else {
          enqueueSnackbar(
            intl.formatMessage({
              id: 'assignment.invalidAssignment',
              defaultMessage: 'Invalid assignment'
            }),
            { variant: "warning" }
          );
          setNavigateToSpecification(true);
        }
      } catch (error: any) {
        raiseError(extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'assignment.loadError',
            defaultMessage: 'Failed to read assignment'
          })
        ));
      }
    };

    loadAssignment();
  }, [assignmentKey, specificationContext.specificationKey, enqueueSnackbar, raiseError]);

  if (navigateToSpecification) {
    return (
      <Navigate to={toSpecificationLink(specificationContext.projectKey, specificationContext.specificationKey)} />
    );
  }

  if (assignment) {
    return (
      <AssignmentContext.Provider
        value={{
          ...specificationContext,
          assignmentUpdated: setAssignment,
          assignmentKey,
          assignment
        }}
      >
        <AssignmentRouting />
      </AssignmentContext.Provider>
    );
  }

  return (
    <AssignmentSkeleton />
  );
};

export default Assignment;
