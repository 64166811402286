import React, { FC } from 'react';

import { Menu, MenuItem, PopoverProps } from '@mui/material';

import { Field } from '../../../types';
import { SmallListItemIcon } from '../../';

export interface FieldMenuProps {
  id: string;
  fields: Field[];
  anchorElement: PopoverProps['anchorEl'];
  onFieldSelected: (field: Field) => void;
  onClose: () => void;
}

const FieldMenu: FC<FieldMenuProps> = ({ id, fields, anchorElement, onFieldSelected, onClose }) => (
  <Menu
    id={id}
    className="FieldMenu-root"
    anchorEl={anchorElement}
    keepMounted
    open={anchorElement !== null}
    onClose={onClose}
    disableAutoFocusItem
  >
    {fields.map(field => {
      const { icon: Icon } = field;
      return (
        <MenuItem
          selected={false}
          key={field.expression}
          onClick={() => onFieldSelected(field)}
        >
          <SmallListItemIcon>
            <Icon fontSize="small" />
          </SmallListItemIcon>
          {field.label}
        </MenuItem>
      );
    })}
  </Menu>
);

export default FieldMenu;
