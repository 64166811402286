import { FC, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import Editor, { Monaco } from '@monaco-editor/react';

import { Box, Button, useTheme } from "@mui/material";
import { ClassNames } from '@emotion/react';

import CodeIcon from "@mui/icons-material/Code";
import AddIcon from "@mui/icons-material/Add";

import { Field } from "../../types";

import FieldMenu from "./editor-components/FieldMenu";

interface MailBodyRawEditorProps {
  value: string;
  onChange: (value: string) => void;
  textFields: Field[];
  linkFields: Field[];
}

const MailBodyRawEditor: FC<MailBodyRawEditorProps> = ({ value, onChange, textFields, linkFields }) => {
  const theme = useTheme();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const monacoRef = useRef<Monaco | null>(null);

  const [open, setOpen] = useState<boolean>(false);

  const handleEditorDidMount = (editor: monaco.editor.IStandaloneCodeEditor, monacoInstance: Monaco) => {
    editorRef.current = editor;
    monacoRef.current = monacoInstance;
  };

  const formatDocument = () => {
    editorRef.current?.getAction('editor.action.formatDocument').run();
  };

  const insertField = (field: Field) => {
    const expression = field.expression;
    const text = textFields.find((f) => f.expression === expression) ? `\${${expression}}` : expression;
    editorRef.current?.trigger('keyboard', 'type', { text });
    setOpen(false);
  };

  return (
    <Box position="relative" className="MailBodyRawEditor-root">
      <ClassNames>
        {({ css, cx }) => (
          <Editor
            height="500px"
            defaultLanguage="html"
            value={value}
            onChange={(newValue) => onChange(newValue || '')}
            className={cx([
              "MailBodyRawEditor-editor",
              css({
                borderColor: theme.palette.divider,
                borderStyle: 'solid',
                borderWidth: '1px',
                borderRadius: theme.shape.borderRadius,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0
              })
            ])}
            options={{
              minimap: { enabled: false }
            }}
            onMount={handleEditorDidMount}
          />
        )}
      </ClassNames>
      <Box display="flex" flexDirection="column" position="absolute" right={theme.spacing(4)} top={theme.spacing(2)}>
        <Button
          name="addFieldButton"
          ref={anchorRef}
          startIcon={<AddIcon />}
          size="small"
          variant="contained"
          className="MailBodyRawEditor-addFieldButton"
          sx={{ mb: 2 }}
          fullWidth
          onClick={() => setOpen(true)}
          color="grey"
        >
          <FormattedMessage id="components.editor.mailBodyRawEditor.addField" defaultMessage="Add Field" />
        </Button>
        <FieldMenu
          id="body-editor-add-field-menu"
          fields={[...textFields, ...linkFields]}
          anchorElement={open ? anchorRef.current : null}
          onClose={() => setOpen(false)}
          onFieldSelected={insertField}
        />
        <Button
          name="formatButton"
          startIcon={<CodeIcon />}
          size="small"
          variant="contained"
          className="MailBodyRawEditor-formatButton"
          fullWidth
          onClick={formatDocument}
          color="grey"
        >
          <FormattedMessage id="components.editor.mailBodyRawEditor.format" defaultMessage="Format" />
        </Button>
      </Box>
    </Box>
  );
};

export default MailBodyRawEditor;
