
import { isNumber } from "lodash";

import GML from 'ol/format/GML2';
import { getCenter, getBottomLeft, getBottomRight, getTopLeft, getTopRight, Extent } from 'ol/extent';

export const createGMLParser = (featureType: string, srsName: string) => {
  return new GML({
    featureNS: 'http://onespatial.com/gothic/',
    featureType: [featureType],
    srsName
  });
};

// geometry is coming from the ObjectReports attributes which is typed as an any
export const parseExtent = (geometry: any) => {
  if (geometry) {
    const x0 = parseFloat(geometry.x0);
    const x1 = parseFloat(geometry.x1);
    const y0 = parseFloat(geometry.y0);
    const y1 = parseFloat(geometry.y1);
    if (isNumber(x0) && isNumber(x1) && isNumber(y0) && isNumber(y1)) {
      return [x0, y0, x1, y1];
    }
  }
  return undefined;
};

/*
 * This is a hack copied from the 1Integrate map viewer.
 *
 * TODO - Replace this with a deterministic alternative perhaps - extent subdivision perhaps?
 */
const generateRandomCoordinatesInExtent = (extent: Extent, num: number) => {
  return Array.from({ length: num }, () => {
    return [
      Math.random() * (extent[2] - extent[0]) + extent[0],
      Math.random() * (extent[3] - extent[1]) + extent[1],
    ];
  });
};

export const generateSearchCoordinates = (extent: Extent) => {
  return [
    getCenter(extent),
    getBottomLeft(extent),
    getTopLeft(extent),
    getTopRight(extent),
    getBottomRight(extent),
    ...generateRandomCoordinatesInExtent(extent, 50)
  ];
};
