import { FC } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import { AppDecorator } from '../../components';
import { ErrorBlock } from '../../contexts/error-block';
import { intl } from '../../Internationalization';

import Submissions from './Submissions';
import Submission from './submission/Submission';

const SubmissionsRouting: FC = () => (
  <AppDecorator
    title={intl.formatMessage({
      id: 'submissions.routing.title',
      defaultMessage: 'Submissions'
    })}
  >
    <ErrorBlock>
      <Routes>
        <Route index element={<Submissions />} />
        <Route path=":submissionReference/*" element={<Submission />} />
        <Route path="*" element={<Navigate to="." replace />} />
      </Routes>
    </ErrorBlock>
  </AppDecorator>
);

export default SubmissionsRouting;
