import React, { useContext, FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Link, Tooltip, Box, Menu, MenuItem } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import * as SubmissionMediaApi from '../../../api/submissionMedia';
import { MessageBox, DefaultButton, ButtonRow } from '../../../components';
import { dataStoreNameFromPath } from '../../../util';
import { intl } from '../../../Internationalization';
import { MediaDetail, VirusCheckedMediaDetail, virusScanStatePassed } from '../../../types';

import { SubmissionContext } from './SubmissionContext';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

interface DownloadFilesButtonProps {
  submissionReference: string;
  input: VirusCheckedMediaDetail;
  modifiedInput: MediaDetail;
  output: MediaDetail;
  mediaRemoved: boolean;
}

const DownloadFilesButton: FC<DownloadFilesButtonProps> = ({
  submissionReference, input, modifiedInput, output, mediaRemoved
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const inputUrl = input && SubmissionMediaApi.downloadSubmissionFileUri(submissionReference, input, 'inputs');
  const modifiedInputUrl = modifiedInput && SubmissionMediaApi.downloadSubmissionFileUri(submissionReference, modifiedInput, 'modified_inputs');
  const outputUrl = output && SubmissionMediaApi.downloadSubmissionFileUri(submissionReference, output, 'outputs');

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const inputDisabled = !inputUrl || !virusScanStatePassed(input.virusScanState) || mediaRemoved;
  const modifiedInputDisabled = !modifiedInputUrl || mediaRemoved;
  const outputDisabled = !outputUrl || mediaRemoved;

  return (
    <ButtonRow>
      <DefaultButton
        name="downloadFiles"
        color="secondary"
        download
        startIcon={<CloudDownloadIcon />}
        endIcon={<ArrowDropDown />}
        onClick={handleOpenMenu}
        disabled={inputDisabled && modifiedInputDisabled && outputDisabled}
      >
        <FormattedMessage id="submission.submissionFiles.downloadFilesButton" defaultMessage="Download Files" />
      </DefaultButton>
      <Menu
        id="submission-files"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <MenuItem
          href={inputUrl}
          component={Link}
          disabled={inputDisabled}
        >
          <FormattedMessage id="submission.submissionFiles.inputFileLabel" defaultMessage="Input file" />
        </MenuItem>
        <MenuItem
          href={modifiedInputUrl}
          component={Link}
          disabled={modifiedInputDisabled}
        >
          <FormattedMessage id="submission.submissionFiles.modifiedInputFileLabel" defaultMessage="Modified input file" />
        </MenuItem>
        <MenuItem
          href={outputUrl}
          component={Link}
          disabled={outputDisabled}
        >
          <FormattedMessage id="submission.submissionFiles.outputFileLabel" defaultMessage="Output file" />
        </MenuItem>
      </Menu>
    </ButtonRow>
  );
};

const SubmissionFiles: FC = () => {
  const { submission } = useContext(SubmissionContext);
  const dataStores = Array.from(new Set([
    ...Object.keys(submission.inputs),
    ...Object.keys(submission.modifiedInputs),
    ...Object.keys(submission.outputs)
  ])).sort();

  const renderContent = () => {
    if (!dataStores.length) {
      return (
        <MessageBox
          className="SubmissionFiles-noFiles"
          message={intl.formatMessage({
            id: 'submission.submissionFiles.noFiles',
            defaultMessage: 'There are no files relating to this submission.'
          })}
          level="info"
        />
      );
    }

    return (
      <>
        <List component="div" className="SubmissionFiles-dataStores" disablePadding>
          {
            dataStores.map((dataStore) => {
              const input = submission.inputs && submission.inputs[dataStore];
              const modifiedInput = submission.modifiedInputs && submission.modifiedInputs[dataStore];
              const output = submission.outputs && submission.outputs[dataStore];

              return (
                <ListItem key={dataStore}>
                  <Tooltip title={dataStore} placement="top-start">
                    <ListItemText primary={dataStoreNameFromPath(dataStore)} secondary={input && input?.filename} />
                  </Tooltip>
                  <ListItemSecondaryAction>
                    <DownloadFilesButton
                      submissionReference={submission.reference}
                      input={input}
                      modifiedInput={modifiedInput}
                      output={output}
                      mediaRemoved={submission.mediaRemoved}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })
          }
        </List>
        {
          submission.mediaRemoved && (
            <Box my={1} className="SubmissionFiles-mediaRemoved">
              <MessageBox
                message={intl.formatMessage({
                  id: 'submission.submissionFiles.mediaRemoved',
                  defaultMessage: 'Media for this submission has been removed.'
                })}
                level="info"
              />
            </Box>
          )
        }
      </>
    );
  };

  return (
    <div className="SubmissionFiles-root">
      {renderContent()}
    </div>
  );

};

export default SubmissionFiles;
