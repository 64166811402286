import { FC, Fragment, useCallback, useState } from 'react';
import { Box } from '@mui/material';

import * as ProjectsApi from '../../api/projects';
import { AddFab, CardGrid, ProjectCard, FilterPagination, FilterToggle, FilterBar, BrowseImageCardsSkeleton, NoContentPlaceholder, StandardPagination, FilterSearch } from '../../components';
import { intl } from '../../Internationalization';
import { updateActiveOnlyValue } from '../../util';
import { useBrowseRequest } from '../../hooks';
import { ProjectDetail } from '../../types';

import NewProjectForm from './project/NewProjectForm';

export const toProjectUrl = (project: ProjectDetail) => `/projects/${project.key}/`;

const PAGE_SIZE = 12;

const Projects: FC = () => {

  const { request, response, processing, updateRequest, setPage } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, active: true, filter: "" },
    onRequest: ProjectsApi.getProjects,
  });

  const [addProject, setAddProject] = useState<boolean>(false);

  const handleFilterUpdate = useCallback((filter: string) => updateRequest({ filter }), [updateRequest]);
  const handleActiveOnlyUpdate = updateActiveOnlyValue(updateRequest);

  const renderProjectCards = () => {
    if (!response) {
      return (
        <BrowseImageCardsSkeleton />
      );
    }

    if (!response.results.length) {
      return (
        <NoContentPlaceholder
          message={intl.formatMessage({
            id: 'projects.noProjects',
            defaultMessage: 'There are no projects matching the filter'
          })}
        />
      );
    }

    return (
      <>
        <CardGrid>
          {response.results.map(project => (
            <ProjectCard
              data-project-key={project.key}
              key={project.key}
              project={project}
              projectUrl={toProjectUrl(project)}
            />
          ))}
        </CardGrid>
        <StandardPagination
          size={request.size}
          page={request.page}
          total={response.total}
          onChange={setPage}
        />
      </>
    );
  };

  return (
    <Box p={3} id="projects">
      <FilterBar
        startInput={
          <FilterSearch
            placeholder={intl.formatMessage({
              id: 'projects.filterSearch.placeholder',
              defaultMessage: 'Filter projects…'
            })}
            onSearch={handleFilterUpdate}
          />
        }
        actions={
          <Fragment>
            <FilterPagination page={request.page} size={request.size} total={response?.total} disabled={processing} setPage={setPage} />
            <FilterToggle
              label={intl.formatMessage({
                id: 'projects.filterActiveToggle.label',
                defaultMessage: 'Active Only'
              })}
              name="active"
              checked={!!request.active}
              onChange={handleActiveOnlyUpdate}
              disabled={processing}
            />
          </Fragment>
        }
      />
      {renderProjectCards()}
      {addProject && <NewProjectForm onCancel={() => setAddProject(false)} />}
      <AddFab
        name="addProject"
        aria-label={intl.formatMessage({
          id: 'projects.addFab.addProject.ariaLabel',
          defaultMessage: 'Add project'
        })}
        onClick={() => setAddProject(true)}
      />
    </Box>
  );
};

export default Projects;
