import { FC, ComponentType } from 'react';
import { MapLayerStyle, MapLayerLineStyle, MapLayerPolygonStyle, MapLayerPointStyle, PointSymbol } from '../../../../../../../types';

interface LayerKeyProps {
  style: MapLayerStyle;
}

const LayerKey: FC<LayerKeyProps> = ({ style: { polygon, line, point } }) => (
  <>
    <PolygonKey style={polygon} />
    <LineKey style={line} />
    <PointKey style={point} />
  </>
);

export default LayerKey;

interface PolygonKeyProps {
  style: MapLayerPolygonStyle;
}

const PolygonKey: FC<PolygonKeyProps> = ({ style: { fill, fillOpacity, stroke, strokeWidth = 1 } }) => (
  <svg height="16" width="16">
    <polygon points="4,2 2,10 10,14 12,14 14,2" style={{ fill, fillOpacity, stroke, strokeWidth }} />
  </svg>
);

interface LineKeyProps {
  style: MapLayerLineStyle;
}

const LineKey: FC<LineKeyProps> = ({ style: { strokeOpacity, stroke } }) => (
  <svg height="16" width="16">
    <polyline points="2,2 4,8 14,14" style={{ fill: "none", strokeOpacity, stroke, strokeWidth: 1 }} />
  </svg>
);

interface PointKeyProps {
  style: MapLayerPointStyle;
}

const PointKey: FC<PointKeyProps> = ({ style }) => {
  const getComponent = (): ComponentType<PointKeyProps> => {
    switch (style.mark) {
      case PointSymbol.ARROW:
        return ArrowPointKey;
      case PointSymbol.CROSS:
        return CrossPointKey;
      case PointSymbol.PLUS:
        return PlusPointKey;
      case PointSymbol.SQUARE:
        return SquarePointKey;
      case PointSymbol.STAR:
        return StarPointKey;
      case PointSymbol.TRIANGLE:
        return TrianglePointKey;
      case PointSymbol.CIRCLE:
      default:
        return CirclePointKey;
    }
  };
  const Component = getComponent();

  return (
    <Component style={style} />
  );
};

const CrossPointKey: FC<PointKeyProps> = ({ style: { stroke, fill, fillOpacity } }) => (
  <svg transform="rotate(45)" fill={fill} fillOpacity={fillOpacity} width="16" height="16">
    <polygon strokeWidth="1" stroke={stroke} points="2,6 2,10 6,10 6,14 10,14 10,10 14,10 14,6 10,6 10,2 6,2 6,6" />
  </svg>
);

const ArrowPointKey: FC<PointKeyProps> = ({ style: { stroke, fill, fillOpacity } }) => (
  <svg fill={fill} fillOpacity={fillOpacity} width="16" height="16">
    <polygon strokeWidth="1" stroke={stroke} points="2,6 2,10 8,10 6,14 10,14 14,8 10,2 6,2 8,6" />
  </svg>
);

const PlusPointKey: FC<PointKeyProps> = ({ style: { stroke, fill, fillOpacity } }) => (
  <svg fill={fill} fillOpacity={fillOpacity} width="16" height="16">
    <polygon strokeWidth="1" stroke={stroke} points="2,6 2,10 6,10 6,14 10,14 10,10 14,10 14,6 10,6 10,2 6,2 6,6" />
  </svg>
);

const TrianglePointKey: FC<PointKeyProps> = ({ style: { stroke, fill, fillOpacity } }) => (
  <svg fill={fill} fillOpacity={fillOpacity} width="16" height="16">
    <polygon strokeWidth="1" stroke={stroke} points="2,14 8,2 14,14" />
  </svg>
);

const StarPointKey: FC<PointKeyProps> = ({ style: { stroke, fill, fillOpacity } }) => (
  <svg fill={fill} fillOpacity={fillOpacity} viewBox="0 0 24 24" width="16" height="16">
    <path strokeWidth="1" stroke={stroke} d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
  </svg>
);

const SquarePointKey: FC<PointKeyProps> = ({ style: { stroke, fill, fillOpacity } }) => (
  <svg fill={fill} fillOpacity={fillOpacity} width="16" height="16">
    <rect strokeWidth="1" stroke={stroke} x="2" y="2" width="12" height="12" />
  </svg>
);

const CirclePointKey: FC<PointKeyProps> = ({ style: { stroke, fill, fillOpacity } }) => (
  <svg fill={fill} fillOpacity={fillOpacity} width="16" height="16">
    <circle strokeWidth="1" stroke={stroke} cx="8" cy="8" r="6" />
  </svg>
);
