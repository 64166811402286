import { useState, useEffect, FC } from 'react';
import { Bar } from 'react-chartjs-2';

import AssessmentIcon from '@mui/icons-material/Assessment';

import { ChartPaper } from '../../../components';
import { ProjectActivity } from '../../../types';
import { simpleChartOptions, chartColors } from '../../../util';
import { intl } from '../../../Internationalization';

interface ProjectActivityChartProps {
  projectActivity: ProjectActivity[];
}

const colors = chartColors(2);
const populateProjectSubmissionsStatsData = (projectActivity: ProjectActivity[]) => ({
  labels: projectActivity.map(project => project.projectName),
  datasets: [
    {
      backgroundColor: colors[0],
      label: intl.formatMessage({
        id: 'dashboard.global.projectActivityChart.dataset.label',
        defaultMessage: 'Successful'
      }),
      borderWidth: 1,
      maxBarThickness: 30,
      data: projectActivity.map(project => project.submissions)
    }
  ]
});

const ProjectActivityChart: FC<ProjectActivityChartProps> = ({ projectActivity }) => {
  const [submissionsData, setSubmissionsData] = useState(populateProjectSubmissionsStatsData(projectActivity));
  useEffect(() => {
    setSubmissionsData(populateProjectSubmissionsStatsData(projectActivity));
  }, [projectActivity]);

  return (
    <ChartPaper
      icon={AssessmentIcon}
      iconColor="#66bb6a"
      title={intl.formatMessage({
        id: 'dashboard.global.projectActivityChart.title',
        defaultMessage: 'Submissions per project'
      })}
    >
      <Bar
        data={submissionsData}
        options={
          simpleChartOptions({
            displayLegend: false,
            xTitle: intl.formatMessage({
              id: 'dashboard.global.projectActivityChart.xAxis.label',
              defaultMessage: 'Projects'
            }),
            yTitle: intl.formatMessage({
              id: 'dashboard.global.projectActivityChart.yAxis.label',
              defaultMessage: 'Submissions'
            }),
            stacked: true
          })
        }
      />
    </ChartPaper>
  );
};

export default ProjectActivityChart;
