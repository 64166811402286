import { FC, useEffect, useState } from 'react';

import * as LdapServerSettingsApi from '../../../api/ldapServerSettings';
import * as LdapUserSettingsApi from '../../../api/ldapUserSettings';
import { extractErrorMessage } from '../../../api/endpoints';
import { LdapServerSettings, LdapUserSettings } from '../../../types';
import { Loading } from '../../../components';
import { useErrorBlock } from '../../../contexts/error-block';
import { intl } from '../../../Internationalization';

import { LdapContext } from './LdapContext';
import LdapRouting from './LdapRouting';

const Ldap: FC = () => {
  const [settings, setSettings] = useState<LdapServerSettings>();
  const [userSettings, setUserSettings] = useState<LdapUserSettings>();

  const { raiseError } = useErrorBlock();

  useEffect(() => {
    const initializeContext = async () => {
      try{
        setSettings((await LdapServerSettingsApi.getServerSettings()).data);
        setUserSettings((await LdapUserSettingsApi.getUserSettings()).data);
      } catch(error: any) {
        raiseError(extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'ldap.fetchSettingsError',
            defaultMessage: 'Failed to load LDAP settings'
          })
        ));
      }
    };

    initializeContext();
  }, [raiseError]);

  if (settings && userSettings) {
    return (
      <LdapContext.Provider
        value={{
          settings: settings,
          settingsUpdated: setSettings,
          userSettings: userSettings,
          userSettingsUpdated: setUserSettings,
        }}
      >
        <LdapRouting />
      </LdapContext.Provider>
    );
  }

  return (
    <Loading />
  );
};

export default Ldap;
