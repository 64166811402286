import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Box, Container, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import * as DeletionJobApi from '../../../api/deletionJob';
import { PaddedPaper, FilterPagination, TableLoadingRow, TableInfoRow, MinWidthTableCell, ButtonRow, DefaultButton } from '../../../components';
import { useBrowseRequest, useInterval } from '../../../hooks';
import { DeletionJobDetail, DeletionJobState, DELETION_JOB_ENTITY_METADATA, DELETION_JOB_STATE_METADATA } from '../../../types';
import { intl } from '../../../Internationalization';
import { dateTimeFormat } from '../../../util';

const PAGE_SIZE = 10;
const POLL_INTERVAL = 5000;

export const toDeletionJobUrl = (deletionJob: DeletionJobDetail) => `/system/storage/deletion_jobs/${deletionJob.id}`;

const DeletionJobs: FC = () => {

  const { request, response, processing, setPage, refresh } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE },
    onRequest: DeletionJobApi.fetchDeletionJobs
  });

  const shouldRefresh = () => response && response.results.find(job => job.state === DeletionJobState.RUNNING);

  const refreshIfRequired = () => {
    if (shouldRefresh()) {
      refresh();
    }
  };

  useInterval(refreshIfRequired, POLL_INTERVAL);

  const renderTableRows = () => {
    if (processing) {
      return (
        <TableLoadingRow colSpan={5} />
      );
    }

    if (!response || !response.results.length) {
      return (
        <TableInfoRow
          colSpan={5}
          size="medium"
          message={intl.formatMessage({
            id: 'storage.deletionJobs.noJobs',
            defaultMessage: 'No deletion jobs to display',
          })}
        />
      );
    }

    return response.results.map((deletionJob) => {
      const { id, entity, entityKey, state, startedAt } = deletionJob;
      const { icon: Icon, label } = DELETION_JOB_STATE_METADATA[state];

      return (
        <TableRow key={id}>
          <MinWidthTableCell>
            <Box display="flex" alignItems="center">
              <Tooltip title={label}>
                <Icon />
              </Tooltip>
            </Box>
          </MinWidthTableCell>
          <TableCell>
            {dateTimeFormat(startedAt)}
          </TableCell>
          <TableCell>{DELETION_JOB_ENTITY_METADATA[entity].label}</TableCell>
          <TableCell>{entityKey}</TableCell>
          <MinWidthTableCell align="right">
            <ButtonRow whiteSpace="nowrap">
              <DefaultButton
                component={Link}
                to={toDeletionJobUrl(deletionJob)}
                color="grey"
                aria-label={intl.formatMessage({
                  id: 'storage.deletionJobs.navigateToDeletionJob.ariaLabel',
                  defaultMessage: 'Navigate to deletion job'
                })}
              >
                <ArrowForwardIcon />
              </DefaultButton>
            </ButtonRow>
          </MinWidthTableCell>
        </TableRow>
      );
    });
  };

  return (
    <Container maxWidth="lg" id="deletion-jobs" disableGutters>
      <PaddedPaper>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h5" gutterBottom>
            <FormattedMessage id="storage.deletionJobs.title" defaultMessage="Deletion Jobs" />
          </Typography>
          <Box>
            <FilterPagination page={request.page} size={request.size} total={response?.total} disabled={processing} setPage={setPage} />
          </Box>
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <MinWidthTableCell>
                <FormattedMessage id="storage.deletionJobs.stateColumn" defaultMessage="State" />
              </MinWidthTableCell>
              <TableCell>
                <FormattedMessage id="storage.deletionJobs.startedColumn" defaultMessage="Started At" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="storage.deletionJobs.entityTypeColumn" defaultMessage="Entity Type" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="storage.deletionJobs.entityKeyColumn" defaultMessage="Entity Key" />
              </TableCell>
              <MinWidthTableCell>
                <FormattedMessage id="storage.deletionJobs.actionsColumn" defaultMessage="Actions" />
              </MinWidthTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderTableRows()}
          </TableBody>
        </Table>
      </PaddedPaper>
    </Container>
  );
};

export default DeletionJobs;
