import { FC } from "react";
import { TaskResult, TaskResultKind } from "../../../../../types";

import { ApplyActionMapTaskHeader, ApplyActionTaskHeader, CheckRulesTaskHeader, DataTaskHeader, ValidateSchemaTaskHeader } from ".";

interface TaskTableHeadProps {
  task: TaskResult;
}

const TaskTableHead: FC<TaskTableHeadProps> = ({ task }) => {
  switch (task.kind) {
    case TaskResultKind.OpenDataTask:
    case TaskResultKind.CopyToTask:
    case TaskResultKind.CommitTask:
      return <DataTaskHeader />;
    case TaskResultKind.CheckRulesTask:
      return <CheckRulesTaskHeader />;
    case TaskResultKind.ApplyActionMapTask:
      return <ApplyActionMapTaskHeader />;
    case TaskResultKind.ApplyActionTask:
      return <ApplyActionTaskHeader />;
    case TaskResultKind.ValidateSchemaTask:
      return <ValidateSchemaTaskHeader />;
    default:
      return null;
  }
};

export default TaskTableHead;
