
import { FC, PropsWithChildren } from 'react';
import { TableRow, TableCell, Box } from '@mui/material';

export interface TableNoContentRowProps extends PropsWithChildren {
  colSpan: number;
  height?: number;
  my?: number;
}

const TableNoContentRow: FC<TableNoContentRowProps> = ({ colSpan, height = 150, my = 3, children }) => (
  <TableRow>
    <TableCell colSpan={colSpan}>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-around" height={height} my={my}>
        {children}
      </Box>
    </TableCell>
  </TableRow>
);

export default TableNoContentRow;
