import React from "react";
import { ProjectDetail } from "../../../types";

export interface ProjectContextValue {
  projectUpdated: (project: ProjectDetail) => void;
  projectKey: string;
  project: ProjectDetail;
}

const ProjectContextDefaultValue = {} as ProjectContextValue;
export const ProjectContext = React.createContext(
  ProjectContextDefaultValue
);
