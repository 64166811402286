import React, { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import debounce from 'lodash/debounce';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';

import { Dialog, DialogTitle, DialogContent } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import { extractErrorMessage } from '../../../api/endpoints';
import * as ProjectLookupApi from '../../../api/projectLookup';
import * as ProjectsApi from '../../../api/projects';
import { intl } from "../../../Internationalization";
import { onEnterCallback, validate } from '../../../util';
import { ValidatedTextField, PaddedDialogActions, DefaultButton } from '../../../components';

import { toProjectUrl } from '../Projects';

interface NewProjectFormProps {
  onCancel: () => void;
}

const VALIDATOR = ProjectsApi.createProjectSettingsValidator();

const NewProjectForm: FC<NewProjectFormProps> = ({ onCancel }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [key, setKey] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [active] = useState<boolean>(true);
  const [description, setDescription] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);
  const [fetchingKey, setFetchingKey] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const validateAndSubmit = () => {
    setProcessing(true);
    validate(VALIDATOR, { name, description, key })
      .then(createProject)
      .catch((errors: ValidateFieldsError) => {
        setFieldErrors(errors);
        setProcessing(false);
      });
  };

  const submitOnEnter = onEnterCallback(validateAndSubmit);

  const createProject = () => {

    ProjectsApi.createProject({ name, description, active, key }).then(response => {
      navigate(toProjectUrl(response.data));
      enqueueSnackbar(
        intl.formatMessage({
          id: 'project.create.saveSuccess',
          defaultMessage: 'Project {name} has been created'
        }, {
          name
        }), { variant: "success" });
    }).catch((error: AxiosError) => {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'project.create.saveError',
          defaultMessage: 'Failed to create project'
        })
      ), { variant: "error" });
      setProcessing(false);
    });
  };

  const updateName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setName(value);

    if (value) {
      setFetchingKey(true);
      suggestKey.current(value);
    }
  };

  const suggestKey = useRef(debounce(async (projectName: string) => {
    try {
      const response = await ProjectLookupApi.projectKeySuggestion(projectName);
      setKey(response.data.key);
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'project.create.keyGenerationError',
          defaultMessage: 'Failed to generate key suggestion'
        })
      ), { variant: "error" });
    } finally {
      setFetchingKey(false);
    }
  }, 500));

  return (
    <Dialog id="add-project-dialog" onClose={onCancel} aria-labelledby="add-project-dialog-title" open={true} fullWidth>
      <DialogTitle id="add-project-dialog-title">
        <FormattedMessage id="project.create.title" defaultMessage="Add Project" />
      </DialogTitle>
      <DialogContent dividers={true}>
        <ValidatedTextField
          tooltip={
            intl.formatMessage({
              id: "project.create.name.tooltip",
              defaultMessage: "A short name for the project."
            })
          }
          fieldErrors={fieldErrors}
          disabled={processing}
          name="name"
          label={intl.formatMessage({
            id: 'project.create.name.label',
            defaultMessage: 'Name'
          })}
          value={name}
          onChange={updateName}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          tooltip={
            intl.formatMessage({
              id: "project.create.description.tooltip",
              defaultMessage: "Detailed free text description of the project."
            })
          }
          fieldErrors={fieldErrors}
          disabled={processing}
          name="description"
          label={intl.formatMessage({
            id: 'project.create.description.label',
            defaultMessage: 'Description'
          })}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onKeyDown={submitOnEnter}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          tooltip={
            intl.formatMessage({
              id: 'project.create.key.tooltip',
              defaultMessage: 'Key is a user-friendly identifier.'
            })
          }
          fieldErrors={fieldErrors}
          disabled={processing || fetchingKey}
          name="key"
          label={intl.formatMessage({
            id: 'project.create.key.label',
            defaultMessage: 'Key'
          })}
          value={key}
          onChange={(e) => setKey(e.target.value)}
          onKeyDown={submitOnEnter}
          margin="normal"
          variant="outlined"
        />
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton
          name="cancelAddNewProject"
          color="secondary"
          onClick={onCancel}
          disabled={processing}
        >
          <FormattedMessage id="project.create.cancelButton" defaultMessage="Cancel" />
        </DefaultButton>
        <DefaultButton
          name="addNewProject"
          onClick={validateAndSubmit}
          disabled={processing}
          startIcon={<AddIcon />}
        >
          <FormattedMessage id="project.create.createButton" defaultMessage="Add Project" />
        </DefaultButton>
      </PaddedDialogActions>
    </Dialog>
  );
};

export default NewProjectForm;
