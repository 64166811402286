import { FC } from "react";

import { ConfirmDialog } from '../../components';
import { intl } from "../../Internationalization";

interface ConfirmNavigationDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

const ConfirmNavigationDialog: FC<ConfirmNavigationDialogProps> = ({ onConfirm, onCancel, isOpen }) => (
  <ConfirmDialog
    id="confirm-navigation"
    isOpen={isOpen}
    confirmAction={onConfirm}
    closeAction={onCancel}
    title={intl.formatMessage({
      id: 'contexts.navigationPrompt.confirmNavigationDialog.title',
      defaultMessage: 'Unsaved changes'
    })}
    text={intl.formatMessage({
      id: 'contexts.navigationPrompt.confirmNavigationDialog.text',
      defaultMessage: 'Any unsaved changes will be lost. Are you sure you wish to continue?'
    })}
    confirmBtnText={intl.formatMessage({
      id: 'contexts.navigationPrompt.confirmNavigationDialog.confirmButton',
      defaultMessage: 'Continue?'
    })}
  />
);

export default ConfirmNavigationDialog;
