import { FC, useEffect, useState } from "react";

import { extractErrorMessage } from "../../../api/endpoints";
import * as SupplierApi from '../../../api/supplier';
import { SupplierDetail } from "../../../types";
import { useErrorBlock } from "../../../contexts/error-block";
import { intl } from "../../../Internationalization";
import { useRequiredParams } from "../../../hooks";

import SupplierSkeleton from "./SupplierSkeleton";
import SupplierRouting from "./SupplierRouting";
import { SupplierContext } from "./SupplierContext";

const Supplier: FC = () => {
  const { supplierKey } = useRequiredParams<{ supplierKey: string }>();
  const { raiseError } = useErrorBlock();

  const [supplier, setSupplier] = useState<SupplierDetail>();

  useEffect(() => {
    const loadSupplier = async () => {
      try {
        const response = await SupplierApi.getSupplier(supplierKey);
        setSupplier(response.data);
      } catch (error: any) {
        raiseError(extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'supplier.loadError',
            defaultMessage: 'Failed to read supplier'
          })
        ));
      }
    };

    loadSupplier();
  }, [raiseError, supplierKey]);

  return (
    supplier ?
      (
        <SupplierContext.Provider
          value={{
            supplierUpdated: setSupplier,
            supplierKey: supplierKey,
            supplier
          }}
        >
          <SupplierRouting />
        </SupplierContext.Provider>
      ) :
      <SupplierSkeleton />
  );
};

export default Supplier;
