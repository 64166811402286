import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';

const AvatarPaperHeader: FC<PropsWithChildren> = ({ children }) => (
  <Box ml={10}>
    {children}
  </Box>
);

export default AvatarPaperHeader;
