import { useState, FC, useEffect, PropsWithChildren } from 'react';
import { Box, Theme, useMediaQuery } from '@mui/material';

import { LayoutAppBar } from './app-bar';
import { Sidebar } from './sidebar';

export const DRAWER_WIDTH = 240;

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const permanentDrawer = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), { noSsr: true });

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);
  const shiftContent = sidebarOpen && permanentDrawer;

  useEffect(() => {
    setSidebarOpen(permanentDrawer);
  }, [permanentDrawer]);

  const handleDrawerToggle = () => {
    setSidebarOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <LayoutAppBar
        handleDrawerToggle={handleDrawerToggle}
        shiftAppBar={shiftContent}
      />
      <Sidebar
        handleDrawerToggle={handleDrawerToggle}
        sidebarOpen={sidebarOpen}
        permanentDrawer={permanentDrawer}
      />
      <Box
        sx={{
          transition: (theme) => theme.transitions.create(['margin-left'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          })
        }}
        flexGrow={1}
        component="main"
        marginLeft={`${shiftContent ? DRAWER_WIDTH : 0}px`}
      >
        {children}
      </Box>
    </>
  );
};

export default Layout;
