import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text, View } from '@react-pdf/renderer';

import { resultsPdfStyles } from '../../../../../components';
import { SubmissionDetail, TaskConformance, TaskResult, taskResultLabel, TASK_KIND_METADATA } from '../../../../../types';
import { ErrorOutlineOutlinedSvg, ThumbDownSvg, ThumbUpSvg } from '../../../../../components/pdf/PdfIcons';
import { intl } from '../../../../../Internationalization';

import { getTaskStateMetadata } from '../../taskResultUtils';
import { PdfSectionHeader, PdfSection, PdfTableCell } from '../components';

import TaskTableHead from './TaskTableHead';
import TaskTableContent from './TaskTableContent';

interface ConformancePassedIconProps {
  taskConformancePassed: boolean;
}

const ConformancePassedIcon: FC<ConformancePassedIconProps> = ({ taskConformancePassed }) => {
  if (taskConformancePassed) {
    return (
      <ThumbUpSvg style={[resultsPdfStyles.mediumIcon, resultsPdfStyles.iconMarginRight]} />
    );
  }

  return (
    <ThumbDownSvg style={[resultsPdfStyles.mediumIcon, resultsPdfStyles.iconMarginRight]} />
  );
};

interface TaskSummaryProps {
  taskConformance?: TaskConformance;
}

const TaskSummary: FC<TaskSummaryProps> = ({ taskConformance }) => {
  if (!taskConformance) {
    return null;
  }
  return (
    <>
      <ConformancePassedIcon taskConformancePassed={taskConformance.passed} />
      <Text style={[resultsPdfStyles.sectionHeaderText]}>
        {
          intl.formatMessage({
            id: 'submission.resultsPdf.taskSummary.taskConformance',
            defaultMessage: '{conformancePercentage}% / {passThreshold}% required'
          }, { conformancePercentage: taskConformance.conformancePercentage, passThreshold: taskConformance.passThreshold })
        }
      </Text>
    </>
  );
};

interface TaskTablesProps {
  submission: SubmissionDetail;
  tasks: TaskResult[];
  hideZeroProcessedRows: boolean;
}

const TaskTables: FC<TaskTablesProps> = ({ submission, tasks, hideZeroProcessedRows }) => {
  if (!tasks.length) {
    return (
      <View style={[resultsPdfStyles.footerSummary, resultsPdfStyles.noTaskIconContainer]}>
        <View style={[resultsPdfStyles.gutterBottom]}>
          <ErrorOutlineOutlinedSvg />
        </View>
        <Text>
          <FormattedMessage
            id="submission.resultsPdf.noTasksToDisplay"
            defaultMessage="There are no tasks to display"
          />
        </Text>
      </View>
    );
  }

  return (
    <>
      {tasks.map((task) => {
        const taskConformance = submission.taskConformance.find(({ taskIdentifier }) => taskIdentifier === task.taskIdentifier);
        const Icon = TASK_KIND_METADATA[task.kind].pdfIcon;
        const { label, pdfIcon: TaskStateIcon } = getTaskStateMetadata(task, taskConformance);

        return (
          <PdfSection key={task.taskIdentifier} gutterBottom>
            <View wrap={false}>
              <PdfSectionHeader>
                <PdfTableCell colSpan="third" icon>
                  <Icon style={[resultsPdfStyles.mediumIcon, resultsPdfStyles.iconMarginRight]} />
                  <Text style={[resultsPdfStyles.sectionHeaderText]}>{taskResultLabel(task)}</Text>
                </PdfTableCell>
                <PdfTableCell colSpan="third" icon>
                  <TaskStateIcon style={[resultsPdfStyles.mediumIcon, resultsPdfStyles.iconMarginRight]} />
                  <Text style={[resultsPdfStyles.sectionHeaderText]}>{label}</Text>
                </PdfTableCell>
                <PdfTableCell colSpan="third" icon>
                  <TaskSummary taskConformance={taskConformance} />
                </PdfTableCell>
              </PdfSectionHeader>
              <TaskTableHead task={task} />
            </View>
            <TaskTableContent task={task} hideZeroProcessedRows={hideZeroProcessedRows} />
          </PdfSection>
        );
      })}
    </>
  );
};

export default TaskTables;
