
import { FC } from 'react';
import { getContrastRatio, useTheme } from '@mui/material/styles';
import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import { ActiveAvatar } from '../../../../components';
import { intl } from '../../../../Internationalization';

import { PaletteColorType } from './ColorPicker';

const MIN_CONTRAST_RATIO = 4.5;

interface TextContrastValidatorProps {
  main: string;
  type: PaletteColorType;
}

const TextContrastValidator: FC<TextContrastValidatorProps> = ({ main, type }) => {
  const theme = useTheme();

  const textConstrastRatio = type === "primary" ?
    getContrastRatio(main, theme.palette.background.default) :
    getContrastRatio(main, theme.palette.getContrastText(main));
  const sufficientContrant = textConstrastRatio >= MIN_CONTRAST_RATIO;

  const renderSufficientConstrastListItem = () => {
    if (sufficientContrant) {
      return (
        <ListItem>
          <ListItemAvatar>
            <ActiveAvatar activated="active">
              <DoneIcon />
            </ActiveAvatar>
          </ListItemAvatar>
          <ListItemText
            sx={{ color: 'success.main' }}
            primary={intl.formatMessage({
              id: 'site.theme.textContrastValidator.sufficient',
              defaultMessage: 'Colour contrast is sufficient'
            })}
          />
        </ListItem>
      );
    }

    return (
      <ListItem>
        <ListItemAvatar>
          <ActiveAvatar activated="inactive">
            <CloseIcon />
          </ActiveAvatar>
        </ListItemAvatar>
        <ListItemText
          sx={{ color: 'error.main' }}
          primary={
            intl.formatMessage({
              id: 'site.theme.textContrastValidator.insufficient',
              defaultMessage: 'Colour contrast is insufficient'
            })
          }
        />
      </ListItem>
    );
  };

  return (
    <List>
      {renderSufficientConstrastListItem()}
      <ListItem>
        <ListItemAvatar>
          <ActiveAvatar activated="default">
            {textConstrastRatio.toFixed(1)}
          </ActiveAvatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            intl.formatMessage({
              id: 'site.theme.textContrastValidator.ratio',
              defaultMessage: 'Text contrast ratio'
            })
          }
        />
      </ListItem>
    </List>
  );
};

export default TextContrastValidator;
