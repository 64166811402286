import { useState, FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import * as LdapUserSettingsApi from '../../../../api/ldapUserSettings';
import { extractErrorMessage } from '../../../../api/endpoints';
import { PaddedPaper, TableLoadingRow, DefaultButton, TableErrorRow, TableInfoRow, TablePreviewRow } from '../../../../components';
import { ActivatedIcon } from '../../../../components/icons';
import { LdapUser } from '../../../../types';
import { intl } from '../../../../Internationalization';

interface UsersPreviewProps {
  users?: LdapUser[];
  setUsers: (users?: LdapUser[]) => void;
  errorMessage?: string;
  setErrorMessage: (errorMessage: string) => void;
}

const UserPreview: FC<UsersPreviewProps> = ({ users, setUsers, errorMessage, setErrorMessage }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const refreshUserPreview = () => {
    setLoading(true);
    setUsers();
    setErrorMessage('');
    LdapUserSettingsApi.previewLdapUsers().then(response => {
      setLoading(false);
      setUsers(response.data);
    }).catch(error => {
      setLoading(false);
      setErrorMessage(extractErrorMessage(error, intl.formatMessage({
        id: 'ldap.userPreview.refreshPreviewError',
        defaultMessage: 'Failed to fetch users'
      })));
    });
  };

  const renderTableRows = () => {
    if (loading) {
      return {
        className: 'UserPreview-loading',
        content: <TableLoadingRow colSpan={4} />
      };
    }

    if (errorMessage) {
      return {
        className: 'UserPreview-error',
        content: (
          <TableErrorRow
            colSpan={4}
            title={intl.formatMessage({
              id: 'ldap.userPreview.errorRow.title',
              defaultMessage: 'There was an error when previewing users: '
            })}
            errorMessage={errorMessage}
          />
        )
      };
    }

    if (!users) {
      return {
        className: 'UserPreview-empty',
        content: (
          <TablePreviewRow
            colSpan={4}
            fetchData={refreshUserPreview}
            message={intl.formatMessage({
              id: 'ldap.userPreview.previewNotFetched',
              defaultMessage: 'You may use this panel to verify your user synchronisation and schema settings'
            })}
          />
        )
      };
    }

    if (!users.length) {
      return {
        className: 'UserPreview-noContent',
        content: (
          <TableInfoRow
            colSpan={4}
            size="medium"
            message={intl.formatMessage({
              id: 'ldap.userPreview.noUsers',
              defaultMessage: 'No users found matching the specified filter'
            })}
          />
        )
      };
    }

    return {
      className: 'UserPreview-loaded',
      content: users.map(user => (
        <TableRow key={user.dn}>
          <TableCell>
            <ActivatedIcon
              disabled={!user.uid}
              titleAccess={
                user.uid ?
                  intl.formatMessage({
                    id: 'ldap.userPreview.activatedStatus.titleAccess',
                    defaultMessage: 'User activated'
                  }) : intl.formatMessage({
                    id: 'ldap.userPreview.notActivatedStatus.titleAccess',
                    defaultMessage: 'User not activated'
                  })
              }
            />
          </TableCell>
          <TableCell>{user.dn}</TableCell>
          <TableCell>{user.name}</TableCell>
          <TableCell>{user.email}</TableCell>
        </TableRow>
      ))
    };
  };

  const content = renderTableRows();
  return (
    <Box pt={3} id="system-ldap-user-preview">
      <PaddedPaper>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h5">
            <FormattedMessage id="ldap.userPreview.title" defaultMessage="Preview Users" />
          </Typography>
          <DefaultButton
            color="grey"
            startIcon={<RefreshIcon />}
            onClick={refreshUserPreview}
            disabled={!users && !errorMessage}
          >
            <FormattedMessage id="ldap.userPreview.refreshButton" defaultMessage="Refresh" />
          </DefaultButton>
        </Box>
        <Table size="small" className={content.className}>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="ldap.userPreview.table.uidColumn" defaultMessage="UID" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="ldap.userPreview.table.dnColumn" defaultMessage="DN" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="ldap.userPreview.table.nameColumn" defaultMessage="Name" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="ldap.userPreview.table.emailColumn" defaultMessage="Email" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {content.content}
          </TableBody>
        </Table>
      </PaddedPaper>
    </Box>
  );
};

export default UserPreview;
