import { AXIOS, wrapUniquePromise } from './endpoints';

export function identityProviderLinkByRemoteId(identityProviderKey: string, remoteId: string): Promise<boolean> {
  return wrapUniquePromise(AXIOS.get('/identityProviderLinkByRemoteId', {
    params: { identityProviderKey, remoteId }
  }));
}

export function identityProviderLinkByUserKey(identityProviderKey: string, userKey: string): Promise<boolean> {
  return wrapUniquePromise(AXIOS.get('/identityProviderLinkByUserKey', {
    params: { identityProviderKey, userKey }
  }));
}
