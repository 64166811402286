
import { CardMedia, CardMediaProps } from '@mui/material';
import { ElementType, FC } from 'react';

import * as ProjectMediaApi from '../../api/projectMedia';
import { intl } from '../../Internationalization';
import { ProjectMetadata } from '../../types';

interface ProjectCardImageProps extends Omit<CardMediaProps<ElementType>, 'image' | 'component'> {
  project: ProjectMetadata;
}

const ProjectCardImage: FC<ProjectCardImageProps> = ({ project, ...props }) => (
  <CardMedia
    component="img"
    image={ProjectMediaApi.projectImageOrDefault(project)}
    alt={intl.formatMessage({
      id: 'components.projectCardMedia.alt',
      defaultMessage: 'Project image'
    })}
    {...props}
  />
);

export default ProjectCardImage;
