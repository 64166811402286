import { createContext } from "react";
import { SupplierDetail } from '../../../types';

export interface SupplierContextValue {
  supplierUpdated: (supplier: SupplierDetail) => void;
  supplierKey: string;
  supplier: SupplierDetail;
}

const SupplierContextDefaultValue = {} as SupplierContextValue;
export const SupplierContext = createContext(
  SupplierContextDefaultValue
);
