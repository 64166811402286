import { FC, useState } from 'react';

import { InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { intl } from '../../Internationalization';
import ValidatedTextField, { ValidatedTextFieldProps } from './ValidatedTextField';

const ValidatedPasswordField: FC<ValidatedTextFieldProps> = ({ ...props }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <ValidatedTextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={showPassword ? intl.formatMessage({
                id: 'components.fields.validatedPasswordField.hidePassword.ariaLabel',
                defaultMessage: 'Hide password'
              }) : intl.formatMessage({
                id: 'components.fields.validatedPasswordField.showPassword.ariaLabel',
                defaultMessage: 'Show password'
              })}
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
              size="large"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default ValidatedPasswordField;
