import { FC, useCallback, useContext, useState } from "react";
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { useSnackbar } from "notistack";

import { Container, TableRow, TableCell, TableBody } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import { extractErrorMessage } from "../../../../api/endpoints";
import * as ApiTokensApi from '../../../../api/apiTokens';
import {
  AddFab, TableLoadingRow, ConfirmDialog, MinWidthTableCell, FilterPagination,
  DefaultButton, FilterBar, BrowseTable, ButtonRow, FilterSearch, StyledTableHead, TableInfoRow
} from '../../../../components';
import { useBrowseRequest } from "../../../../hooks";
import { intl } from "../../../../Internationalization";
import { ApiTokenDetail } from "../../../../types";
import { dateTimeFormat } from "../../../../util";

import { UserContext } from '../UserContext';
import NewApiTokenDialog from './NewApiTokenDialog';

const PAGE_SIZE = 10;

const ApiTokens: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { userKey } = useContext(UserContext);
  const { request, response, processing, updateRequest, setPage } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, userKey, filter: "" },
    onRequest: ApiTokensApi.getApiTokens,
  });

  const [addApiToken, setAddApiToken] = useState<boolean>(false);
  const [apiTokenToDelete, setApiTokenToDelete] = useState<ApiTokenDetail>();

  const handleFilterUpdate = useCallback((filter: string) => updateRequest({ filter }), [updateRequest]);

  const confirmDelete = async () => {
    if (!apiTokenToDelete) {
      return;
    }
    try {
      await ApiTokensApi.deleteApiToken(userKey, apiTokenToDelete.name);
      updateRequest();
      enqueueSnackbar(
        intl.formatMessage({
          id: 'user.apiTokens.deleteSuccess',
          defaultMessage: 'API token has been deleted'
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'user.apiTokens.deleteError',
          defaultMessage: 'Failed to delete API token'
        })
      ), { variant: 'error' });
    } finally {
      setApiTokenToDelete(undefined);
    }
  };

  const renderTableContent = () => {
    if (!response) {
      return (
        <TableLoadingRow colSpan={5} />
      );
    }

    if (!response.results.length) {
      return (
        <TableInfoRow
          colSpan={5}
          size="medium"
          message={intl.formatMessage({ id: "user.apiTokens.noTokens", defaultMessage: "No API tokens found" })}
        />
      );
    }

    return response.results.map((apiToken) => (
      <TableRow key={apiToken.id}>
        <TableCell>
          {apiToken.name}
        </TableCell>
        <TableCell>
          {apiToken.description}
        </TableCell>
        <TableCell>
          {apiToken.createEvent.performedBy.name}
        </TableCell>
        <TableCell>
          {dateTimeFormat(apiToken.expiresAt)}
        </TableCell>
        <MinWidthTableCell>
          <ButtonRow whiteSpace="nowrap">
            <DefaultButton
              color="grey"
              component={Link}
              to={encodeURIComponent(apiToken.name)}
              aria-label={intl.formatMessage({
                id: 'user.apiTokens.navigateApiToken.ariaLabel',
                defaultMessage: 'Navigate to API token'
              })}
            >
              <EditIcon />
            </DefaultButton>
            <DefaultButton
              color="grey"
              onClick={() => setApiTokenToDelete(apiToken)}
              aria-label={intl.formatMessage({
                id: 'user.apiTokens.deleteApiToken.ariaLabel',
                defaultMessage: 'Delete API token'
              })}
            >
              <CloseIcon />
            </DefaultButton>
          </ButtonRow>
        </MinWidthTableCell>
      </TableRow>
    ));
  };

  return (
    <Container maxWidth="lg" id="user-identity-provider-links" disableGutters>
      <FilterBar
        startInput={
          <FilterSearch
            placeholder={intl.formatMessage({
              id: 'user.apiTokens.filterSearch.placeholder',
              defaultMessage: 'Filter API tokens…'
            })}
            onSearch={handleFilterUpdate}
          />
        }
        actions={
          <FilterPagination page={request.page} size={request.size} total={response?.total} disabled={processing} setPage={setPage} />
        }
      />
      <BrowseTable>
        <StyledTableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="user.apiTokens.table.nameColumn" defaultMessage="Name" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="user.apiTokens.table.descriptionColumn" defaultMessage="Description" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="user.apiTokens.table.createdByColumn" defaultMessage="Created By" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="user.apiTokens.table.expiresColumn" defaultMessage="Expires" />
            </TableCell>
            <MinWidthTableCell>
              <FormattedMessage id="user.apiTokens.table.actionsColumn" defaultMessage="Actions" />
            </MinWidthTableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {renderTableContent()}
        </TableBody>
      </BrowseTable>
      <AddFab
        id="add-api-token"
        onClick={() => setAddApiToken(true)}
        aria-label={intl.formatMessage({
          id: 'user.apiTokens.addFab.addApiToken.ariaLabel',
          defaultMessage: 'Add API token'
        })}
      />
      {
        addApiToken &&
        <NewApiTokenDialog onCancel={() => setAddApiToken(false)} />
      }
      <ConfirmDialog
        id="confirm-remove-api-token"
        isOpen={!!apiTokenToDelete}
        title={intl.formatMessage({
          id: 'user.apiTokens.confirmRemoveApiToken.title',
          defaultMessage: 'Remove API token'
        })}
        text={intl.formatMessage({
          id: 'user.apiTokens.confirmRemoveApiToken.text',
          defaultMessage: 'Are you sure you wish to remove this API token?'
        })}
        confirmBtnText={intl.formatMessage({
          id: 'user.apiTokens.confirmRemoveApiToken.button',
          defaultMessage: 'Remove'
        })}
        confirmAction={confirmDelete}
        closeAction={() => setApiTokenToDelete(undefined)}
      />
    </Container>
  );
};

export default ApiTokens;
