
import React, { useState, useRef, FC, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { IconButton, Typography, Badge, Box, Popover, Divider } from '@mui/material';

import AssessmentIcon from '@mui/icons-material/Assessment';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';

import { useInfiniteScroll } from '../../../../hooks';
import * as SubmissionsApi from '../../../../api/submissions';
import { SubmissionsRequest, SubmissionSummary } from '../../../../types';
import { AuthenticatedContext } from '../../../../contexts/authentication';
import { AppBarStatsContext } from '../../../../contexts/app-bar-stats';

import OpenSubmission from './OpenSubmission';
import OpenSubmissionSkeleton from './OpenSubmissionSkeleton';
import { intl } from '../../../../Internationalization';

const generateNextRequest = (currentRequest: SubmissionsRequest, lastItem: SubmissionSummary): SubmissionsRequest => (
  { ...currentRequest, before: lastItem.reference }
);

const PAGE_SIZE = 10;

const OpenSubmissions: FC = () => {
  const { me } = useContext(AuthenticatedContext);
  const appBarStatsContext = useContext(AppBarStatsContext);

  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const { lastItemRef, items, updateRequest, processing, moreItems } = useInfiniteScroll({
    initialRequest: {
      userKey: me.key,
      closed: false,
      size: PAGE_SIZE,
      page: 0,
    },
    onRequest: SubmissionsApi.getSubmissions,
    onGenerateNextRequest: generateNextRequest
  });

  useEffect(() => {
    if (open) {
      updateRequest({ before: undefined });
    }
  }, [open, updateRequest]);

  const handleClose = (event: React.MouseEvent<Document>) => {
    if (anchorRef.current && anchorRef.current.contains(event.currentTarget)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <IconButton
        id="OpenSubmissions-openButton"
        ref={anchorRef}
        aria-owns={open ? 'OpenSubmissions-popover' : undefined}
        aria-haspopup="true"
        onClick={() => setOpen(true)}
        color="inherit"
        size="large"
        aria-label={
          intl.formatMessage({
            id: 'components.appbar.openSubmissions.openButton.ariaLabel',
            defaultMessage: '{openSubmissions} open {openSubmissions,plural,one{submission}other{submissions}}',
          }, {
            openSubmissions: appBarStatsContext.openSubmissions
          })
        }
      >
        <Badge
          badgeContent={appBarStatsContext.openSubmissions}
          color="secondary"
        >
          <AssessmentIcon />
        </Badge>
      </IconButton>
      <Popover
        id="OpenSubmissions-popover"
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableScrollLock
      >
        <Box display="flex" flexDirection="column" sx={{ maxHeight: "70vh", width: 400, overflowY: 'auto' }}>
          {items.map((submission, index) => (
            <div ref={index === items.length - 1 ? lastItemRef : undefined} key={submission.reference}>
              <OpenSubmission submission={submission} onNavigate={() => setOpen(false)} />
              <Divider />
            </div>
          ))}
          {processing && <OpenSubmissionSkeleton />}
          {
            !moreItems &&
            <Box display="flex" flexDirection="column" alignItems="center" m={2}>
              <Box mb={1}>
                <AssessmentOutlinedIcon fontSize="large" />
              </Box>
              <Typography variant="subtitle1" align="center">
                <FormattedMessage id="components.appBar.openSubmissions.noMoreSubmissions" defaultMessage="There are no more open submissions" />
              </Typography>
            </Box>
          }
        </Box>
      </Popover>
    </>
  );
};

export default OpenSubmissions;
