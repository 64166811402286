import { FC, PropsWithChildren } from 'react';

import { Paper, Skeleton } from '@mui/material';

const AvatarPaperSkeleton: FC<PropsWithChildren> = ({ children }) => (
  <Paper sx={{ mt: 3, p: (theme) => theme.spacing(1, 3, 1.5), height: (theme) => `calc(100% - ${theme.spacing(3)})` }}>
    <Skeleton
      variant="circular"
      sx={{ mt: -4, mr: 1, float: 'left', height: (theme) => theme.spacing(8), width: (theme) => theme.spacing(8), }}
    />
    {children}
  </Paper>
);
export default AvatarPaperSkeleton;
