import { FC, useContext, useEffect, useState } from "react";
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { FormattedMessage } from "react-intl";
import { useSnackbar } from "notistack";

import { Dialog, DialogContent, DialogTitle, MenuItem } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { extractErrorMessage } from "../../../../../../api/endpoints";
import { DefaultButton, FullWidthButton, PaddedDialogActions, ValidatedTextField } from "../../../../../../components";
import { intl } from "../../../../../../Internationalization";

import {
  SchemaMappingContext, SchemaMappingDirection, schemaMappingDirections, SCHEMA_MAPPING_DIRECTION_METADATA
} from '../../../../../components/schema-mapper';

import { OpenSubmissionContext } from "../OpenSubmissionContext";
import SchemaPdf from './SchemaPdf';

interface ExportSchemaProps {
  disabled: boolean;
}
const ExportSchema: FC<ExportSchemaProps> = ({ disabled }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { project, specification, assignment } = useContext(OpenSubmissionContext);
  const {
    sourceDataStores, targetDataStores, direction, schemaMappings, validationResults, dataStoreConfigs
  } = useContext(SchemaMappingContext);

  const [exportSchemaDirection, setExportSchemaDirection] = useState<SchemaMappingDirection>(direction);
  const [creatingPdf, setCreatingPdf] = useState<boolean>();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (dialogOpen) {
      setExportSchemaDirection(direction);
    }
  }, [direction, dialogOpen]);

  const handleCreatePdf = async () => {
    setCreatingPdf(true);

    const title = `${project.name} - ${specification.name} - ${assignment.reference}`;
    try {
      const blob = await pdf(
        <SchemaPdf
          title={title}
          schemaMappings={schemaMappings}
          validationResults={validationResults}
          sourceDataStores={sourceDataStores}
          targetDataStores={targetDataStores}
          direction={exportSchemaDirection}
          dataStoreConfigs={dataStoreConfigs}
        />
      ).toBlob();
      saveAs(
        blob,
        intl.formatMessage({
          id: 'openSubmission.schemaMapping.schemaMapper.pdfFilename',
          defaultMessage: 'Schema Mapping - {assignmentReference}.pdf'
        }, { assignmentReference: assignment.reference })
      );
      handleCloseDialog();
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'openSubmission.schemaMapping.schemaMapper.pdfGenerateError',
          defaultMessage: 'Failed to generate PDF'
        })), { variant: 'error' });
    } finally {
      setCreatingPdf(false);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  return (
    <>
      <FullWidthButton
        startIcon={<PictureAsPdfIcon />}
        color="primary"
        onClick={handleOpenDialog}
        disabled={creatingPdf || disabled}
        label={intl.formatMessage({
          id: "openSubmission.schemaMapping.schemaMapper.exportSchemaButton",
          defaultMessage: "Export Schema"
        })}
      />
      <Dialog open={dialogOpen} onClose={handleCloseDialog} id="schema-mapping-export-schema-dialog" fullWidth maxWidth="sm">
        <DialogTitle>
          <FormattedMessage id="openSubmission.schemaMapping.schemaMapper.exportDialog.title" defaultMessage="Export schema" />
        </DialogTitle>
        <DialogContent>
          <ValidatedTextField
            disabled={creatingPdf}
            name="direction"
            label={intl.formatMessage({ id: 'openSubmission.schemaMapping.schemaMapper.exportDialog.toggleLabel', defaultMessage: 'Schema mapping direction' })}
            value={exportSchemaDirection}
            onChange={(event) => setExportSchemaDirection(event.target.value as SchemaMappingDirection)}
            margin="normal"
            variant="outlined"
            select
          >
            {schemaMappingDirections.map((mappingDirection) => (
              <MenuItem key={mappingDirection} value={SchemaMappingDirection[mappingDirection]}>
                {SCHEMA_MAPPING_DIRECTION_METADATA[mappingDirection].label}
              </MenuItem>
            ))}
          </ValidatedTextField>
        </DialogContent>
        <PaddedDialogActions>
          <DefaultButton
            disabled={creatingPdf}
            onClick={handleCloseDialog}
            color="secondary"
            id="cancel-export"
          >
            <FormattedMessage id="openSubmission.schemaMapping.schemaMapper.exportDialog.cancelButton" defaultMessage="Close" />
          </DefaultButton>
          <DefaultButton
            disabled={creatingPdf}
            onClick={handleCreatePdf}
            id="export-schema"
          >
            <FormattedMessage id="openSubmission.schemaMapping.schemaMapper.exportDialog.exportButton" defaultMessage="Export" />
          </DefaultButton>
        </PaddedDialogActions>
      </Dialog>
    </>
  );
};

export default ExportSchema;
