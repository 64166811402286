export const extractInitials = (name: string) => {
  var names = name.split(/\s\W*/);
  if (names.length === 0) {
    return "";
  }
  if (names.length === 1) {
    return firstCharacter(names[0]);
  }
  return firstCharacter(names[0]) + firstCharacter(names[names.length - 1]);
};

const firstCharacter = (name: string) => name.substring(0, 1).toUpperCase();
