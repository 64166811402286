import { FC, PropsWithChildren, useContext } from 'react';
import { AuthenticatedContext } from '../contexts/authentication';

const ReceiverAdminOnly: FC<PropsWithChildren> = ({ children }) => {
  const { me } = useContext(AuthenticatedContext);
  return (
    <>
      {me.receiverPermissions?.admin && children}
    </>
  );
};

export default ReceiverAdminOnly;
