import { FC, useCallback, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useSnackbar } from "notistack";

import { TableCell, TableRow } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import * as AssignmentSavedMappingsApi from '../../../../api/assignmentSavedMappings';
import * as AssignmentSavedMappingApi from '../../../../api/assignmentSavedMapping';
import { extractErrorMessage, RequestConfig } from "../../../../api/endpoints";
import {
  BrowseTable, browseTableBody, ButtonRow, ConfirmDialog, DefaultButton, FilterBar, FilterPagination,
  FilterSearch, MinWidthTableCell, StyledTableHead
} from "../../../../components";
import { intl } from "../../../../Internationalization";
import { useBrowseRequest } from "../../../../hooks";
import { SavedMappingSummary, AssignmentSavedMappingsRequest } from "../../../../types";
import { dateTimeFormat } from "../../../../util";

import { MyAssignmentContext } from "../MyAssignmentContext";
import AddSavedMappingDialog from "./AddSavedMappingDialog";

export const toSavedMapping = (assignmentKey: string, savedMappingKey: string) => (
  `/my_assignments/${assignmentKey}/saved_mappings/${savedMappingKey}`
);

const PAGE_SIZE = 10;

const SavedMappingsTableBody = browseTableBody<SavedMappingSummary>();

const SavedMappings: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { assignmentKey } = useContext(MyAssignmentContext);
  const { request, response, processing, updateRequest, setPage, refresh } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, filter: '' },
    onRequest: useCallback((req: AssignmentSavedMappingsRequest, config?: RequestConfig) => (
      AssignmentSavedMappingsApi.getSavedMappings(assignmentKey, req, config)), [assignmentKey]),
  });
  const [mappingToDelete, setMappingToDelete] = useState<SavedMappingSummary>();

  const handleFilterUpdate = useCallback((filter: string) => updateRequest({ filter }), [updateRequest]);

  const handleDeleteConfirm = async () => {
    if (!mappingToDelete) {
      return;
    }
    try {
      await AssignmentSavedMappingApi.deleteSavedMapping(assignmentKey, mappingToDelete.key);
      refresh();
      enqueueSnackbar(intl.formatMessage({
        id: 'myAssignment.savedMappings.deleteSuccess',
        defaultMessage: 'Saved mapping has been deleted'
      }), { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'myAssignment.savedMappings.deleteError',
        defaultMessage: 'Failed to delete saved mapping'
      })), { variant: "error" });
    } finally {
      setMappingToDelete(undefined);
    }
  };

  const savedMappingRow = (savedMapping: SavedMappingSummary) => (
    <TableRow key={savedMapping.key}>
      <TableCell>{savedMapping.name}</TableCell>
      <TableCell>{dateTimeFormat(savedMapping.createEvent.performedAt)}</TableCell>
      <MinWidthTableCell>
        <ButtonRow whiteSpace="nowrap">
          <DefaultButton
            className="SavedMappings-navigateToMappingButton"
            color="grey"
            component={Link}
            to={toSavedMapping(assignmentKey, savedMapping.key)}
            aria-label={intl.formatMessage({
              id: 'myAssignment.savedMappings.navigateToSavedMapping.ariaLabel',
              defaultMessage: 'Navigate to saved mapping'
            })}
          >
            <EditIcon />
          </DefaultButton>
          <DefaultButton
            className="SavedMappings-deleteMappingButton"
            color="grey"
            onClick={() => setMappingToDelete(savedMapping)}
            aria-label={intl.formatMessage({
              id: 'myAssignment.savedMappings.deleteSavedMapping.ariaLabel',
              defaultMessage: 'Delete saved mapping'
            })}
          >
            <DeleteIcon />
          </DefaultButton>
        </ButtonRow>
      </MinWidthTableCell>
    </TableRow>
  );

  return (
    <>
      <FilterBar
        startInput={
          <FilterSearch
            placeholder={intl.formatMessage({
              id: 'myAssignment.savedMappings.filterSearch.placeholder',
              defaultMessage: 'Filter saved mappings'
            })}
            onSearch={handleFilterUpdate}
          />
        }
        actions={
          <FilterPagination page={request.page} size={request.size} total={response?.total} disabled={processing} setPage={setPage} />
        }
      />
      <BrowseTable>
        <StyledTableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="myAssignment.savedMappings.table.nameColumn" defaultMessage="Name" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="myAssignment.savedMappings.table.createdAtColumn" defaultMessage="Created At" />
            </TableCell>
            <TableCell />
          </TableRow>
        </StyledTableHead>
        <SavedMappingsTableBody
          data={response?.results}
          mapToRow={savedMappingRow}
          noDataMessage={intl.formatMessage({
            id: 'myAssignment.savedMappings.table.noMappings',
            defaultMessage: 'No matching saved mappings.'
          })}
          numCols={3}
        />
      </BrowseTable>
      <ConfirmDialog
        id="savedMappings-confirmDeleteMapping"
        isOpen={!!mappingToDelete}
        title={intl.formatMessage({
          id: 'myAssignment.savedMappings.confirmDeleteMapping.title',
          defaultMessage: 'Delete saved mapping'
        })}
        text={intl.formatMessage({
          id: 'myAssignment.savedMappings.confirmDeleteMapping.text',
          defaultMessage: 'Are you sure you wish to delete the saved mapping: {name}?'
        }, { name: mappingToDelete?.name }
        )}
        confirmBtnText={intl.formatMessage({
          id: 'myAssignment.savedMappings.confirmDeleteMapping.button',
          defaultMessage: 'Delete saved mapping'
        })}
        confirmAction={handleDeleteConfirm}
        closeAction={() => setMappingToDelete(undefined)}
      />
      <AddSavedMappingDialog />
    </>
  );
};

export default SavedMappings;
