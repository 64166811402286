import ConditionalTableCells, { ColumnContent, ColumnStyle } from './ConditionalTableCells';
import { StringKey } from '../../util/types';

interface SelectedTableCellsProps<T extends Record<string, ColumnContent>> {
  columnKeys: readonly (StringKey<T>)[];
  columnSelections: Record<StringKey<T>, boolean>;
  values: T;
  styles: Partial<Record<StringKey<T>, ColumnStyle>>;
}

function SelectedTableCells<T extends { [key: string]: ColumnContent }>({
  columnKeys, columnSelections, ...rest
}: SelectedTableCellsProps<T>) {
  return <ConditionalTableCells selections={columnKeys.filter(k => !!columnSelections[k])} {...rest} />;
}

export default SelectedTableCells;
