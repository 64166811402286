import { useState, useEffect, FC } from 'react';

import { TooltipItem } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import truncate from 'lodash/truncate';

import AssessmentIcon from '@mui/icons-material/Assessment';

import { ValuePercentage } from '../../../types';
import { ChartPaper } from '../../../components';
import { substringRuleNameFromPath } from '../../components/submission/taskResultUtils';
import { chartColors, extractTopNDatasets, truncateTitle, limitLabelValueToTwoDecimals } from '../../../util';
import { intl } from '../../../Internationalization';

const MAX_BARS = 10;

interface TopRulesChartProps {
  ruleConformance: ValuePercentage[];
}

export const populateTopRulesData = (ruleConformance: ValuePercentage[]) => {
  const sortedDatasets = extractTopNDatasets(ruleConformance, MAX_BARS);
  const colorScheme = chartColors(sortedDatasets.length);

  return ({
    labels: sortedDatasets.map(dataset => substringRuleNameFromPath(dataset.x)),
    legend: {
      position: 'right'
    },
    datasets: [
      {
        label: intl.formatMessage({
          id: 'dashboard.specification.topRulesChart.dataset.label',
          defaultMessage: 'Top Rules'
        }),
        backgroundColor: colorScheme,
        borderColor: '#ffffff',
        borderWidth: 1,
        data: sortedDatasets.map(dataset => dataset.y * 100)
      }
    ]
  });
};

const TopRulesChart: FC<TopRulesChartProps> = ({ ruleConformance }) => {
  const [topRulesData, setTopRulesData] = useState(populateTopRulesData(ruleConformance));
  useEffect(() => {
    setTopRulesData(populateTopRulesData(ruleConformance));
  }, [ruleConformance]);

  // Checking to see if all Non-conformances are less than 5
  const hasMaximumGreaterThanFive = topRulesData.datasets[0].data.filter(dataPoint => dataPoint > 5).length;

  return (
    <ChartPaper
      icon={AssessmentIcon}
      iconColor="#66bb6a"
      title={intl.formatMessage({
        id: 'dashboard.specification.topRulesChart.title',
        defaultMessage: 'Top violated rules'
      })}
    >
      <Bar
        data={topRulesData}
        options={{
          indexAxis: 'y',
          plugins: {
            tooltip: {
              callbacks: {
                title: (tooltipItem: TooltipItem<"bar">[]) => truncateTitle(tooltipItem, 64),
                label: limitLabelValueToTwoDecimals
              },
            }
          },
          scales: {
            x: {
              title: {
                display: true,
                text: intl.formatMessage({
                  id: 'dashboard.specification.topRulesChart.xAxis.label',
                  defaultMessage: 'Non-conformance %'
                })
              },
              max: hasMaximumGreaterThanFive ? undefined : 5,
              beginAtZero: true,
              ticks: {
                stepSize: 1,
              }
            },
            y: {
              title: {
                display: true,
                text: intl.formatMessage({
                  id: 'dashboard.specification.topRulesChart.yAxis.label',
                  defaultMessage: 'Rules'
                }),
              },
              ticks: {
                autoSkip: false,
                callback: function (value) {
                  return truncate(this.getLabelForValue(Number(value)), { 'length': 25 });
                }
              },
            }
          },
        }}
      />
    </ChartPaper>
  );
};

export default TopRulesChart;
