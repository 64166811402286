
import React, { ReactNode, FC, CSSProperties } from 'react';
import { PaperProps } from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

import { AvatarPaper } from '../../components';

interface ChartPaperProps extends PaperProps {
  children: ReactNode;
  icon: React.ComponentType<SvgIconProps>;
  iconColor: CSSProperties['backgroundColor'];
  title: string;
}

const ChartPaper: FC<ChartPaperProps> = ({ sx = [], children, icon, iconColor, title }) => (
  <AvatarPaper
    className="ChartPaper"
    sx={[{
      textAlign: 'center',
      '& canvas': {
        width: '100% !important'
      }
    }, ...(Array.isArray(sx) ? sx : [sx])]}
    icon={icon}
    iconColor={iconColor}
  >
    <Typography sx={{ m: (theme) => theme.spacing(1, 9.5, 1.5, 0) }} variant="h6" color="textSecondary">{title}</Typography>
    {children}
  </AvatarPaper>
);

export default ChartPaper;
