import { useRef, FC, PropsWithChildren } from 'react';
import copy from "clipboard-copy";
import { useSnackbar } from 'notistack';

import { Tooltip, Box } from '@mui/material';
import FilterNoneIcon from '@mui/icons-material/FilterNone';

import { intl } from '../Internationalization';
import { DefaultButton } from '.';

export const CopyToClipboard: FC<PropsWithChildren> = ({ children }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipboard = () => {
    if (contentRef.current) {
      copy(contentRef.current.innerText);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'components.copyToClipboard.copySuccess',
          defaultMessage: 'Copied to clipboard'
        }), { variant: 'info' });
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignContent="center" alignItems="center">
      <div ref={contentRef}>
        {children}
      </div>
      <Tooltip
        title={intl.formatMessage({
          id: 'components.copyToClipboard.tooltip',
          defaultMessage: 'Copy to clipboard'
        })}
      >
        <DefaultButton
          color="grey"
          size="small"
          onClick={copyToClipboard}
          aria-label={intl.formatMessage({
            id: 'components.copyToClipboard.button.ariaLabel',
            defaultMessage: "Copy to clipboard"
          })}
          sx={{ ml: 2 }}
        >
          <FilterNoneIcon />
        </DefaultButton>
      </Tooltip>
    </Box>
  );
};
