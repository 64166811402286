import { FC } from 'react';

import { Button, ButtonProps, CircularProgress, styled } from '@mui/material';

interface FullWidthButtonProps extends Exclude<ButtonProps, "variant"> {
  label: string;
  processing?: boolean;
}

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  margin: theme.spacing(0, 1),
  padding: theme.spacing(2, 4),
  width: "100%",
  '&:last-child': {
    marginRight: 0,
  },
  '&:first-child': {
    marginLeft: 0,
  },
}));

const FullWidthButton: FC<FullWidthButtonProps> = ({ processing, label, disabled, ...rest }) => (
  <StyledButton
    variant="contained"
    disabled={disabled || processing}
    {...rest}
  >
    {label}
    {processing && (
      <CircularProgress sx={{ position: 'absolute', }} size={20} />
    )}
  </StyledButton>
);

export default FullWidthButton;
