import { FC, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { DialogContent, Divider } from '@mui/material';

import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';

import { validate } from '../../../../../util';
import { intl } from '../../../../../Internationalization';
import * as AssignmentApi from '../../../../../api/assignment';
import * as AssignmentsApi from '../../../../../api/assignments';
import { extractErrorMessage } from '../../../../../api/endpoints';
import { AssignmentSettings, SupplierDetail } from '../../../../../types';
import { ActiveSupplierAutocomplete, ValidatedTextField, PaddedDialogActions, DefaultButton } from '../../../../../components';

import { SpecificationContext } from '../SpecificationContext';
import { toAssignmentUrl } from '../Assignments';

interface NewAssignmentFormProps {
  onClose: () => void;
}

const NewAssignmentForm: FC<NewAssignmentFormProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const context = useContext(SpecificationContext);
  const { enqueueSnackbar } = useSnackbar();

  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const [reference, setReference] = useState<string>('');
  const [supplier, setSupplier] = useState<SupplierDetail | null>(null);

  const selectSupplier = (newSupplier: SupplierDetail | null) => {
    setSupplier(newSupplier);
    if (newSupplier) {
      setReference(newSupplier.name);
    }
  };

  const collectCreateAssignmentSettings = (): Partial<AssignmentSettings> => ({
    reference,
    active: true,
    restricted: false,
    specificationKey: context.specification.key,
    supplierKey: supplier?.key
  });

  const validateAndSubmitCreateAssignment = async () => {
    setProcessing(true);
    setFieldErrors(undefined);
    const settings = collectCreateAssignmentSettings();
    try {
      createAssignment(
        await validate(AssignmentApi.assignmentSettingsValidator(() => context.specificationKey), settings)
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const createAssignment = async (settings: AssignmentSettings) => {
    try {
      const { data: assignment } = await AssignmentsApi.createAssignment(settings);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'assignment.create.createSuccess',
          defaultMessage: 'Assignment {reference} has been created'
        }, { reference: assignment.reference }),
        { variant: "success" }
      );
      navigate(toAssignmentUrl(assignment));
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'assignment.create.createError',
          defaultMessage: 'Failed to create assignment'
        })
      ), { variant: "error" });
      setProcessing(false);
    }
  };

  return (
    <>
      <DialogContent>
        <ActiveSupplierAutocomplete
          id="active-supplier-select"
          tooltip={
            intl.formatMessage({
              id: 'assignment.create.supplierKey.tooltip',
              defaultMessage: 'Users from this supplier will be able to open submissions if they have the necessary permissions.'
            })
          }
          name="supplierKey"
          label={intl.formatMessage({
            id: 'assignment.create.supplierKey.label',
            defaultMessage: 'Supplier'
          })}
          variant="outlined"
          margin="normal"
          fieldErrors={fieldErrors}
          disabled={processing}
          value={supplier}
          onChange={selectSupplier}
          fullWidth
        />
        <ValidatedTextField
          tooltip={
            intl.formatMessage({
              id: 'assignment.create.reference.tooltip',
              defaultMessage: 'A reference for the assignment, unique within the specification.'
            })
          }
          fieldErrors={fieldErrors}
          disabled={processing}
          name="reference"
          label={intl.formatMessage({
            id: 'assignment.create.reference.label',
            defaultMessage: 'Reference'
          })}
          value={reference}
          onChange={event => setReference(event.target.value)}
          margin="normal"
          variant="outlined"
          fullWidth
        />
      </DialogContent>
      <Divider />
      <PaddedDialogActions>
        <DefaultButton
          name="cancelAddNewAssignment"
          color="secondary"
          onClick={onClose}
          disabled={processing}
        >
          <FormattedMessage id="assignment.create.cancelButton" defaultMessage="Cancel" />
        </DefaultButton>
        <DefaultButton
          name="addNewAssignment"
          onClick={validateAndSubmitCreateAssignment}
          disabled={processing}
          startIcon={<AddIcon />}
        >
          <FormattedMessage id="assignment.create.createButton" defaultMessage="Add Assignment" />
        </DefaultButton>
      </PaddedDialogActions>
    </>
  );
};

export default NewAssignmentForm;
