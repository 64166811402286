import React, { FC, useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import { Paper, MenuItem, Divider, TextField, ButtonGroup, Tabs, Box } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LayersIcon from '@mui/icons-material/Layers';
import ListIcon from '@mui/icons-material/List';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';

import * as SubmissionApi from '../../../../../../../api/submission';
import { extractErrorMessage } from '../../../../../../../api/endpoints';
import { DefaultButton } from '../../../../../../../components';
import InlineIconTab from '../../../../../../../components/tabs/InlineIconTab';
import {
  MapLayerStyles, TaskResult, TaskMapConfig, TaskResultKind, TaskState,
  taskResultMetaData, ObjectReport, SubmissionDetail, taskResultLabel, TASK_STATE_METADATA
} from '../../../../../../../types';
import { intl } from '../../../../../../../Internationalization';
import { getTaskStateMetadata } from '../../../../../../components/submission/taskResultUtils';

import { LayerSelections, ObjectFeature } from '../MapContainer';
import LayersPanel from './LayersPanel';
import ReportPanel from './ReportPanel';
import FeaturePanel from './FeaturePanel';

const isTaskSelectable = (task: TaskResult) => (
  task.status === TaskState.FINISHED || task.status === TaskState.PAUSED || task.status === TaskState.SKIPPED
);
const isFinalTask = (taskLength: number, taskIndex: number) => taskIndex === taskLength - 1;
const isFinalPauseTask = (taskType: TaskResultKind, taskLength: number, taskIndex: number) => (
  taskType === TaskResultKind.PauseTask && isFinalTask(taskLength, taskIndex)
);

interface MapMenuProps {
  submission: SubmissionDetail;

  // task selection
  taskResult: TaskResult;
  setTaskResult: (taskResult: TaskResult) => void;
  taskMapConfig: TaskMapConfig;

  // layer selection
  layerSelections: LayerSelections;
  setLayerSelections: (value: React.SetStateAction<LayerSelections>) => void;

  // feature selection
  featureList: ObjectFeature[];
  selectedFeature?: ObjectFeature;
  setSelectedFeature: (feature?: ObjectFeature) => void;
  setHoveredFeature: (feature?: ObjectFeature) => void;
  zoomToFeature: () => void;

  // object report
  objectReport?: ObjectReport;
  reportStart: number;
  loadingObjectReport: boolean;
  setReportStart: (start: number) => void;
}

const MapMenu: FC<MapMenuProps> = ({
  submission,
  // task selection
  taskResult, taskMapConfig, setTaskResult,
  // layer selection
  layerSelections, setLayerSelections,
  // feature selection
  featureList, selectedFeature, setSelectedFeature, setHoveredFeature, zoomToFeature,
  // object report
  objectReport, reportStart, loadingObjectReport, setReportStart
}) => {
  const { taskResults } = submission;
  const [selectedTab, setSelectedTab] = useState<string>('layers');
  const [mapStyles, setMapStyles] = useState<MapLayerStyles>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (featureList.length) {
      setSelectedFeature(featureList[0]);
    }
  }, [featureList, setSelectedFeature]);

  useEffect(() => {
    SubmissionApi.proxyWmsStyle(submission.reference, taskResult.taskIdentifier, Object.keys(layerSelections))
      .then(response => {
        setMapStyles(response.data);
      })
      .catch((error: AxiosError) => {
        enqueueSnackbar(extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'openSubmission.map.menu.mapMenu.loadStylesError',
            defaultMessage: 'Failed to fetch map styles'
          })
        ), { variant: "error" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentTaskIndex = (): number => taskResults.findIndex(result => result.taskIdentifier === taskResult.taskIdentifier);

  const handleSelectTask = (e: React.ChangeEvent<HTMLInputElement>) => {
    const taskIdentifier = e.target.value;
    const selectedTaskResult = taskResults.find(result => result.taskIdentifier === taskIdentifier);
    if (selectedTaskResult) {
      setTaskResult(selectedTaskResult);
    }
  };

  const renderTaskControlButtons = () => {
    const currentTaskIndex = getCurrentTaskIndex();
    const previousTask = taskResults[currentTaskIndex - 1];
    const previousTaskDisabled = !previousTask;
    const nextTask = taskResults[currentTaskIndex + 1];
    const nextTaskDisabled = !nextTask || !isTaskSelectable(nextTask) || isFinalTask(taskResults.length, currentTaskIndex + 1);

    return (
      <ButtonGroup sx={{ width: '100%', '& button': { width: '50%' } }}>
        <DefaultButton
          onClick={() => setTaskResult(previousTask)}
          variant="text"
          disabled={previousTaskDisabled}
          startIcon={<ArrowBackIosIcon fontSize="small" />}
        >
          <FormattedMessage id="openSubmission.map.menu.mapMenu.prevTaskButton" defaultMessage="Prev Task" />
        </DefaultButton>
        <DefaultButton
          onClick={() => setTaskResult(nextTask)}
          variant="text"
          disabled={nextTaskDisabled}
          endIcon={<ArrowForwardIosIcon fontSize="small" />}
        >
          <FormattedMessage id="openSubmission.map.menu.mapMenu.nextTaskButton" defaultMessage="Next Task" />
        </DefaultButton>
      </ButtonGroup>
    );
  };

  const generateSelectTaskOptions = () => taskResults.filter(
    (task, index) => !isFinalPauseTask(task.kind, taskResults.length, index)
  ).map((task) => {

    const { icon: Icon } = taskResultMetaData(task);
    const { icon: TaskStateIcon } = getTaskStateMetadata(task);

    return (
      <MenuItem
        key={task.taskIdentifier}
        disabled={!isTaskSelectable(task)}
        value={task.taskIdentifier}
      >
        <Icon sx={{ mr: 1 }} />
        {taskResultLabel(task)}
        <Box flexGrow={1} />
        <TaskStateIcon
          fontSize="small"
          titleAccess={TASK_STATE_METADATA[task.status].label}
        />
      </MenuItem>
    );
  });

  return (
    <Paper
      sx={{
        position: "absolute",
        right: 0,
        zIndex: 5,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        height: '100%',
        width: 400,
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div id="map-controls-group">
        <TextField
          sx={{
            width: '100%',
            '& .MuiSelect-select': {
              p: (theme) => theme.spacing(1, 3, 1, 1),
              display: 'flex',
              alignItems: 'center'
            }
          }}
          select
          id="task-selector"
          SelectProps={{
            SelectDisplayProps: {
              id: "task-selector-select"
            }
          }}
          value={taskResult.taskIdentifier}
          onChange={handleSelectTask}
          variant="standard"
        >
          {generateSelectTaskOptions()}
        </TextField>
        {renderTaskControlButtons()}
        <Divider />
        <Tabs
          value={selectedTab}
          onChange={(event, value) => setSelectedTab(value)}
          aria-label="map-control-tabs"
          variant="fullWidth"
          sx={{ '& button': { minWidth: 105 } }}
        >
          <InlineIconTab
            label={intl.formatMessage({
              id: 'openSubmission.map.menu.mapMenu.tabs.layers',
              defaultMessage: 'Layers'
            })}
            value="layers"
            icon={<LayersIcon />}
            aria-controls="map-control-tabpanel-layers"
            id="map-control-tabs-layers"
          />
          <InlineIconTab
            label={intl.formatMessage({
              id: 'openSubmission.map.menu.mapMenu.tabs.feature',
              defaultMessage: 'Feature'
            })}
            value="feature"
            icon={<ListIcon />}
            aria-controls="map-control-tabpanel-feature"
            id="map-control-tabs-feature"
          />
          <InlineIconTab
            label={intl.formatMessage({
              id: 'openSubmission.map.menu.mapMenu.tabs.report',
              defaultMessage: 'Report'
            })}
            value="report"
            icon={<AssignmentLateIcon />}
            aria-controls="map-control-tabpanel-report"
            id="map-control-tabs-report"
          />
        </Tabs>
      </div>
      <Box
        sx={{
          overflowY: 'hidden',
          height: '100%'
        }}
      >
        {selectedTab === 'layers' && (
          <LayersPanel
            mapStyles={mapStyles}
            taskMapConfig={taskMapConfig}
            layerSelections={layerSelections}
            setLayerSelections={setLayerSelections}
          />
        )}
        {selectedTab === 'feature' && (
          <FeaturePanel
            mapStyles={mapStyles}
            featureList={featureList}
            selectedFeature={selectedFeature}
            setSelectedFeature={setSelectedFeature}
            setHoveredFeature={setHoveredFeature}
          />
        )}
        {selectedTab === 'report' && (
          <ReportPanel
            objectReport={objectReport}
            reportCount={taskResult.reportCount}
            reportStart={reportStart}
            setReportStart={setReportStart}
            zoomToFeature={zoomToFeature}
            loading={loadingObjectReport}
          />
        )}
      </Box>
    </Paper>
  );
};

export default MapMenu;
