import { FC } from 'react';
import { Card, CardActionArea, CardHeader, Avatar, Typography, CardContent } from '@mui/material';

import { MailTemplateMetadata } from '../../../../types';

interface TemplateCardStyleProps {
  selected?: boolean;
}

interface TemplateCardProps extends TemplateCardStyleProps {
  template: MailTemplateMetadata;
  onSelect: () => void;
}

const TemplateCard: FC<TemplateCardProps> = ({ selected, onSelect, template: { name, title, description, icon: Icon } }) => (
  <Card sx={{ height: '100%', '& button': { height: '100%', } }} elevation={selected ? 4 : 1}>
    <CardActionArea onClick={onSelect} name={`template-card-${name}`} className="TemplateCard">
      <CardHeader
        className="TemplateCardHeader"
        title={title}
        titleTypographyProps={{ variant: "subtitle1" }}
        avatar={
          <Avatar sx={{ bgcolor: (theme) => selected ? 'info.main' : theme.palette.grey[400] }}>
            <Icon />
          </Avatar>
        }
      />
      <CardContent>
        <Typography variant="body2">
          {description}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

export default TemplateCard;
