import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ForwardIcon from '@mui/icons-material/Forward';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { IdentityProviderType } from '../../../types';
import { DefaultButton, FormButtons, PaddedPaper } from '../../../components';
import { intl } from '../../../Internationalization';

interface IdentityProviderInformationProps {
  identityProviderType: IdentityProviderType;
  identityProviderKey: string;
}

const calculateRootUrl = () => new URL("/", window.location.toString());
const resolveUrl = (relativePath: string) => new URL(relativePath, calculateRootUrl()).toString();

const IdentityProviderInformation: FC<IdentityProviderInformationProps> = ({ identityProviderType, identityProviderKey }) => (
  <PaddedPaper>
    <Typography variant="h5" gutterBottom>
      <FormattedMessage id="identityProvider.information.title" defaultMessage="Configuration Details" />
    </Typography>
    {
      identityProviderType === IdentityProviderType.SAML2 &&
      <>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                #
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={intl.formatMessage({
                id: 'identityProvider.information.entityId.label',
                defaultMessage: 'Entity Id'
              })}
              secondary={resolveUrl(`saml2/service-provider-metadata/${identityProviderKey}`)}
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <VpnKeyIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={intl.formatMessage({
                id: 'identityProvider.information.ssoUrl.label',
                defaultMessage: 'SSO URL'
              })}
              secondary={resolveUrl(`login/saml2/sso/${identityProviderKey}`)}
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <ForwardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={intl.formatMessage({
                id: 'identityProvider.information.loginUrl.label',
                defaultMessage: 'Login URL'
              })}
              secondary={resolveUrl(`saml2/authenticate/${identityProviderKey}`)}
            />
          </ListItem>
        </List>
        <Divider variant="fullWidth" />
        <FormButtons sx={{ display: 'flex', justifyContent: 'end' }}>
          <DefaultButton
            name="samlDownloadButton"
            href={resolveUrl(`saml2/service-provider-metadata/${identityProviderKey}`)}
            download
            color="secondary"
            startIcon={<CloudDownloadIcon />}
          >
            <FormattedMessage id="identityProvider.information.samlMetadata.label" defaultMessage="SAML2.0 Metadata" />
          </DefaultButton>
        </FormButtons>
      </>
    }
    {
      identityProviderType === IdentityProviderType.OAUTH2 &&
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <VpnKeyIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={intl.formatMessage({
              id: 'identityProvider.information.ssoUrl.label',
              defaultMessage: 'SSO URL'
            })}
            secondary={resolveUrl(`login/oauth2/code/${identityProviderKey}`)}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <ForwardIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={intl.formatMessage({
              id: 'identityProvider.information.loginUrl.label',
              defaultMessage: 'Login URL'
            })}
            secondary={resolveUrl(`oauth2/authorize/${identityProviderKey}`)}
          />
        </ListItem>
      </List>
    }
  </PaddedPaper>
);

export default IdentityProviderInformation;
