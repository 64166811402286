import { FC, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { MenuItem, TextField, Grid, InputLabel } from '@mui/material';

import { SchemaMappingContext } from './SchemaMappingContext';
import { aggregateMappingStatus } from './schemaMappingUtils';
import MappingStatusIcon from './MappingStatusIcon';

const DataStoreSelect: FC = () => {
  const { selectedDataStoreName, handleSelectDataStoreName, sourceDataStores, validationResults } = useContext(SchemaMappingContext);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <InputLabel>
          <FormattedMessage id="schemaMapper.dataStoreSelect.dataSet.label" defaultMessage="Data Set" />
        </InputLabel>
      </Grid>
      <Grid item xs={12}>
        <InputLabel>
          <TextField
            select
            fullWidth
            value={selectedDataStoreName}
            onChange={(event) => handleSelectDataStoreName(event.target.value)}
            sx={{
              '& .MuiInput-input svg': {
                display: 'none'
              }
            }}
            variant="standard"
          >
            {sourceDataStores.map(({ path }) => {
              return (
                <MenuItem key={path} value={path}>
                  <MappingStatusIcon
                    status={aggregateMappingStatus(
                      validationResults.target.dataStoreValidationResults[path].status,
                      validationResults.source.dataStoreValidationResults[path].status
                    )}
                    sx={{ mr: 1 }}
                    iconSize="small"
                  />
                  {path}
                </MenuItem>
              );
            })}
          </TextField>
        </InputLabel>
      </Grid>
    </Grid>
  );
};

export default DataStoreSelect;
