import { FC } from 'react';
import { View, Text, Image } from '@react-pdf/renderer';

import { resultsPdfStyles } from '../../../../../components';
import { GREY, WHITE } from '../../../../../components/pdf/PdfIcons';

interface SubmissionAvatarProps {
  initials: string;
  backgroundColor?: string;
  textColor?: string;
  borderRadius?: number;
  imageSrc?: string;
  objectFit: 'cover' | 'contain';
}

export const SubmissionAvatar: FC<SubmissionAvatarProps> = ({
  backgroundColor = GREY, textColor = WHITE, borderRadius = 12, initials, imageSrc, objectFit
}) => (
  <View
    style={[
      resultsPdfStyles.rowContainer,
      {
        width: 24,
        height: 24,
        justifyContent: 'center',
        marginVertical: 1,
        backgroundColor: imageSrc ? undefined : backgroundColor, borderRadius
      }
    ]}
  >
    {
      imageSrc ? (
        <Image
          src={imageSrc}
          style={{ width: '100%', height: '100%', borderRadius, objectFit }}
        />
      ) : (
        <Text
          style={[{
            fontSize: 10,
            fontWeight: 500,
            textAlign: "center",
            color: textColor
          }]}
        >
          {initials}
        </Text>
      )
    }
  </View>
);
