import Schema from 'async-validator';
import { AxiosPromise } from 'axios';

import {
  SpecificationDetail, SpecificationSettings, DataStoreConfig, TaskConfig,
  SpecificationValidationResult, MediaDetail, SessionSchema, evaluateSpecificationValidationResult, DataStoreConfigDetail
} from '../types';
import { AXIOS, FileUploadConfig } from './endpoints';
import { NOT_BLANK_REGEX, duplicateValidator, notTrimmableValidator, nameLengthValidator, descriptionLengthValidator } from '../util';
import { intl } from '../Internationalization';
import { specificationByName } from './specificationLookup';

export function getSpecification(specificationKey: string): AxiosPromise<SpecificationDetail> {
  return AXIOS.get(`/specifications/${specificationKey}`);
}

export function updateSpecification(specificationKey: string, specification: SpecificationSettings): AxiosPromise<SpecificationDetail> {
  return AXIOS.post(`/specifications/${specificationKey}`, specification);
}

export function validateSpecification(specificationKey: string): AxiosPromise<SpecificationValidationResult> {
  return AXIOS.get(`/specifications/${specificationKey}/validate`);
}

export const isSpecificationValid = async (specificationKey: string) => {
  return evaluateSpecificationValidationResult((await AXIOS.get(`/specifications/${specificationKey}/validate`)).data);
};

export function getDataStoreConfigs(specificationKey: string): AxiosPromise<DataStoreConfigDetail[]> {
  return AXIOS.get(`/specifications/${specificationKey}/dataStores`);
}

export function updateDataStoreConfigs(specificationKey: string, request: DataStoreConfig[]): AxiosPromise<DataStoreConfigDetail[]> {
  return AXIOS.post(`/specifications/${specificationKey}/dataStores`, request);
}

export function getSessionSchema(specificationKey: string): AxiosPromise<SessionSchema> {
  return AXIOS.get(`/specifications/${specificationKey}/session`);
}

export function getTaskConfigs(specificationKey: string): AxiosPromise<TaskConfig[]> {
  return AXIOS.get(`/specifications/${specificationKey}/tasks`);
}

export function updateTaskConfigs(specificationKey: string, request: TaskConfig[]): AxiosPromise<TaskConfig[]> {
  return AXIOS.post(`/specifications/${specificationKey}/tasks`, request);
}

export function uploadDocument(specificationKey: string, files: File[], config: FileUploadConfig): AxiosPromise<MediaDetail[]> {
  var formData = new FormData();

  files.forEach(file => {
    formData.append('file', file);
  });

  return AXIOS.post(`/specifications/${specificationKey}/documents`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    ...(config || {})
  });
}

export function removeDocument(specificationKey: string, storedFilename: string): AxiosPromise<MediaDetail[]> {
  return AXIOS.delete(`/specifications/${specificationKey}/documents`, { params: { storedFilename } });
}

export function extractSpecificationSettings({
  name,
  description,
  active,
  sessionPath,
  allowSourceUnmapped,
  allowTargetUnmapped,
  showTargetUnmappedWarning,
  showInformation,
  documentation,
  timeoutPeriod,
  autoClosePeriod,
  mediaRetentionPeriod,
  mediaRetentionPolicy,
  conformanceMode
}: SpecificationDetail): SpecificationSettings {
  return {
    name,
    description,
    active,
    sessionPath,
    allowSourceUnmapped,
    allowTargetUnmapped,
    showTargetUnmappedWarning,
    showInformation,
    documentation,
    timeoutPeriod,
    autoClosePeriod,
    mediaRetentionPeriod,
    mediaRetentionPolicy,
    conformanceMode
  };
}

export function specificationSettingsValidator(getProjectKey: () => string, getExistingName?: () => string): Schema {
  return new Schema({
    name: [
      {
        required: true,
        pattern: NOT_BLANK_REGEX,
        message: intl.formatMessage({
          id: 'specification.validator.name.required',
          defaultMessage: 'Please provide a name'
        })
      },
      nameLengthValidator,
      notTrimmableValidator,
      duplicateValidator({
        regex: NOT_BLANK_REGEX,
        existingValue: getExistingName,
        checkUnique: (name: string) => specificationByName(getProjectKey(), name),
        alreadyExistsMessage: intl.formatMessage({
          id: 'specification.validator.name.unique',
          defaultMessage: 'A specification with this name already exists'
        }),
        errorMessage: intl.formatMessage({
          id: 'specification.validator.name.checkUniqueError',
          defaultMessage: 'There was a problem verifying the specification name'
        })
      })
    ],
    description: [
      {
        required: true,
        message: intl.formatMessage({
          id: 'specification.validator.description.required',
          defaultMessage: 'Please provide a description'
        })
      },
      descriptionLengthValidator
    ],
    timeoutPeriod: {
      required: true,
      message: intl.formatMessage({
        id: 'specification.validator.timeoutPeriod.required',
        defaultMessage: 'Please select a timeout period'
      })
    },
    autoClosePeriod: {
      required: true,
      message: intl.formatMessage({
        id: 'specification.validator.autoClosePeriod.required',
        defaultMessage: 'Please select an auto close period'
      })
    },
    mediaRetentionPolicy: {
      required: true,
      message: intl.formatMessage({
        id: 'specification.validator.mediaRetentionPolicy.required',
        defaultMessage: 'Please select a media retention policy'
      })
    },
    mediaRetentionPeriod: {
      required: true,
      message: intl.formatMessage({
        id: 'specification.validator.mediaRetentionPeriod.required',
        defaultMessage: 'Please select a media retention period'
      })
    },
    conformanceMode: {
      required: true,
      message: intl.formatMessage({
        id: 'specification.validator.conformanceMode.required',
        defaultMessage: 'Please select a conformance mode'
      })
    }
  });
}
