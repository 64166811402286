import React from 'react';
import { Typography, Box } from '@mui/material';

import { CopyToClipboard, PaddedPaper, DownloadLogButton, ReceiverOnly } from '../../../components';
import { SubmissionDetail } from '../../../types';
import { intl } from '../../../Internationalization';

export interface ConfiguredSubmissionMessageProps {
  submission: SubmissionDetail;
}

export interface ConfiguredSubmissionErrorMessageProps extends ConfiguredSubmissionMessageProps {
  errorMessage?: string;
}

export interface SubmissionMessageProps extends ConfiguredSubmissionMessageProps {
  title: string;
  description: string;
}

const SubmissionMessage: React.FC<SubmissionMessageProps> = ({ submission, title, description }) => {
  return (
    <PaddedPaper>
      <ReceiverOnly>
        <DownloadLogButton submission={submission} sx={{ float: 'right' }} />
      </ReceiverOnly>
      <Typography gutterBottom variant="h5">{title}</Typography>
      <Typography>
        {description}
      </Typography>
      <Box py={2} whiteSpace="nowrap">
        <CopyToClipboard>
          <Typography variant="h6">
            {submission.reference}
          </Typography>
        </CopyToClipboard>
      </Box>
    </PaddedPaper>
  );
};

export const ConfigurationIssueMessage: React.FC<ConfiguredSubmissionMessageProps> = ({ submission }) => (
  <SubmissionMessage
    submission={submission}
    description={intl.formatMessage({
      id: 'submission.submissionErrorMessage.configurationIssue.description',
      defaultMessage: 'There is a configuration issue, please contact your administrator.'
    })}
    title={intl.formatMessage({
      id: 'submission.submissionErrorMessage.configurationIssue.title',
      defaultMessage: 'Configuration issue'
    })}
  />
);

export const SubmissionErrorMessage: React.FC<ConfiguredSubmissionErrorMessageProps> = ({
  submission,
  errorMessage = intl.formatMessage({
    id: 'submission.submissionErrorMessage.defaultDescription',
    defaultMessage: 'You can use the reference below to report this issue.'
  })
}) => (
  <SubmissionMessage
    submission={submission}
    description={errorMessage}
    title={intl.formatMessage({
      id: 'submission.submissionErrorMessage.title',
      defaultMessage: 'Error processing submission'
    })}
  />
);
