import { SvgIconProps, Theme } from '@mui/material';

import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import GppGoodIcon from '@mui/icons-material/GppGood';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import GppBadIcon from '@mui/icons-material/GppBad';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';

import { intl } from "../Internationalization";

export interface VirusScannerSettings {
  enabled: boolean;
  scannerSettings: ScannerSettings;
}

export enum VirusScannerType {
  CLAMAV = "CLAMAV"
}

export const VIRUS_SCANNER_TYPE = [VirusScannerType.CLAMAV];
export const VIRUS_SCANNER_TYPE_METADATA: Record<VirusScannerType, { label: string }> = {
  CLAMAV: {
    label: 'ClamAV'
  }
};

export type ScannerSettings = ClamScannerSettings;

export enum VirusScanState {
  PENDING = "PENDING",
  SKIPPED = "SKIPPED",
  ERROR = "ERROR",
  OK = "OK",
  FOUND = "FOUND"
}

export const VIRUS_SCANNER_STATE = [
  VirusScanState.PENDING, VirusScanState.SKIPPED, VirusScanState.ERROR, VirusScanState.OK, VirusScanState.FOUND
];

interface VirusScannerMetadata {
  label: string;
  icon: React.ComponentType<SvgIconProps>;
  color?: (theme: Theme) => string;
}

export const VIRUS_SCANNER_STATE_METADATA: Record<VirusScanState, VirusScannerMetadata> = {
  PENDING: {
    label: intl.formatMessage({
      id: 'virusScanner.state.metadata.pending.label',
      defaultMessage: 'Pending'
    }),
    icon: SafetyCheckIcon,
    color: (theme) => theme.palette.info.main
  },
  SKIPPED: {
    label: intl.formatMessage({
      id: 'virusScanner.state.metadata.skipped.label',
      defaultMessage: 'Skipped'
    }),
    icon: RemoveModeratorIcon,
    color: (theme) => theme.palette.grey.main
  },
  ERROR: {
    label: intl.formatMessage({
      id: 'virusScanner.state.metadata.error.label',
      defaultMessage: 'Error'
    }),
    icon: GppBadIcon,
    color: (theme) => theme.palette.error.main
  },
  OK: {
    label: intl.formatMessage({
      id: 'virusScanner.state.metadata.ok.label',
      defaultMessage: 'No threats found'
    }),
    icon: GppGoodIcon,
    color: (theme) => theme.palette.success.main
  },
  FOUND: {
    label: intl.formatMessage({
      id: 'virusScanner.state.metadata.found.label',
      defaultMessage: 'Threats found'
    }),
    icon: GppMaybeIcon,
    color: (theme) => theme.palette.warning.main
  }
};

export interface ClamScannerSettings {
  scannerPath: string;
  additionalArguments: string[];
  scanTimeout: string;
  type: VirusScannerType.CLAMAV;
}

export interface VirusScannerTestResponse {
  success: boolean;
  scannerOutput: string;
}

export const virusScanStatePassed = (state: VirusScanState) => {
  return state === VirusScanState.OK || state === VirusScanState.SKIPPED;
};
