import { CardContent, Typography, Grid } from '@mui/material';

import { ProjectMetadata, SpecificationMetadata, SupplierMetadata } from '../../types';

import { NavigationCard, SupplierAvatar } from '../';

import ProjectCardImage from './ProjectCardImage';

interface AssignmentCardProps {
  reference: string;
  assignmentKey: string;
  project: ProjectMetadata;
  specification: SpecificationMetadata;
  assignmentUrl: string;
  supplier: SupplierMetadata;
}

const AssignmentCard = ({ reference, assignmentKey, project, specification, assignmentUrl, supplier }: AssignmentCardProps) => (
  <Grid item xs={12} sm={6} lg={4} xl={3}>
    <NavigationCard to={assignmentUrl} key={assignmentKey}>
      <SupplierAvatar sx={{ position: 'absolute', right: 10, top: 10 }} supplier={supplier} size="large" />
      <ProjectCardImage project={project} sx={{ width: '100%', height: 200 }} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {specification.name} - {reference}
        </Typography>
        <Typography gutterBottom variant="subtitle1" color="textSecondary" component="h3">
          {project.name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {project.description}
        </Typography>
      </CardContent>
    </NavigationCard>
  </Grid>
);

export default AssignmentCard;
