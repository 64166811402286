import { Container, Box, Grid, Skeleton } from "@mui/material";

import { PaddedPaper } from '../';

interface SkeletonTabsProps {
  contentRows: number;
}

const MDPaperAvatarRowsSkeleton = ({ contentRows }: SkeletonTabsProps) => {
  const renderContentRows = () => {
    const rows = [];
    let i = 0;
    while (i < contentRows) {
      rows.push(
        <Grid key={`avatar-${i}`} item xs={1}>
          <Skeleton variant="circular" height={50} width={50} />
        </Grid>,
        <Grid key={`text-${i}`}  item xs={11}>
          <Skeleton variant="text" height={20} width={450} />
          <Skeleton variant="text" height={20} width={250} />
        </Grid>
      );
      i++;
    }

    return rows;
  };

  return (
    <Box p={3}>
      <Container maxWidth="md" disableGutters>
        <PaddedPaper>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" height={25} width={350} />
            </Grid>
            {renderContentRows()}
          </Grid>
        </PaddedPaper>
      </Container>
    </Box>
  );
};

export default MDPaperAvatarRowsSkeleton;
