import { FC } from "react";
import { Pagination, Box } from "@mui/material";
import { PaginationProps } from '@mui/lab';

interface StandardPaginationProps extends Omit<PaginationProps, 'variant' | 'color' | 'page' | 'size' | 'count' | 'disabled' | 'onChange'> {
  page: number;
  total?: number;
  size: number;
  onChange: (page: number) => void;
}

const StandardPagination: FC<StandardPaginationProps> = ({ page, size, total = 0, onChange, ...rest }) => (
  <Box mt={3} display="flex" justifyContent="center">
    <Pagination
      variant="outlined"
      color="primary"
      page={page + 1}
      count={Math.ceil(total / size)}
      disabled={total - size < 1}
      onChange={(event, newPage) => {
        newPage = newPage - 1;
        newPage !== page && onChange(newPage);
      }}
      {...rest}
    />
  </Box>
);

export default StandardPagination;
