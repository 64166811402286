import { AxiosPromise } from 'axios';
import Schema from 'async-validator';

import { AXIOS, RequestConfig, wrapUniquePromise } from './endpoints';
import { IdentityProvidersRequest, IdentityProviderResponse } from '../types';
import { NOT_BLANK_REGEX, duplicateValidator, notTrimmableValidator } from '../util';
import { intl } from '../Internationalization';
import { identityProviderByName } from './identityProviderLookup';

const IDENTITY_PROVIDER_ID_REGEX = /^[a-zA-Z0-9_]+$/;

export function getIdentityProviders(request: IdentityProvidersRequest, config?: RequestConfig): AxiosPromise<IdentityProviderResponse> {
  return AXIOS.get('/identity_providers', {
    ...config,
    params: request
  });
}

export function uniqueByKey(identityProviderKey: string): Promise<boolean> {
  return wrapUniquePromise(AXIOS.head(`/identity_providers/${identityProviderKey}`));
}

const nameValidator = (getExistingName?: () => string) => [
  {
    required: true,
    message: intl.formatMessage({
      id: 'identityProvider.validator.name.required',
      defaultMessage: 'Please provide a name'
    })
  },
  notTrimmableValidator,
  duplicateValidator({
    regex: NOT_BLANK_REGEX,
    existingValue: getExistingName,
    checkUnique: identityProviderByName,
    alreadyExistsMessage: intl.formatMessage({
      id: 'identityProvider.validator.name.unique',
      defaultMessage: 'An identity provider with this name already exists'
    }),
    errorMessage: intl.formatMessage({
      id: 'identityProvider.validator.name.checkUniqueError',
      defaultMessage: 'There was a problem verifying the identity provider name'
    }),
  })
];

export function identityProviderSettingsValidator(getExistingName?: () => string): Schema {
  return new Schema({
    name: nameValidator(getExistingName),
    emailAttribute: {
      required: true,
      message: intl.formatMessage({
        id: 'identityProvider.validator.emailAttribute.required',
        defaultMessage: 'Please provide an email attribute'
      }),
    },
    nameAttribute: {
      required: true,
      message: intl.formatMessage({
        id: 'identityProvider.validator.nameAttribute.required',
        defaultMessage: 'Please provide a name attribute'
      }),
    },
    groupAttribute: {
      required: true,
      message: intl.formatMessage({
        id: 'identityProvider.validator.groupAttribute.required',
        defaultMessage: 'Please provide a group attribute'
      }),
    }
  });
}

export const CREATE_IDENTITY_PROVIDER_SETTINGS_VALIDATOR = new Schema({
  name: nameValidator(),
  key: [
    {
      required: true,
      pattern: IDENTITY_PROVIDER_ID_REGEX,
      message: intl.formatMessage({
        id: 'identityProvider.validator.key.required',
        defaultMessage: 'Please provide a valid key'
      }),
    },
    duplicateValidator({
      regex: IDENTITY_PROVIDER_ID_REGEX,
      checkUnique: uniqueByKey,
      alreadyExistsMessage: intl.formatMessage({
        id: 'identityProvider.validator.key.unique',
        defaultMessage: 'An identity provider with this key already exists'
      }),
      errorMessage: intl.formatMessage({
        id: 'identityProvider.validator.key.checkUniqueError',
        defaultMessage: 'There was a problem verifying the identity provider key'
      }),
    })
  ]
});
