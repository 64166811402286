import { FC, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

import {
  Box, Table, TableBody, TableHead, TableRow, TextField, Select, MenuItem, FormControl, InputLabel, ListItemText,
  Checkbox, Typography, Grid, IconButton, Tooltip, TableCell,
} from "@mui/material";

import { MinWidthTableCell, TableInfoRow } from "../../../components";
import { DataStoreClassMapping, DataStoreClassSchema, SchemaMappingMode } from "../../../types";
import { intl } from "../../../Internationalization";

import DataStoreClass from './DataStoreClass';
import { MappingStatus, MAPPING_STATUSES, MAPPING_STATUS_METADATA } from "./schemaMappingUtils";
import { SchemaMappingContext } from "./SchemaMappingContext";
import MappingStatusIcon from "./MappingStatusIcon";
import { SchemaMappingDirection, SCHEMA_MAPPING_DIRECTION_METADATA } from "./schemaMappingMetadata";

interface DataStoreClassesProps {
  schemaMappingMode: SchemaMappingMode;
  sourceClasses: DataStoreClassSchema[];
  targetClasses: DataStoreClassSchema[];
  classMappings: DataStoreClassMapping[];
}

const DataStoreClasses: FC<DataStoreClassesProps> = ({ schemaMappingMode,  sourceClasses, targetClasses, classMappings }) => {
  const { specification, validationResults, selectedDataStoreName, direction, setDirection } = useContext(SchemaMappingContext);

  const {
    selectLeftOrSource,
    extractLeftValidationResults,
    leftClassHeaderText,
    rightClassHeaderText,
    leftDataStoreValidationResults: LeftValidationResults,
    rightDataStoreValidationResults: RightValidationResults
  } = SCHEMA_MAPPING_DIRECTION_METADATA[direction];

  const [classNameFilter, setClassNameFilter] = useState<string>('');
  const [classStatusFilter, setClassStatusFilter] = useState<MappingStatus[]>([]);
  const [filteredClasses, setFilteredClasses] = useState<DataStoreClassSchema[]>([]);
  const [openAttributesClassName, setOpenAttributesClassName] = useState<string>('');

  const allowUnmapped = selectLeftOrSource(specification.allowSourceUnmapped, specification.allowTargetUnmapped);

  useEffect(() => {
    const leftClassValidationResults = (
      extractLeftValidationResults(validationResults).dataStoreValidationResults[selectedDataStoreName].classValidationResults
    );
    setFilteredClasses(selectLeftOrSource(sourceClasses, targetClasses)
      .filter((classToFilter) => classToFilter.name.toLocaleLowerCase().includes(classNameFilter.toLocaleLowerCase()))
      .filter((classToFilter) => {
        if (!classStatusFilter.length) {
          return true;
        }
        return classStatusFilter.includes(leftClassValidationResults[classToFilter.name].status);
      })
    );
  }, [
    sourceClasses, targetClasses, classNameFilter, classStatusFilter, selectLeftOrSource,
    extractLeftValidationResults, validationResults, selectedDataStoreName
  ]);

  useEffect(() => {
    setOpenAttributesClassName('');
  }, [direction, classNameFilter, classStatusFilter]);

  const handleOpenClassAttribute = (newClass: string) => {
    setOpenAttributesClassName((prevClass) => prevClass === newClass ? '' : newClass);
  };

  const onClassChange = (className: string) => {
    if (className === openAttributesClassName) {
      setOpenAttributesClassName('');
    }
  };

  return (
    <>
      <Box mb={2}>
        <Grid container spacing={3}>
          <Grid item xs={6} xl={3}>
            <TextField
              label={intl.formatMessage({
                id: 'schemaMapper.dataStoreClasses.classNameFilter.label',
                defaultMessage: "Class Name"
              })}
              value={classNameFilter}
              onChange={(event) => setClassNameFilter(event.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} xl={3}>
            <FormControl
              fullWidth
              variant="outlined"
            >
              <InputLabel>
                <FormattedMessage
                  id="schemaMapper.dataStoreClasses.classStatusFilter.label"
                  defaultMessage="Mapping Status"
                />
              </InputLabel>
              <Select
                name="classStatusFilter"
                id="DataStoreClasses-mappingStatusFilter"
                label={intl.formatMessage({
                  id: 'schemaMapper.dataStoreClasses.classStatusFilter.label',
                  defaultMessage: "Mapping Status"
                })}
                multiple
                value={classStatusFilter}
                onChange={(event) => setClassStatusFilter(event.target.value as MappingStatus[])}
                renderValue={(selected) => (selected as MappingStatus[]).map((status) => (MAPPING_STATUS_METADATA[status].label)).join(', ')}
                fullWidth
              >
                {MAPPING_STATUSES
                  .filter(m => (m !== MappingStatus.WARNING && !allowUnmapped) || (m !== MappingStatus.ERROR && allowUnmapped))
                  .map((status) => (
                    <MenuItem
                      key={status}
                      value={status}
                      sx={{
                        '& .MuiListItem-root, &.Mui-selected': {
                          bgcolor: 'rgba(0, 0, 0, 0)',
                        }
                      }}
                    >
                      <Checkbox checked={classStatusFilter.indexOf(status) > -1} />
                      <MappingStatusIcon
                        status={status}
                        iconSize="small"
                        sx={{ mr: 1 }}
                      />
                      <ListItemText primary={MAPPING_STATUS_METADATA[status].label} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} xl={6}>
            <Box display="flex" justifyContent="space-evenly" alignItems="center">
              <LeftValidationResults />
              <Tooltip
                title={intl.formatMessage({
                  id: 'schemaMapper.dataStoreClasses.swapMappingDirection.tooltip',
                  defaultMessage: "Swap schema mapping direction"
                })}
              >
                <IconButton
                  sx={{ mx: 1 }}
                  color="primary"
                  name="swapSchemaMappingDirection"
                  onClick={() => setDirection(value => {
                    if (value === SchemaMappingDirection.TARGET_TO_SOURCE) {
                      return (SchemaMappingDirection.SOURCE_TO_TARGET);
                    }
                    return (SchemaMappingDirection.TARGET_TO_SOURCE);
                  })}
                  size="large"
                  aria-label={intl.formatMessage({
                    id: 'schemaMapper.dataStoreClasses.swapMappingDirection.ariaLabel',
                    defaultMessage: 'Swap schema mapping direction'
                  })}
                >
                  <SwapHorizIcon fontSize="large" />
                </IconButton>
              </Tooltip>
              <RightValidationResults />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <MinWidthTableCell />
            <MinWidthTableCell nowrap>
              <Typography>
                <FormattedMessage id="schemaMapper.dataStoreClasses.table.mappingStatusColumn" defaultMessage="Status" />
              </Typography>
            </MinWidthTableCell>
            <TableCell>
              <Typography>
                {leftClassHeaderText}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {rightClassHeaderText}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            filteredClasses.length ?
              filteredClasses.map((dataStoreClass: DataStoreClassSchema) => (
                <DataStoreClass
                  key={dataStoreClass.name}
                  schemaMappingMode={schemaMappingMode}
                  leftClass={dataStoreClass}
                  classMappings={classMappings}
                  sourceClasses={sourceClasses}
                  targetClasses={targetClasses}
                  classAttributesOpen={openAttributesClassName === dataStoreClass.name}
                  handleOpenClassAttribute={handleOpenClassAttribute}
                  onClassChange={() => onClassChange(dataStoreClass.name)}
                />
              )) : (
                <TableInfoRow
                  colSpan={4}
                  size="medium"
                  message={intl.formatMessage({
                    id: "schemaMapper.dataStoreClasses.noClassesFound",
                    defaultMessage: "No classes match the filters provided"
                  })}
                />
              )
          }
        </TableBody>
      </Table>
    </>
  );
};

export default DataStoreClasses;
