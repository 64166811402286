import { FC, PropsWithChildren, useContext } from 'react';
import { AuthenticatedContext } from '../contexts/authentication';

const ReceiverOnly: FC<PropsWithChildren> = ({ children }) => {
  const { me } = useContext(AuthenticatedContext);
  return (
    <>
      {me.receiverPermissions && children}
    </>
  );
};

export default ReceiverOnly;
