import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { FC } from 'react';
import { AccountEventType, ACCOUNT_EVENT_TYPE_METADATA } from '../../../types';

export type AccountEventTypeSelections = Record<AccountEventType, boolean>;

export const toAccountEventTypeArray = (accountTypeSelections: AccountEventTypeSelections): AccountEventType[] => {
  return (Object.keys(accountTypeSelections) as AccountEventType[]).filter(accountType => accountTypeSelections[accountType]);
};

interface AccountEventTypeSelectorProps {
  selections: AccountEventTypeSelections;
  onSelectionsUpdated: (accountTypeSelections: AccountEventTypeSelections) => void;
}

const ACCOUNT_EVENT_TYPE_COLUMNS = [
  [
    AccountEventType.CREATED, AccountEventType.ACTIVATION_EMAIL_SENT,
    AccountEventType.ACTIVATED, AccountEventType.WELCOME_EMAIL_SENT
  ],
  [
    AccountEventType.LOGIN_SUCCESS, AccountEventType.LOGIN_FAILURE
  ],
  [
    AccountEventType.SUSPENDED, AccountEventType.UNSUSPENDED,
    AccountEventType.RECEIVER_PERMISSIONS_UPDATED, AccountEventType.RECEIVER_PERMISSIONS_REMOVED
  ],
  [
    AccountEventType.PASSWORD_RESET_REQUESTED, AccountEventType.PASSWORD_RESET_COMPLETE,
    AccountEventType.PASSWORD_CHANGED
  ]
];

const AccountEventTypeSelector: FC<AccountEventTypeSelectorProps> = ({ selections, onSelectionsUpdated }) => (
  <Box display="flex" my={1.5} mx={1} flexWrap="wrap" gap={2}>
    {ACCOUNT_EVENT_TYPE_COLUMNS.map((eventTypes) => {
      return (
        <Box display="flex" flexDirection="column" gap={1}>
          {eventTypes.map((eventTypeKey) => {
            const checked = selections[eventTypeKey];
            return (
              <FormControlLabel
                key={eventTypeKey}
                control={
                  <Checkbox
                    checked={checked}
                    onChange={() => onSelectionsUpdated({
                      ...selections,
                      [eventTypeKey]: !checked
                    })}
                    name={eventTypeKey}
                    sx={{ p: (theme) => theme.spacing(0, 0.5, 0, 1) }}
                  />
                }
                label={ACCOUNT_EVENT_TYPE_METADATA[eventTypeKey].label}
              />
            );
          })}
        </Box>
      );
    })}
  </Box>
);

export default AccountEventTypeSelector;
