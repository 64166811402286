import React, { useState, FC, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';

import { Typography, MenuItem, Checkbox, Grid } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';

import { extractErrorMessage } from '../../../../api/endpoints';
import * as MailSenderSettingsApi from '../../../../api/mailSenderSettings';
import * as MailSenderConnectionApi from '../../../../api/mailSenderConnection';
import { PaddedPaper, ValidatedTextField, ValidatedPasswordField, FormButtons, DefaultButton, BlockFormControlLabel, MessageBox } from '../../../../components';
import { MailSenderSettings, MailSenderProtocol, MailSenderSecurity, MAIL_SENDER_SECURITY_METADATA, mailSenderSecurities, mailSenderProtocols } from '../../../../types';
import { validate } from '../../../../util';
import { intl } from '../../../../Internationalization';
import { ApplicationContext } from '../../../../contexts/application';

interface SenderSettingsFormProps {
  senderSettings: MailSenderSettings;
}

const SenderSettingsForm: FC<SenderSettingsFormProps> = ({ senderSettings }) => {
  const { enqueueSnackbar } = useSnackbar();
  const applicationContext = useContext(ApplicationContext);

  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>({});
  const [processing, setProcessing] = useState<boolean>(false);

  const [enabled, setEnabled] = useState<boolean>(senderSettings.enabled);
  const [from, setFrom] = useState<string>(senderSettings.from);
  const [host, setHost] = useState<string>(senderSettings.host);
  const [username, setUsername] = useState<string>(senderSettings.username);
  const [password, setPassword] = useState<string>(senderSettings.password);
  const [port, setPort] = useState<string>(senderSettings.port ? senderSettings.port.toString() : '');
  const [protocol, setProtocol] = useState<MailSenderProtocol>(senderSettings.protocol);
  const [security, setSecurity] = useState<MailSenderSecurity>(senderSettings.security);

  const validateAndPerform = (onValidated: (updatedSettings: MailSenderSettings) => void) => {
    setFieldErrors({});

    const updatedSettings: MailSenderSettings = {
      enabled,
      from,
      host,
      password,
      port: port ? Number(port) : undefined,
      protocol,
      security,
      username
    };
    validate(MailSenderSettingsApi.SENDER_SETTINGS_VALIDATOR, updatedSettings)
      .then(() => onValidated(updatedSettings))
      .catch((newFieldErrors: ValidateFieldsError) => {
        setProcessing(false);
        setFieldErrors(newFieldErrors);
      });
  };

  const validateAndSaveSettings = () => {
    setProcessing(true);
    validateAndPerform(saveSettings);
  };

  const saveSettings = (settings: MailSenderSettings) => {
    MailSenderSettingsApi.updateSenderSettings(settings).then(() => {
      setProcessing(false);
      enqueueSnackbar(intl.formatMessage({
        id: 'mail.senderSettings.saveSuccess',
        defaultMessage: 'Mail sender settings updated'
      }), { variant: "success" });
      applicationContext.refresh();
    }).catch(error => {
      setProcessing(false);
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'mail.senderSettings.saveError',
        defaultMessage: 'Failed to update mail sender settings'
      })), { variant: "error" });
    });
  };

  const validateAndTestConnection = () => {
    setProcessing(true);
    validateAndPerform(testSettings);
  };

  const testSettings = (settings: MailSenderSettings) => {
    MailSenderConnectionApi.testServerConnection(settings).then(() => {
      setProcessing(false);
      enqueueSnackbar(intl.formatMessage({
        id: 'mail.senderSettings.testSuccess',
        defaultMessage: 'Successfully connected to the mail server'
      }), { variant: "success" });
    }).catch(error => {
      setProcessing(false);
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'mail.senderSettings.testError',
        defaultMessage: 'Failed to connect to mail server'
      })), { variant: "error" });
    });
  };

  return (
    <PaddedPaper id="system-mail-sender-settings">
      <Typography variant="h5" gutterBottom>
        <FormattedMessage id="mail.senderSettings.title" defaultMessage="Sender Settings" />
      </Typography>
      <BlockFormControlLabel
        control={
          <Checkbox
            color="primary"
            name="enabled"
            checked={enabled}
            onChange={(event, boolean) => setEnabled(boolean)}
            disabled={processing}
          />
        }
        label={intl.formatMessage({
          id: 'mail.senderSettings.enabled.label',
          defaultMessage: 'Enabled?'
        })}
      />
      {
        enabled ?
          <MessageBox
            message={intl.formatMessage({
              id: 'mail.senderSettings.enabledTrue',
              defaultMessage: 'Mail sender is enabled, mail will be sent using the settings below'
            })}
            level="info"
          /> :
          <MessageBox
            message={intl.formatMessage({
              id: 'mail.senderSettings.enabledFalse',
              defaultMessage: 'Mail sender is disabled, no mail will be sent'
            })}
            level="warning"
          />
      }
      <ValidatedTextField
        fieldErrors={fieldErrors}
        disabled={processing}
        name="from"
        label={intl.formatMessage({
          id: 'mail.senderSettings.from.label',
          defaultMessage: 'From address'
        })}
        value={from}
        onChange={(event) => setFrom(event.target.value)}
        margin="normal"
        variant="outlined"
      />
      <ValidatedTextField
        fieldErrors={fieldErrors}
        disabled={processing}
        name="protocol"
        label={intl.formatMessage({
          id: 'mail.senderSettings.protocol.label',
          defaultMessage: 'Protocol'
        })}
        value={protocol}
        onChange={(event) => setProtocol(event.target.value as MailSenderProtocol)}
        margin="normal"
        variant="outlined"
        select
      >
        {mailSenderProtocols.map(mailProtocol => (
          <MenuItem key={mailProtocol} value={mailProtocol}>
            {mailProtocol}
          </MenuItem>
        ))}
      </ValidatedTextField>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <ValidatedTextField
            fieldErrors={fieldErrors}
            disabled={processing}
            name="host"
            label={intl.formatMessage({
              id: 'mail.senderSettings.host.label',
              defaultMessage: 'Host name'
            })}
            value={host}
            onChange={(event) => setHost(event.target.value)}
            margin="normal"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={4}>
          <ValidatedTextField
            fieldErrors={fieldErrors}
            disabled={processing}
            name="port"
            label={intl.formatMessage({
              id: 'mail.senderSettings.port.label',
              defaultMessage: 'Port'
            })}
            value={port}
            onChange={(event) => setPort(event.target.value)}
            margin="normal"
            variant="outlined"
            placeholder={protocol === MailSenderProtocol.SMTP ? '25' : '465'}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <ValidatedTextField
        fieldErrors={fieldErrors}
        disabled={processing}
        name="security"
        label={intl.formatMessage({
          id: 'mail.senderSettings.security.label',
          defaultMessage: 'Security'
        })}
        value={security}
        onChange={(event) => setSecurity(event.target.value as MailSenderSecurity)}
        margin="normal"
        variant="outlined"
        select
      >
        {mailSenderSecurities.map(mailSecurity => (
          <MenuItem key={mailSecurity} value={mailSecurity}>
            {MAIL_SENDER_SECURITY_METADATA[mailSecurity]}
          </MenuItem>
        ))}
      </ValidatedTextField>
      <ValidatedTextField
        autoComplete="off"
        fieldErrors={fieldErrors}
        disabled={processing}
        name="username"
        label={intl.formatMessage({
          id: 'mail.senderSettings.username.label',
          defaultMessage: 'Username'
        })}
        value={username}
        onChange={(event) => setUsername(event.target.value)}
        margin="normal"
        variant="outlined"
      />
      <ValidatedPasswordField
        type="password"
        autoComplete="new-password"
        fieldErrors={fieldErrors}
        disabled={processing}
        name="password"
        label={intl.formatMessage({
          id: 'mail.senderSettings.password.label',
          defaultMessage: 'Password'
        })}
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        margin="normal"
        variant="outlined"
      />
      <FormButtons>
        <DefaultButton
          name="updateSenderSettings"
          onClick={validateAndSaveSettings}
          disabled={processing}
          startIcon={<SaveIcon />}
        >
          <FormattedMessage id="mail.senderSettings.saveButton" defaultMessage="Save Settings" />
        </DefaultButton>
        <DefaultButton
          name="testConnection"
          onClick={validateAndTestConnection}
          disabled={processing}
          startIcon={<SettingsInputAntennaIcon />}
          color="secondary"
        >
          <FormattedMessage id="mail.senderSettings.testButton" defaultMessage="Test Connection" />
        </DefaultButton>
      </FormButtons>
    </PaddedPaper>
  );
};

export default SenderSettingsForm;
