import { useContext, FC, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';
import SaveIcon from '@mui/icons-material/Save';
import { Container, Typography, MenuItem } from '@mui/material';

import * as OAuth2IdentityProviderApi from '../../../../api/oAuth2IdentityProvider';
import { extractErrorMessage } from '../../../../api/endpoints';
import { PaddedPaper, ValidatedTextField, FormButtons, DefaultButton, ValidatedChipsArrayField } from '../../../../components';
import {
  OAuth2AuthenticationMethod, OAuth2ClientAuthenticationMethod, OAUTH2_CLIENT_AUTHENTICATION_METHOD_METADATA,
  oAuth2ClientAuthenticationMethods, oAuth2AuthenticationMethod, OAUTH2_AUTHENTICATION_METHOD_METADATA, OAuth2IdentityProviderSettings
} from '../../../../types';
import { validate, normalizeUri } from '../../../../util';
import { intl } from '../../../../Internationalization';

import { OAuth2IdentityProviderContext } from './OAuth2IdentityProviderContext';

const OAuth2IdentityProviderServiceSettings: FC = () => {
  const { identityProvider, identityProviderUpdated } = useContext(OAuth2IdentityProviderContext);
  const { enqueueSnackbar } = useSnackbar();
  const [processing, setProcessing] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const [authenticationMethod, setAuthenticationMethod] = useState<OAuth2ClientAuthenticationMethod>(identityProvider.authenticationMethod);
  const [clientId, setClientId] = useState<string>(identityProvider.clientId);
  const [clientSecret, setClientSecret] = useState<string>(identityProvider.clientSecret);
  const [scopes, setScopes] = useState<string[]>(identityProvider.scopes);
  const [authorizationUri, setAuthorizationUri] = useState<string>(identityProvider.authorizationUri);
  const [tokenUri, setTokenUri] = useState<string>(identityProvider.tokenUri);
  const [jwkSetUri, setJwkSetUri] = useState<string>(identityProvider.jwkSetUri || '');
  const [userInfoUri, setUserInfoUri] = useState<string>(identityProvider.userInfoUri);
  const [userNameAttributeName, setUserNameAttributeName] = useState<string>(identityProvider.userNameAttributeName);
  const [userInfoAuthenticationMethod, setUserInfoAuthenticationMethod] = useState<OAuth2AuthenticationMethod>(
    identityProvider.userInfoAuthenticationMethod
  );

  useEffect(() => {
    setAuthenticationMethod(identityProvider.authenticationMethod);
    setClientId(identityProvider.clientId);
    setClientSecret(identityProvider.clientSecret);
    setScopes(identityProvider.scopes);
    setAuthorizationUri(identityProvider.authorizationUri);
    setTokenUri(identityProvider.tokenUri);
    setJwkSetUri(identityProvider.jwkSetUri || '');
    setUserInfoUri(identityProvider.userInfoUri);
    setUserNameAttributeName(identityProvider.userNameAttributeName);
    setUserInfoAuthenticationMethod(identityProvider.userInfoAuthenticationMethod);
  }, [identityProvider]);

  const collectServiceSettings = (): Partial<OAuth2IdentityProviderSettings> => ({
    authenticationMethod,
    clientId,
    clientSecret,
    scopes,
    authorizationUri: normalizeUri(authorizationUri),
    tokenUri: normalizeUri(tokenUri),
    jwkSetUri: jwkSetUri ? normalizeUri(jwkSetUri) : undefined,
    userInfoUri: normalizeUri(userInfoUri),
    userNameAttributeName,
    userInfoAuthenticationMethod
  });

  const validateAndSubmit = async () => {
    setProcessing(true);
    const serviceSettings = collectServiceSettings();
    try {
      updateIdentityProvider(
        await validate(OAuth2IdentityProviderApi.OAUTH_IDENTITY_PROVIDER_SERVICE_SETTINGS_VALIDATOR, serviceSettings)
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const updateIdentityProvider = async (serviceSettings: Partial<OAuth2IdentityProviderSettings>) => {
    setFieldErrors(undefined);
    try {
      const { data: updatedIdentityProvider } = await OAuth2IdentityProviderApi.updateIdentityProvider(
        identityProvider.key, { ...identityProvider, ...serviceSettings }
      );
      identityProviderUpdated(updatedIdentityProvider);
      enqueueSnackbar(intl.formatMessage({
        id: 'oauth2.identityProviderServiceSettings.saveSuccess',
        defaultMessage: 'Identity provider updated'
      }), { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'oauth2.identityProviderServiceSettings.saveError',
        defaultMessage: 'Failed to update identity provider'
      })), { variant: 'error' });
    }
    setProcessing(false);
  };

  return (
    <Container maxWidth="md" id="system-identity-provider-oauth2-service-settings" disableGutters>
      <PaddedPaper>
        <Typography variant="h5" gutterBottom>
          <FormattedMessage id="oauth2.identityProviderServiceSettings.title" defaultMessage="Identity Provider Service Settings" />
        </Typography>
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="authenticationMethod"
          label={intl.formatMessage({
            id: 'oauth2.identityProviderServiceSettings.authenticationMethod.label',
            defaultMessage: 'Authentication Method'
          })}
          value={authenticationMethod}
          onChange={event => setAuthenticationMethod(event.target.value as OAuth2ClientAuthenticationMethod)}
          margin="normal"
          variant="outlined"
          select
        >
          {oAuth2ClientAuthenticationMethods.map((method) => (
            <MenuItem key={method} value={OAuth2ClientAuthenticationMethod[method]}>
              {OAUTH2_CLIENT_AUTHENTICATION_METHOD_METADATA[method]}
            </MenuItem>
          ))}
        </ValidatedTextField>
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="clientId"
          label={intl.formatMessage({
            id: 'oauth2.identityProviderServiceSettings.clientId.label',
            defaultMessage: 'Client Id'
          })}
          value={clientId}
          onChange={event => setClientId(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="clientSecret"
          label={intl.formatMessage({
            id: 'oauth2.identityProviderServiceSettings.clientSecret.label',
            defaultMessage: 'Client Secret'
          })}
          value={clientSecret}
          onChange={event => setClientSecret(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <ValidatedChipsArrayField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="scopes"
          label={intl.formatMessage({
            id: 'oauth2.identityProviderServiceSettings.scopes.label',
            defaultMessage: 'Scopes'
          })}
          endAdornmentAriaLabel={intl.formatMessage({
            id: 'oauth2.identityProviderServiceSettings.scopes.endAdornmentAriaLabel',
            defaultMessage: 'Add scope'
          })}
          chips={scopes}
          onChange={setScopes}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="authorizationUri"
          label={intl.formatMessage({
            id: 'oauth2.identityProviderServiceSettings.authorizationUri.label',
            defaultMessage: 'Authorization URI'
          })}
          value={authorizationUri}
          onChange={event => setAuthorizationUri(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="tokenUri"
          label={intl.formatMessage({
            id: 'oauth2.identityProviderServiceSettings.tokenUri.label',
            defaultMessage: 'Token URI'
          })}
          value={tokenUri}
          onChange={event => setTokenUri(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="jwkSetUri"
          label={intl.formatMessage({
            id: 'oauth2.identityProviderServiceSettings.jwkSetUri.label',
            defaultMessage: 'Jwk Set URI'
          })}
          value={jwkSetUri}
          onChange={event => setJwkSetUri(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="userInfoUri"
          label={intl.formatMessage({
            id: 'oauth2.identityProviderServiceSettings.userInfoUri.label',
            defaultMessage: 'User Info URI'
          })}
          value={userInfoUri}
          onChange={event => setUserInfoUri(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="userNameAttributeName"
          label={intl.formatMessage({
            id: 'oauth2.identityProviderServiceSettings.userNameAttributeName.label',
            defaultMessage: 'User Name Attribute Name'
          })}
          value={userNameAttributeName}
          onChange={event => setUserNameAttributeName(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="userInfoAuthenticationMethod"
          label={intl.formatMessage({
            id: 'oauth2.identityProviderServiceSettings.userInfoAuthenticationMethod.label',
            defaultMessage: 'User Info Authentication Method'
          })}
          value={userInfoAuthenticationMethod}
          onChange={event => setUserInfoAuthenticationMethod(event.target.value as OAuth2AuthenticationMethod)}
          margin="normal"
          variant="outlined"
          select
        >
          {oAuth2AuthenticationMethod.map((method) => (
            <MenuItem key={method} value={OAuth2AuthenticationMethod[method]}>
              {OAUTH2_AUTHENTICATION_METHOD_METADATA[method]}
            </MenuItem>
          ))}
        </ValidatedTextField>
        <FormButtons>
          <DefaultButton
            id="update-identity-provider-service-settings"
            onClick={validateAndSubmit}
            disabled={processing}
            startIcon={<SaveIcon />}
          >
            <FormattedMessage id="oauth2.identityProviderServiceSettings.saveButton" defaultMessage="Save Settings" />
          </DefaultButton>
        </FormButtons>
      </PaddedPaper>
    </Container>
  );
};

export default OAuth2IdentityProviderServiceSettings;
