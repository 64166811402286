import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSnackbar } from "notistack";

import { Box, Container, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

import * as SupplierMappingsApi from '../../../api/supplierMappings';
import * as SupplierMappingApi from '../../../api/supplierMapping';
import { extractErrorMessage } from "../../../api/endpoints";

import {
  ActiveSupplierAutocomplete, AddFab, ButtonRow, ConfirmDialog, FilterTextField, DefaultButton, FilterPagination,
  MinWidthTableCell, NamedSupplierAvatar, PaddedPaper, TableInfoRow, TableLoadingRow, StyledTableHead
} from "../../../components";
import { useBrowseRequest } from "../../../hooks";
import { intl } from "../../../Internationalization";
import { TickIcon } from "../../../components/icons";
import { SupplierDetail, SupplierMappingDetail } from "../../../types";

import NewSupplierMappingForm from './NewSupplierMappingForm';

interface SupplierMappingsProps {
  identityProviderKey: string;
}

const PAGE_SIZE = 10;

const SupplierMappings: FC<SupplierMappingsProps> = ({ identityProviderKey }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [addSupplierMapping, setAddSupplierMapping] = useState<boolean>(false);
  const [supplierMappingToDelete, setSupplierMappingToDelete] = useState<SupplierMappingDetail>();

  const [group, setGroup] = useState<string>('');
  const [supplier, setSupplier] = useState<SupplierDetail | null>(null);

  const { request, response, processing, setPage, refresh, updateRequest } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, identityProviderKey },
    onRequest: SupplierMappingsApi.getSupplierMappings
  });

  const handleSupplierChange = (selectedSupplier: SupplierDetail | null) => {
    setSupplier(selectedSupplier);
    updateRequest({ supplierKey: selectedSupplier?.key });
  };

  const handleGroupChange = (groupFilter: string) => {
    setGroup(groupFilter);
    updateRequest({ group: groupFilter });
  };

  const handleDeleteConfirm = async () => {
    if (!supplierMappingToDelete) {
      return;
    }
    try {
      await SupplierMappingApi.deleteSupplierMapping(supplierMappingToDelete.key);
      refresh();
      enqueueSnackbar(intl.formatMessage({
        id: 'identityProvider.supplierMappings.deleteSuccess',
        defaultMessage: 'Supplier mapping has been deleted'
      }), { variant: "success" });
      setSupplierMappingToDelete(undefined);
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'identityProvider.supplierMappings.deleteError',
        defaultMessage: 'Failed to delete supplier mapping'
      })), { variant: "error" });
    }
  };

  const renderTableRows = () => {
    if (!response) {
      return (
        <TableLoadingRow colSpan={4} />
      );
    }

    if (!response.results.length) {
      return (
        <TableInfoRow
          colSpan={4}
          size="small"
          message={intl.formatMessage({
            id: 'identityProvider.supplierMappings.noMappings',
            defaultMessage: 'No supplier mappings found matching the specified filters',
          })}
        />
      );
    }

    return response.results.map((supplierMapping) => (
      <TableRow key={supplierMapping.key}>
        <TableCell>
          <NamedSupplierAvatar supplier={supplierMapping.supplier} />
        </TableCell>
        <MinWidthTableCell>
          {
            supplierMapping.supervisor &&
            <TickIcon
              titleAccess={intl.formatMessage({
                id: 'identityProvider.supplierMappings.supervisor.titleAccess',
                defaultMessage: 'Supervisor'
              })}
            />
          }
        </MinWidthTableCell>
        <TableCell>{supplierMapping.group}</TableCell>
        <MinWidthTableCell>
          <ButtonRow whiteSpace="nowrap">
            <DefaultButton
              color="grey"
              onClick={() => setSupplierMappingToDelete(supplierMapping)}
              aria-label={intl.formatMessage({
                id: 'identityProvider.supplierMappings.deleteSupplierMapping.ariaLabel',
                defaultMessage: 'Delete supplier mapping'
              })}
            >
              <DeleteIcon />
            </DefaultButton>
          </ButtonRow>
        </MinWidthTableCell>
      </TableRow>
    ));
  };

  return (
    <>
      <Container maxWidth="md" id="system-identity-provider-supplier-mapping" disableGutters>
        <PaddedPaper>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" gutterBottom>
              <FormattedMessage id="identityProvider.supplierMappings.title" defaultMessage="Supplier Mappings" />
            </Typography>
            <Box>
              <FilterPagination page={request.page} size={request.size} total={response?.total} disabled={processing} setPage={setPage} />
            </Box>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <ActiveSupplierAutocomplete
                id="supplier-filter"
                name="supplierKey"
                label={intl.formatMessage({
                  id: 'identityProvider.supplierMappings.supplierFilter.label',
                  defaultMessage: 'Supplier'
                })}
                variant="outlined"
                margin="normal"
                value={supplier}
                onChange={handleSupplierChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FilterTextField
                id="group-filter"
                name="group"
                label={intl.formatMessage({
                  id: 'identityProvider.supplierMappings.groupFilter.label',
                  defaultMessage: 'Group'
                })}
                value={group}
                onSearch={value => handleGroupChange(value)}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <Table sx={{ mt: 1.5 }} size="small">
            <StyledTableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="identityProvider.supplierMappings.supplierColumn" defaultMessage="Supplier" />
                </TableCell>
                <MinWidthTableCell>
                  <FormattedMessage id="identityProvider.supplierMappings.supervisorColumn" defaultMessage="Supervisor" />
                </MinWidthTableCell>
                <TableCell>
                  <FormattedMessage id="identityProvider.supplierMappings.groupColumn" defaultMessage="Group" />
                </TableCell>
                <MinWidthTableCell />
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {renderTableRows()}
            </TableBody>
          </Table>
        </PaddedPaper>
      </Container>
      <ConfirmDialog
        id="confirm-delete-supplier-mapping"
        isOpen={!!supplierMappingToDelete}
        title={intl.formatMessage({
          id: 'identityProvider.supplierMappings.confirmDeleteSupplierMapping.title',
          defaultMessage: 'Delete supplier mapping?'
        })}
        text={intl.formatMessage(
          {
            id: 'identityProvider.supplierMappings.confirmDeleteSupplierMapping.text',
            defaultMessage: 'Are you sure you wish to delete the supplier mapping for group: {group}?'
          },
          { group: supplierMappingToDelete?.group }
        )}
        confirmBtnText={intl.formatMessage({
          id: 'identityProvider.supplierMappings.confirmDeleteSupplierMapping.button',
          defaultMessage: 'Delete supplier mapping'
        })}
        confirmAction={handleDeleteConfirm}
        closeAction={() => setSupplierMappingToDelete(undefined)}
      />
      {addSupplierMapping && (
        <NewSupplierMappingForm
          identityProviderKey={identityProviderKey}
          onCreate={() => refresh()}
          onClose={() => setAddSupplierMapping(false)}
        />)}
      <AddFab
        id="add-supplier-mapping"
        onClick={() => setAddSupplierMapping(true)}
        aria-label={intl.formatMessage({
          id: 'identityProvider.supplierMappings.addFab.addSupplierMapping.ariaLabel',
          defaultMessage: 'Add supplier mapping'
        })}
      />
    </>
  );
};

export default SupplierMappings;
