import { useState, FC } from 'react';
import { CirclePicker } from 'react-color';
import { AxiosPromise } from 'axios';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import { Grid, Typography, Box } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { extractErrorMessage, FileUploadConfig } from '../../api/endpoints';
import { useMediaUpload } from '../../hooks';
import { MediaDetail, UpdateAvatarRequest, Avatar } from '../../types';
import { intl } from '../../Internationalization';

import { ImageAvatar, Upload, PaddedPaper, ConfirmDialog, DefaultButton } from '../';
import { imageMimeTypes } from '../../api/media';

interface AvatarSelectProps {
  avatar: Avatar;
  title: string;
  name: string;
  logo?: boolean;
  imageUrl: (image: MediaDetail) => string;
  onUpdate: (avatar: Avatar) => void;
  onDelete: () => void;
  deleteImage: () => AxiosPromise;
  uploadImage: (image: File, config: FileUploadConfig) => AxiosPromise<MediaDetail>;
  updateAvatar: (request: UpdateAvatarRequest) => AxiosPromise<Avatar>;
}

const AvatarSelect: FC<AvatarSelectProps> = ({
  avatar, title, name, logo, imageUrl, onUpdate,
  onDelete, deleteImage, uploadImage, updateAvatar
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [upload, uploading, uploadProgress] = useMediaUpload<MediaDetail>({
    onUpload: (files: File[], config: FileUploadConfig) => uploadImage(files[0], config),
    onUploadComplete: (image) => onUpdate({ ...avatar, image })
  });
  const [selectedColor, setSelectedColor] = useState<string>(avatar.color);
  const [removeAvatarConfirm, setRemoveAvatarConfirm] = useState<boolean>(false);

  const handleDelete = () => {
    setRemoveAvatarConfirm(false);
    deleteImage().then(() => {
      onDelete();
      enqueueSnackbar(
        intl.formatMessage({
          id: 'components.avatarSelect.imageSelect.removeSuccess',
          defaultMessage: 'Avatar image removed'
        }), { variant: "success" });
    }).catch(error => {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'components.avatarSelect.imageSelect.removeError',
          defaultMessage: 'Failed to remove avatar image'
        })
      ), { variant: "error" });
    });
  };

  const saveColor = () => {
    updateAvatar({ color: selectedColor }).then(response => {
      onUpdate(response.data);
      enqueueSnackbar(intl.formatMessage({
        id: 'components.avatarSelect.colorSelect.updateSuccess',
        defaultMessage: 'Avatar colour updated'
      }), { variant: 'success' });
    }).catch(error => {
      enqueueSnackbar(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'components.avatarSelect.colorSelect.updateError',
          defaultMessage: 'Failed to update avatar colour'
        })
      ), { variant: 'error' });
    });
  };

  return (
    <PaddedPaper className="AvatarSelect-root">
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box p={2} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h5" gutterBottom>{title}</Typography>
            <ImageAvatar
              logo={logo}
              label={name}
              size="large"
              color={selectedColor}
              alt={intl.formatMessage({ id: 'components.avatarSelect.imageSelect.avatar.alt', defaultMessage: 'Avatar preview' })}
              src={avatar.image && imageUrl(avatar.image)}
            />
            <Box py={2}>
              <CirclePicker color={selectedColor} onChangeComplete={(newColor) => setSelectedColor(newColor.hex)} />
            </Box>
            <DefaultButton
              name="saveColor"
              fullWidth
              onClick={saveColor}
              disabled={!!avatar.image}
              startIcon={<SaveIcon />}
            >
              <FormattedMessage id="components.avatarSelect.colorSelect.saveButton" defaultMessage="Save Colour" />
            </DefaultButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box p={2} display="flex" flexDirection="column" textAlign="center" height="100%" justifyContent="space-between">
            <Typography variant="h5" component="h6" gutterBottom>
              <FormattedMessage id="components.avatarSelect.imageSelect.title" defaultMessage="Upload Avatar Image" />
            </Typography>
            <Box py={2}>
              <Upload onDrop={upload} uploading={uploading} progress={uploadProgress} accept={imageMimeTypes} />
            </Box>
            <ConfirmDialog
              id="confirm-remove-avatar"
              isOpen={removeAvatarConfirm}
              title={intl.formatMessage({
                id: 'components.avatarSelect.imageSelect.confirmRemove.title',
                defaultMessage: 'Remove avatar image'
              })}
              text={intl.formatMessage({
                id: 'components.avatarSelect.imageSelect.confirmRemove.text',
                defaultMessage: 'Are you sure you wish to remove avatar image?'
              })}
              confirmBtnText={intl.formatMessage({
                id: 'components.avatarSelect.imageSelect.confirmRemove.confirmButton',
                defaultMessage: 'Remove image'
              })}
              confirmAction={handleDelete}
              closeAction={() => setRemoveAvatarConfirm(false)}
            />
            <DefaultButton
              name="removeImage"
              fullWidth
              disabled={!avatar.image}
              onClick={() => setRemoveAvatarConfirm(true)}
              startIcon={<DeleteForeverIcon />}
            >
              <FormattedMessage id="components.avatarSelect.imageSelect.removeButton" defaultMessage="Remove image" />
            </DefaultButton>
          </Box>
        </Grid>
      </Grid>
    </PaddedPaper>
  );
};

export default AvatarSelect;
