import { Container, Grid } from '@mui/material';

import { PaperFormSkeleton } from "../../../../../components";

const AssignmentSkeleton = () => (
  <Container maxWidth="md" disableGutters>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PaperFormSkeleton contentRows={3} />
      </Grid>
      <Grid item xs={12}>
        <PaperFormSkeleton contentRows={1} />
      </Grid>
    </Grid>
  </Container>
);

export default AssignmentSkeleton;
