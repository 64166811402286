import { Tooltip } from '@mui/material';

import { SubmissionSummary, SUBMISSION_STATE_METADATA } from '../types';

interface SubmissionStateIconProps {
  submission: SubmissionSummary;
  size?: 'inherit' | 'medium' | 'small' | 'large';
}

const SubmissionStateIcon = ({ submission: { state }, size = 'large' }: SubmissionStateIconProps) => {
  const Icon = SUBMISSION_STATE_METADATA[state].icon;
  return (
    <Tooltip title={SUBMISSION_STATE_METADATA[state].label}>
      <Icon fontSize={size} />
    </Tooltip>
  );
};

export default SubmissionStateIcon;
