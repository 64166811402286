import { FC, useContext } from "react";
import { Grid } from '@mui/material';

import { SchemaMappingContext } from './SchemaMappingContext';
import DataStoreSelect from './DataStoreSelect';
import DataStoreClasses from './DataStoreClasses';

import { findDataStoreMapping, findDataStoreSchema, InvalidSchemaMapperDataMessage } from ".";
import { MessageBox } from "../../../components";
import { SchemaMappingMode } from "../../../types";
import { intl } from "../../../Internationalization";

interface SchemaMapperProps {
  renderSecondaryAction?: React.ReactNode;
}

const schemaMappingModeMessages: Record<SchemaMappingMode, string | null> = {
  MANUAL: null,
  AUTOMATIC: intl.formatMessage({
    id: 'schemaMapper.schemaMappingMode.automaticMessage',
    defaultMessage: 'This data set has been automatically mapped.'
  }),
  AUTOMATIC_HIDDEN: intl.formatMessage({
    id: 'schemaMapper.schemaMappingMode.automaticHiddenMessage',
    defaultMessage: "This data set has been automatically mapped but isn't viewable."
  }),
  NONE: intl.formatMessage({
    id: 'schemaMapper.schemaMappingMode.noneMessage',
    defaultMessage: 'Schema mapping is not enabled for this data set.'
  })
};

const SchemaMapper: FC<SchemaMapperProps> = ({ renderSecondaryAction }) => {
  const {
    dataStoreConfigs, selectedDataStoreName, sourceDataStores, targetDataStores, schemaMappings, direction
  } = useContext(SchemaMappingContext);

  const sourceDataStore = findDataStoreSchema(selectedDataStoreName, sourceDataStores);
  const targetDataStore = findDataStoreSchema(selectedDataStoreName, targetDataStores);
  const mappedClasses = findDataStoreMapping(selectedDataStoreName, schemaMappings);

  if (!mappedClasses || !sourceDataStore?.importSchema || !targetDataStore?.importSchema) {
    return (
      <InvalidSchemaMapperDataMessage />
    );
  }

  const schemaMappingMode = dataStoreConfigs[selectedDataStoreName].schemaMappingMode;
  const schemaMappingModeMessage = schemaMappingModeMessages[schemaMappingMode];
  return (
    <Grid item container spacing={3} alignItems="flex-end">
      <Grid item xs={6}>
        <DataStoreSelect />
      </Grid>
      {
        renderSecondaryAction && (
          <Grid item xs={6}>
            {renderSecondaryAction}
          </Grid>
        )
      }
      {schemaMappingModeMessage && (
        <Grid item xs={12}>
          <MessageBox level="info" message={schemaMappingModeMessage} />
        </Grid>
      )}
      {(schemaMappingMode !== SchemaMappingMode.NONE && schemaMappingMode !== SchemaMappingMode.AUTOMATIC_HIDDEN) && (
        <Grid item xs={12}>
          <DataStoreClasses
            key={direction + "#" + selectedDataStoreName}
            schemaMappingMode={schemaMappingMode}
            classMappings={mappedClasses.importMappings}
            sourceClasses={sourceDataStore.importSchema.classes}
            targetClasses={targetDataStore.importSchema.classes}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SchemaMapper;
