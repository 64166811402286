import { FC, useCallback } from "react";

import * as AssignmentsApi from '../../api/assignments';
import { AssignmentDetail } from "../../types";

import FilteredAutocomplete, { BasicFilteredAutocompleteProps } from "./FilteredAutocomplete";

interface AssignmentAutocompleteProps extends BasicFilteredAutocompleteProps<AssignmentDetail> {
  specificationKey?: string;
}

const fetchAssignments = async (specificationKey: string, filter: string) => {
  const response = await AssignmentsApi.getAssignments({ page: 0, size: 100, specificationKey, filter });
  return response.data.results;
};

const renderOption = (assignment: AssignmentDetail) => assignment.reference;
const getOptionSelected = (option: AssignmentDetail, value: AssignmentDetail) => option.key === value.key;
const getOptionLabel = (assignment: AssignmentDetail) => assignment.reference;

const AssignmentAutocomplete: FC<AssignmentAutocompleteProps> = ({ specificationKey, disabled, ...rest }) => {
  const fetchOptions = useCallback((filter: string) => (
    specificationKey ? fetchAssignments(specificationKey, filter) : Promise.resolve([])
  ), [specificationKey]);
  return (
    <FilteredAutocomplete
      {...rest}
      fetchOptions={fetchOptions}
      renderOption={renderOption}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      disabled={disabled || !specificationKey}
    />
  );
};

export default AssignmentAutocomplete;
