import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSnackbar } from "notistack";

import { Box, Container, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

import * as ReceiverMappingsApi from '../../../api/receiverMappings';
import * as ReceiverMappingApi from '../../../api/receiverMapping';
import { extractErrorMessage } from "../../../api/endpoints";

import {
  AddFab, ButtonRow, ConfirmDialog, DefaultButton, FilterPagination, MinWidthTableCell,
  PaddedPaper, TableInfoRow, TableLoadingRow, FilterTextField, StyledTableHead
} from "../../../components";
import { TickIcon } from "../../../components/icons";
import { ReceiverMappingDetail } from "../../../types";
import { useBrowseRequest } from "../../../hooks";
import { intl } from "../../../Internationalization";

import NewReceiverMappingForm from './NewReceiverMappingForm';

const PAGE_SIZE = 10;

interface ReceiverMappingsProps {
  identityProviderKey: string;
}

const ReceiverMappings: FC<ReceiverMappingsProps> = ({ identityProviderKey }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [addReceiverMapping, setAddReceiverMapping] = useState<boolean>(false);
  const [receiverMappingToDelete, setReceiverMappingToDelete] = useState<ReceiverMappingDetail>();

  const [group, setGroup] = useState<string>('');

  const { request, response, processing, setPage, refresh, updateRequest } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, identityProviderKey },
    onRequest: ReceiverMappingsApi.getReceiverMappings
  });

  const handleGroupChange = (groupFilter: string) => {
    setGroup(groupFilter);
    updateRequest({ group: groupFilter });
  };

  const handleDeleteConfirm = async () => {
    if (!receiverMappingToDelete) {
      return;
    }
    try {
      await ReceiverMappingApi.deleteReceiverMapping(receiverMappingToDelete.key);
      refresh();
      enqueueSnackbar(intl.formatMessage({
        id: 'identityProvider.receiverMappings.deleteSuccess',
        defaultMessage: 'Receiver mapping has been deleted'
      }), { variant: "success" });
      setReceiverMappingToDelete(undefined);
    } catch (error: any) {
      enqueueSnackbar(extractErrorMessage(error, intl.formatMessage({
        id: 'identityProvider.receiverMappings.deleteError',
        defaultMessage: 'Failed to delete receiver mapping'
      })), { variant: "error" });
    }
  };

  const renderTableRows = () => {
    if (!response) {
      return (
        <TableLoadingRow colSpan={3} />
      );
    }

    if (!response.results.length) {
      return (
        <TableInfoRow
          colSpan={3}
          size="small"
          message={intl.formatMessage({
            id: 'identityProvider.receiverMappings.noMappings',
            defaultMessage: 'No receiver mappings found matching the specified filters',
          })}
        />
      );
    }

    return response.results.map((receiverMapping) => (
      <TableRow key={receiverMapping.key}>
        <MinWidthTableCell>
          {
            receiverMapping.admin &&
            <TickIcon
              titleAccess={intl.formatMessage({
                id: 'identityProvider.receiverMappings.admin.titleAccess',
                defaultMessage: 'Admin'
              })}
            />
          }
        </MinWidthTableCell>
        <TableCell>{receiverMapping.group}</TableCell>
        <MinWidthTableCell>
          <ButtonRow whiteSpace="nowrap">
            <DefaultButton
              color="grey"
              onClick={() => setReceiverMappingToDelete(receiverMapping)}
              aria-label={intl.formatMessage({
                id: 'identityProvider.receiverMappings.deleteReceiverMapping.ariaLabel',
                defaultMessage: 'Delete receiver mapping'
              })}
            >
              <DeleteIcon />
            </DefaultButton>
          </ButtonRow>
        </MinWidthTableCell>
      </TableRow>
    ));
  };

  return (
    <>
      <Container maxWidth="md" id="system-identity-provider-receiver-mapping" disableGutters>
        <PaddedPaper>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="h5" gutterBottom>
              <FormattedMessage id="identityProvider.receiverMappings.title" defaultMessage="Receiver Mappings" />
            </Typography>
            <Box>
              <FilterPagination page={request.page} size={request.size} total={response?.total} disabled={processing} setPage={setPage} />
            </Box>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FilterTextField
                id="group-filter"
                name="group"
                label={intl.formatMessage({
                  id: 'identityProvider.receiverMappings.groupFilter.label',
                  defaultMessage: 'Group'
                })}
                value={group}
                onSearch={value => handleGroupChange(value)}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <Table sx={{ mt: 1.5 }} size="small">
            <StyledTableHead>
              <TableRow>
                <MinWidthTableCell nowrap>
                  <FormattedMessage id="identityProvider.receiverMappings.adminColumn" defaultMessage="Site Administrator" />
                </MinWidthTableCell>
                <TableCell>
                  <FormattedMessage id="identityProvider.receiverMappings.groupColumn" defaultMessage="Group" />
                </TableCell>
                <MinWidthTableCell />
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {renderTableRows()}
            </TableBody>
          </Table>
        </PaddedPaper>
      </Container>
      <ConfirmDialog
        id="confirm-delete-receiver-mapping"
        isOpen={!!receiverMappingToDelete}
        title={intl.formatMessage({
          id: 'identityProvider.receiverMappings.confirmDeleteReceiverMapping.title',
          defaultMessage: 'Delete receiver mapping?'
        })}
        text={intl.formatMessage(
          {
            id: 'identityProvider.receiverMappings.confirmDeleteReceiverMapping.text',
            defaultMessage: 'Are you sure you wish to delete the receiver mapping for group: {group}?'
          },
          { group: receiverMappingToDelete?.group }
        )}
        confirmBtnText={intl.formatMessage({
          id: 'identityProvider.receiverMappings.confirmDeleteReceiverMapping.button',
          defaultMessage: 'Delete receiver mapping'
        })}
        confirmAction={handleDeleteConfirm}
        closeAction={() => setReceiverMappingToDelete(undefined)}
      />
      {addReceiverMapping && (
        <NewReceiverMappingForm
          identityProviderKey={identityProviderKey}
          onCreate={() => refresh()}
          onClose={() => setAddReceiverMapping(false)}
        />
      )}
      <AddFab
        id="add-receiver-mapping"
        onClick={() => setAddReceiverMapping(true)}
        aria-label={intl.formatMessage({
          id: 'identityProvider.receiverMappings.addFab.addReceiverMapping.ariaLabel',
          defaultMessage: 'Add receiver mapping'
        })}
      />
    </>
  );
};

export default ReceiverMappings;
