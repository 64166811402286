import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';

import ReceiverAdminOnly from '../../ReceiverAdminOnly';

import { DRAWER_WIDTH } from '../Layout';
import { AppDecorationContext } from '..';

import { OpenSubmissions, Notifications, AuthMenu } from './';
import { intl } from '../../../Internationalization';

interface LayoutAppBarProps {
  shiftAppBar: boolean;
  handleDrawerToggle: () => void;
}

const LayoutAppBar: FC<LayoutAppBarProps> = ({ shiftAppBar, handleDrawerToggle }) => {

  const appDecorationContext = useContext(AppDecorationContext);

  return (
    <>
      <AppBar
        id="app-menu"
        sx={[
          {
            boxShadow: 'none',
            transition: (theme) => theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            })
          },
          shiftAppBar ? {
            marginLeft: DRAWER_WIDTH,
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            transition: (theme) => theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          } : {}
        ]}
        position="fixed"
      >
        <Toolbar>
          <IconButton
            id="open-drawer"
            color="inherit"
            edge="start"
            aria-label={intl.formatMessage({
              id: 'components.appBar.layoutAppBar.openSidebar.ariaLabel',
              defaultMessage: 'Open sidebar'
            })}
            onClick={handleDrawerToggle}
            sx={{
              display: shiftAppBar ? 'none' : 'inline-flex',
              mr: 1
            }}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            {appDecorationContext.title}
          </Typography>
          <Notifications />
          <OpenSubmissions />
          <ReceiverAdminOnly>
            <IconButton
              id="open-help"
              component={Link}
              to="/system/help"
              aria-label={intl.formatMessage({
                id: 'components.layoutAppBar.navigateToHelp.ariaLabel',
                defaultMessage: 'Navigate to help page'
              })}
              color="inherit"
              size="large"
            >
              <HelpIcon />
            </IconButton>
          </ReceiverAdminOnly>
          <AuthMenu />
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default LayoutAppBar;
