import { useState, useContext, useEffect, FC } from 'react';
import { startOfDay, sub } from 'date-fns';

import { FilterBar, FilterContainer, MessageBox } from '../../../../components';
import * as AssignmentStatsApi from '../../../../api/assignmentStats';
import { extractErrorMessage } from '../../../../api/endpoints';
import { ValuePercentage, GroupedDataSet, DateCount, OffsetDateTimePercentage, PeriodStatsRequest, CompleteDateRange } from '../../../../types';
import { toCompleteOffsetDateTimeRange, getCurrentTimeZone } from '../../../../util';

import { useErrorBlock } from '../../../../contexts/error-block';
import { intl } from '../../../../Internationalization';
import FilterCompleteDateRange from '../../../../components/browse-table/FilterCompleteDateRange';

import SpecificationCharts from '../../../dashboard/specification/SpecificationCharts';
import ChartsSkeleton from '../../../dashboard/ChartsSkeleton';

import { MyAssignmentContext } from '../MyAssignmentContext';

const createRequest = (dateRange: CompleteDateRange): PeriodStatsRequest => ({
  period: toCompleteOffsetDateTimeRange(dateRange),
  timeZone: getCurrentTimeZone()
});

const endDate = startOfDay(new Date());
const MyDashboard: FC = () => {
  const { assignmentKey } = useContext(MyAssignmentContext);
  const { raiseError } = useErrorBlock();

  const [dateRange, setDateRange] = useState<CompleteDateRange>({ start: sub(endDate, { months: 1 }), end: endDate });
  const [ruleConformance, setRuleConformance] = useState<ValuePercentage[]>();
  const [submissionCounts, setSubmissionCounts] = useState<GroupedDataSet<string, DateCount>[]>();
  const [submissionQuality, setSubmissionQuality] = useState<GroupedDataSet<string, OffsetDateTimePercentage>[]>();

  const fetchTopRulesStats = () => {
    AssignmentStatsApi.ruleConformance(assignmentKey, createRequest(dateRange)).then(response => {
      setRuleConformance(response.data);
    }).catch(error => {
      raiseError(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'myAssignment.dashboard.fetchTopRulesStatsError',
          defaultMessage: 'Failed to fetch top rules stats'
        })
      ));
    });
  };

  const fetchSubmissionCountStats = () => {
    AssignmentStatsApi.submissionCounts(assignmentKey, createRequest(dateRange)).then(response => {
      setSubmissionCounts(response.data);
    }).catch(error => {
      raiseError(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'myAssignment.dashboard.fetchSubmissionCountStatsError',
          defaultMessage: 'Failed to fetch submission count stats'
        })
      ));
    });
  };

  const fetchSubmissionQualityStats = () => {
    AssignmentStatsApi.submissionQuality(assignmentKey, createRequest(dateRange)).then(response => {
      setSubmissionQuality(response.data);
    }).catch(error => {
      raiseError(extractErrorMessage(
        error,
        intl.formatMessage({
          id: 'myAssignment.dashboard.fetchSubmissionQualityStatsError',
          defaultMessage: 'Failed to fetch submission quality stats'
        })
      ));
    });
  };

  useEffect(() => {
    const { start, end } = dateRange;

    if (start && end) {
      fetchTopRulesStats();
      fetchSubmissionCountStats();
      fetchSubmissionQualityStats();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, assignmentKey]);

  const renderContent = () => {
    if (!ruleConformance || !submissionCounts || !submissionQuality) {
      return (
        <ChartsSkeleton />
      );
    }

    if (!ruleConformance.length && !submissionCounts.length && !submissionQuality.length) {
      return (
        <MessageBox
          message={intl.formatMessage({
            id: 'myAssignment.dashboard.noData',
            defaultMessage: 'There is no data for your selected date period. Please try to refine your search to try again.'
          })}
          level="info"
        />
      );
    }

    return (
      <SpecificationCharts
        ruleConformance={ruleConformance}
        submissionCounts={submissionCounts}
        submissionQuality={submissionQuality}
      />
    );
  };

  return (
    <div id="my-assignment-dashboard">
      <FilterBar
        barFilters={
          <FilterContainer>
            <FilterCompleteDateRange range={dateRange} onRangeUpdated={setDateRange} rangeLimit={{ years: 1 }} />
          </FilterContainer>
        }
      />
      {renderContent()}
    </div>
  );
};

export default MyDashboard;
