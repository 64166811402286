import { FC } from 'react';

import { Grid } from '@mui/material';

import GroupIcon from '@mui/icons-material/Group';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WorkIcon from '@mui/icons-material/Work';
import AssessmentIcon from '@mui/icons-material/Assessment';

import { StatPaper } from '../../../components';
import { GlobalActivity } from '../../../types';
import { intl } from '../../../Internationalization';

interface GlobalStatsProps {
  globalActivity: GlobalActivity;
}

const GlobalStats: FC<GlobalStatsProps> = ({ globalActivity }) => (
  <Grid container spacing={3}>
    <Grid item lg={3} sm={6} xs={12}>
      <StatPaper
        id="active-users-stat"
        name={intl.formatMessage({
          id: 'dashboard.global.stats.activeUsers',
          defaultMessage: 'Active Users'
        })}
        stat={globalActivity.users}
        icon={GroupIcon}
        iconColor="#00acc1"
      />
    </Grid>
    <Grid item lg={3} sm={6} xs={12}>
      <StatPaper
        id="active-projects-stat"
        name={intl.formatMessage({
          id: 'dashboard.global.stats.activeProjects',
          defaultMessage: 'Active Projects'
        })}
        stat={globalActivity.projects}
        icon={WorkIcon}
        iconColor="#ffa726"
      />
    </Grid>
    <Grid item lg={3} sm={6} xs={12}>
      <StatPaper
        id="active-assignments-stat"
        name={intl.formatMessage({
          id: 'dashboard.global.stats.activeAssignments',
          defaultMessage: 'Active Assignments'
        })}
        stat={globalActivity.assignments}
        icon={AssignmentIcon}
        iconColor="#ef5350"
      />
    </Grid>
    <Grid item lg={3} sm={6} xs={12}>
      <StatPaper
        id="submission-count-stat"
        name={intl.formatMessage({
          id: 'dashboard.global.stats.Submissions',
          defaultMessage: 'Submissions'
        })}
        stat={globalActivity.submissions}
        icon={AssessmentIcon}
        iconColor="#66bb6a"
      />
    </Grid>
  </Grid>
);

export default GlobalStats;
