import { FC } from "react";

import { Box, Container, Grid } from "@mui/material";
import { Skeleton } from '@mui/material';

import { PaddedPaper } from "../../../components";

const SchemaMapperSkeleton: FC = () => (
  <Container disableGutters maxWidth="xl">
    <PaddedPaper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={30} width={350} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Skeleton variant="rectangular" height={50} width="100%" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Skeleton variant="rectangular" height={50} width="100%" />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rectangular" height={50} width="100%" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <Skeleton variant="circular" width={40} height={40} />
                <Box mx={1} />
                <Skeleton variant="rectangular" height={50} width="85%" />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <Skeleton variant="circular" width={40} height={40} />
                <Box mx={1} />
                <Skeleton variant="rectangular" height={50} width="85%" />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={350} width="100%" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Skeleton variant="rectangular" height={50} width="100%" />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rectangular" height={50} width="100%" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PaddedPaper>
  </Container>
);

export default SchemaMapperSkeleton;
