import Schema from 'async-validator';
import { AxiosPromise } from 'axios';

import { CopySavedMappingRequest, SavedMappingDetail } from '../types';
import { savedMappingNameValidator } from './assignmentSavedMappings';

import { AXIOS } from './endpoints';

export function copySavedMapping(assignmentKey: string, request: CopySavedMappingRequest): AxiosPromise<SavedMappingDetail> {
  return AXIOS.post(`assignments/${assignmentKey}/copy_saved_mapping`, request);
}

export const copySavedMappingValidator = (getAssignmentKey: () => string): Schema => new Schema({
  name: savedMappingNameValidator(getAssignmentKey)
});
