import { Duration, intervalToDuration } from 'date-fns';

const zeroPad = (num?: number) => String(num || 0).padStart(2, '0');

export const milliesToTimeFormat = (millis: number) => durationToTimeFormat(intervalToDuration({ start: 0, end: millis }));

export const durationToTimeFormat = (duration: Duration) => {
  const durationArray = [duration.hours, duration.minutes, duration.seconds].map(zeroPad);

  if (duration.days) {
    durationArray.unshift(zeroPad(duration.days));
  }

  return durationArray.join(':');
};

// TODO:: date-fns v2 doesnt have parseIOS duration support will be available in v3: https://github.com/date-fns/date-fns/pull/3151
//        using "duration-fns" until date-fns has proper support
